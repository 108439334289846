import { createSlice } from "@reduxjs/toolkit";

export const relevamientoSlice = createSlice({
    name: 'relevamiento',
    initialState: {
        limpieza:{},
        limpiezaCestos:{},
        cesped:{},
        canteros:{},
        caminos:{},
        postaAerobica:{},
        caminoAreaSeca:{},
        patioJuegos:{},
        areaDeportiva:{},
        caniles:{},
        mobiliario:{},
        hora:"",
        isTipping:false
    },
    reducers: {
        createRelevamiento: (state, action) => {
            if(action.payload.limpieza){
                state.limpieza = action.payload.limpieza
            }
            if(action.payload.limpiezaCestos){
                state.limpiezaCestos = action.payload.limpiezaCestos
            }
            if(action.payload.espaciosverdes){
                state.espaciosverdes = action.payload.espaciosverdes
            }
            if(action.payload.mobiliario){
                state.mobiliario = action.payload.mobiliario
            }
            if(action.payload.espaciossecos){
                state.espaciossecos = action.payload.espaciossecos
            }
            if(action.payload.cesped){
                state.cesped = action.payload.cesped
            }
            if(action.payload.canteros){
                state.canteros = action.payload.canteros
            }
            if(action.payload.caminos){
                state.caminos = action.payload.caminos
            }
            if(action.payload.postaAerobica){
                state.postaAerobica = action.payload.postaAerobica
            }
            if(action.payload.patioJuegos){
                state.patioJuegos = action.payload.patioJuegos
            }
            if(action.payload.caniles){
                state.caniles = action.payload.caniles
            }
            if(action.payload.caminoAreaSeca){
                state.caminoAreaSeca = action.payload.caminoAreaSeca
            }
            if(action.payload.areaDeportiva){
                state.areaDeportiva = action.payload.areaDeportiva
            }
            state.hora = action.payload.hora
            state.fechaRelevado = action.payload.fechaRelevado
        },
        cleanRelevamiento: (state, action) => {
            state.limpieza = {}
            state.limpiezaCestos = {}
            state.cesped = {}
            state.canteros = {}
            state.caminos = {}
            state.postaAerobica = {}
            state.patioJuegos = {}
            state.areaDeportiva = {}
            state.caniles = {}
            state.mobiliario = {}
            state.caminoAreaSeca = {}
        },
    }
});

export const { createRelevamiento, cleanRelevamiento } = relevamientoSlice.actions;
export const getRelevamientoCache = (state) => state.relevamiento


