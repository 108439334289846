/* eslint-disable no-unused-vars */
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PersonIcon from "@mui/icons-material/Person";
import { SelectMultiple, Select, ButtonIcon, Divider, Input, PasswordInput } from "../../elementos";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CancelOutlined } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import Container from "@mui/material/Container";

const ListaDeAtributosClientes = ({ cliente, setcliente, poligonos, perfiles, onSubmit }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const id = searchParams.get("id");
  const [errorPassword, seterrorPassword] = useState({
    error: false,
    message: "",
  });
  const formatValuesForSelect = (name, valor) => {
    if (
      valor !== undefined &&
      valor !== null &&
      !(Array.isArray(valor) && valor.length === 0) &&
      !(typeof valor === "object" && Object.keys(valor).length === 0)
    ) {
      return valor?.map((element) => ({
        label: element?.nombre,
        value: element?.codigo,
      }));
    }
  };

  const handleCancel = () => {
    sessionStorage.removeItem("creandoUsuario");
    if (!id) {
      navigate(`/configuracion/usuarios`);
    } else {
      navigate(`/configuracion/usuario?id=${id}`);
    }
  };

  const changeValue = (value, key) => {
    setcliente((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (cliente) {
      sessionStorage.setItem("creandoUsuario", JSON.stringify(cliente));
    }
    if (
      cliente?.contrasena1 !== cliente?.contrasena2 &&
      cliente?.contrasena1?.length > 0 &&
      cliente?.contrasena2?.length > 0
    ) {
      seterrorPassword({
        error: true,
        message: "Las contraseñas son distintas.",
      });
    } else {
      seterrorPassword({
        error: false,
        message: "",
      });
    }
  }, [cliente]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!errorPassword.error) {
      onSubmit();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container component="main" maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Input
              label="Nombre"
              icon={<PersonIcon />}
              value={cliente?.nombre ? cliente?.nombre : ""}
              name="nombre"
              onChange={changeValue}
              type="text"
              restricciones={["alfanumerico"]}
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label="Apellido"
              icon={<PersonIcon />}
              value={cliente?.apellido}
              name="apellido"
              onChange={changeValue}
              type="text"
              restricciones={["alfanumerico"]}
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label="Email"
              icon={<MailOutlineIcon />}
              value={cliente?.email}
              name="email"
              onChange={changeValue}
              type="text"
              restricciones={["email"]}
              helperText="Ej correo@hotmail.com"
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label="Usuario"
              icon={<PersonIcon />}
              value={cliente?.usuario}
              name="usuario"
              onChange={changeValue}
              type="text"
              restricciones={["alfanumerico"]}
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label="Telefono"
              icon={<WhatsAppIcon />}
              helperText="Ej: 5491112341234. Sin tel colocar 0."
              name="telefono"
              value={cliente?.telefono}
              onChange={changeValue}
              type="tel"
              restricciones={["soloTelefono"]}
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Perfil"
              disabled={false}
              value={cliente?.perfil}
              defaultValue=""
              options={formatValuesForSelect("perfil", perfiles)}
              type="text"
              onChange={changeValue}
              name={"perfil"}
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectMultiple
              label="Poligono"
              disabled={false}
              defaultValue=""
              options={formatValuesForSelect("poligonos", poligonos)}
              type="text"
              onChange={(e) => changeValue(e, "poligonos")}
              elegidos={cliente?.poligonos ? cliente?.poligonos : []}
              name={"poligonos"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <PasswordInput
              text="Contraseña"
              value={cliente?.contrasena1}
              setcliente={setcliente}
              name={"contrasena1"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <PasswordInput
              text="Repita la contraseña"
              value={cliente?.contrasena2}
              setcliente={setcliente}
              name={"contrasena2"}
            />
          </Grid>
          {errorPassword.error && (
            <Grid item xs={12} md={4}>
              <h3 style={{ color: "#d33939" }}>{errorPassword.message}</h3>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ButtonIcon
              type="button"
              variant="contained"
              color="error"
              noLink
              handleClick={handleCancel}
              startIcon={<CancelOutlined />}
            >
              Cancelar
            </ButtonIcon>
            <ButtonIcon
              type="submit"
              variant="contained"
              color="success"
              noLink
              startIcon={<CheckIcon />}
              styleButton={{ marginLeft: "10px" }}
            >
              Guardar
            </ButtonIcon>
          </Grid>
        </Grid>
      </Container>
    </form>
  );
};

export default ListaDeAtributosClientes;
