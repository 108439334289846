/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, lazy, useCallback, useEffect, useState } from "react";
import "./index.css";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useGetSelfMutation } from "../../../store/api/endpoints/authApiSlice";
import { setCredentials } from "../../../store/slices/auth";
import {
  useGetAreasEspacioVerdeMutation,
  useGetCantidadDeAreasMutation,
} from "../../../store/api/endpoints/espaciosApiSlice";
import {
  Build,
  ButtonIcon,
  Loading,
  Error,
  HeaderVista,
  WarningFaltaAtributos,
  Volver,
  Divider,
} from "../../elementos/index";
import { getDateToday } from "../../../utils/getDateToday";
import { dateAndHourToday } from "../../../utils/getDateTodayString";
import { useCreateMutation, useCreateRelWithPhotosMutation } from "../../../store/api/endpoints/relevamientoApiSlice";
import { useGetAllOptionsMutation } from "../../../store/api/endpoints/optionsApiSlice";
import { options } from "../../../store/slices/options";
import inicializarObjetoGlobal from "../../../utils/configRelevamiento";
import ListAreasOFRelevamientos from "./ListaAreasDeRelevamientos";
import { Container, Grid } from "@mui/material";
import { espacioVerde } from "../../../store/slices/espacioverdes";
import { openFeedback } from "../../../store/slices/feedback";
import CheckIcon from "@mui/icons-material/Check";

const LazySectionAreasVerdes = lazy(() => import("./Areas Verdes/SectionAreasVerde"));
const LazySectionAreasSeca = lazy(() => import("./Areas Seca/SectionAreaSecas"));
const LazySectionlimpieza = lazy(() => import("./Limpieza/SectionLimpieza"));

export const RelevamientoScreen = () => {
  const [searchParams] = useSearchParams();
  const id = parseInt(searchParams.get("id"));
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [imagesRelevamient, setimagesRelevamient] = useState({});
  const [date, setDate] = useState(getDateToday());
  const [optionRelevamient, setoptionRelevamient] = useState("limpieza");
  const [Action, setAction] = useState(false);
  const [infoEspacioVerde, setInfoEspacioVerde] = useState({});
  const [config, setconfig] = useState(
    sessionStorage.getItem("configAttributesRequired") !== null &&
      sessionStorage.getItem("configAttributesRequired") !== "undefined" &&
      JSON.parse(sessionStorage.getItem("configAttributesRequired"))
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [areasConErrores, setAreasConErrores] = useState(
    sessionStorage.getItem("areasConErrores") !== null &&
      sessionStorage.getItem("areasConErrores") !== "undefined" &&
      JSON.parse(sessionStorage.getItem("areasConErrores"))
  );
  const [errorInArea, seterrorInArea] = useState(
    sessionStorage.getItem("errorInArea")
      ? JSON.parse(sessionStorage.getItem("errorInArea"))
      : {
          limpieza: true,
          area_verde: true,
          area_seca: false,
        }
  );
  const [rel_relevamiento, setrel_relevamiento] = useState();
  const [getAllOptions, responseOptions] = useGetAllOptionsMutation();
  const [getEspacio, responseEspacio] = useGetAreasEspacioVerdeMutation();
  const [getSelf, responseSelf] = useGetSelfMutation();
  const [postRelevamientoAPI, response] = useCreateMutation();
  const [postRelevamientoPhotos, responseRelPhotos] = useCreateRelWithPhotosMutation();
  const [getCantidadAreas, responseCantidadAreas] = useGetCantidadDeAreasMutation();

  useEffect(() => {
    if (sessionStorage.getItem("rel_relevamientos")) {
      setrel_relevamiento(JSON.parse(sessionStorage.getItem("rel_relevamientos")));
    } else {
      setrel_relevamiento({
        limpieza_general: {
          limpieza: {},
          cestos: {},
          vereda: {},
        },
        area_seca: {
          areaDeportiva: {},
          posta_aero: {},
          mobiliarios: {},
          patio_juegos: {},
          caminos: {},
          caniles: {},
        },
        area_verde: {
          cesped: {},
          canteros: {},
          riego: {},
        },
        espacio_verde: parseInt(searchParams.get("id")),
      });
    }
    sessionStorage.removeItem("rutaRelevamientos");
  }, []);

  /**
   * Valida si todas las areas estan completas
   * return @boolean true si hay una que no esta ocmpleta
   * return @boolean false si todo se completo
   */
  const checkerAreasSecas = useCallback((arr) => {
    if (arr) {
      return Object.values(arr).some((elemento) => {
        return Object.values(elemento).some((valor) => {
          return typeof valor === "boolean" && valor;
        });
      });
    }
  }, []);
  const checkIfAllAtributesAreFALSE = (arr) => {
    // Verificar que todos los valores del array sean del tipo boolean
    const areAllValuesBoolean = arr.filter((v) => typeof v === "boolean");

    // Verificar que todos los valores sean iguales a false
    const areAllValuesFalse = areAllValuesBoolean.every((v) => v === false);
    return areAllValuesFalse;
  };
  const [isError, setisError] = useState({});

  useEffect(() => {
    if (!id) {
      !searchParams.get("id") && navigate("/");
    }
    Promise.all([getAllOptions(), getEspacio(id), getSelf(sessionStorage.getItem("token")), getCantidadAreas(id)]);
  }, []);

  useEffect(() => {
    sessionStorage.setItem("rel_relevamientos", JSON.stringify(rel_relevamiento));
    sessionStorage.setItem("areasConErrores", JSON.stringify(areasConErrores));
  }, [rel_relevamiento]);

  useEffect(() => {
    if (responseEspacio.isSuccess) {
      setInfoEspacioVerde(infoEspacioVerde?.data?.dato);
      dispatch(espacioVerde(responseEspacio?.data?.espacioverde));
      if (
        !parseInt(sessionStorage.getItem("id")) ||
        parseInt(sessionStorage.getItem("id")) !== parseInt(id) ||
        sessionStorage.getItem("areasConErrores") === "{}" ||
        sessionStorage.getItem("areasConErrores") === "false"
      ) {
        sessionStorage.setItem("id", parseInt(searchParams.get("id")));
        /*       sessionStorage.removeItem("areasConErrores");
        sessionStorage.removeItem("errorInArea"); */
        /*         sessionStorage.removeItem("rel_relevamientos"); */
        let atributosRequeridos = inicializarObjetoGlobal(responseEspacio?.data);
        let atributosRequeridos2 = inicializarObjetoGlobal(responseEspacio?.data);

        setconfig(atributosRequeridos2);
        setAreasConErrores(atributosRequeridos);
        sessionStorage.setItem("configAttributesRequired", JSON.stringify(config));
      }
    }
    if (responseOptions.isSuccess) {
      dispatch(options(responseOptions?.data));
    }
    if (responseSelf?.isSuccess) {
      dispatch(
        setCredentials({
          id: responseSelf?.data?.usuario.id,
          nombre: responseSelf?.data?.usuario.nombre,
          permisos: responseSelf?.data?.usuario.permisos,
          token: sessionStorage.getItem("token"),
        })
      );
    }
  }, [responseEspacio.isSuccess, responseOptions.isSuccess, responseSelf?.isSuccess]);

  useEffect(() => {
    if (Action) createRelevamiento();
  }, [Action]);

  useEffect(() => {
    sessionStorage.setItem("areasConErrores", JSON.stringify(areasConErrores));
    if (areasConErrores?.area_seca?.caminos) {
      setisError({
        area_deportiva: checkerAreasSecas(areasConErrores?.area_seca?.area_deportiva),
        patio_juegos: checkerAreasSecas(areasConErrores?.area_seca?.patio_juegos),
        mobiliarios: checkerAreasSecas(areasConErrores?.area_seca?.mobiliarios),
        posta_aero: checkerAreasSecas(areasConErrores?.area_seca?.posta_aero),
        caminos: !checkIfAllAtributesAreFALSE(Object.values(areasConErrores?.area_seca?.caminos)),
        caniles: checkerAreasSecas(areasConErrores?.area_seca?.caniles),
      });
    }
    if (areasConErrores) {
      let booleanLimpieza = [
        !checkIfAllAtributesAreFALSE(Object?.values(areasConErrores?.limpieza_general?.limpieza)),
        !checkIfAllAtributesAreFALSE(Object?.values(areasConErrores?.limpieza_general?.cestos)),
        !checkIfAllAtributesAreFALSE(Object?.values(areasConErrores?.limpieza_general?.veredas)),
      ];

      seterrorInArea({
        limpieza: !checkIfAllAtributesAreFALSE(booleanLimpieza),
        area_verde: !Object.values({
          ...areasConErrores?.area_verde?.canteros,
          ...areasConErrores?.area_verde?.cesped,
          ...areasConErrores?.area_verde?.riego,
        }).every((x) => {
          return x === false;
        }),
      });
    }
  }, [areasConErrores]);

  useEffect(() => {
    if (isError) {
      seterrorInArea((prev) => ({
        ...prev,
        area_seca: !checkIfAllAtributesAreFALSE(Object.values(isError)),
      }));
    }
  }, [isError]);

  //Crea las rutas para el flujo de relevamiento

  useEffect(() => {
    if (responseCantidadAreas.isSuccess) {
      sessionStorage.removeItem("rutaRelevamientos");
      let arrayDesplegablesTotales = ["limpieza", "cestos", "veredas", "canteros", "cesped", "riego", "caminos"];
      Object.entries(responseCantidadAreas?.data).forEach((el) => {
        if (el[1] > 0) {
          let nombreArea = el[0].split("_")[1];
          arrayDesplegablesTotales.push(nombreArea);
        }
      });
      sessionStorage.setItem("rutaRelevamientos", JSON.stringify(arrayDesplegablesTotales));
    }
  }, [responseCantidadAreas.isSuccess]);

  //----

  useEffect(() => {
    sessionStorage.setItem("errorInArea", JSON.stringify(errorInArea));
  }, [errorInArea]);

  useEffect(() => {
    sessionStorage.setItem("configAttributesRequired", JSON.stringify(config));
  }, [config]);

  useEffect(() => {
    if (response.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "Relevamiento creado.",
        })
      );
      sessionStorage.removeItem("id");
      sessionStorage.removeItem("areasConErrores");
      sessionStorage.removeItem("configAttributesRequired");
      sessionStorage.removeItem("errorInArea");
      sessionStorage.removeItem("rel_relevamientos");
      navigate(`/espaciosverdes/relevamiento?id=${response.data?.relevamiento?.codigo}&espacio_verde=${id}`);
    }
    if (response.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: "Error al crear el relevamiento.",
        })
      );
    }
  }, [response.isSuccess, response.isError]);

  const handleOption = (option) => {
    setoptionRelevamient(option);
  };

  const loadDataOfRelevamiento = async () => {
    const { hora, fechaRelevado } = dateAndHourToday();
    setrel_relevamiento((prev) => ({
      ...prev,
      espacio_verde: parseInt(searchParams.get("id")),
      hora: hora,
      fecha_relevado: fechaRelevado,
      usuario: responseSelf?.data?.id,
    }));

    if (Object.values(errorInArea).some((el) => el)) {
      setOpenDialog(true);
    } else {
      createRelevamiento();
    }
  };

  const createRelevamiento = async () => {
    postRelevamientoAPI(rel_relevamiento);
    postRelevamientoPhotos(imagesRelevamient);
  };

  if (
    responseEspacio?.isLoading ||
    responseSelf?.isLoading ||
    responseOptions.isLoading ||
    responseCantidadAreas.isLoading ||
    response.isLoading ||
    responseRelPhotos.isLoading
  ) {
    return <Loading />;
  }

  if (
    responseEspacio?.data?.espacioverde[0] &&
    responseEspacio?.data?.espacioverde[0]?.en_obra === 1 &&
    responseEspacio.isSuccess
  ) {
    return <Build />;
  }
  if (responseOptions.isError || responseSelf.isError || responseEspacio.isError) {
    return <Error message={responseEspacio?.error?.data?.message} />;
  }

  if (responseEspacio.isSuccess && responseOptions.isSuccess && responseSelf.isSuccess) {
    if (responseEspacio?.data?.message?.includes("El espacio no pertenece a tu poligono")) {
      return <Error message={responseEspacio?.data?.message} />;
    } else {
      return (
        <Container component={"main"} maxWidth="lg">
          <HeaderVista
            start={<Volver label="Volver" to={`/espaciosverdes/detalle?id=${id}`} />}
            titulo={
              location.state
                ? `${location.state.label} del Espacio ${responseEspacio?.data?.espacioverde[0].nombre_eevv}`
                : "Nuevo Relevamiento en " + responseEspacio?.data?.espacioverde[0].nombre_eevv
            }
            end={
              <ButtonIcon variant="outlined" type="button" to={`/espaciosverdes/espacio?id=${id}`}>
                Datos del espacio
              </ButtonIcon>
            }
          />
          <ListAreasOFRelevamientos
            handleOption={handleOption}
            errorInArea={errorInArea}
            selected={optionRelevamient}
          />
          <Grid container spacing={2}>
            {optionRelevamient === "limpieza" && (
              <Suspense fallback={<Loading />}>
                <Grid item xs={12} md={12}>
                  <LazySectionlimpieza
                    config={config}
                    areasConErrores={areasConErrores}
                    setrel_relevamiento={setrel_relevamiento}
                    rel_relevamiento={rel_relevamiento}
                    setAreasConErrores={setAreasConErrores}
                    date={date}
                    setimagesRelevamient={setimagesRelevamient}
                    checker={checkIfAllAtributesAreFALSE}
                    setoptionRelevamient={setoptionRelevamient}
                  />
                </Grid>
              </Suspense>
            )}
            {optionRelevamient === "area_verde" && (
              <Suspense fallback={<Loading />}>
                <Grid item xs={12} md={12}>
                  <LazySectionAreasVerdes
                    config={config}
                    setrel_relevamiento={setrel_relevamiento}
                    rel_relevamiento={rel_relevamiento}
                    areasConErrores={areasConErrores}
                    setAreasConErrores={setAreasConErrores}
                    setimagesRelevamient={setimagesRelevamient}
                    checker={checkIfAllAtributesAreFALSE}
                    setoptionRelevamient={setoptionRelevamient}
                  />
                </Grid>
              </Suspense>
            )}
            {optionRelevamient === "area_seca" && (
              <Suspense fallback={<Loading />}>
                <Grid item xs={12} md={12}>
                  <LazySectionAreasSeca
                    setrel_relevamiento={setrel_relevamiento}
                    rel_relevamiento={rel_relevamiento}
                    areasConErrores={areasConErrores}
                    config={config}
                    errorInAreas={isError}
                    setisError={setisError}
                    setAreasConErrores={setAreasConErrores}
                    setimagesRelevamient={setimagesRelevamient}
                    checker={checkIfAllAtributesAreFALSE}
                    seterrorInArea={seterrorInArea}
                    setoptionRelevamient={setoptionRelevamient}
                  />
                </Grid>
              </Suspense>
            )}
            {openDialog && (
              <WarningFaltaAtributos
                title={"Faltan completar campos"}
                mensajeDeConfirmacion="Por favor, revisá los campos e intentalo nuevamente."
                setAction={setAction}
                setOpenDialog={setOpenDialog}
                openDialog={openDialog}
              />
            )}
            {response.isError && <Error message="Ocurrio un error al crear el relevamiento" />}
            <Divider />
            <Grid item xs={12} md={12}>
              <ButtonIcon
                type="submit"
                variant="contained"
                color="success"
                noLink
                handleClick={() => loadDataOfRelevamiento()}
                startIcon={<CheckIcon />}
                styleButton={{ marginLeft: "10px" }}
              >
                Crear relevamiento
              </ButtonIcon>
            </Grid>
          </Grid>
          <br />
        </Container>
      );
    }
  } else {
    return <h2>Ocurrio un error.</h2>;
  }
};
