/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { getDateToday, getLastMonth } from "../../../utils/getDateToday";
import "../../../assets/css/reportesScreen.css";
import { Container, Grid } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";

import "../../../assets/css/Filter.css";
import { useGetInformesMutation } from "../../../store/api/endpoints/informesApiSlice";
import { useGetSelfMutation } from "../../../store/api/endpoints/authApiSlice";

import { useDispatch } from "react-redux";
import { openFeedback } from "../../../store/slices/feedback";
import { HeaderVista, Pagination, Error, Loading, FiltroInformes, ButtonIcon } from "../../elementos/";

export const ListadoInformes = ({ comunas }) => {
  const [openFilter, setopenFilter] = useState(false);
  const [btnClicked, setbtnClicked] = useState(false);
  const [displayIconFilter, setdisplayIconFilter] = useState(true);
  const [page, setpage] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [informes, setinformes] = useState([]);
  const [cantRowPerPage, setcantRowPerPage] = useState(0);
  const [cantidadRegistros, setcantidadRegistros] = useState(0);
  const [getSelf, responseSelf] = useGetSelfMutation();
  const [getIformes, responseInformes] = useGetInformesMutation();
  const dispatch = useDispatch();
  const [filter, setfilter] = useState({
    dateFrom: getLastMonth(),
    dateTo: getDateToday(),
    usuario: ``,
    empresa: "",
    comunas: "",
  });
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    getSelf();
  }, []);

  useEffect(() => {
    if (responseSelf.isSuccess) {
      setfilter((prev) => ({
        ...prev,
        usuario: responseSelf?.data?.usuario?.codigo,
      }));
    }
  }, [responseSelf.isSuccess]);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      if (newWidth !== screenWidth) {
        setScreenWidth(newWidth);
        if (newWidth >= 700) {
          setdisplayIconFilter(false);
          setopenFilter(true);
        }
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);

  const recortarTexto = (texto, longitud) => {
    if (texto?.length > longitud) {
      return texto.substring(0, longitud) + "...";
    } else {
      return texto;
    }
  };

  useEffect(() => {
    const filterFormat = `usuario=${filter.usuario}&empresa=${filter.empresa}&dateTo=${filter.dateTo}&dateFrom=${filter.dateFrom}&comuna=${filter.comunas}`;
    if (btnClicked) {
      getIformes(filterFormat);
      setbtnClicked(false);
      const newWidth = window.innerWidth;
      if (newWidth < 700) {
        setopenFilter(false);
      }
    }
    if (setinformes.length === 0) {
      getIformes(filterFormat);
    }
  }, [btnClicked]);

  useEffect(() => {
    if (responseInformes.isSuccess) {
      setcantidadRegistros(responseInformes?.data?.informes.length);
    }
    if (responseInformes.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: "Error al obtener los datos!",
        })
      );
    }
  }, [responseInformes.isSuccess, responseInformes.isError]);

  if (responseInformes.isLoading) {
    return <Loading />;
  }

  if (responseInformes.isError) {
    return <Error />;
  }

  return (
    <Container component={"main"} maxWidth="lg">
      <Grid container spacing={2}>
        <HeaderVista
          titulo="Informes"
          end={
            <>
              {displayIconFilter && (
                <ButtonIcon
                  variant="outlined"
                  size="large"
                  color="neutral"
                  handleClick={() => setopenFilter(!openFilter)}
                  startIcon={<FilterListIcon fontSize="large" />}
                  noLink
                >
                  Filtrar
                </ButtonIcon>
              )}
            </>
          }
        />

        <div className="containerFilter">
          {openFilter && (
            <div style={{ width: "100%", position: "relative", display: "flex", justifyContent: "center", zIndex: 1 }}>
              <FiltroInformes setFilter={setfilter} filter={filter} btnClicked={setbtnClicked} comunas={comunas} />
            </div>
          )}
        </div>
        <Grid container justifyContent="space-evenly" spacing={2}>
          {responseInformes?.data?.informes?.map((el, index) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              key={index}
              style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <Grid item xs={6} sm={6} md={6} style={{ width: "60%", maxWidth: "200px" }}>
                <p style={{ textAlign: "start" }}>{recortarTexto(`EV${el.espacio_verde}_T${el.fecha_relevado}`, 20)}</p>
              </Grid>
              <Grid item xs={6} sm={6} md={6} style={{ width: "35%", maxWidth: "200px" }}>
                <ButtonIcon
                  variant="outlined"
                  size="large"
                  color="neutral"
                  noLink
                  startIcon={<FileDownloadIcon color="primary" />}
                >
                  Descargar
                </ButtonIcon>
              </Grid>
            </Grid>
          ))}
          {cantidadRegistros === 0 && (
            <Grid item xs={12} md={12}>
              <span>No se encontraron registros</span>
            </Grid>
          )}
          <Pagination
            setrowsPerPages={setcantRowPerPage}
            label="Usuarios"
            setpage={setpage}
            page={page}
            rowsPerPage={cantRowPerPage}
            total={10}
            nextPage={false}
          />
        </Grid>
      </Grid>
      <br />
    </Container>
  );
};
