import * as React from "react";
import TablePagination from "@mui/material/TablePagination";
import "../../assets/css/Pagination.css";
import { Container, Grid } from "@mui/material";

export default function Pagination({ setpage, setrowsPerPages, rowsPerPage, page, total, label }) {
  const handleChangePage = (e, newPage) => {
    setpage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setrowsPerPages(parseInt(event.target.value, 10));
    setpage(0);
  };

  return (
    <Container component={"main"} maxWidth="sm">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TablePagination
            component="div"
            count={total}
            rowsPerPageOptions={[12, 24, 36, 48, 60, 72, 84, 96]}
            page={page}
            style={{ display: "flex", justifyContent: "center" }}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={label}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
