import { createSlice } from "@reduxjs/toolkit";

export const poligonosSlice = createSlice({
  name: "poligonos",
  initialState: {
    poligonos: [],
  },
  reducers: {
    poligonos: (state, action) => {
      state.poligonos = action.payload;
    },
  },
});

export const { poligonos } = poligonosSlice.actions;
export const getPoligonos = (state) => state.poligonos.poligonos;
