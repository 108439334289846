/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Loading from "../../elementos/Loading";
import { openFeedback } from "../../../store/slices/feedback";

import { useCreateClienteMutation } from "../../../store/api/endpoints/clientesApiSlice";
import HeaderVista2 from "../../elementos/HeaderVista2";
import Volver from "../../elementos/Volver";
import "../../../assets/css/vistaFormulario.css";

import ListaDeAtributosClientes from "./ListaDeAtributosClientes";
import { useGetAllPoligonosMutation } from "../../../store/api/endpoints/espaciosApiSlice";
import { useGetAllPerfilesMutation } from "../../../store/api/endpoints/perfilesApiSlice";

export const ClienteNuevo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [poligonos, setpoligonos] = useState();
  const [allPerfiles, setAllPerfiles] = useState({});
  const [getAllPerfiles, responsePerfiles] = useGetAllPerfilesMutation();
  const [getAllPoligonos, responsePoligonos] = useGetAllPoligonosMutation();
  const [
    createCliente,
    {
      isLoading: isLoadingCreaCliente,
      isSuccess: isSuccessCreaCliente,
      isError: isErrorCreaCliente,
      error: errorDataCreaCliente,
    },
  ] = useCreateClienteMutation();
  const [cliente, setcliente] = useState();

  const handleSubmit = () => {
    createCliente(cliente);
  };

  useEffect(() => {
    if (!sessionStorage.getItem("creandoUsuario")) {
      setcliente(JSON.parse(sessionStorage.getItem("creandoUsuario")));
    } else {
      setcliente({
        nombre: "",
        apellido: "",
        email: "",
        telefono: "",
        usuario: "",
        poligonos: [],
        perfil: "",
        contrasena1: "",
        contrasena2: "",
      });
    }
    Promise.all([getAllPoligonos(), getAllPerfiles()]);
  }, []);

  useEffect(() => {
    if (isSuccessCreaCliente) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha creado el usuario!",
        })
      );
      navigate("/configuracion/usuarios");
    }

    if (isErrorCreaCliente || responsePoligonos.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: errorDataCreaCliente.data.message || responsePoligonos?.error?.data?.message,
        })
      );
    }
    if (responsePoligonos.isSuccess) {
      setpoligonos(responsePoligonos?.data);
    }
    if (responsePerfiles.isSuccess) {
      setAllPerfiles(responsePerfiles?.data?.perfiles);
    }
  }, [
    isSuccessCreaCliente,
    responsePerfiles.isSuccess,
    isErrorCreaCliente,
    responsePoligonos.isSuccess,
    responsePoligonos.isError,
  ]);

  if (
    isLoadingCreaCliente ||
    responsePoligonos.isLoading ||
    responsePerfiles.isLoading ||
    responsePoligonos.isUninitialized
  ) {
    return <Loading />;
  }
  return (
    <>
      <HeaderVista2
        start={<Volver label="Volver" to="/configuracion/usuarios" />}
        titulo={"Crear Usuario"}
        end={<></>}
      />
      <ListaDeAtributosClientes
        cliente={cliente}
        setcliente={setcliente}
        valuesClient={[]}
        poligonos={poligonos}
        perfiles={allPerfiles}
        type="crear"
        onSubmit={handleSubmit}
      />
      <br />
    </>
  );
};
