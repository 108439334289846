import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";

import { openFeedback } from "../../store/slices/feedback";

import { Loading, Input, ButtonIcon } from "../elementos/";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export default function ConfirmDelete(props) {
  const dispatch = useDispatch();

  const [openDialog, setOpenDialog] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const navigate = useNavigate();

  const handleOpen = () => {
    setInputValue("");
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleChangeInputValue = (value) => {
    if (value.toLowerCase() === "eliminar") {
      setInputError(false);
    } else {
      setInputError(true);
    }

    setInputValue(value);
  };

  const handleConfirmDelete = () => {
    if (inputValue.toLowerCase() === "eliminar") {
      props.slice(props.id);
    }
  };

  useEffect(() => {
    if (props.response.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha eliminado el registro!",
        })
      );
      handleClose();
      navigate(props.returnUrl);
    }

    if (props.response.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: props.response.error?.message ? props.response.error?.message : "No se pudo eliminar el registro.",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.response.isSuccess, props.response.isError]);

  return (
    <div>
      {props.soloIcono && (
        <ButtonIcon
          size="medium"
          variant={"outlined"}
          color="error"
          handleClick={handleOpen}
          noLink
          startIcon={<DeleteOutlineIcon />}
        />
      )}
      {!props.soloIcono && (
        <ButtonIcon
          handleClick={handleOpen}
          size="medium"
          variant={"outlined"}
          color="error"
          noLink
          startIcon={<DeleteOutlineIcon />}
        >
          Eliminar
        </ButtonIcon>
      )}

      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>¿Eliminar registro?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas eliminar el registro?
            <br />
            Por favor ingresa la palabra <b>eliminar</b> para continuar.
          </DialogContentText>
          <Input
            type="text"
            fullWidth
            value={inputValue}
            variant="standard"
            error={inputError}
            onChange={handleChangeInputValue}
          />
        </DialogContent>
        {!props.response.isLoading && (
          <DialogActions>
            <Button variant="contained" color="neutral" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="contained" color="error" onClick={handleConfirmDelete} startIcon={<DeleteOutlineIcon />}>
              Eliminar
            </Button>
          </DialogActions>
        )}
        {props.response.isLoading && (
          <DialogActions>
            <Loading />
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
