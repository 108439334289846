/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, lazy, Suspense, useRef } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useGetEspacioVerdeMutation } from "../../../../store/api/endpoints/espaciosApiSlice";
import { Error, Loading, Volver, ButtonIcon, HeaderVista } from "../../../elementos/";
import { useState } from "react";
import { Container, Grid } from "@mui/material";
import DirectionsIcon from "@mui/icons-material/Directions";
const Mapa = lazy(() => import("./Mapa"));

export const InfoEspacio = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const id = searchParams.get("id");
  const [espacioVerde, setespacioVerde] = useState();
  const routeBack = useRef(null);
  useEffect(() => {
    routeBack.current = location.pathname.includes("/espaciosverdes")
      ? `/espaciosverdes/detalle?id=${id}`
      : `/inspecciones/detalles?id=${id}`;
  }, []);
  const [getEspacioVerde, responseEspacioVerde] = useGetEspacioVerdeMutation();
  useEffect(() => {
    getEspacioVerde(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (responseEspacioVerde.isSuccess) {
      setespacioVerde(responseEspacioVerde?.data?.dato);
    }
  }, [responseEspacioVerde.isSuccess]);

  const redirectGoogleMaps = () => {
    window.open(
      `https://www.google.de/maps/search/?api=1&query=${espacioVerde?.latitud},${espacioVerde?.longitud}`,
      "_blank"
    );
  };

  if (responseEspacioVerde.isLoading || responseEspacioVerde.isUninitialized || !espacioVerde) {
    return <Loading />;
  }

  if (responseEspacioVerde.isError) {
    return <Error />;
  }

  if (!responseEspacioVerde?.data?.dato?.latitud || !responseEspacioVerde?.data?.dato?.longitud) {
    return (
      <Container component={"main"} maxWidth="lg">
        <Grid cointaine xs={12} md={12}>
          <span>El espacio no tiene latitud y longitud</span>
        </Grid>
      </Container>
    );
  }

  return (
    <Container component={"main"} maxWidth="lg">
      <HeaderVista
        start={<Volver label="Volver" to={routeBack.current} />}
        titulo={responseEspacioVerde?.data?.dato?.nombre_eevv}
        end={
          <>
            <ButtonIcon
              style={{ width: "100%", maxWidth: "500px", fontSize: "0.67rem" }}
              noLink
              handleClick={() => redirectGoogleMaps()}
              size={"small"}
              variant={"contained"}
              endIcon={<DirectionsIcon fontSize="large" />}
              styleButton={{ fontSize: "0.696rem", padding: "8px 3px" }}
            >
              Abrir mapa
            </ButtonIcon>
          </>
        }
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <p>{responseEspacioVerde?.data?.dato?.direccion}</p>
        </Grid>
        <Grid item xs={6} md={6}>
          <p style={{ fontSize: "13px", fontWeight: "bold" }}>
            Superficie seca {responseEspacioVerde?.data?.dato?.superficie_seca}m2
          </p>
        </Grid>
        <Grid item xs={6} md={6}>
          <p style={{ fontSize: "13px", fontWeight: "bold" }}>
            Superficie Verde {responseEspacioVerde?.data?.dato?.superficie_verde}m2
          </p>
        </Grid>
      </Grid>

      <Grid container height={700} spacing={2}>
        <Grid item xs={12} md={12}>
          <Suspense fallback={<Loading />}>
            <Mapa espacioVerde={espacioVerde} />
          </Suspense>
        </Grid>
      </Grid>
    </Container>
  );
};
