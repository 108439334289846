import YardIcon from "@mui/icons-material/Yard";
import DownloadIcon from "@mui/icons-material/Download";
import SettingsIcon from "@mui/icons-material/Settings";
import FolderIcon from '@mui/icons-material/Folder';
import { PERMISOS } from "./permisos";
export const SidebarData = [
  {
    id: 1,
    label: "Inspecciones",
    icono: (
      <YardIcon
        sx={{
          color: "green",
        }}
      />
    ),
    path: "/inspecciones",
    desplegable: false,
    permiso: [
      PERMISOS.ADMINISTRADOR,
      PERMISOS.VISUALIZADOR_CON_REPORTES,
      PERMISOS.INSPECTOR,
      PERMISOS.VISUALIZADOR_SIN_REPORTES,
    ],
  },
  {
    id: 2,
    label: "Descargas",
    icono: (
      <DownloadIcon
        sx={{
          color: "black",
        }}
      />
    ),
    desplegable: true,
    items: [
      { to: "/descargas/inspecciones", label: "Inspecciones" },
      /*       { to: "/configuracion/reportes", label: "Descarga completa" }, */
    ],
    permiso: [PERMISOS.ADMINISTRADOR, PERMISOS.VISUALIZADOR_CON_REPORTES],
  },
  {
    id: 3,
    label: "Logs",
    icono: (
      <FolderIcon
        sx={{
          color: "black",
        }}
      />
    ),
    desplegable: true,
    items: [
      { to: "/logs/ultimosinformes", label: "Ultimos informes" },
      /*       { to: "/configuracion/reportes", label: "Descarga completa" }, */
    ],
    permiso: [PERMISOS.ADMINISTRADOR],
  },
  /* {
    id: 1,
    label: "Espacios Verdes",
    icono: (
      <YardIcon
        sx={{
          color: "green",
        }}
      />
    ),
    path: "/",
    desplegable: false,
    permiso: [
      PERMISOS.INSPECTOR,
      PERMISOS.ADMINISTRADOR,
      PERMISOS.VISUALIZADOR_CON_REPORTES,
      PERMISOS.VISUALIZADOR_SIN_REPORTES,
    ],
  }, */
  {
    id: 4,
    label: "Configuracion",
    icono: (
      <SettingsIcon
        sx={{
          color: "black",
        }}
      />
    ),
    desplegable: true,
    items: [
      /*       { to: "/configuracion/usuarios", label: "Usuarios" }, */
      { to: "/configuracion/inspecciones", label: "Inspecciones" },
      /*     { to: "/configuracion/cuadrantes", label: "Cuadrantes y Poligonos" },
      { to: "/configuracion/hora-relevamiento", label: "Hora max editar relevamiento" }, */
    ],
    permiso: [PERMISOS.ADMINISTRADOR],
  },
  /* {
    id: 3,
    label: "Informes",
    path: "/informes",
    icono: (
      <DownloadIcon
        sx={{
          color: "black",
        }}
      />
    ),
    desplegable: false,
    permiso: [PERMISOS.ADMINISTRADOR, PERMISOS.VISUALIZADOR_CON_REPORTES],
  }, */

  /*  {
    id: 4,
    label: "Inspecciones",
    path: "/Inspecciones",
    icono: (
      <TextSnippetIcon
        sx={{
          color: "black",
        }}
      />
    ),
    desplegable: true,
    items: [{ to: "/informes", label: "Inspecciones" }],
  }, */
  /*  {
    id: 5,
    label: "",
    path: "/hora-relevamiento",
    icono: (
      <AccessTimeIcon
        sx={{
          color: "black",
        }}
      />
    ),
    desplegable: false,
    permiso: [PERMISOS.ADMINISTRADOR, PERMISOS.VISUALIZADOR_CON_REPORTES],
  }, */
];
