import React from "react";
import ConstructionIcon from "@mui/icons-material/Construction";

const Build = () => {
  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
        <h2 style={{ marginLeft: "8%" }}>Espacio verde en obra</h2>
      </div>
      <ConstructionIcon fontSize="large" />
    </div>
  );
};

export default Build;
