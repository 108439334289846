import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { store } from "./store";

import Feedback from "./components/elementos/Feedback";

const theme = createTheme({
  palette: {
    dark: {
      main: "#6c757d",
      contrastText: "#fff",
      dark: "#6c757d",
    },
    neutral: {
      main: "#6c757d",
      contrastText: "#fff",
      dark: "#6c757d",
    },
  },
});

/**
 * No imprime console.logs en ENV Prod
 */
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const rootNode = document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
        <Feedback />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  rootNode
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
