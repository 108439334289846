import { Autocomplete, TextField } from "@mui/material";
import React from "react";

const AutoComplete = ({ options, label, disabled, handleWrite, styles, handleSelect, value }) => {
  const onInputChange = async (event, value, reason) => {
    if (value) {
      handleWrite(value);
    }
  };
  const HandleSelector = (event, value) => {
    handleSelect(value);
  };

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={options}
      sx={[styles,{ marginTop: "15px" }]}
      freeSolo
      value={value}
      disabled={disabled}
      onInputChange={onInputChange}
      onChange={(e, value) => HandleSelector(e, value)}
      renderInput={(params) => <TextField {...params} label={label} style={{ styles }} />}
    />
  );
};

export default AutoComplete;
