import React from "react";
import Input from "../../../../elementos/Input";
import { Container, Grid } from "@mui/material";

export const Mobiliario = ({ isDisabled, areas, setareas, index, options }) => {
  const handleChange = (value, key) => {
    setareas((prev) => ({
      ...prev,
      mobiliarios: {
        ...prev.mobiliarios,
        [index]: {
          ...prev.mobiliarios[index],
          [key]: value,
        },
      },
    }));
  };
  return (
    <Container component={"main"} maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <h3>Mobiliario {++index}</h3>
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="tel"
            value={areas?.bancos_tot}
            disabled={isDisabled}
            label="Cantidad de bancos"
            variant="standard"
            onChange={handleChange}
            name="bancos_tot"
            restricciones={["soloNumeros"]}
            min={0}
            max={200}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="number"
            value={areas?.bancos_defectuosos}
            disabled={isDisabled}
            label="Bancos defectuosos"
            variant="standard"
            onChange={handleChange}
            name="bancos_defectuosos"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="text"
            value={areas?.bancos_grafiteados}
            disabled={isDisabled}
            label="Bancos grafiteados"
            variant="standard"
            onChange={handleChange}
            name="bancos_grafiteados"
            restricciones={["soloNumeros"]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="text"
            value={areas?.bebederos_tot}
            disabled={isDisabled}
            label="Bebederos totales"
            variant="standard"
            onChange={handleChange}
            name="bebederos_tot"
            restricciones={["soloNumeros"]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="text"
            value={areas?.bebederos_defectuosos}
            disabled={isDisabled}
            label="Bebederos defectuosos"
            variant="standard"
            onChange={handleChange}
            name="bebederos_defectuosos"
            restricciones={["soloNumeros"]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="text"
            value={areas?.bebederos_grafiteados}
            disabled={isDisabled}
            label="Bebederos grafiteados"
            variant="standard"
            onChange={handleChange}
            name="bebederos_grafiteados"
            restricciones={["soloNumeros"]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="text"
            value={areas?.mesas_tot}
            disabled={isDisabled}
            label="Mesas totales"
            variant="standard"
            onChange={handleChange}
            name="mesas_tot"
            restricciones={["soloNumeros"]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="text"
            value={areas?.mesas_defectuosas}
            disabled={isDisabled}
            label="Mesas defectuosas"
            variant="standard"
            onChange={handleChange}
            name="mesas_defectuosas"
            restricciones={["soloNumeros"]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="text"
            value={areas?.mesas_grafiteadas}
            disabled={isDisabled}
            label="Mesas grafiteadas"
            variant="standard"
            onChange={handleChange}
            name="mesas_grafiteadas"
            restricciones={["soloNumeros"]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="tel"
            value={areas?.bicicleteros_tot}
            disabled={isDisabled}
            label="Cantidad bicicleteros"
            variant="standard"
            onChange={handleChange}
            name="bicicleteros_tot"
            restricciones={["soloNumeros"]}
            min={0}
            max={200}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="tel"
            value={areas?.bicicleteros_defectuosos}
            disabled={isDisabled}
            label="Cantidad defectuosos"
            variant="standard"
            onChange={handleChange}
            name="bicicleteros_defectuosos"
            restricciones={["soloNumeros"]}
            min={0}
            max={200}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="tel"
            value={areas?.bicicleteros_grafiteados}
            disabled={isDisabled}
            label="Cantidad grafiteados"
            variant="standard"
            onChange={handleChange}
            name="bicicleteros_grafiteados"
            restricciones={["soloNumeros"]}
            min={0}
            max={200}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="tel"
            value={areas?.carteles_tot}
            disabled={isDisabled}
            label="Cantidad de carteles"
            variant="standard"
            onChange={handleChange}
            name="carteles_tot"
            restricciones={["soloNumeros"]}
            min={0}
            max={200}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="text"
            value={areas?.carteles_grafiteados}
            disabled={isDisabled}
            label="Carteles grafiteados"
            variant="standard"
            onChange={handleChange}
            name="carteles_grafiteados"
            restricciones={["soloNumeros"]}
            min={0}
            max={200}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="text"
            value={areas?.carteles_defectuosos}
            disabled={isDisabled}
            label="Carteles defectuosos"
            variant="standard"
            onChange={handleChange}
            name="carteles_defectuosos"
            restricciones={["soloNumeros"]}
            min={0}
            max={200}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="text"
            value={areas?.ploteo_defectuosos}
            disabled={isDisabled}
            label="Ploteo defectuosos"
            variant="standard"
            onChange={handleChange}
            name="ploteo_defectuosos"
            restricciones={["soloNumeros"]}
          />
        </Grid>
      </Grid>
      <br />
    </Container>
  );
};
