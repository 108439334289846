import React from "react";
import Input from "../../../../elementos/Input";
import Select from "../../../../elementos/Select";
import { Container, Grid } from "@mui/material";

export const AreaDeportiva = ({ isDisabled = true, index, areas, setareas, dataAllAtributes, options }) => {
  let idAMostrar = index;
  idAMostrar++;
  const handleChange = (value, key) => {
    setareas((prev) => ({
      ...prev,
      areaDeportiva: {
        ...prev.areaDeportiva,
        [index]: {
          ...prev.areaDeportiva[index],
          [key]: value,
        },
      },
    }));
  };
  return (
    <Container component={"main"} maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <h3>{areas?.descripcion ? areas?.descripcion : `Area deportiva ${idAMostrar}`}</h3>
        </Grid>
        <Grid item xs={12} md={12}>
          <Input
            fullWidth
            type="tel"
            label="Cantidad de equipos"
            value={areas?.cant_equipos_tot}
            variant="standard"
            onChange={handleChange}
            disabled={isDisabled}
            restricciones={["soloNumeros"]}
            name="cant_equipos_tot"
            min={0}
            max={200}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="Tipo area"
            value={areas?.area}
            defaultValue=""
            options={dataAllAtributes?.tipo_area_deportiva?.map((element) => ({
              label: element.descripcion,
              value: element.codigo,
            }))}
            type="text"
            onChange={handleChange}
            name={"area"}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="number"
            label="Equipos defectuosos"
            value={areas?.equipos_defectuosos}
            variant="standard"
            onChange={handleChange}
            disabled={isDisabled}
            restricciones={["alfanumerico"]}
            name="equipos_defectuosos"
            autoFocus
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="number"
            label="Equipos con falta de pintura"
            value={areas?.equipos_falta_pintura}
            variant="standard"
            onChange={handleChange}
            disabled={isDisabled}
            restricciones={["alfanumerico"]}
            name="equipos_falta_pintura"
            autoFocus
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="Estado de solado"
            value={areas?.estado_solado}
            defaultValue=""
            options={options?.porcentaje}
            onChange={handleChange}
            name={"estado_solado"}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="Limpieza"
            value={areas?.limpieza}
            defaultValue=""
            type="text"
            options={options?.limpieza}
            onChange={handleChange}
            name={"tipo_reja"}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="Tejido perimetral"
            value={areas?.tejido_perimetral}
            defaultValue=""
            type="text"
            onChange={handleChange}
            options={options?.porcentaje}
            name={"tejido_perimetral"}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      <br />
    </Container>
  );
};
