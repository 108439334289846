import React from "react";
import ButtonIcon from "../../elementos/ButtonIcon";
import SearchIcon from "@mui/icons-material/Search";
import { Container } from "@mui/material";
import { shallowEqual, useSelector } from "react-redux";
import { getCuadrantes } from "../../../store/slices/cuadrantes";

const ListaCuadrantes = () => {
  const cuadrantes = useSelector(getCuadrantes, shallowEqual);
  return (
    <Container component="main" maxWidth="lg" className="tableContainer">
      <table className="formato1" cellSpacing="0" cellPadding="0">
        <thead>
          <tr style={{ height: "10%" }}>
            <th align="left">Nombre </th>
            <th align="center">Ver</th>
          </tr>
        </thead>
        <tbody>
          {cuadrantes?.length > 0 ? (
            cuadrantes?.map((cuadrante, index) => {
              return (
                <tr key={index} style={{ height: "10%" }}>
                  <td align="left">{cuadrante.nombre}</td>
                  <td align="center">
                    <ButtonIcon
                      variant="outlined"
                      size="small"
                      arialLabel={cuadrante.nombre}
                      color="primary"
                      startIcon={<SearchIcon />}
                      to={"/configuracion/cuadrante?id=" + cuadrante.codigo}
                      marginRight
                    />
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="4">No se encontraron registros</td>
            </tr>
          )}
        </tbody>
      </table>
    </Container>
  );
};

export default ListaCuadrantes;
