/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BodyProgramarInspeccion from "./BodyProgramarInspeccion";
import { useDispatch } from "react-redux";
import { useCreateProgramacionMutation } from "../../../../store/api/endpoints/informesApiSlice";
import { HeaderVista, ButtonIcon, Loading, Volver, Divider } from "../../../elementos";
import { openFeedback } from "../../../../store/slices/feedback";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { completeDateAndNotEqualDates } from "../../../../utils/validateDate";

const NewProgramacionInspeccion = () => {
  const [error, seterror] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [programarInspeccion, resposneCreate] = useCreateProgramacionMutation();
  const [programacion, setprogramacion] = useState({
    fecha_desde: "",
    fecha_hasta: "",
    semana: "",
    mes: "",
    anio: new Date().getFullYear(),
  });

  const handleCreate = () => {
    if (
      !programacion.fecha_desde ||
      !programacion.fecha_hasta ||
      !programacion.semana ||
      !programacion.mes ||
      !programacion.anio
    ) {
      dispatch(
        openFeedback({
          severity: "error",
          message: "Debe completar todos los campos.",
        })
      );
      return;
    } 
    if(error){
      dispatch(
        openFeedback({
          severity: "error",
          message: "Los valores ingresados son invalidos",
        })
      );
      return;
    }else {
      programarInspeccion(programacion);
    }
  };

  const handleCancel = () => {
    navigate("/configuracion/inspecciones");
  };

  useEffect(() => {
    if (resposneCreate.isSuccess) {
      navigate(`/configuracion/inspecciones`);
    }
    if (resposneCreate.isError) {
      setTimeout(() => {
        dispatch(
          openFeedback({
            severity: "error",
            message: resposneCreate?.error?.data,
          })
        );
      }, 800);
    }
  }, [resposneCreate.isSuccess, resposneCreate.isError]);

  if (resposneCreate.isLoading) {
    return <Loading />;
  }

  //Editar fecha desde , hasta y semana
  return (
    <Container component={"main"} maxWidth="lg">
      <HeaderVista
        titulo="Periodo de inspeccion"
        start={<Volver label="Volver" to={`/configuracion/inspecciones`} />}
      />
      <BodyProgramarInspeccion programacion={programacion} setprogramacion={setprogramacion} seterror={seterror}/>
      {completeDateAndNotEqualDates(programacion) &&(
        <p style={{ color: "orange", fontSize: "1rem", fontWeight: "bold" }}>Las fechas desde y hasta son iguales.</p>
      )}
      <Divider />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-evenly",
        }}
      >
        <ButtonIcon
          startIcon={<CloseIcon />}
          styleButton={{ color: "#D32F2F" }}
          size="small"
          color="error"
          variant="outlined"
          noLink
          handleClick={handleCancel}
        >
          Cancelar
        </ButtonIcon>
        <ButtonIcon
          startIcon={<CheckIcon />}
          styleButton={{ color: "#7bb57d" }}
          size="small"
          noLink
          handleClick={() => handleCreate()}
          variant="outlined"
          color="success"
        >
          Crear
        </ButtonIcon>
      </Box>
      <br />
    </Container>
  );
};

export default NewProgramacionInspeccion;
