/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useGetSelfMutation } from "../../../../store/api/endpoints/authApiSlice";
import Input from "../../../elementos/Input";
import Loading from "../../../elementos/Loading";
import "../../../../assets/css/miPerfilScreen.css";
import HeaderVista2 from "../../../elementos/HeaderVista2";
import { Container, Grid } from "@mui/material";
import { useGetAllPerfilesMutation } from "../../../../store/api/endpoints/perfilesApiSlice";

export const MiPerfilScreen = () => {
  const [permiso, setpermiso] = useState();
  const [getSelf, responseSelf] = useGetSelfMutation({
    pollingInterval: 3000,
    refetchOnMountOrArgChange: true,
    skip: false,
  });
  const [getPerfiles, responsePerfiles] = useGetAllPerfilesMutation({
    pollingInterval: 3000,
    refetchOnMountOrArgChange: true,
    skip: false,
  });

  useEffect(() => {
    getSelf(sessionStorage.getItem("token"));
    getPerfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (responsePerfiles.isSuccess && responseSelf.isSuccess) {
      setpermiso(
        responsePerfiles.data.perfiles
          .filter((perfil) => perfil.codigo === responseSelf.data.usuario.perfil)
          .map((perfil) => perfil.nombre)
          .join("")
      );
    }
  }, [responsePerfiles.isSuccess, responseSelf.isSuccess]);

  if (responseSelf?.isLoading) {
    return <Loading />;
  }
  if (responseSelf.isSuccess) {
    return (
      <Container component="main" maxWidth="lg">
        <HeaderVista2 start={<></>} titulo={"Mi perfil"} end={<></>} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Input
              label="Nombre"
              value={responseSelf?.data?.usuario?.nombre + " " + responseSelf?.data?.usuario?.apellido}
              type="text"
              restricciones={["alfanumerico"]}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label="Usuario"
              value={responseSelf?.data?.usuario?.usuario}
              type="text"
              restricciones={["alfanumerico"]}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label="Telefono"
              value={`+${responseSelf?.data?.usuario?.telefono}`}
              type="tel"
              restricciones={["soloNumeros"]}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label="Email"
              value={responseSelf?.data?.usuario?.email}
              type="text"
              restricciones={["alfanumerico"]}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label="Poligono"
              value={
                responseSelf?.data?.poligonos.length > 0
                  ? responseSelf?.data?.poligonos
                  : "No tiene asociado ningun poligono"
              }
              type="text"
              restricciones={["alfanumerico"]}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input label="Perfil" value={permiso} type="text" restricciones={["alfanumerico"]} disabled />
          </Grid>
        </Grid>
        <br />
      </Container>
    );
  } else {
    return <h2>Ocurrio un error.</h2>;
  }
};
