import React from "react";
import { Desplegable } from "../../../../elementos/Desplegable/Desplegable";
import { NA } from "../../../../elementos/NA";

export const CardAreaVerde = ({
  option,
  setoption,
  areas,
  isEditable,
  setareas,
  options,
  dataAllAtributes,
  title,
  component,
  areaSelected,
}) => {
  let cantidadAreas = typeof areas === "object" ? Object.values(areas).length : 0;
  areaSelected.current = title;
  return (
    <Desplegable text={`${title} (${cantidadAreas})`} option={option} setoption={setoption} name={title}>
      {cantidadAreas > 0 ? (
        <div>
          {Object.values(areas).map((area, index) =>
            React.cloneElement(component, {
              key: index,
              areas: area,
              index: index,
              setareas: setareas,
              isDisabled: isEditable,
              options: options,
              setoption: setoption,
              option: option,
              dataAllAtributes: dataAllAtributes,
            })
          )}
        </div>
      ) : (
        <NA type={title} />
      )}
    </Desplegable>
  );
};
