/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import "../../../../assets/css/vistaFormulario.css";
import Loading from "../../../elementos/Loading";
import ButtonIcon from "../../../elementos/ButtonIcon2";
import { ConfirmAction } from "../../../elementos/ConfirmAction";

import { openFeedback } from "../../../../store/slices/feedback";
import { useGetSelfMutation } from "../../../../store/api/endpoints/authApiSlice";
import { useGetAllOptionsMutation } from "../../../../store/api/endpoints/optionsApiSlice";
import {
  useCreateAreaEspacioMutation,
  useCreateEspacioVerdeMutation,
  useGetAllAtributesOfAreasMutation,
} from "../../../../store/api/endpoints/espaciosApiSlice";

import FormNuevoEspacio from "./FormNuevoEspacio";
import { AgregarAreas } from "./AgregarAreas";
import { Container, Grid } from "@mui/material";
import HeaderVista2 from "../../../elementos/HeaderVista2";
import { Volver } from "../../../elementos";

export const EspacioVerdeNuevo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [atributesForAreas, setAtributesForAreas] = useState();
  const [getAllOptions, responseOptions] = useGetAllOptionsMutation();
  const [getSelf, responseSelf] = useGetSelfMutation();
  const [createEspacio, responseCreateEspacio] = useCreateEspacioVerdeMutation();
  const [createAreas, responseCreateAreas] = useCreateAreaEspacioMutation();
  const [getAllAtributesOfAreas, responseAllAtributsOfAreas] = useGetAllAtributesOfAreasMutation();
  // eslint-disable-next-line no-unused-vars
  const [initValues, setinitValues] = useState({
    espacio: {
      nombre_eevv: "",
      tipo: 0,
      direccion: "",
      comuna: 0,
      cuadrante: 0,
      poligono: 0,
      empresa_prestadora: 0,
      area: 0,
      latitud: "",
      longitud: "",
      sup_seca: 0,
      sup_verde: 0,
      punto_verde: 0,
      cent_jubilados: 0,
      calesita: 0,
      mastil: 0,
      monumento: 0,
      obra_de_arte: 0,
      guarda_parque: 0,
      garita: 0,
      otro_tipo_cerramiento: 0,
      barrio: 0,
      clase: 0,
      es_emblematico: 0,
      estado: 0,
      bolardo: 0,
      muretes: 0,
      cant_cestos: 0,
      con_cesped: 0,
      con_cantero: 0,
      tipo_riego: 0,
      con_camino_interno: 0,
      con_camino_int_solado_suelto: 0,
      con_cerramiento: 0,
      tipo_rejas_canteros: 0,
      cant_patiojuegos: 0,
      cant_postaaerobica: 0,
      cant_areadeportiva: 0,
      cant_caniles: 0,
      cant_mobiliario: 0,
      tiene_vereda: 0,
    },
    areas: {
      areaDeportiva: [],
      patiodejuegos: [],
      mobiliarios: [],
      postaAerobica: [],
      caniles: [],
    },
    exist: false,
  });
  const [Action, setAction] = useState(false);
  const [espaciosVerdes, setEspaciosVerdes] = useState(JSON.parse(sessionStorage.getItem("espacioverde")));
  const [areas, setareas] = useState(JSON.parse(sessionStorage.getItem("areas")));
  const [optionEspacio, setoptionEspacio] = useState("espacio");
  const handleCancel = () => {
    navigate("/");
  };

  useLayoutEffect(() => {
    if (!atributesForAreas) {
      getAllAtributesOfAreas();
    }

    if (typeof areas === "undefined" || typeof espaciosVerdes === "undefined" || !areas || !espaciosVerdes) {
      setEspaciosVerdes(initValues.espacio);
      setareas(initValues.areas);
    } else {
      setOpenDialog(true);
    }
    getAllOptions();
    getSelf(sessionStorage.getItem("token"));
  }, []);

  useEffect(() => {
    if (Action) {
      sessionStorage.removeItem("areas");
      sessionStorage.removeItem("espacioverde");
      setEspaciosVerdes(initValues.espacio);
      setareas(initValues.areas);
    }
  }, [Action]);

  //*Parsea y guarda el state de espacios */
  useEffect(() => {
    sessionStorage.setItem("espacioverde", JSON.stringify(espaciosVerdes));
  }, [espaciosVerdes]);

  useEffect(() => {
    sessionStorage.setItem("areas", JSON.stringify(areas));
  }, [areas]);
  //----

  useEffect(() => {
    if (responseAllAtributsOfAreas.isSuccess) {
      setAtributesForAreas(responseAllAtributsOfAreas?.data);
    }
  }, [responseAllAtributsOfAreas.isSuccess]);

  useEffect(() => {
    if (responseCreateEspacio.isSuccess) {
      if (
        Object.values(areas?.areaDeportiva)?.length > 0 ||
        Object.values(areas?.patiodejuegos)?.length > 0 ||
        Object.values(areas?.mobiliarios)?.length > 0 ||
        Object.values(areas?.postaAerobica)?.length > 0 ||
        Object.values(areas?.caniles)?.length > 0
      ) {
        createAreas({
          body: areas,
          id: responseCreateEspacio?.data?.espacioVerde?.id_espacio_verde,
        });
      } else {
        navigate("/");
      }
    }
  }, [responseCreateEspacio.isSuccess]);

  useEffect(() => {
    if (responseCreateEspacio.isSuccess && responseAllAtributsOfAreas.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha creado el espacio verde!",
        })
      );
      sessionStorage.removeItem("areas");
      sessionStorage.removeItem("espacioverde");
    }

    if (responseCreateAreas.isSuccess) {
      navigate("/");
    }

    if (responseCreateEspacio.isError || responseCreateAreas.isError || responseAllAtributsOfAreas.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message:
            responseCreateEspacio?.error?.data?.message ||
            responseCreateAreas?.error?.data?.message ||
            responseAllAtributsOfAreas?.error?.data?.message,
        })
      );
    }
  }, [
    responseCreateEspacio.isSuccess,
    responseCreateEspacio.isError,
    responseCreateAreas.isSuccess,
    responseCreateAreas.isError,
    responseAllAtributsOfAreas.isError,
    responseAllAtributsOfAreas.isSuccess,
    responseCreateAreas.isSuccess,
  ]);

  const handleBottom = (e) => {
    setoptionEspacio(e);
  };

  const handleSubmit = (e) => {
    createEspacio(espaciosVerdes);
  };

  if (
    responseCreateAreas.isLoading ||
    responseSelf.isLoading ||
    responseCreateAreas.isLoading ||
    responseAllAtributsOfAreas.isLoading
  ) {
    return <Loading />;
  }
  if (openDialog) {
    return (
      <ConfirmAction
        title="Existe un espacio verde en memoria"
        mensajeDeConfirmacion="¿Desea crear uno nuevo?"
        setAction={setAction}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
      />
    );
  }

  if (responseOptions.isSuccess && responseSelf.isSuccess && responseAllAtributsOfAreas.isSuccess) {
    return (
      <Container component={"main"} maxWidth="lg">
        <HeaderVista2
          titulo="Crear espacio"
          start={<Volver label="Volver" to="/" />}
          end={
            <>
              <ButtonIcon variant="outlined" type="button" handleClick={() => handleBottom("espacio")} noLink>
                ESPACIO VERDE
              </ButtonIcon>
              <ButtonIcon variant="outlined" type="button" handleClick={() => handleBottom("areas")} noLink>
                AREAS
              </ButtonIcon>
            </>
          }
        />
        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
          <br />
          {optionEspacio === "espacio" && espaciosVerdes && (
            <FormNuevoEspacio setespacio={setEspaciosVerdes} espacio={espaciosVerdes} />
          )}
          {optionEspacio === "areas" && (
            <AgregarAreas
              valoresDeLasAreas={areas}
              setvaloresDeLasAreas={setareas}
              responseOptions={responseOptions?.data}
              dataAllAtributes={atributesForAreas}
            />
          )}
        </Grid>
        <br />

        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
          <Grid item sx={6} md={2}>
            <ButtonIcon variant="contained" size="small" noLink color="error" handleClick={handleCancel}>
              Cancelar
            </ButtonIcon>
          </Grid>
          <Grid item sx={6} md={2}>
            <ButtonIcon
              variant="contained"
              size="small"
              /* type="submit"
               */
              noLink
              handleClick={() => handleSubmit()}
              color="success" /*  startIcon={<CheckIcon />} */
            >
              Crear
            </ButtonIcon>
          </Grid>
        </Grid>
        <br />
      </Container>
    );
  } else {
    return <h2>Ocurrio un error.</h2>;
  }
};
