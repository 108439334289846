/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Divider, Input, Loading, Pagination } from "../../../elementos";
import SearchIcon from "@mui/icons-material/Search";
import {
  useGetAllEspaciosMutation,
  useGetEspaciosOrdenadosPorCercaniaMutation,
} from "../../../../store/api/endpoints/espaciosApiSlice";
import { useDispatch } from "react-redux";
import { espaciosVerdes } from "../../../../store/slices/espacioverdes";
import ListaEspacios from "./ListaEspacios";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { useGetCurrentPosition } from "../../espacioverdes/Maps/useWatchPosition";
import { useGetSelfMutation } from "../../../../store/api/endpoints/authApiSlice";

const DashboardInformes = () => {
  const dispatch = useDispatch();
  const [inputFilter, setinputFilter] = useState("");
  const [page, setpage] = useState(0);
  const [totalEspacios, settotalEspacios] = useState(0);
  const [limit, setLimit] = useState(12);
  const [timer, settimer] = useState(null);
  const [btnMasCercanos, setbtnMasCercanos] = useState(false);
  const [getCercano, responseCercano] = useGetEspaciosOrdenadosPorCercaniaMutation();
  const [getAllEspacios, responseEspacios] = useGetAllEspaciosMutation();
  const [getSelf, responseSelf] = useGetSelfMutation();

  const { location, obtenerUbicacion } = useGetCurrentPosition();
  const handleInput = React.useCallback(
    (e) => {
      setinputFilter(e);
      clearTimeout(timer);
      const newTimer = setTimeout(async () => {
        if (btnMasCercanos) {
          callApiMasCercanos(e);
        } else {
          getAllEspacios({ page, rowsPerPage: limit, name: e });
        }
      }, 1000);
      settimer(newTimer);
    },
    [timer, inputFilter]
  );
  const getEspaciosCercanos = async () => {
    setbtnMasCercanos(!btnMasCercanos);
  };
  const callApiMasCercanos = async (e) => {
    try {
      obtenerUbicacion();
      if (location.latitude && location.longitude) {
        getCercano({
          latitude: location.latitude,
          longitude: location.longitude,
          page,
          espacio: e,
          rowsPerPage: limit,
        });
      }
    } catch (error) {
      console.error("Error al obtener la posición:", error);
    }
  };

  useEffect(() => {
    Promise.all([getSelf()]);
  }, []);

  useEffect(() => {
    if (btnMasCercanos) {
      callApiMasCercanos(inputFilter);
    } else {
      getAllEspacios({ page, rowsPerPage: limit, name: inputFilter });
    }
  }, [page, limit, btnMasCercanos]);

  useEffect(() => {
    setpage(0);
  }, [inputFilter]);

  useEffect(() => {
    if (responseEspacios.isSuccess && !btnMasCercanos) {
      dispatch(espaciosVerdes(responseEspacios?.data?.resultados));
      settotalEspacios(responseEspacios?.data?.total_registros);
    }
    if (responseCercano.isSuccess && btnMasCercanos) {
      dispatch(espaciosVerdes(responseCercano?.data?.espacios));
      settotalEspacios(responseCercano?.data?.total);
    }
  }, [responseEspacios.isSuccess, responseCercano.isSuccess]);

  if (responseEspacios.isLoading || responseCercano.isLoading) {
    return <Loading />;
  }

  return (
    <Container component={"main"} maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <h2>Inspecciones</h2>
          {responseSelf?.data?.usuario?.perfil === 1 && (
            <MyLocationIcon
              fontSize="large"
              sx={{
                color: btnMasCercanos ? "#1A73E8" : "black",
                marginTop: "15px",
              }}
              onClick={() => getEspaciosCercanos()}
            />
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <Input
            label="Buscar espacio verde"
            icon={<SearchIcon />}
            value={inputFilter}
            onChange={handleInput}
            type="text"
            placeholder="Ingrese nombre o codigo de espacio"
            restricciones={["alfanumerico"]}
          />
        </Grid>
        <Divider />
        {btnMasCercanos && (
          <Grid item xs={12} md={12}>
            <p className="text-ubicacionespacio">Espacios verdes mas cerca de tu ubicacion</p>
          </Grid>
        )}

        <Grid item xs={12} md={12}>
          <ListaEspacios />
          {btnMasCercanos || (responseEspacios?.data?.total_registros === 0 && <p>No hay datos</p>)}
        </Grid>
        <Grid item xs={12} md={12}>
          <Divider />
          <Pagination
            label="Espacios"
            setrowsPerPages={setLimit}
            setpage={setpage}
            page={page}
            rowsPerPage={limit}
            total={totalEspacios}
          />
        </Grid>
      </Grid>
      <br />
    </Container>
  );
};

export default DashboardInformes;
