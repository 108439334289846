import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useGetOneInformeMutation } from "../../../../../store/api/endpoints/informesApiSlice";
import { Loading } from "../../../../elementos";
import PropTypes from "prop-types";
const useStyles = makeStyles({
  table: {
    minWidth: 620,
    "& .MuiTableCell-root": {
      border: "1px solid black",
    },
    "& .MuiTableCell-root:nth-child(2n-1)": {
      backgroundColor: "#bfbdbd",
      color: "white",
    },
  },
  tablaEspacio: {
    "& .MuiTableCell-root": {
      border: "1px solid black",
      backgroundColor: "#a8a8a8",
      color: "white",
    },
    "& tr": {
      border: "1px solid black",
    },
    "& tr > td": {
      border: "1px solid black",
    },
    "& td": {
      border: "1px solid black",
    },
    width: "100%",
  },
  descriptionRow: {
    textAlign: "left",
    fontSize: "1rem",
    padding: "5px 2px",
    fontWeight: "bold",
    backgroundColor: "#bababa",
    color: "white",
  },
  valueRow: {
    textAlign: "left",
  },
});

const TablaDescripcionEspacioVerde = ({ id }) => {
  const [getInforme, responseInforme] = useGetOneInformeMutation();
  const clasess = useStyles();
  useEffect(() => {
    getInforme(id);
  }, []);

  if (responseInforme.isLoading) {
    return <Loading />;
  }

  return (
    <div style={{ width: "100%" }}>
      <table className={clasess.tablaEspacio} cellSpacing={0}>
        <tbody>
          <tr>
            <td className={clasess.descriptionRow}>Nombre EEVV</td>
            <td colSpan="5" className={clasess.valueRow}>
              {responseInforme?.data?.nombre_eevv}
            </td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>Dirección</td>
            <td colSpan="5" className={clasess.valueRow}>
              {responseInforme?.data?.direccion}
            </td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>Código EEVV </td>
            <td colSpan="2" className={clasess.valueRow}>
              {responseInforme?.data?.codigo_eevv}
            </td>
            <td className={clasess.descriptionRow}>Empresa </td>
            <td colSpan="3" className={clasess.valueRow}>
              {responseInforme?.data?.empresa}
            </td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>Clase EEVV</td>
            <td className={clasess.valueRow}>{responseInforme?.data?.clase}</td>
            <td className={clasess.descriptionRow}>Puntaje mes anterior</td>
            <td className={clasess.valueRow}>{responseInforme?.data?.puntaje_mes_anterior ?? "-"}</td>
            <td className={clasess.descriptionRow}>Fecha inspección</td>
            <td className={clasess.valueRow}>{responseInforme?.data?.fecha_ult_modif}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

TablaDescripcionEspacioVerde.propTypes = {
  id: PropTypes.string,
};

export default TablaDescripcionEspacioVerde;
