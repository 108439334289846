/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ButtonIcon, Divider, HeaderVista, Loading, Volver } from "../../../elementos";
import TableListaInformes from "./ListadoInformes";
import { useGetEspacioVerdeMutation } from "../../../../store/api/endpoints/espaciosApiSlice";
import { useGetAllInformesOfEEVVMutation } from "../../../../store/api/endpoints/informesApiSlice";
import { Container, Grid } from "@mui/material";
import mapIcon from "../../../../assets/images/mapicon.png";
import mapicondisabled from "../../../../assets/images/mapicondisabled.png";
import "./index.css";
const DetalleEspacio = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = parseInt(searchParams.get("id"));
  const [listadoRelevamientos, setlistadoRelevamientos] = useState();
  const [noCoordenates, setnoCoordenates] = useState("");
  const [espacioVerde, setespacioVerde] = useState({});
  const [getEspacioVerde, responseEspacio] = useGetEspacioVerdeMutation();
  const [getAllInformes, responseAllInformes] = useGetAllInformesOfEEVVMutation();

  const handleNavigate = () => {
    if (espacioVerde?.latitud && espacioVerde?.longitud) {
      navigate(`/inspecciones/mapa?id=${espacioVerde?.id_espacio_verde}`);
    }
  };
  useEffect(() => {
    if (responseEspacio.isSuccess) {
      setespacioVerde(responseEspacio?.data?.dato);
      if (!responseEspacio?.data?.dato?.latitud || !responseEspacio?.data?.dato?.longitud) {
        setnoCoordenates("No tiene coordenadas");
      } else {
        setnoCoordenates("");
      }
    }
    if (responseAllInformes.isSuccess) {
      setlistadoRelevamientos(responseAllInformes?.data);
    }
  }, [responseEspacio.isSuccess, responseAllInformes.isSuccess]);

  useEffect(() => {
    if (!id) navigate("/inspecciones");
    Promise.all([getEspacioVerde(id), getAllInformes(id)]);
  }, []);

  if (responseEspacio.isLoading || responseAllInformes.isLoading) {
    return <Loading />;
  }

  return (
    <>
      <HeaderVista
        start={<Volver label="Volver" to="/inspecciones" />}
        end={
          <>
            <ButtonIcon variant={"contained"} size="small" to={`/inspecciones/espacio?id=${id}`}>
              Datos del espacio
            </ButtonIcon>
          </>
        }
        titulo={<p style={{ fontSize: "1rem", fontWeight: "bold" }}>{espacioVerde?.nombre_eevv}</p>}
      />
      <Grid item xs={6} md={3}>
        <div onClick={() => handleNavigate()} style={{ position: "relative" }}>
          <img
            src={!noCoordenates ? mapIcon : mapicondisabled}
            style={{
              width: "65px",
              maxWidth: "70px",
              height: "65px",
              maxHeight: "70px",
              right: 0,
              cursor: "pointer",
            }}
            alt="imagen de mapa"
          />
          {noCoordenates && (
            <span
              style={{
                fontSize: "2.2rem",
                fontWeight: "bold",
                position: "relative",
                right: "45px",
                bottom: "0px",
                color: "red",
              }}
            >
              X
            </span>
          )}

          {<p style={{ margin: 0 }}>{noCoordenates}</p>}
        </div>
      </Grid>
      <Container component={"main"} maxWidth="lg">
        <div>
          <p> Direccion: {espacioVerde?.direccion}</p>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "row",
            flexWrap: "wrap",
            margin: "14px 0px",
            flex: "1 1 0",
            justifyContent: "space-evenly",
          }}
        >
          <p style={{ margin: "2px 0px 2px 0px", fontWeight: "bold", fontSize: "1rem" }}>
            Poligono: {espacioVerde?.poligono}
          </p>
          <p style={{ margin: "2px 0px 2px 0px", fontWeight: "bold", fontSize: "1rem" }}>
            Cuadrante: {espacioVerde?.cuadrante}
          </p>
          <p style={{ margin: "2px 0px 2px 0px", fontWeight: "bold", fontSize: "1rem" }}>
            Comuna: {espacioVerde?.comuna}
          </p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-evenly",
            height: "15%",
          }}
        ></div>
        <Divider />
        <TableListaInformes informes={listadoRelevamientos} id_espacio_verde={id} />
      </Container>
      <br />
    </>
  );
};

export default DetalleEspacio;
