/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { useGetClienteFilterMutation, useGetClientesMutation } from "../../../store/api/endpoints/clientesApiSlice";

import { Loading, ButtonIcon, HeaderVista, Pagination, Input } from "../../elementos/";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import "../../../assets/css/table.css";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import { openFeedback } from "../../../store/slices/feedback";
import ListaClientes from "./ListaClientes";
import { clientes } from "../../../store/slices/clientes";

export const Clientes = () => {
  const [page, setPage] = useState(0);
  const [totalClientes, settotalClientes] = useState(0);
  const dispatch = useDispatch();
  const refClients = useRef(null);
  const [listclientes, setclientes] = useState({});
  const [cantRows, setrowsPerPages] = useState(10);
  const [inputFilter, setinputFilter] = useState("");
  const [timer, settimer] = useState(null);
  const [getClientes, responseAllClientes] = useGetClientesMutation();
  const [getOneCliente, responseClienteFilter] = useGetClienteFilterMutation();

  useEffect(() => {
    getClientes({ page, cantRows });
  }, [page, cantRows]);

  const handleInput = (e) => {
    setinputFilter(e);
    clearTimeout(timer);
    if (e.trim().length > 0) {
      const newTimer = setTimeout(async () => {
        getOneCliente(e);
      }, 400);
      settimer(newTimer);
    } else {
      settotalClientes(refClients.current.length);
      setclientes(refClients.current);
    }
  };

  useEffect(() => {
    if (responseAllClientes.isSuccess) {
      refClients.current = responseAllClientes?.data?.data;
      setclientes(responseAllClientes?.data?.data);
      settotalClientes(responseAllClientes?.data?.total);
    }
    if (responseClienteFilter.isSuccess) {
      setclientes(responseClienteFilter?.data);
      settotalClientes(responseClienteFilter?.data.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseAllClientes.isSuccess, responseClienteFilter.isSuccess]);

  useEffect(() => {
    dispatch(clientes(listclientes));
  }, [listclientes]);

  if (responseAllClientes?.isLoading || responseAllClientes.isUninitialized) return <Loading />;

  if (responseClienteFilter?.isError || responseAllClientes?.isError)
    dispatch(
      openFeedback({
        severity: "error",
        message: "Error al obtener los datos.",
      })
    );

  if (responseAllClientes.isSuccess) {
    return (
      <>
        <HeaderVista
          start={<></>}
          titulo={"Usuarios"}
          end={
            <>
              <ButtonIcon
                variant="outlined"
                size="small"
                color="primary"
                startIcon={<AddIcon />}
                to="/configuracion/usuario/nuevo"
                children="Nuevo"
              />
            </>
          }
        />
        <Container className="containerBigTable" component="main" maxWidth="lg">
          <Grid
            container
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignitems: "center",
            }}
          >
            <Grid className="titulo" item xs={12} md={8}>
              <Box>
                <Input
                  label="Buscar usuario"
                  icon={<SearchIcon />}
                  value={inputFilter}
                  onChange={handleInput}
                  type="text"
                  placeholder="Ingresa nombre o apellido"
                  restricciones={["alfanumerico"]}
                  style={{ width: "100%" }}
                />
              </Box>
              <p>Se encontraron {totalClientes} registros.</p>
            </Grid>
          </Grid>
          {responseClienteFilter?.isLoading ? <Loading /> : <ListaClientes />}
          <Pagination
            setrowsPerPages={setrowsPerPages}
            label="Usuarios"
            setpage={setPage}
            page={page}
            rowsPerPage={cantRows}
            total={totalClientes ? totalClientes : 0}
            nextPage={responseAllClientes?.nextUrl ? responseAllClientes?.nextUrl : false}
          />
        </Container>
      </>
    );
  } else {
    return <h2>Algun error</h2>;
  }
};
