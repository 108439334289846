/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";

import ButtonIcon from "../../../elementos/ButtonIcon";
import { Desplegable } from "../../../elementos/Desplegable/Desplegable";
import { AreaDeportiva } from "../Relevamientos/areas/AreaDeportiva";
import { PostaAerobica } from "../Relevamientos/areas/PostaAerobica";
import { Caniles } from "../Relevamientos/areas/Caniles";
import { Mobiliario } from "../Relevamientos/areas/Mobiliario";
import { PatioJuegos } from "../Relevamientos/areas/PatioJuegos";
import Caminos from "../Relevamientos/areas/Caminos";

import Canteros from "./Areas verdes/Canteros";
import Cesped from "./Areas verdes/Cesped";
import Cestos from "./Limpieza/Cestos";
import Limpieza from "./Limpieza/Limpieza";

import { useGetAllAtributesOfAreasMutation } from "../../../../store/api/endpoints/espaciosApiSlice";

const RelevamientoGeneral = ({ relevamiento, responseOptions, isEditable = true }) => {
  const [optionArea, setoptionArea] = useState("");
  const [atributesOfAreas, setatributesOfAreas] = useState();
  const [getAllAtributesOfAreas, responseAllAtributes] = useGetAllAtributesOfAreasMutation();
  const [optionRelevamient, setoptionRelevamient] = useState("");

  useEffect(() => {
    getAllAtributesOfAreas();
  }, []);

  useEffect(() => {
    if (responseAllAtributes.isSuccess) {
      setatributesOfAreas(responseAllAtributes?.data);
    }
  }, [responseAllAtributes.isSuccess]);

  return (
    <Container component={"main"} maxWidth="lg">
      <Grid container spacing={2}>
        <Container component={"main"} maxWidth="xs">
          <Grid container spacing={2}>
            <Grid item xs={4} md={4}>
              <ButtonIcon
                type="button"
                handleClick={() => setoptionRelevamient("limpieza")}
                noLink
                size={"1rem"}
                variant={"outlined"}
                color="success"
              >
                Limpieza
              </ButtonIcon>
            </Grid>
            <Grid item xs={4} md={4}>
              <ButtonIcon
                type="button"
                handleClick={() => setoptionRelevamient("verde")}
                size={"0.8rem"}
                noLink
                variant={"outlined"}
                color="success"
              >
                A. verde
              </ButtonIcon>
            </Grid>
            <Grid item xs={4} md={4}>
              <ButtonIcon
                type="button"
                handleClick={() => setoptionRelevamient("seca")}
                size={"0.8rem"}
                noLink
                variant={"outlined"}
                color="success"
              >
                A. Seca
              </ButtonIcon>
            </Grid>
          </Grid>
          <br />
        </Container>
        <Container component={"main"} maxWidth="lg">
          <Grid container spacing={2}>
            {optionRelevamient === "limpieza" && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Desplegable text="Limpieza" option={optionArea} setoption={setoptionArea} name="limpieza">
                    <Limpieza
                      setoptionArea={setoptionArea}
                      optionArea={optionArea}
                      valueLimpieza={relevamiento?.relevamientos}
                      optionLimpieza={responseOptions?.data?.limpieza}
                      name="limpieza"
                    />
                  </Desplegable>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Desplegable text="Cestos" option={optionArea} setoption={setoptionArea} name="cestos">
                    <Cestos
                      setoptionArea={setoptionArea}
                      optionArea={optionArea}
                      valueCestos={relevamiento?.relevamientos}
                      optionCestos={responseOptions?.data}
                      name="cestos"
                    />
                  </Desplegable>
                </Grid>
              </Grid>
            )}
            {optionRelevamient === "verde" && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Desplegable text="Cesped" option={optionArea} setoption={setoptionArea} name="cesped">
                    <br />
                    <Cesped
                      setoptionArea={setoptionArea}
                      optionArea={optionArea}
                      valueCesped={relevamiento?.relevamientos}
                      optionCesped={responseOptions?.data}
                      name="cesped"
                    />
                  </Desplegable>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Desplegable text="Canteros" option={optionArea} setoption={setoptionArea} name="canteros">
                    <br />
                    <Canteros
                      setoptionArea={setoptionArea}
                      optionArea={optionArea}
                      valueCenteros={relevamiento?.relevamientos}
                      optionCanteros={responseOptions?.data}
                      name="canteros"
                    />
                  </Desplegable>
                </Grid>
              </Grid>
            )}
            {optionRelevamient === "seca" && (
              <Grid container spacing={2}>
                {relevamiento?.caminos && (
                  <Grid item xs={12} md={12}>
                    <Desplegable
                      text="Caminos y cerramientos"
                      setoption={setoptionArea}
                      option={optionArea}
                      name="caminos"
                    >
                      <Caminos valueCaminos={relevamiento?.caminos} optionCaminos={responseOptions?.data} />
                    </Desplegable>
                  </Grid>
                )}

                {relevamiento?.postaAerobica.length > 0 && (
                  <Grid item xs={12} md={12}>
                    <Desplegable
                      text={`Posta aerobica (${relevamiento?.postaAerobica.length})`}
                      setoption={setoptionArea}
                      option={optionArea}
                      name="posta"
                    >
                      {relevamiento?.postaAerobica.map((elemento, index) => (
                        <PostaAerobica
                          areas={elemento}
                          options={responseOptions?.data}
                          index={index}
                          dataAllAtributes={atributesOfAreas}
                        />
                      ))}
                    </Desplegable>
                  </Grid>
                )}
                {relevamiento?.areaDeportiva.length > 0 && (
                  <Grid item xs={12} md={12}>
                    <Desplegable
                      text={`Area deportiva (${relevamiento?.areaDeportiva.length})`}
                      setoption={setoptionArea}
                      option={optionArea}
                      name="deportiva"
                    >
                      {relevamiento?.areaDeportiva?.map((elemento, index) => (
                        <AreaDeportiva
                          areas={elemento}
                          index={index}
                          options={responseOptions?.data}
                          isDisabled={isEditable}
                          dataAllAtributes={atributesOfAreas}
                        />
                      ))}
                    </Desplegable>
                  </Grid>
                )}
                {relevamiento?.patioDeJuegos.length > 0 && (
                  <Grid item xs={12} md={12}>
                    <Desplegable
                      text={`Patio de juegos (${relevamiento?.patioDeJuegos.length})`}
                      setoption={setoptionArea}
                      option={optionArea}
                      name="juegos"
                    >
                      {relevamiento?.patioDeJuegos.map((elemento, index) => (
                        <PatioJuegos
                          areas={elemento}
                          options={responseOptions?.data}
                          index={index}
                          isDisabled={isEditable}
                          dataAllAtributes={atributesOfAreas}
                        />
                      ))}
                    </Desplegable>
                  </Grid>
                )}
                {relevamiento?.mobiliario.length > 0 && (
                  <Grid item xs={12} md={12}>
                    <Desplegable
                      text={`Mobiliario (${relevamiento?.mobiliario.length})`}
                      setoption={setoptionArea}
                      option={optionArea}
                      name="mobiliario"
                    >
                      {relevamiento?.mobiliario.map((elemento, index) => (
                        <Mobiliario
                          areas={elemento}
                          index={index}
                          options={responseOptions?.data}
                          isDisabled={isEditable}
                          dataAllAtributes={atributesOfAreas}
                        />
                      ))}
                    </Desplegable>
                  </Grid>
                )}
                {relevamiento?.caniles.length > 0 && (
                  <Grid item xs={12} md={12}>
                    <Desplegable
                      text={`Canil (${relevamiento?.caniles.length})`}
                      setoption={setoptionArea}
                      option={optionArea}
                      name="canil"
                    >
                      {relevamiento?.caniles.map((elemento, index) => (
                        <Caniles
                          areas={elemento}
                          index={index}
                          options={responseOptions?.data}
                          isDisabled={isEditable}
                          dataAllAtributes={atributesOfAreas}
                        />
                      ))}
                    </Desplegable>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Container>
      </Grid>
      <br />
    </Container>
  );
};

export default RelevamientoGeneral;
