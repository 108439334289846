import * as React from "react";
import { Container, Grid } from "@mui/material";
import "../../../../../assets/css/TablaInformes.css";
import { monthNames } from "../../../../../constants/Meses";

export default function TableDataTitleInforme({ informe }) {
  const styles = { paddingTop: "2px", paddingBottom: "2px" };
  return (
    <Container component={"main"}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} style={styles}>
          <div className="informeTabla">
            <b>{informe?.nombre_eevv}</b>
          </div>
        </Grid>
        <Grid item xs={12} md={6} style={styles}>
          <div className="informeTabla">
            Dirección: <br className="informeSoloMobile" />
            {" "+informe?.direccion}
          </div>
        </Grid>
        <Grid item xs={6} md={6} style={styles}>
          <div className="informeTabla">Mes: {monthNames[informe?.mes_codigo?.toString().slice(-2)-1]}</div>
        </Grid>
        <Grid item xs={6} md={6} style={styles}>
          <div className="informeTabla">Poligono: {informe?.poligono}</div>
        </Grid>
        <Grid item xs={6} md={6} style={styles}>
          <div className="informeTabla">Comuna: {informe?.comuna}</div>
        </Grid>
        <Grid item xs={6} md={6} style={styles}>
          <div className="informeTabla">Clase: {informe?.clase?.split(" ")[1]}</div>
        </Grid>
        <Grid item xs={6} md={6} style={styles}>
          <div className="informeTabla">
            Codigo:
            <br className="informeSoloMobile" />
            {" "+informe?.codigo_eevv}
          </div>
        </Grid>
        <Grid item xs={6} md={6} style={styles}>
          <div className="informeTabla">
            Ult. modif:
            <br className="informeSoloMobile" />
            {" " + informe?.fecha_ult_modif}
          </div>
        </Grid>
        <Grid item xs={12} md={6} style={styles}>
          <div className="informeTabla">Puntaje mes anterior: {informe?.puntaje || "-"}</div>
        </Grid>
      </Grid>
    </Container>
  );
}
