/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { ButtonIcon, Error, Loading, Volver, HeaderVista } from "../../../elementos";

import { Container, Grid } from "@mui/material";

import { useGetAllOptionsMutation } from "../../../../store/api/endpoints/optionsApiSlice";
import { openFeedback } from "../../../../store/slices/feedback";
import {
  useCreateAreaEspacioMutation,
  useGetAllAtributesOfAreasMutation,
} from "../../../../store/api/endpoints/espaciosApiSlice";
import SelectAreaVerde from "./SelectArea";
import ListaAreasAMostrar from "./ListaAreasAMostrar";
import { options } from "../../../../store/slices/options";

const CrearArea = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id-eevv");
  const dispatch = useDispatch();
  const [stepCreate, setStepCreate] = useState({
    initial: false,
    setDates: false,
  });
  const [optionSelected, setoptionSelected] = useState("");
  const [selectedArea, setselectedArea] = useState();
  const [areas, setareas] = useState({
    patiodejuegos: [],
    postaAerobica: [],
    caniles: [],
    mobiliarios: [],
    areaDeportiva: [],
  });
  const [getAllOptions, responseOptions] = useGetAllOptionsMutation();
  const [createArea, responseCreate] = useCreateAreaEspacioMutation();
  const [
    getAllAtributesOfAreas,
    {
      data: dataAllAtributes,
      isLoading: isLoadingAllAtributes,
      isError: isErrorToAllAtributes,
      isSuccess: isSuccessAllAtributes,
    },
  ] = useGetAllAtributesOfAreasMutation();
  const handleSubmit = () => {
    createArea({ body: areas, id });
  };

  const handleCancel = () => {
    navigate(`/espaciosverdes/areas?id=${id}`);
  };

  useEffect(() => {
    try {
      Promise.all([getAllOptions(), getAllAtributesOfAreas()]);
    } catch (error) {
      console.log("Error al llamar al microservicio");
    }
  }, []);

  useEffect(() => {
    if (responseOptions.isSuccess && isSuccessAllAtributes) {
      let atributesNecesaries = { ...responseOptions?.data, ...dataAllAtributes };
      dispatch(options(atributesNecesaries));
    }
  }, [responseOptions.isSuccess, isSuccessAllAtributes]);

  useEffect(() => {
    if (selectedArea && !stepCreate.initial) {
      setStepCreate((prev) => ({
        ...prev,
        initial: true,
      }));
    }
    if (selectedArea) {
      setareas({ patiodejuegos: [], postaAerobica: [], caniles: [], mobiliarios: [], areaDeportiva: [] });
    }
  }, [selectedArea]);

  const handleChange = (event) => {
    setselectedArea(event);
  };

  useEffect(() => {
    if (responseCreate.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "Se ha creado el area.",
        })
      );
      navigate(`/espaciosverdes/areas?id=${id}`);
    }
    if (responseCreate.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: "No se pudo crear el area.",
        })
      );
    }
  }, [responseCreate.isError, responseCreate.isSuccess]);

  if (responseOptions.isLoading || responseCreate.isLoading || isLoadingAllAtributes) return <Loading />;
  if (isErrorToAllAtributes || responseOptions.isError || isErrorToAllAtributes) return <Error />;
  return (
    <>
      <HeaderVista
        start={<Volver label="Volver" />}
        titulo={
          <>
            <p>Creando area al espacio</p>
            <p>{location?.state?.nombre ? location?.state?.nombre : "N/A"}</p>
          </>
        }
        end={
          <ButtonIcon variant="outlined" type="button" to={`/espaciosverdes/espacio?id=${id}`}>
            ESPACIO VERDE
          </ButtonIcon>
        }
      />
      <Container component={"main"} maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <SelectAreaVerde
              initial={stepCreate.initial}
              optionSelected={optionSelected}
              setoptionSelected={setoptionSelected}
              selectedArea={selectedArea}
              handleChange={handleChange}
            ></SelectAreaVerde>
          </Grid>
          {stepCreate.initial && (
            <Grid item xs={12} md={12}>
              <ListaAreasAMostrar
                selectedArea={selectedArea}
                areas={areas}
                optionSelected={optionSelected}
                setoptionSelected={setoptionSelected}
                setareas={setareas}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
              />
            </Grid>
          )}
          <br />
        </Grid>
        <br />
      </Container>
    </>
  );
};

export default CrearArea;
