import { React } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

export const ConfirmAction = ({ setAction, setOpenDialog, openDialog, title, mensajeDeConfirmacion }) => {
  const handleResponse = () => {
    setOpenDialog(false);
    setAction(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setAction(false);
  };

  return (
    <>
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: "center" }}>{mensajeDeConfirmacion}</DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            style={{ width: "35%" }}
            color="neutral"
            onClick={handleClose}
            startIcon={<CancelIcon />}
          >
            No
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: "green", width: "35%" }}
            onClick={handleResponse}
            startIcon={<CheckIcon />}
          >
            Si
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
