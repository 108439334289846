/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useGetOneInformeMutation } from "../../../../../store/api/endpoints/informesApiSlice";
import { Loading } from "../../../../elementos";
import SeccionFoto from "./../SeccionFotos/SeccionFoto";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  table: {
    minWidth: 600,
    "& .MuiTableCell-root": {
      border: "1px solid black",
    },
    "& .MuiTableCell-root:nth-child(2n-1)": {
      backgroundColor: "#bfbdbd",
      color: "white",
    },
  },
  tableSemanal: {
    "& .MuiTableCell-root": {
      border: "1px solid black",
      backgroundColor: "#a8a8a8",
      color: "white",
    },
    "& tr": {
      border: "1px solid black",
    },
    "& tr > td": {
      border: "1px solid black",
    },
    "& td": {
      border: "1px solid black",
    },
    width: "100%",
  },
  tableTitle: {
    color: "white",
    fontWeight: "bold",
    fontSize: "1rem",
    backgroundColor: "#b2b2b2",
    height: "30px",
  },
  descriptionRow: {
    textAlign: "left",
    padding: "5px 3px",
    backgroundColor: "#eee",
  },
  spanMultilinea: {
    width: "105px",
    display: "flex",
    flexDirection: "column",
  },
  rowMultilinea: {
    overflow: "visible",
    minWidth: "100px",
    minHeight: "42px",
    verticalAlign: "middle",
    wordWrap: "break-word",
    overflowWrap: "break-word",
  },
  fotos: {
    width: "100%",
  },
});
const TablaPlantillaSeguimientoSemanal = ({ id, lastId, totalInspecciones }) => {
  const [getInforme, responseInforme] = useGetOneInformeMutation();
  const clasess = useStyles();
  const [informe, setinforme] = useState();
  useEffect(() => {
    getInforme(id);
  }, []);

  useEffect(() => {
    if (lastId && responseInforme.isSuccess && !responseInforme.isLoading) {
      setTimeout(() => {
        window.print();
        /*       window.onafterprint = () => {
          window.close();
        }; */
      }, 3000);
    }
  }, [lastId, responseInforme.isSuccess]);

  useEffect(() => {
    if (responseInforme.isSuccess) {
      setinforme(responseInforme?.data);
    }
  }, [responseInforme.isSuccess]);

  if (responseInforme.isLoading || responseInforme.isUninitialized) {
    return <Loading />;
  }

  return (
    <div style={{ marginTop: "15px", width: "100%", height: "100%" }}>
      <table className={clasess.tableSemanal} style={{ height: "80%" }} cellSpacing={0} id="tablaSemanal">
        <tbody>
          <tr className={clasess.tableTitle}>
            <td colSpan={2} className="sectionTitle">
              PLANILLA DE SEGUIMIENTO SEMANAL
            </td>
            <td>SEMANA 1</td>
            <td>SEMANA 2</td>
            <td>SEMANA 3</td>
            <td>SEMANA 4</td>
          </tr>
          <tr>
            <td rowSpan="2" className={clasess.descriptionRow}>
              Limpieza
            </td>
            <td className={clasess.descriptionRow}>Limpieza general</td>
            <td>{informe?.semana_1?.limpieza?.general}</td>
            <td>{informe?.semana_2?.limpieza?.general}</td>
            <td>{informe?.semana_3?.limpieza?.general}</td>
            <td>{informe?.semana_4?.limpieza?.general}</td>
          </tr>
          <tr colSpan={4}>
            <td className={clasess.descriptionRow}>Observaciones (breves)</td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_1?.limpieza?.observaciones}</span>
            </td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_2?.limpieza?.observaciones}</span>
            </td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_3?.limpieza?.observaciones}</span>
            </td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_4?.limpieza?.observaciones}</span>
            </td>
          </tr>
          <tr>
            <td rowSpan="3" className={clasess.descriptionRow}>
              Cesped
            </td>
            <td className={clasess.descriptionRow}>Cobertura</td>
            <td>{informe?.semana_1?.cesped?.cobertura}</td>
            <td>{informe?.semana_2?.cesped?.cobertura}</td>
            <td>{informe?.semana_3?.cesped?.cobertura}</td>
            <td>{informe?.semana_4?.cesped?.cobertura}</td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>Estado</td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_1?.cesped?.estado}</span>
            </td>
            <td>
              <span className={clasess.spanMultilinea}>{informe?.semana_2?.cesped?.estado}</span>
            </td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_3?.cesped?.estado}</span>
            </td>
            <td>
              <span className={clasess.spanMultilinea}>{informe?.semana_4?.cesped?.estado}</span>
            </td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>Observaciones (breves)</td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_1?.cesped?.observaciones}</span>
            </td>
            <td>
              <span className={clasess.spanMultilinea}>{informe?.semana_2?.cesped?.observaciones}</span>
            </td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_3?.cesped?.observaciones}</span>
            </td>
            <td>
              <span className={clasess.spanMultilinea}>{informe?.semana_4?.cesped?.observaciones}</span>
            </td>
          </tr>
          <tr>
            <td rowSpan="3" className={clasess.descriptionRow}>
              Canteros
            </td>
            <td className={clasess.descriptionRow}>Cobertura</td>
            <td>{informe?.semana_1?.canteros?.cobertura}</td>
            <td>{informe?.semana_2?.canteros?.cobertura}</td>
            <td>{informe?.semana_3?.canteros?.cobertura}</td>
            <td>{informe?.semana_4?.canteros?.cobertura}</td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>Estado (breves)</td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_1?.canteros?.estado}</span>
            </td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_2?.canteros?.estado}</span>
            </td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_3?.canteros?.estado}</span>
            </td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_4?.canteros?.estado}</span>
            </td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>Observaciones (breves)</td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_1?.canteros?.observaciones}</span>
            </td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_2?.canteros?.observaciones}</span>
            </td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_3?.canteros?.observaciones}</span>
            </td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_4?.canteros?.observaciones}</span>
            </td>
          </tr>
          <tr>
            <td rowSpan="5" className={clasess.descriptionRow}>
              Patio de juegos
            </td>
            <td className={clasess.descriptionRow}>Cantidad de equipos a reparar o pintar</td>
            <td>{informe?.semana_1?.patiojuegos?.cantidad_equipos_a_reparar}</td>
            <td>{informe?.semana_2?.patiojuegos?.cantidad_equipos_a_reparar}</td>
            <td>{informe?.semana_3?.patiojuegos?.cantidad_equipos_a_reparar}</td>
            <td>{informe?.semana_4?.patiojuegos?.cantidad_equipos_a_reparar}</td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>Limpieza de equipos </td>
            <td>{informe?.semana_1?.patiojuegos?.limpieza_de_equipos}</td>
            <td>{informe?.semana_2?.patiojuegos?.limpieza_de_equipos}</td>
            <td>{informe?.semana_3?.patiojuegos?.limpieza_de_equipos}</td>
            <td>{informe?.semana_4?.patiojuegos?.limpieza_de_equipos}</td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>Estado del Solado</td>
            <td>{informe?.semana_1?.patiojuegos?.estado_del_solado}</td>
            <td>{informe?.semana_2?.patiojuegos?.estado_del_solado}</td>
            <td>{informe?.semana_3?.patiojuegos?.estado_del_solado}</td>
            <td>{informe?.semana_4?.patiojuegos?.estado_del_solado}</td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>Estado Rejas </td>
            <td>{informe?.semana_1?.patiojuegos?.estado_rejas}</td>
            <td>{informe?.semana_2?.patiojuegos?.estado_rejas}</td>
            <td>{informe?.semana_3?.patiojuegos?.estado_rejas}</td>
            <td>{informe?.semana_4?.patiojuegos?.estado_rejas}</td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>Observaciones (breves)</td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_1?.patiojuegos?.observaciones}</span>
            </td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_2?.patiojuegos?.observaciones}</span>
            </td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_3?.patiojuegos?.observaciones}</span>
            </td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_4?.patiojuegos?.observaciones}</span>
            </td>
          </tr>
          <tr>
            <td rowSpan="6" className={clasess.descriptionRow}>
              Caniles
            </td>
            <td className={clasess.descriptionRow}>Funcionamiento bebedero</td>
            <td>{informe?.semana_1?.caniles?.funcionamiento_bebedero}</td>
            <td>{informe?.semana_2?.caniles?.funcionamiento_bebedero}</td>
            <td>{informe?.semana_3?.caniles?.funcionamiento_bebedero}</td>
            <td>{informe?.semana_4?.caniles?.funcionamiento_bebedero}</td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>Cantidad equipos a reparar o pintar</td>
            <td>{informe?.semana_1?.caniles?.cantidad_equipos_a_reparar}</td>
            <td>{informe?.semana_2?.caniles?.cantidad_equipos_a_reparar}</td>
            <td>{informe?.semana_3?.caniles?.cantidad_equipos_a_reparar}</td>
            <td>{informe?.semana_4?.caniles?.cantidad_equipos_a_reparar}</td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>limpieza de equipos </td>
            <td>{informe?.semana_1?.caniles?.limpieza_de_equipos}</td>
            <td>{informe?.semana_2?.caniles?.limpieza_de_equipos}</td>
            <td>{informe?.semana_3?.caniles?.limpieza_de_equipos}</td>
            <td>{informe?.semana_4?.caniles?.limpieza_de_equipos}</td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>Estado del Solado </td>
            <td>{informe?.semana_1?.caniles?.estado_del_solado}</td>
            <td>{informe?.semana_2?.caniles?.estado_del_solado}</td>
            <td>{informe?.semana_3?.caniles?.estado_del_solado}</td>
            <td>{informe?.semana_4?.caniles?.estado_del_solado}</td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>Estado Rejas </td>
            <td>{informe?.semana_1?.caniles?.estado_rejas}</td>
            <td>{informe?.semana_2?.caniles?.estado_rejas}</td>
            <td>{informe?.semana_3?.caniles?.estado_rejas}</td>
            <td>{informe?.semana_4?.caniles?.estado_rejas}</td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>Observaciones (breves)</td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_1?.caniles?.observaciones}</span>
            </td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_2?.caniles?.observaciones}</span>
            </td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_3?.caniles?.observaciones}</span>
            </td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_4?.caniles?.observaciones}</span>
            </td>
          </tr>
          <tr>
            <td rowSpan="3" className={clasess.descriptionRow}>
              Postas aerobicas
            </td>
            <td className={clasess.descriptionRow}>Cantidad equipos a reparar o pintar</td>
            <td>{informe?.semana_1?.postas?.cantidad_equipos_a_reparar}</td>
            <td>{informe?.semana_2?.postas?.cantidad_equipos_a_reparar}</td>
            <td>{informe?.semana_3?.postas?.cantidad_equipos_a_reparar}</td>
            <td>{informe?.semana_4?.postas?.cantidad_equipos_a_reparar}</td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>Estado del Solado</td>
            <td>{informe?.semana_1?.postas?.estado_solado}</td>
            <td>{informe?.semana_2?.postas?.estado_solado}</td>
            <td>{informe?.semana_3?.postas?.estado_solado}</td>
            <td>{informe?.semana_4?.postas?.estado_solado}</td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>Observaciones (breves)</td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_1?.postas?.observaciones}</span>
            </td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_2?.postas?.observaciones}</span>
            </td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_3?.postas?.observaciones}</span>
            </td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_4?.postas?.observaciones}</span>
            </td>
          </tr>
          <tr>
            <td rowSpan="3" className={clasess.descriptionRow}>
              Playón deportivo
            </td>
            <td className={clasess.descriptionRow}>Cantidad equipos a reparar o pintar</td>
            <td>{informe?.semana_1?.deportiva?.cantidad_equipos_a_reparar}</td>
            <td>{informe?.semana_2?.deportiva?.cantidad_equipos_a_reparar}</td>
            <td>{informe?.semana_3?.deportiva?.cantidad_equipos_a_reparar}</td>
            <td>{informe?.semana_4?.deportiva?.cantidad_equipos_a_reparar}</td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>Estado del Solado</td>
            <td>{informe?.semana_1?.deportiva?.estado_solado}</td>
            <td>{informe?.semana_2?.deportiva?.estado_solado}</td>
            <td>{informe?.semana_3?.deportiva?.estado_solado}</td>
            <td>{informe?.semana_4?.deportiva?.estado_solado}</td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>Observaciones (breves)</td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_1?.deportiva?.observaciones}</span>
            </td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_2?.deportiva?.observaciones}</span>
            </td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_3?.deportiva?.observaciones}</span>
            </td>
            <td className={clasess.rowMultilinea}>
              <span className={clasess.spanMultilinea}>{informe?.semana_4?.deportiva?.observaciones}</span>
            </td>
          </tr>
          <tr>
            <td rowSpan="6" className={clasess.descriptionRow}>
              Mobiliario
            </td>
            <td className={clasess.descriptionRow} rowSpan="1">
              Bancos - Cantidad a reparar/grafiteados
            </td>
            <td rowSpan="1">{informe?.semana_1?.mobiliario?.bancos?.grafiteados}</td>
            <td rowSpan="1">{informe?.semana_2?.mobiliario?.bancos?.grafiteados}</td>
            <td rowSpan="1">{informe?.semana_3?.mobiliario?.bancos?.grafiteados}</td>
            <td rowSpan="1">{informe?.semana_4?.mobiliario?.bancos?.grafiteados}</td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>Mesas - Cant a reparar/grafiteados</td>
            <td>{informe?.semana_1?.mobiliario?.mesas?.grafiteados}</td>
            <td>{informe?.semana_2?.mobiliario?.mesas?.grafiteados}</td>
            <td>{informe?.semana_3?.mobiliario?.mesas?.grafiteados}</td>
            <td>{informe?.semana_4?.mobiliario?.mesas?.grafiteados}</td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>Bicicleteros - Cantidad a reparar/grafiteados</td>
            <td>{informe?.semana_1?.mobiliario?.bicicleteros?.grafiteados}</td>
            <td>{informe?.semana_2?.mobiliario?.bicicleteros?.grafiteados}</td>
            <td>{informe?.semana_3?.mobiliario?.bicicleteros?.grafiteados}</td>
            <td>{informe?.semana_4?.mobiliario?.bicicleteros?.grafiteados}</td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>Bebederos - Cantidad a reparar/grafiteados</td>
            <td>{informe?.semana_1?.mobiliario?.bebederos?.grafiteados}</td>
            <td>{informe?.semana_2?.mobiliario?.bebederos?.grafiteados}</td>
            <td>{informe?.semana_3?.mobiliario?.bebederos?.grafiteados}</td>
            <td>{informe?.semana_4?.mobiliario?.bebederos?.grafiteados}</td>
          </tr>
          {/*   <tr>
            <td>Cestos - Cantidad a reparar/grafiteados</td>
            <td>{informe?.semana_1?.mobiliario?.bebederos?.grafiteados}</td>
            <td>{informe?.semana_2?.mobiliario?.bebederos?.grafiteados}</td>
            <td>{informe?.semana_3?.mobiliario?.bebederos?.grafiteados}</td>
            <td>{informe?.semana_4?.mobiliario?.bebederos?.grafiteados}</td>
          </tr> */}
          <tr>
            <td className={clasess.descriptionRow}>Carteles - Cantidad rotos o faltantes</td>
            <td>{informe?.semana_1?.mobiliario?.carteles?.rotos}</td>
            <td>{informe?.semana_2?.mobiliario?.carteles?.rotos}</td>
            <td>{informe?.semana_3?.mobiliario?.carteles?.rotos}</td>
            <td>{informe?.semana_4?.mobiliario?.carteles?.rotos}</td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>Carteles - Cantidad ploteos defectuoso/grafieados</td>
            <td>{informe?.semana_1?.mobiliario?.carteles?.ploteos_defectuosos}</td>
            <td>{informe?.semana_2?.mobiliario?.carteles?.ploteos_defectuosos}</td>
            <td>{informe?.semana_3?.mobiliario?.carteles?.ploteos_defectuosos}</td>
            <td>{informe?.semana_4?.mobiliario?.carteles?.ploteos_defectuosos}</td>
          </tr>
        </tbody>
      </table>
      <div>
        <p className="sectionTitle">
          <b>Observaciones generales semana 1</b>
        </p>
        <span style={{ textAlign: "left" }}>{informe?.semana_1.observaciones_generales}</span>
        <p className="sectionTitle">
          <b>Observaciones generales semana 2</b>
        </p>
        <span style={{ textAlign: "left" }}>{informe?.semana_2.observaciones_generales}</span>
        <p className="sectionTitle">
          <b>Observaciones generales semana 3</b>
        </p>
        <span style={{ textAlign: "left" }}>{informe?.semana_3.observaciones_generales}</span>
        <p className="sectionTitle">
          <b>Observaciones generales semana 4</b>
        </p>
        <span style={{ textAlign: "left" }}>{informe?.semana_4.observaciones_generales}</span>
      </div>

      <div className={"fotos"}>
        <SeccionFoto
          semana_1={informe?.semana_1?.limpieza?.imagenes}
          semana_2={informe?.semana_2?.limpieza?.imagenes}
          semana_3={informe?.semana_3?.limpieza?.imagenes}
          semana_4={informe?.semana_4?.limpieza?.imagenes}
          id={id}
          label="Foto general del espacio y limpieza general"
        />
        <SeccionFoto
          semana_1={informe?.semana_1?.cesped?.imagenes}
          semana_2={informe?.semana_2?.cesped?.imagenes}
          semana_3={informe?.semana_3?.cesped?.imagenes}
          semana_4={informe?.semana_4?.cesped?.imagenes}
          id={id}
          label="Foto cobertura del césped"
        />

        <SeccionFoto
          semana_1={informe?.semana_1?.canteros?.imagenes}
          semana_2={informe?.semana_2?.canteros?.imagenes}
          semana_3={informe?.semana_3?.canteros?.imagenes}
          semana_4={informe?.semana_4?.canteros?.imagenes}
          id={id}
          label="Foto cobertura de canteros"
        />
        <SeccionFoto
          semana_1={informe?.semana_1?.patiojuegos?.imagenes}
          semana_2={informe?.semana_2?.patiojuegos?.imagenes}
          semana_3={informe?.semana_3?.patiojuegos?.imagenes}
          semana_4={informe?.semana_4?.patiojuegos?.imagenes}
          id={id}
          label="Fotos Patio de juegos"
        />
        <SeccionFoto
          semana_1={informe?.semana_1?.postas?.imagenes}
          semana_2={informe?.semana_2?.postas?.imagenes}
          semana_3={informe?.semana_3?.postas?.imagenes}
          semana_4={informe?.semana_4?.postas?.imagenes}
          id={id}
          label="Fotos Postas aeróbicas"
        />
        <SeccionFoto
          semana_1={informe?.semana_1?.mobiliario?.imagenes}
          semana_2={informe?.semana_2?.mobiliario?.imagenes}
          semana_3={informe?.semana_3?.mobiliario?.imagenes}
          semana_4={informe?.semana_4?.mobiliario?.imagenes}
          id={id}
          label="Fotos mobiliario"
        />
      </div>
      <br />
    </div>
  );
};

TablaPlantillaSeguimientoSemanal.propTypes = {
  id: PropTypes.string,
};

export default TablaPlantillaSeguimientoSemanal;
