export const defAtributesUserInput = [
  {
    label: "Nombre",
    value: "nombre_eevv",
    type: "text",
    restricciones: ["alfanumerico"],
    name: "nombre_eevv",
  },
  /*   {
    label: "Tipo",
    value: "tipo",
    type: "text",
    restricciones: ["alfanumerico"],
    name: "tipo",
  }, */
  {
    label: "Direccion",
    value: "direccion",
    type: "text",
    restricciones: ["alfanumerico"],
    name: "direccion",
  },
  {
    label: "Latitud",
    value: "latitud",
    type: "text",
    restricciones: ["alfanumerico"],
    name: "latitud",
  },
  {
    label: "Longitud",
    value: "longitud",
    type: "text",
    restricciones: ["alfanumerico"],
    name: "longitud",
  },
  {
    label: "Superficie seca",
    value: "superficie_seca",
    type: "text",
    restricciones: ["soloNumerosDecimales"],
    name: "superficie_seca",
  },
  {
    label: "Superficie verde",
    value: "superficie_verde",
    type: "text",
    restricciones: ["soloNumerosDecimales"],
    name: "superficie_verde",
  },
  {
    label: "Tiene vereda",
    value: "tiene_vereda",
    type: "text",
    restricciones: ["soloNumerosDecimales"],
    name: "tiene_vereda",
  },
  {
    label: "Punto verde",
    value: "punto_verde",
    type: "text",
    restricciones: ["soloNumeros"],
    name: "punto_verde",
  },
  {
    label: "Cent de jubilados",
    value: "cent_jubilados",
    type: "text",
    restricciones: ["soloNumeros"],
    name: "cent_jubilados",
  },
  {
    label: "Calesita",
    value: "calesita",
    type: "text",
    restricciones: ["soloNumeros"],
    name: "calesita",
  },
  {
    label: "Fuente",
    value: "fuente",
    type: "text",
    restricciones: ["soloNumeros"],
    name: "fuente",
  },
  {
    label: "Mastil",
    value: "mastil",
    type: "text",
    restricciones: ["soloNumeros"],
    name: "mastil",
  },
  {
    label: "Monumento",
    value: "monumento",
    type: "text",
    restricciones: ["soloNumeros"],
    name: "monumento",
  },
  {
    label: "Obra de arte",
    value: "obra_arte",
    type: "text",
    restricciones: ["soloNumeros"],
    name: "obra_arte",
  },
  {
    label: "Guarda parque",
    value: "guarda_parque",
    type: "text",
    restricciones: ["soloNumeros"],
    name: "guarda_parque",
  },
  {
    label: "Garita",
    value: "garita",
    type: "text",
    restricciones: ["soloNumeros"],
    name: "garita",
  },
  {
    label: "Otro tipo de cerramiento",
    value: "otro_tipo_cerramiento",
    type: "text",
    restricciones: ["soloNumeros"],
    name: "otro_tipo_cerramiento",
  },
  {
    label: "Es emblematico",
    value: "es_emblematico",
    type: "text",
    restricciones: ["soloNumeros"],
    name: "es_emblematico",
  },
  {
    label: "Bolardo",
    value: "bolardo",
    type: "text",
    restricciones: ["soloNumeros"],
    name: "bolardo",
  },
  {
    label: "Muretes",
    value: "muretes",
    type: "text",
    restricciones: ["soloNumeros"],
    name: "muretes",
  },
  {
    label: "Cant cestos",
    value: "cant_cestos",
    type: "text",
    restricciones: ["soloNumeros"],
    name: "cant_cestos",
  },
  {
    label: "Con cesped",
    value: "con_cesped",
    type: "text",
    restricciones: ["soloNumeros"],
    name: "con_cesped",
  },
  {
    label: "Con cantero",
    value: "con_cantero",
    type: "text",
    restricciones: ["soloNumeros"],
    name: "con_cantero",
  },
  {
    label: "Tipo riego",
    value: "tipo_riego",
    type: "text",
    restricciones: ["soloNumeros"],
    name: "tipo_riego",
  },
  {
    label: "Con camino interno",
    value: "con_camino_interno",
    type: "text",
    restricciones: ["soloNumeros"],
    name: "con_camino_interno",
  },
  {
    label: "Con camino int solado suelto",
    value: "con_camino_int_solado_suelto",
    type: "text",
    restricciones: ["soloNumeros"],
    name: "con_camino_int_solado_suelto",
  },
  {
    label: "Con cerramiento",
    value: "con_cerramiento",
    type: "text",
    restricciones: ["soloNumeros"],
    name: "con_cerramiento",
  },
];
