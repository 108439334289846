import React from "react";
import Input from "../../../../elementos/Input";
import { Container, Grid } from "@mui/material";

const Cestos = ({ valueCestos }) => {
  return (
    <Container component={"main"} maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Input value={valueCestos?.cestos_tot} label="Cestos tototales" disabled={true} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input value={valueCestos?.cestos_rotos} label="Cestos rotos" disabled={true} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input value={valueCestos?.av_limpieza_cestos} label="Limpieza cestos" disabled={true} />
        </Grid>
      </Grid>
      <br />
    </Container>
  );
};

export default Cestos;
