/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { setCredentials } from "../../../store/slices/auth";
import { useLoginMutation } from "../../../store/api/endpoints/authApiSlice";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import { Input, Loading, PasswordInput } from "../../elementos/";
import PersonIcon from "@mui/icons-material/Person";
import "../../../assets/css/Login.css";

const Login = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [usuario, setusuario] = useState({
    email: "",
  });
  const token = sessionStorage.getItem("token");

  const [login, response] = useLoginMutation();
  const dispatch = useDispatch();

  const submitForm = async (e) => {
    e.preventDefault();
    await login(usuario);
  };

  const handleChange = (value, key) => {
    setusuario((prev) => ({
      ...prev,
      email: value,
    }));
  };
  useEffect(() => {
    if (token) {
      navigate("/inspecciones");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setusuario({
      email: "",
      contrasena: "",
    });
    if (response.isError) {
      setErrorMessage(
        response?.error?.data?.message ? response?.error?.data?.message : "Error al conectarse con el servidor. "
      );
    }
    if (response.isSuccess) {
      dispatch(setCredentials(response.data));
      navigate("/inspecciones");
    }
  }, [response.isSuccess, response.isError]);

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Grid container spacing={2}>
          <Grid item xs={12} className="container-login">
            <div className="login-component">
              {!response.isLoading && (
                <form onSubmit={submitForm} className="form-login">
                  <h3 className="text-welcome">¡Bienvenido!</h3>
                  <Input
                    label="Ingrese su Email"
                    value={usuario?.email}
                    fullWidth
                    disabled={false}
                    type="email"
                    variant="standard"
                    icon={<PersonIcon />}
                    restricciones={["email"]}
                    onChange={handleChange}
                    autoFocus
                    name={"email"}
                    required
                  />

                  <br />
                  <br />
                  <PasswordInput
                    label="Ingrese su contraseña"
                    setcliente={setusuario}
                    value={usuario?.contrasena1}
                    name={"contrasena"}
                    required
                  />

                  <br />
                  <br />

                  <h3 className="error">{errorMessage ? errorMessage : ""}</h3>
                  <p>
                    <Button type="submit" variant="outlined" color="success">
                      Ingresar
                    </Button>
                  </p>
                </form>
              )}
              {response.isLoading && <Loading />}
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Login;
