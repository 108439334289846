import { tiendasApi } from "../api";

export const authApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "self/login",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    /*     refreshToken: builder.mutation({
      query: (token) => ({
        url: "self/refresh-token",
        method: "GET",
        headers: {
          Authorization: token,
        },
      }),
    }), */
    getSelf: builder.mutation({
      query: (token) => ({
        url: "/self/",
        method: "GET",
        headers: {
          Authorization: token,
        },
      }),
    }),
    logOut: builder.mutation({
      query: (token) => ({
        url: "self/logout",
        method: "POST",
        headers: {
          Authorization: token,
        },
      }),
    }),
  }),
});

export const { useLoginMutation, /* useRefreshTokenMutation, */ useGetSelfMutation, useLogOutMutation } = authApiSlice;
