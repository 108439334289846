/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  useGetCantidadDeAreasMutation,
  useGetEspacioVerdeMutation,
  useGetObrasEnEspacioMutation,
} from "../../../../store/api/endpoints/espaciosApiSlice";
import mapicondisabled from "../../../../assets/images/mapicondisabled.png";
import mapIcon from "../../../../assets/images/mapicon.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Divider, HeaderVista, Loading, Volver, ButtonIcon } from "../../../elementos";
import { Container, Grid } from "@mui/material";
import BasicTable from "./TableListaAreas";
import TableListaRelevamientos from "./TableListaRelevamientos";
import { useGetRelevamientosMutation } from "../../../../store/api/endpoints/relevamientoApiSlice";

const DetalleEspacioVerde = () => {
  const [searchParams] = useSearchParams();
  const id = parseInt(searchParams.get("id"));
  const espacioVerde = useRef(null);
  const navigate = useNavigate();
  const [getEspacioVerde, responseEspacio] = useGetEspacioVerdeMutation();

  const [getObrasEspacio, responseObrasEspacio] = useGetObrasEnEspacioMutation();
  const [getCantidadDeAreas, responseCantidadAreas] = useGetCantidadDeAreasMutation();
  const [getAllRelevamientos, responseAllRelevamientos] = useGetRelevamientosMutation();
  const [espacioVerdeEnObra, setespacioVerdeEnObra] = useState();
  const [noCoordenates, setnoCoordenates] = useState("");
  const listadoAreas = useRef(null);
  const listadoActivas = useRef(null);
  const listadoEnObras = useRef(null);
  const listadoRelevamientos = useRef(null);
  const [iconMap, seticonMap] = useState(mapicondisabled);
  const handleNavigate = () => {
    if (espacioVerde.current?.latitud && espacioVerde.current?.longitud) {
      navigate(`/espaciosverdes/mapa?id=${espacioVerde.current?.id_espacio_verde}`);
    }
  };

  useEffect(() => {
    if (responseObrasEspacio.isSuccess) {
      let { espacioVerdeEnObra, ...resto } = responseObrasEspacio?.data?.areas;
      setespacioVerdeEnObra(responseObrasEspacio?.data?.areas?.espacioVerdeEnObra);
      listadoEnObras.current = resto;
    }
    if (responseEspacio.isSuccess) {
      listadoAreas.current = {
        mobiliarios: responseCantidadAreas?.data?.dato?.cantidad_mobiliarios,
        caniles: responseCantidadAreas?.data?.dato?.cantidad_caniles,
        patios: responseCantidadAreas?.data?.dato?.cantidad_patios,
        postas: responseCantidadAreas?.data?.dato?.cantidad_postas,
        deportiva: responseCantidadAreas?.data?.dato?.cantidad_deportivas,
      };

      espacioVerde.current = responseEspacio?.data?.dato;
      listadoActivas.current = responseCantidadAreas?.data;
      if (!espacioVerde.current?.latitud || !espacioVerde.current?.longitud) {
        seticonMap(mapicondisabled);
        setnoCoordenates("No tiene coordenadas");
      } else {
        seticonMap(mapIcon);
      }
    }
    if (responseAllRelevamientos.isSuccess) {
      listadoRelevamientos.current = responseAllRelevamientos?.data?.relevamientos.slice(0, 4).sort((actual, next) => {
        return actual.fecha > next.fecha ? -1 : 1;
      });
    }
  }, [responseObrasEspacio.isSuccess, responseEspacio.isSuccess, responseAllRelevamientos.isSuccess]);

  useEffect(() => {
    Promise.all([getEspacioVerde(id), getObrasEspacio(id), getCantidadDeAreas(id), getAllRelevamientos(id)]);
  }, []);

  if (
    responseCantidadAreas.isLoading ||
    responseEspacio.isLoading ||
    responseObrasEspacio.isLoading ||
    responseAllRelevamientos.isLoading
  ) {
    return <Loading />;
  }

  return (
    <>
      <HeaderVista
        start={<Volver label="Volver" to="/" />}
        end={
          <>
            <ButtonIcon to={`/espaciosverdes/espacio?id=${id}`} variant={"contained"} children={"Datos del espacio"} />
          </>
        }
        titulo={<p style={{ fontSize: "1rem", fontWeight: "bold" }}>{espacioVerde.current?.nombre_eevv}</p>}
      />
      <Container component={"main"} maxWidth="lg">
        <Grid container spacing="2" alignItems={"center"} maxWidth={"lg"} justifyContent={"center"}>
          <Grid item xs={6} md={3}>
            <p
              style={{
                margin: "3px 0px 3px 0px",
                fontWeight: "bold",
                fontSize: "0.8rem",
                textAlign: "left",
              }}
            >
              Espacio en obra: {espacioVerdeEnObra ? "SI" : "NO"}
            </p>
          </Grid>
          <Grid item xs={6} md={3}>
            <div onClick={() => handleNavigate()} style={{ position: "relative" }}>
              <img
                src={iconMap}
                style={{
                  width: "65px",
                  maxWidth: "70px",
                  height: "65px",
                  maxHeight: "70px",
                  right: 0,
                  cursor: "pointer",
                }}
                alt="imagen de mapa"
              />
              {noCoordenates && (
                <span
                  style={{
                    fontSize: "2.2rem",
                    fontWeight: "bold",
                    position: "relative",
                    right: "45px",
                    bottom: "0px",
                    color: "red",
                  }}
                >
                  X
                </span>
              )}

              {<p style={{ margin: 0 }}>{noCoordenates}</p>}
            </div>
          </Grid>
        </Grid>
        <div>
          <p> Direccion: {espacioVerde.current?.direccion}</p>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "row",
            flexWrap: "wrap",
            margin: "14px 0px",
            flex: "1 1 0",
            justifyContent: "space-evenly",
          }}
        >
          <p style={{ margin: "2px 0px 2px 0px", fontWeight: "bold", fontSize: "1rem" }}>
            Poligono:{espacioVerde.current?.poligono}
          </p>
          <p style={{ margin: "2px 0px 2px 0px", fontWeight: "bold", fontSize: "1rem" }}>
            Cuadrante:{espacioVerde.current?.cuadrante}
          </p>
          <p style={{ margin: "2px 0px 2px 0px", fontWeight: "bold", fontSize: "1rem" }}>
            Comuna:{espacioVerde.current?.comuna}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-evenly",
            height: "15%",
          }}
        ></div>
        <Divider />
        <BasicTable
          areas={listadoAreas.current}
          cantidadActivas={listadoActivas.current}
          enObra={listadoEnObras.current}
          id_espacio_verde={id}
        />
        <br />
        <TableListaRelevamientos relevamientos={listadoRelevamientos.current} id_espacio_verde={id} />
        <br />
      </Container>
      <br />
    </>
  );
};

export default DetalleEspacioVerde;
