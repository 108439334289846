/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import "./EspacioVerdeScreen.css";
import { Loading, Input, Pagination, ButtonIcon, Divider } from "../../elementos";
import SearchIcon from "@mui/icons-material/Search";
import {
  useGetAllEspaciosMutation,
  useGetEspaciosOrdenadosPorCercaniaMutation,
} from "../../../store/api/endpoints/espaciosApiSlice";
import Error from "../../elementos/Error";
import { useGetCurrentPosition } from "./Maps/useWatchPosition";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import ListaEspaciosVerdes from "./ListaEspaciosVerdes";
import { useGetSelfMutation } from "../../../store/api/endpoints/authApiSlice";
import { PERMISOS } from "../../../constants/permisos";
import { useDispatch } from "react-redux";
import { espaciosVerdes } from "../../../store/slices/espacioverdes";
import { openFeedback } from "../../../store/slices/feedback";
import { Container, Grid } from "@mui/material";

export default function StickyHeadTable() {
  const [espacios, setespacios] = useState([]);
  const refespaciosVerdes = useRef(null);
  const refTotalEspacios = useRef(null);
  const [perfil, setPerfil] = useState(false);
  const dispatch = useDispatch();
  const [inputFilter, setinputFilter] = useState("");
  const [limit, setlimit] = useState(10);
  const [toggleLocation, settoggleLocation] = useState(false);
  const [page, setpage] = useState(0);
  const [timer, settimer] = useState(null);
  const [totalEspacios, settotalEspacios] = useState(0);
  const actualCoordenates = useGetCurrentPosition();
  const [getAllEspacios, responseEspacios] = useGetAllEspaciosMutation();
  const [getCercano, responseCercano] = useGetEspaciosOrdenadosPorCercaniaMutation();
  const [getSelf, responseSelf] = useGetSelfMutation();

  useEffect(() => {
    getSelf(sessionStorage.getItem("token"));
  }, []);

  useEffect(() => {
    getAllEspacios({ page, rowsPerPage: limit, name: inputFilter });
    if (toggleLocation) {
      getCercano({
        latitude: actualCoordenates?.latitude,
        longitude: actualCoordenates?.longitude,
        page,
        rowsPerPage: limit,
      });
    }
  }, [page, limit]);

  const handleInput = React.useCallback(
    (e) => {
      setinputFilter(e);
      settoggleLocation(false);
      clearTimeout(timer);
      if (e.trim().length > 0) {
        const newTimer = setTimeout(async () => {
          getAllEspacios({ page, rowsPerPage: limit, name: e });
        }, 600);
        settimer(newTimer);
      } else {
        settotalEspacios(refTotalEspacios.current);
        setespacios(refespaciosVerdes);
      }
    },
    [timer]
  );

  useEffect(() => {
    if (responseCercano.isSuccess) {
      setespacios(responseCercano?.data?.espacios);
      setpage(0);
    }
  }, [responseCercano.isSuccess]);

  useEffect(() => {
    if (responseEspacios.isSuccess) {
      refespaciosVerdes.current = responseEspacios?.data?.resultados;
      refTotalEspacios.current = responseEspacios?.data?.total;
      settotalEspacios(responseEspacios?.data?.total_registros);
      setespacios(responseEspacios?.data?.resultados);
    }
    /*     if (responseEspacioFilter.isSuccess) {
      setespacios(responseEspacioFilter?.data?.data);
      settotalEspacios(responseEspacioFilter?.data.length);
      if (responseEspacioFilter?.data?.data.length === 0) {
        dispatch(
          openFeedback({
            severity: "warning",
            message: "No se encontro el espacio.",
          })
        );
      }
    } */
    if (responseSelf.isSuccess) {
      setPerfil(responseSelf?.data?.usuario?.perfil);
    }
  }, [responseEspacios.isSuccess, responseSelf.isSuccess]);

  useEffect(() => {
    if (inputFilter.length === 0) {
      setespacios(refespaciosVerdes.current);
    }
  }, [inputFilter]);

  /**
   * Escucha el toggle
   * Si el toggle es TRUE
   * Hace la llamada
   */
  useEffect(() => {
    if (toggleLocation) {
      getCercano({
        latitude: actualCoordenates?.latitude,
        longitude: actualCoordenates?.longitude,
        rowsPerPage: limit,
        page,
      });
      dispatch(
        openFeedback({
          severity: "info",
          message: "Espacios verdes mas cercanos a vos.",
        })
      );
    } else {
      setespacios(refespaciosVerdes.current);
    }
  }, [toggleLocation]);

  useEffect(() => {
    if (espacios?.length > 0) {
      dispatch(espaciosVerdes(espacios));
    }
  }, [espacios]);

  const handleLocation = () => {
    settoggleLocation(!toggleLocation);
  };

  if (!espacios || responseCercano.isLoading || responseEspacios.isLoading || responseSelf.isLoading)
    return <Loading />;

  if (espacios) {
    return (
      <Container component={"main"} maxWidth="lg">
        <Grid container alignItems={"center"} spacing={1}>
          <Grid item xs={10} md={10}>
            <Input
              label="Buscar espacio verde"
              icon={<SearchIcon />}
              value={inputFilter}
              onChange={handleInput}
              type="text"
              placeholder="Ingrese nombre o codigo de espacio"
              restricciones={["alfanumerico"]}
            />
          </Grid>
          <Grid item xs={2} md={2}>
            <MyLocationIcon
              fontSize="large"
              sx={{
                color: toggleLocation ? "#1A73E8" : "black",
                marginTop: "15px",
              }}
              onClick={handleLocation}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            {toggleLocation && <p className="text-ubicacionespacio">Espacios verdes mas cerca de tu ubicacion</p>}
          </Grid>
          <Grid item xs={12} md={12}>
            {perfil === PERMISOS.ADMINISTRADOR && (
              <ButtonIcon
                size={"big"}
                variant={"contained"}
                children={"Crear espacio verde"}
                to={"/espaciosverdes/crear"}
              />
            )}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <ListaEspaciosVerdes toggleLocation={toggleLocation} />
          </Grid>
          <Divider />
          <Pagination
            label="Espacios"
            setrowsPerPages={setlimit}
            setpage={setpage}
            page={page}
            rowsPerPage={limit}
            total={totalEspacios}
            nextPage={espacios?.nextUrl ? espacios?.nextUrl : true}
          />
        </Grid>
      </Container>
    );
  } else {
    <p>No hay espacios verdes.</p>;
  }

  if (responseCercano.isError) {
    return <Error message="Error al obtener los datos" />;
  }
}
