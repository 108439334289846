import { createSlice } from "@reduxjs/toolkit";

export const typesOFFormSlice = createSlice({
    name: 'types',
    initialState: {
        barrios:[],
        areas:[],

    },
    reducers: {
        typesOfValues: (state, action) => {

            state.barrios = action.payload.barrios
        }
    }
});

export const { typesOfValues } = typesOFFormSlice.actions;

export const getTypesBarrios = (state ) => state.barrios
