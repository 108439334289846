import { createSlice } from "@reduxjs/toolkit";

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    status: null,
  },
  reducers: {
    changeStatusSidebar: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const { changeStatusSidebar } = sidebarSlice.actions;

export const getStatusSidebar = (state) => state.sidebar.status;
