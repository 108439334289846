import { tiendasApi } from "../api";

export const relevamientoApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    create: builder.mutation({
      query: (relevamiento) => ({
        url: "/relevamientos",
        method: "POST",
        body: { ...relevamiento },
      }),
    }),
    createRelWithPhotos: builder.mutation({
      query: (images) => ({
        url: "/relevamientos/photos",
        method: "POST",
        body: { ...images },
      }),
    }),
    getRelevamientos: builder.mutation({
      query: (id) => ({
        url: `/relevamientos?id=${id}`,
        method: "GET",
      }),
    }),
    getOneRelevamiento: builder.mutation({
      query: (id) => ({
        url: `/relevamientos/getone/?id=${id}`,
        method: "GET",
      }),
    }),
    deleteRelevamiento: builder.mutation({
      query: (id) => ({
        url: `/relevamientos/delete?id=${id}`,
        method: "DELETE",
      }),
    }),
    getRelevamientoForPeriod: builder.mutation({
      query: (options) => ({
        url: `/relevamientos/getall?${options}`,
        method: "GET",
      }),
    }),
    exportDataWithDates: builder.mutation({
      query: (options) => ({
        url: `/relevamientos/export-data?${options}`,
        method: "GET",
      }),
    }),
    downloadRelevamiento: builder.mutation({
      query: (id) => ({
        url: `/relevamientos/download-relevamiento?id=${id}`,
      }),
    }),
  }),
});

export const {
  useCreateMutation,
  useCreateRelWithPhotosMutation,
  useGetRelevamientosMutation,
  useGetOneRelevamientoMutation,
  useGetRelevamientoForPeriodMutation,
  useDeleteRelevamientoMutation,
  useExportDataWithDatesMutation,
  useDownloadRelevamientoMutation,
} = relevamientoApiSlice;
