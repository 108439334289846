export default function inicializarObjetoGlobal(espacioVerde) {
  let atributosRequeridos = {};
  if (espacioVerde) {
    let canilesBuff = [];
    let patioJuegos = [];
    let mobiliario = [];
    let deportivo = [];
    let postaAero = [];
    espacioVerde?.caniles?.forEach((element, index) => {
      // eslint-disable-next-line no-unused-expressions, no-undef
      canilesBuff.push({
        cant_equipos_tot: element.cant_equipos,
        equipos_defectuosos: element.cant_equipos > 0,
        equipos_falta_pintura: element.cant_equipos > 0,
        cant_bebederos_tot: element.cant_bebederos,
        bebederos_defectuosos: element.cant_bebederos > 0,
        limpieza_equipamiento: element.cant_bebederos > 0,
        limpieza_solado: true,
        estado_solado: true,
        estado_rejas: element.rejas > 0,
        pintura_rejas: element.rejas > 0,
        cant_bolsas_exp_tot: element.cant_bolsas_exp,
        expend_sin_bolsa: element.cant_bolsas_exp > 0,
        expendedora_mal_estado: element.cant_bolsas_exp > 0,
      });
    });
    espacioVerde?.mobiliarios?.forEach((element, index) => {
      // eslint-disable-next-line no-unused-expressions, no-undef
      mobiliario.push({
        limpieza_bajo: true,
        limpieza_sobre: true,
        bancos_tot: element.bancos_cantidad,
        bancos_defectuosos: element.bancos_cantidad > 0,
        bancos_grafiteados: element.bancos_cantidad > 0,
        mesas_tot: element.mesas_cantidad,
        mesas_defectuosas: element.mesas_cantidad > 0,
        mesas_grafiteadas: element.mesas_cantidad > 0,
        bebederos_tot: element.bebederos_cantidad,
        bebederos_defectuosos: element.bebederos_cantidad > 0,
        bebederos_grafiteados: element.bebederos_cantidad > 0,
        bicicleteros_tot: element.bicicleteros_cantidad,
        bicicleteros_defectuosos: element.bicicleteros_cantidad > 0,
        bicicleteros_grafiteados: element.bicicleteros_cantidad > 0,
        carteles_tot: element.carteles_cantidad,
        carteles_defectuosos: element.carteles_cantidad > 0,
        carteles_grafiteados: element.carteles_cantidad > 0,
        ploteo_defectuosos: element.carteles_cantidad > 0,
      });
    });
    espacioVerde?.patiodejuegos?.forEach((element, index) => {
      // eslint-disable-next-line no-unused-expressions, no-undef
      patioJuegos.push({
        cant_equipos: element?.cant_equipos,
        equipos_defectuosos: element?.cant_equipos > 0,
        equipos_falta_pintura: element?.cant_equipos > 0,
        limpieza_solado: true,
        limpieza_equipamiento: element?.cant_equipos > 0,
        estado_solado: true,
        estado_rejas: element?.rejas > 0,
        pintura_rejas: element?.rejas > 0,
      });
    });
    espacioVerde?.areas_deportivas?.forEach((element, index) => {
      // eslint-disable-next-line no-unused-expressions, no-undef
      deportivo.push({
        cant_equipos_tot: element.cant_equipos,
        equipos_defectuosos: element.cant_equipos > 0,
        equipos_falta_pintura: element.cant_equipos > 0,
        limpieza: element.cant_equipos > 0,
        estado_solado: true,
        tejido_perimetral: element.tejido_perimetral > 0,
        nombre: element.descripcion,
      });
    });
    espacioVerde?.postaAerobica?.forEach((element, index) => {
      // eslint-disable-next-line no-unused-expressions, no-undef
      postaAero.push({
        cant_equipos: element.cant_equipos,
        equipos_defectuosos: element.cant_equipos > 0,
        equipos_falta_pintura: element.cant_equipos > 0,
        limpieza_solado: true,
        limpieza_postas: true,
        estado_solado: true,
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    atributosRequeridos = {
      limpieza_general: {
        limpieza: {
          av_limpieza_gral: true,
          av_limpieza_dia: true,
          av_limpieza_garitas: espacioVerde?.espacioverde[0].garita > 0,
          av_limpieza_pto_verde: espacioVerde?.espacioverde[0].punto_verde > 0,
        },
        cestos: {
          cestos_tot: espacioVerde?.espacioverde[0].cant_cestos,
          av_limpieza_cestos: espacioVerde?.espacioverde[0].cant_cestos > 0,
          cestos_rotos: espacioVerde?.espacioverde[0].cant_cestos > 0,
        },
        veredas: {
          as_limpieza_veredas: espacioVerde?.espacioverde[0].tiene_vereda,
        },
      },
      area_verde: {
        canteros: {
          av_limpieza_canteros: espacioVerde?.espacioverde[0].con_cantero > 0,
          av_cobertura_canteros: espacioVerde?.espacioverde[0].con_cantero > 0,
          av_estado_canteros: espacioVerde?.espacioverde[0].con_cantero > 0,
          av_carpido_desmalezado: espacioVerde?.espacioverde[0].con_cantero > 0,
        },
        cesped: {
          av_cobertura_cesped: espacioVerde?.espacioverde[0].con_cesped > 0,
          av_estado_cesped: espacioVerde?.espacioverde[0].con_cesped > 0,
          av_perfilado: espacioVerde?.espacioverde[0].con_cesped > 0,
        },
        riego: {
          funcionamiento_riego: espacioVerde?.espacioverde[0].funcionamiento_riego > 0,
        },
      },
      area_seca: {
        caminos: {
          as_estado_caminos_int: espacioVerde?.espacioverde[0].con_camino_interno > 0,
          as_vegetacion_espontanea: espacioVerde?.espacioverde[0].con_camino_interno > 0,
          as_nivel_cobertura_solado_suelto: espacioVerde?.espacioverde[0].con_camino_interno > 0,
          as_limpieza_camino_int:
            espacioVerde?.espacioverde[0].con_camino_interno > 0 ||
            espacioVerde?.espacioverde[0].con_camino_int_solado_suelto > 0,
          estado_de_bolardos: espacioVerde?.espacioverde[0].bolardo > 0,
          pintura_de_muretes: espacioVerde?.espacioverde[0].mueretes > 0,
          limpieza_rejas: espacioVerde?.espacioverde[0].con_cerramiento > 0,
          estado_de_rejas: espacioVerde?.espacioverde[0].con_cerramiento > 0,
          pintura_de_rejas: espacioVerde?.espacioverde[0].con_cerramiento > 0,
          as_limpieza_veredas: espacioVerde?.espacioverde[0].con_cerramiento > 0,
        },
        patio_juegos: [...patioJuegos],
        posta_aero: [...postaAero],
        area_deportiva: [...deportivo],
        mobiliarios: [...mobiliario],
        caniles: [...canilesBuff],
      },
    };
  }
  return atributosRequeridos;
}
