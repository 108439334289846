import { Container, Grid } from "@mui/material";
import { Desplegable, Select } from "../../../elementos";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function SelectAreaVerde(props) {
  return (
    <Container component={"main"} maxWidth="lg">
      <Desplegable
        text="Seleccionar el area"
        option={props.optionSelected}
        setoption={props.setoptionSelected}
        name="SeleccionArea"
        icon={
          !props.initial ? (
            <ErrorIcon
              style={{
                fill: "red",
              }}
            />
          ) : (
            <CheckCircleIcon
              style={{
                fill: "green",
              }}
            />
          )
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Select
              label="Seleccione area"
              disabled={false}
              value={props.selectedArea}
              defaultValue=""
              options={[
                { label: "Canil", value: 5 },
                { label: "Area deportiva", value: 1 },
                { label: "Mobiliario", value: 2 },
                { label: "Patio de juegos", value: 3 },
                { label: "Posta aerobica", value: 4 },
              ]}
              type="text"
              onChange={props.handleChange}
              name={"area"}
            />
          </Grid>
        </Grid>
        <br />
      </Desplegable>
    </Container>
  );
}
