/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "../../assets/css/Input.css";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

export default function SelectMultiple(props) {
  const [optionSelected, setoptionSelected] = useState("");
  const [seelctAlls, setseelctAlls] = useState(false);
  /*  useEffect(() => {
    console.log("validdando seleccion",!selectAll)
    if (selectAll && props.elegidos.length === 0) {
      const newOptionSelected = props.options.map((value) => value.label);
      const allValues = props.options.map((option) => option.value);
      setoptionSelected(newOptionSelected.join(","));
      props.onChange(allValues);
    }
    console.log
    if(!selectAll && props.elegidos.length === props.options.length){

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll]); */

  useEffect(() => {
    const newOptionSelected = props.elegidos
      .map((elemento) => {
        const option = props.options.find((value) => elemento === value.value);
        return option ? option.label : null;
      })
      .filter((label) => label);
    setoptionSelected(newOptionSelected.join(", "));
  }, [props.elegidos]);

  const handleToggle = (option) => () => {
    const currentIndex = props.elegidos.indexOf(option.value);
    const newChecked = [...props.elegidos];
    if (currentIndex === -1) {
      newChecked.push(option.value);
      // setoptionSelected(newChecked.join(", "));
      // props.onChange(newChecked);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    props.onChange(newChecked);
  };

  const handleSelectAll = () => {
    const selectAlls = props.elegidos.length === props.options.length;
    if (!selectAlls) {
      
      const newOptionSelected = props.options.map((value) => value.label);
      const allValues = props.options.map((option) => option.value);
      setoptionSelected(newOptionSelected.join(","));
      props.onChange(allValues);
    } else {
      setoptionSelected([]);
      props.onChange([]);
    }
  };

  useEffect(() => {
    // props.elegidos.length === 0 && handleSelectAll();
  }, []);

  return (
    <TextField
      select
      sx={{
        color: "red",
        marginTop: "15px",
      }}
      label={props.label}
      value={props.elegidos}
      disabled={props.disabled}
      helperText={props.helperText}
      fullWidth
      required={props.required}
      type={props.type}
      SelectProps={{
        multiple: true,
        renderValue: () => optionSelected,
        MenuProps: {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        },
      }}
    >
      <MenuItem onClick={() => handleSelectAll(seelctAlls)}>
        <Checkbox checked={props?.elegidos?.length === props?.options?.length} />
        <ListItemText
          primary={props?.elegidos?.length === props?.options?.length ? "Deseleccionar todo" : "Seleccionar todo"}
        />
      </MenuItem>
      {props?.options?.map((option, index) => (
        <MenuItem key={index} value={option} onClick={handleToggle(option)}>
          <Checkbox checked={props?.elegidos && props?.elegidos.indexOf(option.value) !== -1} />
          <ListItemText primary={option.label} />
        </MenuItem>
      ))}
    </TextField>
  );
}
