import React from "react";
import { Input } from "../../../../elementos";
import { Container, Grid } from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getInformeFull, updateInformeFull } from "../../../../../store/slices/informes";

const ObservacioensGenerales = ({ semana, editable }) => {
  const dispatch = useDispatch()
  const informeStore = useSelector(getInformeFull, shallowEqual);

  const handleChange = (name, value) => {
    let informeTemp = JSON.stringify(informeStore)
    informeTemp = JSON.parse(informeTemp)
    informeTemp[`${semana}`][`${name}`] = value;
    dispatch(updateInformeFull(informeTemp));
  };

  return (
    <Container maxWidth="lg" component={"main"}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Input
            fullWidth
            type="text"
            label={`Observaciones generales`}
            variant="standard"
            multiline
            value={informeStore[`${semana}`]?.observaciones_generales ?? ""}
            onChange={(e) => handleChange("observaciones_generales", e)}
            restricciones={[]}
            name="observaciones_generales"
            inputProps={{ maxLength: 1000 }}
            disabled={!editable}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ObservacioensGenerales;
