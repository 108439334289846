/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useExportDataWithDatesMutation } from "../../../store/api/endpoints/relevamientoApiSlice";
import { getDateToday } from "../../../utils/getDateToday";
import { useDispatch } from "react-redux";
import { openFeedback } from "../../../store/slices/feedback";
import { Loading, Input, ButtonIcon, SelectMultiple, Error, HeaderVista, Divider, Select } from "../../elementos/";
import "../../../assets/css/reportesScreen.css";
import { Container, Grid } from "@mui/material";
import { useGetAllPoligonosMutation, useGetComunasMutation } from "../../../store/api/endpoints/espaciosApiSlice";
export const ReportesScreen = () => {
  const dispatch = useDispatch();
  const [filters, setfilters] = useState({
    poligonos: [],
    comunas: [],
    fechaInicio: null,
    fechaFin: null,
    fileType: "",
  });
  const [listPoligonos, setlistPoligonos] = useState([]);
  const [listComunas, setlistComunas] = useState([]);
  const [dateSelectErrror, setdateSelectErrror] = useState("");
  const [date, setdate] = useState(getDateToday());
  const [getRelevamientos, { data: returnedData, isLoading, isSuccess, isError }] = useExportDataWithDatesMutation();
  const [getPoligonos, responsePoligonos] = useGetAllPoligonosMutation();
  const [getComunas, responseComunas] = useGetComunasMutation();

  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line no-unused-vars

  const handleSubmit = () => {
    let options = "";
    if (filters.dateFrom && filters.dateTo) {
      options = `from=${filters.dateFrom}-01&to=${filters.dateTo}-31`;
    }
    if (filters.poligonos.length > 0) {
      options = options + `&poligonos=${filters.poligonos}`;
    }
    if (filters.comunas.length > 0) {
      options = options + `&comunas=${filters.comunas}`;
    }
    if (!filters.dateFrom || !filters.dateTo) {
      dispatch(
        openFeedback({
          severity: "error",
          message: "Debe seleccionar las fechas!",
        })
      );
    }
    if (filters.fileType === 1) {
      ///PDF
    } else {
      getRelevamientos(options);
    }
  };

  function differenceInMonths(date1, date2) {
    const monthDiff = date2.getMonth() - date1.getMonth();
    const yearDiff = date2.getYear() - date1.getYear();

    return monthDiff + yearDiff * 12;
  }

  const handleChange = (value, name) => {
    if (name === "from") {
      if (!filters.dateTo) {
        setfilters((prev) => ({
          ...prev,
          dateTo: value,
        }));
      }
      setfilters((prev) => ({
        ...prev,
        dateFrom: value,
      }));
    }
    if (name === "to") {
      setfilters((prev) => ({
        ...prev,
        dateTo: value,
      }));
    } else {
      setfilters((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    const date1 = new Date(filters.dateFrom + "/01");
    const date2 = new Date(filters.dateTo + "/01");
    setdateSelectErrror("");
    if (differenceInMonths(date1, date2) >= 6) {
      setdateSelectErrror("El rango de fechas es muy amplio. Intente con un rango menor.");
    }
  }, [filters.dateFrom, filters.dateTo]);

  useEffect(() => {
    getPoligonos();
    getComunas();
  }, []);

  useEffect(() => {
    if (responsePoligonos.isSuccess) {
      setlistPoligonos(
        responsePoligonos?.data.map((poligono) => {
          return { label: poligono?.nombre, value: poligono?.codigo };
        })
      );
    }
    if (responseComunas.isSuccess) {
      setlistComunas(
        responseComunas?.data?.comunas?.map((comuna) => {
          return { label: `Comuna ${comuna.codigo}`, value: comuna.codigo };
        })
      );
    }
  }, [responsePoligonos.isSuccess, responseComunas.isSuccess]);

  useEffect(() => {
    if (listPoligonos.length > 0) {
      let todosseleccionados = listPoligonos.map((el, index) => {
        return el?.value;
      });
      setfilters((prev) => ({
        ...prev,
        poligonos: todosseleccionados,
      }));
    }
  }, [listPoligonos]);

  useEffect(() => {
    if (isError) {
      if (!returnedData) {
        dispatch(
          openFeedback({
            severity: "warning",
            message: "No se encontraron relevamientos para los filtros seleccionados.",
          })
        );
      } else {
        dispatch(
          openFeedback({
            severity: "error",
            message: "Error al obtener los datos!",
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      window.open(returnedData?.ruta);
    }
  }, [isSuccess]);

  if (responsePoligonos.isLoading || isLoading) {
    return <Loading />;
  }

  if (responsePoligonos.isError) {
    return <Error />;
  }

  return (
    <Container component={"main"} maxWidth="lg">
      <HeaderVista titulo="Buscar relevamiento por fecha (PUNTAJES)"></HeaderVista>
      <Grid container spacing={2}>
        {dateSelectErrror && (
          <Grid item xs={12} md={12}>
            <span style={{ color: "red" }}>{dateSelectErrror}</span>
          </Grid>
        )}

        <Grid item xs={12} md={12}>
          <span>Para descargar, por favor indicanos la fecha.</span>
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            defaultValue=""
            value={filters.fileType}
            label="Tipo de descarga"
            options={[
              { label: "PDF", value: 1 },
              { label: "EXCEL", value: 2 },
            ]}
            onChange={(e) => handleChange(e, "fileType")}
            /*     icon={exelIcon} */
            name="fileType"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            type="month"
            value={filters.dateFrom}
            label="Seleccione la fecha desde"
            onChange={(e) => handleChange(e, "from")}
            required
            restricciones={["alfanumerico"]}
            min={"2018-01"}
            max={filters.dateTo}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            type="month"
            value={filters.dateTo}
            label="Seleccione la fecha hasta"
            onChange={(e) => handleChange(e, "to")}
            required
            restricciones={["alfanumerico"]}
            min={filters.dateFrom}
            max={date}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectMultiple
            label="Poligonos"
            disabled={false}
            defaultValue=""
            options={listPoligonos}
            type="text"
            onChange={(e) => handleChange(e, "poligonos")}
            elegidos={filters.poligonos}
            name={"poligono"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectMultiple
            label="Comunas"
            disabled={false}
            defaultValue=""
            options={listComunas}
            type="text"
            onChange={(e) => handleChange(e, "comunas")}
            elegidos={filters.comunas}
            name={"comunas"}
          />
        </Grid>
        <Divider />
        <Grid item xs={12} sm={12} md={12}>
          <ButtonIcon
            type="submit"
            size={"big"}
            variant={"outlined"}
            noLink
            startIcon={<SearchIcon />}
            children={"Buscar"}
            handleClick={handleSubmit}
          />
        </Grid>
        {isSuccess && (
          <Grid container>
            {returnedData?.cantidad === 0 && (
              <Grid item xs={12} sm={12} md={12}>
                <h3>No hay relevamientos.</h3>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      <br />
    </Container>
  );
};
