/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { Loading } from "../../../../elementos";
import { shallowEqual, useSelector } from "react-redux";
import { getSemanasEditables } from "../../../../../store/slices/informes";
import { useGetSelfMutation } from "../../../../../store/api/endpoints/authApiSlice";
import { PERMISOS } from "../../../../../constants/permisos";

const FooterSemanas = ({ setsemanaSeleccionada, selecter, seteditable, updating }) => {
  const listadoSemanasEditables = useSelector(getSemanasEditables, shallowEqual);
  const [weekCompleted, setweekCompleted] = useState([]);
  const [getSelf, responseSelf] = useGetSelfMutation();

  useEffect(() => {
    getSelf();
  }, []);

  useEffect(() => {
    if (listadoSemanasEditables.length > 0 && responseSelf.isSuccess) {
      let cacheEditar =
        updating === "true" &&
        (responseSelf?.data?.usuario.perfil === PERMISOS.ADMINISTRADOR ||
          responseSelf?.data?.usuario.perfil === PERMISOS.INSPECTOR);
      listadoSemanasEditables?.forEach((element) => {
        if (element.editable && cacheEditar) {
          setsemanaSeleccionada(element.semana);
          seteditable(true);
          return;
        }
      });
      setweekCompleted(listadoSemanasEditables);
    }
  }, [listadoSemanasEditables, responseSelf.isSuccess]);

  const handleChange = (data) => {
    seteditable(data.editable);
    setsemanaSeleccionada(data.semana);
  };

  if (!weekCompleted || responseSelf.isLoading) {
    return <Loading />;
  }

  return (
    <Grid
      container
      style={{
        cursor: "pointer",
        border: "1px solid black",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        backgroundColor: "white",
      }}
    >
      {weekCompleted?.map((element, index) => (
        <Grid key={element.semana ?? index} item xs={3} md={3}>
          <div
            onClick={() => handleChange(element)}
            className={`btnFooterInforme ${selecter === element.semana && "current"} `}
          >
            <span>
              {element.editable ? (
                <b>{`Semana ${element?.semana?.split("_")[1]}* `}</b>
              ) : (
                `Semana ${element?.semana?.split("_")[1]} `
              )}
            </span>

            <DateRangeIcon sx={{ color: "grey" }} />
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default FooterSemanas;
