import React, { useState } from "react";
import { HeaderVista, Input } from "../../elementos";
import { Container, Grid } from "@mui/material";

const HoraDeRelevamiento = () => {
  const [horaRelevamiento, sethoraRelevamiento] = useState(8);
  return (
    <Container component="main" maxWidth="lg">
      <Grid Container spacing={2}>
        <HeaderVista titulo={<>Valor actual : {horaRelevamiento}HS</>} />
        <Input
          label="Hora de relevamiento"
          value={horaRelevamiento}
          type="text"
          onChange={sethoraRelevamiento}
          restricciones={["alfanumerico"]}
        />
      </Grid>
      <br />
    </Container>
  );
};

export default HoraDeRelevamiento;
