/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import "../../../../assets/css/crearPoligono.css";
import { Container, Grid } from "@mui/material";
import { CancelOutlined } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import { ButtonIcon, Divider, Error, HeaderVista, Input, Loading, Select, Volver } from "../../../elementos";
import { useGetAllMutation } from "../../../../store/api/endpoints/cuadranteApiSlice";
import { useCreatePoligonoMutation } from "../../../../store/api/endpoints/poligonoApiSlice";
import { openFeedback } from "../../../../store/slices/feedback";

const CrearPoligono = () => {
  const { id } = useParams();
  const [cuadrantes, setcuadrantes] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getAllCuadrantes, responseCuadrantes] = useGetAllMutation();
  const [createPoligono, responseCreate] = useCreatePoligonoMutation();

  const [cuadrante, setcuadrante] = useState({
    cuadrante: id,
    nombre: "",
  });

  const handleChange = useCallback((name, value) => {
    setcuadrante((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleCancel = () => {
    navigate(`/configuracion/cuadrante?id=${id}`);
  };

  useEffect(() => {
    getAllCuadrantes();
    setcuadrante((prev) => ({
      ...prev,
      cuadrante: id,
    }));
  }, [getAllCuadrantes, id]);

  const handleCreate = (e) => {
    e.preventDefault();
    createPoligono(cuadrante);
  };

  useEffect(() => {
    if (responseCreate.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "Poligono creado.",
        })
      );
      navigate(`/configuracion/cuadrante?id=${id}`);
    }

    if (responseCreate.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: responseCreate.error?.data?.message,
        })
      );
    }
    if (responseCuadrantes.isSuccess && responseCuadrantes.data?.data) {
      setcuadrantes(
        responseCuadrantes.data.data.map((el) => ({
          label: el.nombre,
          value: el.codigo,
        }))
      );
    }
  }, [responseCreate.isSuccess, responseCreate.isError, responseCuadrantes.isSuccess]);

  if (responseCuadrantes.isLoading) {
    return <Loading />;
  }

  if (responseCuadrantes.isError) {
    return <Error message="Error al obtener los datos." />;
  }

  return (
    <>
      <HeaderVista start={<Volver label="Volver" to={`/configuracion/cuadrante?id=${id}`} />} titulo="Crear poligono" />
      <form onSubmit={handleCreate}>
        <Container maxWidth="lg" component={"main"}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Input
                label="Nombre del Poligono"
                value={cuadrante.poligono}
                onChange={(valor) => handleChange("nombre", valor)}
                type="text"
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                label="Cuadrante"
                value={cuadrante.cuadrante}
                defaultValue=""
                options={cuadrantes ?? []}
                type="text"
                onChange={(valor) => handleChange("cuadrante", valor)}
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ButtonIcon
                type="button"
                variant="contained"
                color="error"
                noLink
                handleClick={handleCancel}
                startIcon={<CancelOutlined />}
              >
                Cancelar
              </ButtonIcon>
              <ButtonIcon
                type="submit"
                variant="contained"
                color="success"
                noLink
                startIcon={<CheckIcon />}
                styleButton={{ marginLeft: "10px" }}
              >
                Guardar
              </ButtonIcon>
            </Grid>
          </Grid>
          <br />
        </Container>
      </form>
    </>
  );
};

export default CrearPoligono;
