import { Container, Grid } from "@mui/material";
import React from "react";
import { Input, Select } from "../../../../elementos";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getOptions } from "../../../../../store/slices/options";
import ListImages from "../components/Images/ListImages";
import { getInformeFull, updateInformeFull } from "../../../../../store/slices/informes";

const Caniles = ({  semana, editable }) => {
  const listOptions = useSelector(getOptions);
  const dispatch = useDispatch()
  const informeStore = useSelector(getInformeFull, shallowEqual);
  const estadoRejas = [...listOptions.porcentaje];
  estadoRejas.push({label:"No tiene",value:'0.00'});

  const handleChange = (name, value, type) => {
    let informeTemp = JSON.stringify(informeStore)
    informeTemp = JSON.parse(informeTemp)
    informeTemp[`${semana}`].caniles[`${name}`] = value
    dispatch(updateInformeFull(informeTemp));
  };

  return (
    <Container maxWidth="lg" component={"main"}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Select
            defaultValue=""
            label="Funcionamiento de bebederos"
            options={[
              { label: "Funciona", value: 0 },
              { label: "No funciona", value: 1 },
              { label: "No posee", value: 2 },
            ]}
            value={informeStore[`${semana}`]?.caniles?.funcionamiento_bebedero ?? ""}
            onChange={(e) => handleChange("funcionamiento_bebedero", e, "select")}
            name="funcionamiento_bebedero"
            disabled={!editable}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            defaultValue=""
            value={informeStore[`${semana}`]?.caniles?.en_obra ? informeStore[`${semana}`]?.caniles?.en_obra: false}
            label="¿Espacio completo en Obra?"
            options={[
              { label: "Si", value: true },
              { label: "No", value: false },
            ]}
            disabled={!editable}
            onChange={(e) => handleChange("en_obra",e,"select")}
          />
        </Grid>
        {informeStore[`${semana}`]?.caniles?.cantidad_equipos_totales > 0 && (
          <>
            <Grid item xs={12} md={6}>
              <Input
                fullWidth
                type="number"
                label="Cantidad de equipos totales"
                variant="standard"
                value={informeStore[`${semana}`]?.caniles?.cantidad_equipos_totales}
                restricciones={["soloNumeros"]}
                name="totales"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                fullWidth
                type="number"
                label="Cantidad de equipos a reparar o pintar"
                variant="standard"
                value={informeStore[`${semana}`]?.caniles?.cantidad_equipos_a_reparar ?? ""}
                restricciones={["soloNumeros"]}
                onChange={(e) => handleChange("cantidad_equipos_a_reparar", e, "input")}
                name="cantidad_equipos_a_reparar"
                disabled={!editable || informeStore[`${semana}`]?.caniles?.cantidad_equipos_totales === 0}
                min={0}
                max={informeStore[`${semana}`]?.caniles?.cantidad_equipos_totales}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                defaultValue=""
                label="Limpieza de equipos"
                options={listOptions.limpieza}
                value={informeStore[`${semana}`]?.caniles?.limpieza_de_equipos ?? ""}
                onChange={(e) => handleChange("limpieza_de_equipos", e, "select")}
                name="limpieza_de_equipos"
                disabled={!editable || informeStore[`${semana}`]?.caniles?.cantidad_equipos_totales === 0}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={6}>
          <Select
            defaultValue=""
            label="Estado de Solado"
            options={listOptions.porcentaje}
            value={informeStore[`${semana}`]?.caniles?.estado_solado ?? ""}
            onChange={(e) => handleChange("estado_solado", e, "select")}
            name="estado_solado"
            disabled={!editable}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            defaultValue=""
            label="Estado Rejas"
            options={estadoRejas}
            value={informeStore[`${semana}`]?.caniles?.estado_rejas ?? ""}
            onChange={(e) => handleChange("estado_rejas", e, "select")}
            name="estado_rejas"
            disabled={!editable}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Input
            fullWidth
            type="text"
            label="Observaciones"
            variant="standard"
            multiline
            value={informeStore[`${semana}`]?.caniles?.observaciones ?? ""}
            restricciones={[]}
            onChange={(e) => handleChange("observaciones", e, "input")}
            name="observaciones"
            inputProps={{ maxLength: 1000 }}
            disabled={!editable}
          />
        </Grid>
        <Grid item xs={12} md={12} flexWrap={"nowrap"}>
          <ListImages
            imagenes={informeStore[`${semana}`]?.caniles?.imagenes}
            ruta={`${semana}.limpieza.imagenes`}
            handleChange={handleChange}
            editable={editable}
          />
        </Grid>
      </Grid>
      <br />
    </Container>
  );
};

export default Caniles;
