import React from "react";
import Input from "../../../../elementos/Input";
import Select from "../../../../elementos/Select";
import { Container, Grid } from "@mui/material";

export const AreaDeportiva = ({ isDisabled = true, index, areas, setareas, dataAllAtributes, options }) => {
  /* let idAMostrar = index; */
  /*   idAMostrar++; */
  const handleChange = (value, key) => {
    setareas((prev) => ({
      ...prev,
      areaDeportiva: {
        ...prev.areaDeportiva,
        [index]: {
          ...prev.areaDeportiva[index],
          [key]: value,
        },
      },
    }));
  };
  return (
    <Container component={"main"} maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <h3>{areas?.descripcion ? areas?.descripcion : `Area deportiva `}</h3>
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="Tipo area"
            value={areas?.tipo_area}
            defaultValue=""
            options={dataAllAtributes?.tipo_area_deportiva?.map((element) => ({
              label: element.descripcion,
              value: element.codigo,
            }))}
            type="text"
            onChange={handleChange}
            name={"tipo_area"}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="tel"
            label="Cantidad de equipos"
            value={areas?.cant_equipos}
            variant="standard"
            onChange={handleChange}
            disabled={isDisabled}
            restricciones={["soloNumeros"]}
            name="cant_equipos"
            autoFocus
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Select
            label="Tipo de reja"
            value={areas?.tipo_reja}
            defaultValue=""
            options={dataAllAtributes?.tipo_rejas?.map((element) => ({
              label: element.nombre,
              value: element.codigo,
            }))}
            type="text"
            onChange={handleChange}
            name={"tipo_reja"}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="¿Tiene Tejido Perimetral?"
            value={areas?.tejido_perimetral}
            defaultValue=""
            type="text"
            options={[
              { label: "Si", value: 1 },
              { label: "No", value: 0 },
            ]}
            onChange={handleChange}
            name={"tejido_perimetral"}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="En obra"
            value={areas?.en_obra ? areas?.en_obra : "0"}
            defaultValue=""
            options={[
              { label: "Si", value: 1 },
              { label: "No", value: 0 },
            ]}
            type="text"
            onChange={handleChange}
            disabled={isDisabled}
            name={"en_obra"}
          />
        </Grid>
        {/*  <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="tet"
            label="Descripcion"
            value={areas?.descripcion}
            variant="standard"
            onChange={handleChange}
            disabled={isDisabled}
            restricciones={["alfanumerico"]}
            name="descripcion"
            autoFocus
          />
        </Grid> */}
      </Grid>
      <br />
    </Container>
  );
};
