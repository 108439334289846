import { Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Input from "../../../elementos/Input";
import "../../../../assets/css/vistaFormulario.css";
import { useGetAllAtributesOfEEVVMutation } from "../../../../store/api/endpoints/espaciosApiSlice";
import Loading from "../../../elementos/Loading";
import Error from "../../../elementos/Error";
import Select from "../../../elementos/Select";
import { defAtributesUserInput } from "../../../../constants/listAtributesUser";

const FormNuevoEspacio = ({ espacio, setespacio }) => {
  const [getAllAtributesOfAreas, responseAllData] = useGetAllAtributesOfEEVVMutation();
  const [PoligonosFilter, setfilterPoligonos] = useState();

  useEffect(() => {
    Promise.all([getAllAtributesOfAreas()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Retorna los poligonos del cuadrante
   */
  useEffect(() => {
    if (espacio?.cuadrante > 0) {
      const poligonosFiltrados = responseAllData.data?.poligonos.filter(
        (poligono) => espacio?.cuadrante === poligono.cuadrante
      );
      setfilterPoligonos(
        poligonosFiltrados?.map((el) => {
          return { value: el.codigo, label: el.nombre };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [espacio.cuadrante]);

  const changeAttibute = (value, key) => {
    setespacio((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatEspacioVerde = (data, name) => {
    if (!name || data === []) {
      return [
        { label: "SI", value: 1 },
        { label: "NO", value: 0 },
      ];
    }
    if (name === "otro2") {
      return data?.map((element) => ({
        label: element.nombre,
        value: element.codigo,
      }));
    }
    return data?.map((element) => ({
      label:
        name === "comunas"
          ? `Comuna ${element.codigo}`
          : name === "barrios"
          ? element.name
          : name === "espacios"
          ? element.descripcion
          : element.nombre,
      value:
        name === "comunas"
          ? element.codigo
          : name === "barrios"
          ? element.id_barrio
          : name === "espacios"
          ? element.id_ev_tipo
          : element.codigo,
    }));
  };

  if (responseAllData.isLoading) {
    return <Loading />;
  }

  if (responseAllData.isError) {
    return <Error message="Error al obtener los datos." />;
  }
  if (responseAllData.isSuccess && defAtributesUserInput && espacio) {
    return (
      <Container component={"main"} maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Select
              label="Tipo"
              value={espacio?.tipo}
              defaultValue=""
              options={formatEspacioVerde(responseAllData?.data?.tipos_eevv, "espacios")}
              type="text"
              onChange={changeAttibute}
              name={"tipo"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label={"Codigo"}
              value={espacio.codigo}
              onChange={changeAttibute}
              type={"number"}
              restricciones={"alfanumeric"}
              name={"codigo"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label={"Nombre"}
              value={espacio.nombre_eevv}
              onChange={changeAttibute}
              type={"text"}
              restricciones={"alfanumeric"}
              name={"nombre_eevv"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label={"Direccion"}
              value={espacio.direccion}
              onChange={changeAttibute}
              type={"text"}
              restricciones={"alfanumeric"}
              name={"direccion"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Comuna"
              value={espacio?.comuna}
              defaultValue=""
              options={formatEspacioVerde(responseAllData?.data?.comunas, "comunas")}
              type="text"
              onChange={changeAttibute}
              name={"comuna"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Barrio"
              value={espacio?.barrio}
              defaultValue=""
              options={formatEspacioVerde(responseAllData?.data?.barrios, "barrios")}
              type="text"
              onChange={changeAttibute}
              name={"barrio"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Area"
              value={espacio?.area}
              defaultValue=""
              options={formatEspacioVerde(responseAllData?.data?.areas, "areas")}
              type="text"
              onChange={changeAttibute}
              name={"area"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Empresa prestadora"
              value={espacio?.empresa_prestadora}
              defaultValue=""
              options={formatEspacioVerde(responseAllData?.data?.empresas, "otro2")}
              type="text"
              onChange={changeAttibute}
              name={"empresa_prestadora"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Cuadrante"
              value={espacio?.cuadrante}
              defaultValue=""
              options={formatEspacioVerde(responseAllData?.data?.cuadrantes, "otro")}
              type="text"
              onChange={changeAttibute}
              name={"cuadrante"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <div style={{ display: espacio.cuadrante ? "block" : "none" }}>
              <Select
                label="Poligono"
                value={espacio?.poligono}
                defaultValue=""
                options={PoligonosFilter ?? []}
                type="text"
                onChange={changeAttibute}
                name={"poligono"}
              />
            </div>
          </Grid>

          <Grid item xs={12} md={4}>
            <Input
              label={"Superficie seca"}
              value={espacio.superficie_seca}
              onChange={changeAttibute}
              type={"text"}
              restricciones={"alfanumeric"}
              name={"superficie_seca"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label={"Superficie verde"}
              value={espacio.superficie_verde}
              onChange={changeAttibute}
              type={"text"}
              restricciones={"alfanumeric"}
              name={"superficie_verde"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Punto verde"
              value={espacio?.punto_verde}
              defaultValue=""
              options={formatEspacioVerde([], "")}
              type="text"
              onChange={changeAttibute}
              name={"punto_verde"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Centro jubilados"
              value={espacio?.cent_jubilados}
              defaultValue=""
              options={formatEspacioVerde([], "")}
              type="text"
              onChange={changeAttibute}
              name={"cent_jubilados"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Calesita"
              value={espacio?.calesita}
              defaultValue=""
              options={formatEspacioVerde([], "")}
              type="text"
              onChange={changeAttibute}
              name={"calesita"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Mastil"
              value={espacio?.mastil}
              defaultValue=""
              options={formatEspacioVerde([], "")}
              type="text"
              onChange={changeAttibute}
              name={"mastil"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Monumento"
              value={espacio?.monumento}
              defaultValue=""
              options={formatEspacioVerde([], "")}
              type="text"
              onChange={changeAttibute}
              name={"monumento"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Fuente"
              value={espacio?.fuente}
              defaultValue=""
              options={formatEspacioVerde([], "")}
              type="text"
              onChange={changeAttibute}
              name={"fuente"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Obra de arte"
              value={espacio?.obra_arte}
              defaultValue=""
              options={formatEspacioVerde([], "")}
              type="text"
              onChange={changeAttibute}
              name={"obra_arte"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Guarda parque"
              value={espacio?.guarda_parque}
              defaultValue=""
              options={formatEspacioVerde([], "")}
              type="text"
              onChange={changeAttibute}
              name={"guarda_parque"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Garita"
              value={espacio?.garita}
              defaultValue=""
              options={formatEspacioVerde([], "")}
              type="text"
              onChange={changeAttibute}
              name={"garita"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Otro tipo de cerramiento"
              value={espacio?.otro_tipo_cerramiento}
              defaultValue=""
              options={formatEspacioVerde(responseAllData?.data?.tipo_cerramiento, "otro")}
              type="text"
              onChange={changeAttibute}
              name={"otro_tipo_cerramiento"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Clase"
              value={espacio?.clase}
              defaultValue=""
              options={formatEspacioVerde(responseAllData?.data?.clases, "clases")}
              type="text"
              onChange={changeAttibute}
              name={"clase"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Estado"
              value={espacio?.estado}
              defaultValue=""
              options={formatEspacioVerde(responseAllData?.data?.estados, "clases")}
              type="text"
              onChange={changeAttibute}
              name={"estado"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Es emblematico"
              value={espacio?.es_emblematico}
              defaultValue=""
              options={formatEspacioVerde([], "")}
              type="text"
              onChange={changeAttibute}
              name={"es_emblematico"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Tiene bolardos"
              value={espacio?.bolardo}
              defaultValue=""
              options={formatEspacioVerde([], "")}
              type="text"
              onChange={changeAttibute}
              name={"bolardo"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Tiene muretes"
              value={espacio?.muretes}
              defaultValue=""
              options={formatEspacioVerde([], "")}
              type="text"
              onChange={changeAttibute}
              name={"muretes"}
            />
          </Grid>
          {/*  <Grid item xs={12} md={4}>
            <Select
              label="Poligonos"
              value={espacio?.poligono}
              defaultValue=""
              options={formatEspacioVerde(responsePoligonos?.data, "otro")}
              type="text"
              onChange={changeAttibute}
              name={"poligono"}
            />
          </Grid> */}
          <Grid item xs={12} md={12}>
            <p style={{ backgroundColor: "#C9F3CB", padding: "16px 16px", fontSize: " 1.1rem", fontWeight: "500" }}>
              Limpieza E Higiene
            </p>
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label={"Cantidad cestos"}
              value={espacio.cant_cestos}
              onChange={changeAttibute}
              type={"tel"}
              restricciones={"soloNumeros"}
              name={"cant_cestos"}
              min={0}
              max={200}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <p style={{ backgroundColor: "#C9F3CB", padding: "16px 16px", fontSize: " 1.1rem", fontWeight: "500" }}>
              Mantenimiento, caminos y cerramientos
            </p>
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Con cesped"
              value={espacio?.con_cesped}
              defaultValue=""
              options={formatEspacioVerde([], "")}
              type="text"
              onChange={changeAttibute}
              name={"con_cesped"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Con cantero"
              value={espacio?.con_cantero}
              defaultValue=""
              options={formatEspacioVerde([], "")}
              type="text"
              onChange={changeAttibute}
              name={"con_cantero"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Tipo de riego"
              value={espacio?.tipo_riego}
              defaultValue=""
              options={formatEspacioVerde([], "")}
              type="text"
              onChange={changeAttibute}
              name={"tipo_riego"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Con camino interno"
              value={espacio?.con_camino_interno}
              defaultValue=""
              options={formatEspacioVerde([], "")}
              type="text"
              onChange={changeAttibute}
              name={"con_camino_interno"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Camino int. con solado suelto"
              value={espacio?.con_cantero}
              defaultValue=""
              options={formatEspacioVerde([], "")}
              type="text"
              onChange={changeAttibute}
              name={"con_camino_int_solado_suelto"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Tiene vereda"
              value={espacio?.tiene_vereda}
              defaultValue=""
              options={formatEspacioVerde([], "")}
              type="text"
              onChange={changeAttibute}
              name={"tiene_vereda"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Con cerramiento"
              value={espacio?.con_cerramiento}
              defaultValue=""
              options={formatEspacioVerde([], "")}
              type="text"
              onChange={changeAttibute}
              name={"con_cerramiento"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Tipo de reja cantero"
              value={espacio?.tipo_rejas_canteros}
              defaultValue=""
              options={formatEspacioVerde([], "")}
              type="text"
              onChange={changeAttibute}
              name={"tipo_rejas_canteros"}
            />
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return <h2>Ocurrio un error.</h2>;
  }
};

export default FormNuevoEspacio;
