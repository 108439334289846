/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Input, Loading, Select, ButtonIcon, Divider } from "../../elementos";
import { listValuesEspaciosVerdes } from "../../../constants/listaEspacioVerde";
import { useUpdateEspacioVerdeMutation } from "../../../store/api/endpoints/espaciosApiSlice";
import { openFeedback } from "../../../store/slices/feedback";
import { getEditAreas, getEspacioVerde } from "../../../store/slices/espacioverdes";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import { Container, Grid } from "@mui/material";

export const EspacioVerdeForm = ({ isEditable, id, setisEditable }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getValuesOfEditAreas = useSelector(getEditAreas, shallowEqual);
  const getespacioVerde = useSelector(getEspacioVerde, shallowEqual);
  const [espacioVerde, setespacioVerde] = useState();
  const [updateEspacio, responseUpdate] = useUpdateEspacioVerdeMutation();
  const [PoligonosFilter, setfilterPoligonos] = useState(getValuesOfEditAreas.poligonos);
  const handleLimpieza = /* useCallback( */ (value, name) => {
    if (name === "cuadrante") {
      setespacioVerde((prev) => ({
        ...prev,
        poligono: "",
      }));
    }

    setespacioVerde((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    setespacioVerde(getespacioVerde);
  }, [getespacioVerde]);

  const handleSubmit = () => {
    updateEspacio({ espacioVerde, id });
    setisEditable(false);
  };

  const handleCancel = () => {
    setisEditable(false);
  };

  useEffect(() => {
    if (!id) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (responseUpdate.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: responseUpdate?.error?.message?.error ?? "Error al guardar el espacio verde.",
        })
      );
    }
    if (responseUpdate.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se actualizó el espacio verde!.",
        })
      );
      navigate(`/espaciosverdes/espacio?id=${id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseUpdate.isSuccess, responseUpdate.isError]);

  /**
   * Valida si cuadrante o poligonos se selecciono
   * @returns cuadrante -> los poligonos del cuadrante
   * @returns poligonos -> cuadrante al que pertenece
   */

  useEffect(() => {
    if (espacioVerde?.cuadrante > 0) {
      setfilterPoligonos(
        getValuesOfEditAreas?.poligonos.filter((poligono) => espacioVerde?.cuadrante === poligono.cuadrante)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [espacioVerde]);

  if (responseUpdate.isLoading || !espacioVerde) {
    return <Loading />;
  }

  return (
    <Container component="main" maxWidth="lg">
      <Grid container spacing={2}>
        <br />
        <Grid container spacing={2}>
          {listValuesEspaciosVerdes?.map((element, index) => {
            return (
              element.type === "input" && (
                <Grid item xs={12} md={4} key={index}>
                  <Input
                    type="text"
                    disabled={!isEditable}
                    value={espacioVerde[element?.value] ? espacioVerde[element?.value] : 0}
                    variant="standard"
                    onChange={handleLimpieza}
                    label={element.name}
                    name={element.value}
                  />
                </Grid>
              )
            );
          })}
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Select
              label="Comunas"
              disabled={!isEditable}
              value={espacioVerde?.comuna}
              defaultValue=""
              options={getValuesOfEditAreas?.comunas}
              type="text"
              onChange={handleLimpieza}
              required
              name={"comuna"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Empresa prestadora"
              disabled={!isEditable}
              value={espacioVerde?.empresa_prestadora}
              defaultValue=""
              options={getValuesOfEditAreas?.empresas}
              type="text"
              onChange={handleLimpieza}
              name={"empresa_prestadora"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Con cantero"
              disabled={!isEditable}
              value={espacioVerde?.con_cantero}
              defaultValue=""
              options={[
                { label: "Si", value: 1 },
                { label: "No", value: 0 },
              ]}
              type="text"
              onChange={handleLimpieza}
              name={"con_cantero"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Punto verde"
              disabled={!isEditable}
              value={espacioVerde?.punto_verde}
              defaultValue=""
              options={[
                { label: "Si", value: 1 },
                { label: "No", value: 0 },
              ]}
              type="text"
              onChange={handleLimpieza}
              name={"punto_verde"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Tipo de rejas en canteros"
              disabled={!isEditable}
              value={espacioVerde?.tipo_rejas_canteros}
              defaultValue=""
              options={getValuesOfEditAreas?.tipo_rejas}
              type="text"
              onChange={handleLimpieza}
              name={"tipo_rejas_canteros"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Con cerramiento"
              disabled={!isEditable}
              value={espacioVerde?.con_cerramiento}
              defaultValue=""
              options={[
                { label: "Si", value: 1 },
                { label: "No", value: 0 },
              ]}
              type="text"
              onChange={handleLimpieza}
              name={"con_cerramiento"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Con camino interno solado suelto"
              disabled={!isEditable}
              value={espacioVerde?.con_camino_int_solado_suelto}
              defaultValue=""
              options={[
                { label: "Si", value: 1 },
                { label: "No", value: 0 },
              ]}
              type="text"
              onChange={handleLimpieza}
              name={"con_camino_int_solado_suelto"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Garita"
              disabled={!isEditable}
              value={espacioVerde?.garita}
              defaultValue=""
              options={[
                { label: "Si", value: 1 },
                { label: "No", value: 0 },
              ]}
              type="text"
              onChange={handleLimpieza}
              name={"garita"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Guarda parque"
              disabled={!isEditable}
              value={espacioVerde?.guarda_parque}
              defaultValue=""
              options={[
                { label: "Si", value: 1 },
                { label: "No", value: 0 },
              ]}
              type="text"
              onChange={handleLimpieza}
              name={"guarda_parque"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Fuente"
              disabled={!isEditable}
              value={espacioVerde?.fuente}
              defaultValue=""
              options={[
                { label: "Si", value: 1 },
                { label: "No", value: 0 },
              ]}
              type="text"
              onChange={handleLimpieza}
              name={"fuente"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Monumento"
              disabled={!isEditable}
              value={espacioVerde?.monumento}
              defaultValue=""
              options={[
                { label: "Si", value: 1 },
                { label: "No", value: 0 },
              ]}
              type="text"
              onChange={handleLimpieza}
              name={"monumento"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Mastil"
              disabled={!isEditable}
              value={espacioVerde?.mastil}
              defaultValue=""
              options={[
                { label: "Si", value: 1 },
                { label: "No", value: 0 },
              ]}
              type="text"
              onChange={handleLimpieza}
              name={"mastil"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Calesita"
              disabled={!isEditable}
              value={espacioVerde?.calesita}
              defaultValue=""
              options={[
                { label: "Si", value: 1 },
                { label: "No", value: 0 },
              ]}
              type="text"
              onChange={handleLimpieza}
              name={"calesita"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Con camino interno"
              disabled={!isEditable}
              value={espacioVerde?.con_camino_interno}
              defaultValue=""
              options={[
                { label: "Si", value: 1 },
                { label: "No", value: 0 },
              ]}
              type="text"
              onChange={handleLimpieza}
              name={"con_camino_interno"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Tipo riego"
              disabled={!isEditable}
              value={espacioVerde?.tipo_riego}
              defaultValue=""
              options={getValuesOfEditAreas?.opciones_riego}
              type="text"
              onChange={handleLimpieza}
              name={"tipo_riego"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Cuadrante"
              disabled={!isEditable}
              value={espacioVerde?.cuadrante}
              defaultValue=""
              options={getValuesOfEditAreas?.cuadrantes}
              type="text"
              onChange={handleLimpieza}
              name={"cuadrante"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Poligono"
              disabled={!isEditable}
              value={espacioVerde?.poligono}
              defaultValue=""
              options={PoligonosFilter}
              type="text"
              onChange={handleLimpieza}
              name={"poligono"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Barrio"
              disabled={!isEditable}
              value={espacioVerde?.barrio}
              defaultValue=""
              options={getValuesOfEditAreas?.barrios}
              type="text"
              onChange={handleLimpieza}
              name={"barrio"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Area"
              disabled={!isEditable}
              value={espacioVerde?.area}
              defaultValue=""
              options={getValuesOfEditAreas?.areas}
              type="text"
              onChange={handleLimpieza}
              name="area"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Muretes"
              disabled={!isEditable}
              value={espacioVerde?.muretes}
              defaultValue=""
              options={[
                { label: "Si", value: 1 },
                { label: "No", value: 0 },
              ]}
              type="text"
              onChange={handleLimpieza}
              name={"muretes"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Bolardo"
              disabled={!isEditable}
              value={espacioVerde?.bolardo}
              defaultValue=""
              options={[
                { label: "Si", value: 1 },
                { label: "No", value: 0 },
              ]}
              type="text"
              onChange={handleLimpieza}
              name={"bolardo"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Es emblematico"
              disabled={!isEditable}
              value={espacioVerde?.es_emblematico}
              defaultValue=""
              options={[
                { label: "Si", value: 1 },
                { label: "No", value: 0 },
              ]}
              type="text"
              onChange={handleLimpieza}
              name={"es_emblematico"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Con cesped"
              disabled={!isEditable}
              value={espacioVerde?.con_cesped}
              defaultValue=""
              options={[
                { label: "Si", value: 1 },
                { label: "No", value: 0 },
              ]}
              type="text"
              onChange={handleLimpieza}
              name={"con_cesped"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Centro de jubilados"
              disabled={!isEditable}
              value={espacioVerde?.cent_jubilados}
              options={[
                { label: "Si", value: 1 },
                { label: "No", value: 0 },
              ]}
              type="text"
              onChange={handleLimpieza}
              name={"cent_jubilados"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Estado"
              disabled={!isEditable}
              defaultValue=""
              value={espacioVerde?.estado}
              options={getValuesOfEditAreas?.estados}
              type="text"
              onChange={handleLimpieza}
              name={"estado"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Otro tipo de cerramiento"
              disabled={!isEditable}
              value={espacioVerde?.otro_tipo_cerramiento}
              defaultValue=""
              options={getValuesOfEditAreas?.tipo_cerramiento}
              type="text"
              onChange={handleLimpieza}
              name={"otro_tipo_cerramiento"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="En obra"
              disabled={!isEditable}
              value={espacioVerde?.en_obra}
              options={[
                { label: "NO", value: 0 },
                { label: "SI", value: 1 },
              ]}
              onChange={handleLimpieza}
              name={"en_obra"}
            />
          </Grid>
        </Grid>
        <br />
        <br />
        {isEditable && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6} md={6}>
              <ButtonIcon
                color="error"
                handleClick={handleCancel}
                variant={"contained"}
                noLink
                startIcon={<CancelIcon />}
              >
                Cancelar
              </ButtonIcon>
            </Grid>
            <Grid item xs={6} md={4}>
              <ButtonIcon
                color="success"
                handleClick={handleSubmit}
                variant={"contained"}
                noLink
                startIcon={<CheckIcon />}
              >
                Guardar
              </ButtonIcon>
            </Grid>
          </Grid>
        )}
      </Grid>
      <br />
    </Container>
  );
};
