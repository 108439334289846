import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Typography } from "@mui/material";
import { ButtonIcon, Loading } from "../../../elementos";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

function createData(fecha, usuario, id) {
  return { fecha, usuario, id };
}

const TitleTable = ({ id }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between", padding: "0.45rem 0.45rem" }}>
      <Typography textAlign={"left"} variant="h6" id="tableTitle" component="span" fontSize={"1rem"}>
        Ultimos relevamientos
      </Typography>
      <div>
        <ButtonIcon to={`/relevamiento/crear?id=${id}`} variant={"contained"} styleButton={{ color: "white" }}>
          Crear
        </ButtonIcon>
        <ButtonIcon to={`/relevamiento/listado?id=${id}`} variant={"contained"} styleButton={{ color: "white" }}>
          Ver todos
        </ButtonIcon>
      </div>
    </div>
  );
};

export default function TableListaRelevamientos({ relevamientos, id_espacio_verde }) {
  const navigate = useNavigate();
  const dataRows = [];
  if (relevamientos) {
    relevamientos?.forEach((item, i) => {
      dataRows.push(
        createData(
          `${item.fecha.split("-").join("/")} ${item.hora.split(":").splice(0, 2).join(":")}`,
          item.usuario,
          item.codigo
        )
      );
    });
  }
  const handleNavigate = (id_relevamiento) => {
    navigate(`/espaciosverdes/relevamiento?id=${id_relevamiento}&espacio_verde=${id_espacio_verde}`);
  };
  if (!relevamientos) {
    <Loading />;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <TitleTable id={id_espacio_verde} />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 150 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Fecha y hora</TableCell>
                <TableCell align="left">Usuario</TableCell>
                <TableCell align="left">Ver</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataRows.length > 0 ? (
                dataRows.map((row) => (
                  <TableRow key={row.codigo} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.fecha}
                    </TableCell>
                    <TableCell align="left">{row.usuario}</TableCell>
                    <TableCell align="left" onClick={() => handleNavigate(row.id)} style={{ cursor: "pointer" }}>
                      <SearchIcon />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow sx={{ textAlign: "center", "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell align="center" colSpan={6}>
                    No hay registros
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
