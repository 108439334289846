import React from "react";
import Input from "../../../../elementos/Input";
import Select from "../../../../elementos/Select";
import { Container, Grid } from "@mui/material";

export const Caniles = ({ isDisabled = false, areas, setareas, index, dataAllAtributes }) => {
  /*   let indexCaniles = index; */
  /*   indexCaniles++; */
  const handleChange = (value, key) => {
    setareas((prev) => ({
      ...prev,
      caniles: {
        ...prev.caniles,
        [index]: {
          ...prev.caniles[index],
          [key]: value,
        },
      },
    }));
  };
  return (
    <Container component={"main"} maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <h3>Canil</h3>
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Cantidad de equipos"
            value={areas?.cant_equipos}
            disabled={isDisabled}
            type="tel"
            name={`cant_equipos`}
            restricciones={["soloNumeros"]}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="Tipos solados"
            value={areas?.tipo_solados}
            defaultValue=""
            options={dataAllAtributes?.solado_caniles?.map((element) => ({
              label: element.descripcion,
              value: element.codigo,
            }))}
            type="text"
            onChange={handleChange}
            disabled={isDisabled}
            name={"tipo_solados"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Cantidad de bolsas exp"
            disabled={isDisabled}
            type="tel"
            name={`cant_bolsas_exp`}
            value={areas?.cant_bolsas_exp}
            restricciones={["soloNumeros"]}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Cantidad de bebederos"
            value={areas?.cant_bebederos}
            disabled={isDisabled}
            type="tel"
            name={`cant_bebederos`}
            restricciones={["soloNumeros"]}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="¿Tiene Rejas?"
            value={areas?.rejas ? areas?.rejas : "0"}
            defaultValue=""
            options={[
              { label: "Si", value: 1 },
              { label: "No", value: 0 },
            ]}
            type="text"
            onChange={handleChange}
            disabled={isDisabled}
            name={"rejas"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="En obra"
            value={areas?.en_obra ? areas?.en_obra : "0"}
            defaultValue=""
            options={[
              { label: "Si", value: 1 },
              { label: "No", value: 0 },
            ]}
            type="text"
            onChange={handleChange}
            disabled={isDisabled}
            name={"en_obra"}
          />
        </Grid>
      </Grid>
      <br />
    </Container>
  );
};
