import { configureStore } from "@reduxjs/toolkit";

import { setupListeners } from "@reduxjs/toolkit/query";
import { tiendasApi } from "./api/api";

import { authSlice } from "./slices/auth";
import { sidebarSlice } from "./slices/sidebar";
import { feedbackSlice } from "./slices/feedback";
import { relevamientoSlice } from "./slices/relevamiento";
import { espacioVerdeSlice } from "./slices/espacioverdes";
import { typesOFFormSlice } from "./slices/types";
import { optionsSlice } from "./slices/options";
import { clienteSlice } from "./slices/clientes";
import { poligonosSlice } from "./slices/poligonos";
import { cuadrantesSlice } from "./slices/cuadrantes";
import { informeSlice } from "./slices/informes";
import { comunasSlice } from "./slices/comunas";

export const store = configureStore({
  reducer: {
    [tiendasApi.reducerPath]: tiendasApi.reducer,
    auth: authSlice.reducer,
    sidebar: sidebarSlice.reducer,
    feedback: feedbackSlice.reducer,
    relevamiento: relevamientoSlice.reducer,
    espacioVerde: espacioVerdeSlice.reducer,
    typesOfValues: typesOFFormSlice.reducer,
    options: optionsSlice.reducer,
    clientes: clienteSlice.reducer,
    poligonos: poligonosSlice.reducer,
    cuadrantes: cuadrantesSlice.reducer,
    informes: informeSlice.reducer,
    comunas: comunasSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(tiendasApi.middleware),
});

setupListeners(store.dispatch);
