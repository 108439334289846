import { useState, useEffect } from "react";

export const useGetCurrentPosition = () => {
  const [location, setLocation] = useState({ latitude: null, longitude: null });

  const obtenerUbicacion = () => {
    if (navigator.geolocation) {
      navigator.permissions.query({ name: "geolocation" }).then(async function (result) {
        if (result.state === "granted" || result.state === "prompt") {
          try {
            const position = await new Promise((resolve, reject) => {
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  resolve({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                  });
                },
                (error) => {
                  reject(error);
                }
              );
            });
            setLocation(position);
          } catch (error) {
            console.error("Error obtaining location:", error);
          }
        } else if (result.state === "denied") {
          console.error("Permission denied.");
        }
      });
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    obtenerUbicacion(); // Obtener la ubicación al cargar el componente
  }, []);

  return { location, obtenerUbicacion };
};
