import React from "react";
import Select from "../../../../elementos/Select";
import { Container, Grid } from "@mui/material";

const Caminos = ({ optionCaminos, valueCaminos }) => {
  return (
    <Container component={"main"} maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Select
            defaultValue=""
            value={valueCaminos?.as_vegetacion_espontanea}
            label="Vegetacion espontanea"
            options={optionCaminos?.porcentaje}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            defaultValue=""
            value={valueCaminos?.as_estado_caminos_int}
            label="Estado de caminos internos"
            options={optionCaminos?.atencion2}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            defaultValue=""
            value={valueCaminos?.as_nivel_cobertura_solado_suelto}
            label="Cobetura de solado"
            options={optionCaminos?.porcentaje}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            defaultValue=""
            value={valueCaminos?.as_limpieza_int}
            label="Cobetura de solado"
            options={optionCaminos?.porcentaje}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            defaultValue=""
            value={valueCaminos?.pintura_de_muretes}
            label="Pintura murertes"
            options={optionCaminos?.porcentaje}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            defaultValue=""
            value={valueCaminos?.estado_de_bolardos}
            label="Estado de bolardos"
            options={optionCaminos?.limpieza}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            defaultValue=""
            value={valueCaminos?.limpieza_rejas}
            label="Limpieza de rejas"
            options={optionCaminos?.limpieza}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            defaultValue=""
            value={valueCaminos?.estado_de_rejas}
            label="Estado de rejas"
            options={optionCaminos?.porcentaje}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            defaultValue=""
            value={valueCaminos?.pintura_de_rejas}
            label="Pintura de rejas"
            options={optionCaminos?.porcentaje}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            defaultValue=""
            value={valueCaminos?.as_limpieza_veredas}
            label="Limpieza de veredas"
            options={optionCaminos?.limpieza}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            defaultValue=""
            value={valueCaminos?.as_limpieza_caminos_int}
            label="Limpieza de caminos internos"
            options={optionCaminos?.limpieza}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            defaultValue=""
            value={valueCaminos?.pintura_de_muretes}
            label="Pintura murertes"
            options={optionCaminos?.porcentaje}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            defaultValue=""
            value={valueCaminos?.limpieza_rejas}
            label="Limpieza de rejas"
            options={optionCaminos?.limpieza}
            disabled={true}
          />
        </Grid>
      </Grid>
      <br />
    </Container>
  );
};

export default Caminos;
