/* eslint-disable no-sparse-arrays */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Container } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BodyProgramarInspeccion from "./BodyProgramarInspeccion";
import { useDispatch } from "react-redux";
import {
  useDeleteProgramacionesMutation,
  useGetProgramacionMutation,
  useUpdateProgramacionMutation,
} from "../../../../store/api/endpoints/informesApiSlice";
import { openFeedback } from "../../../../store/slices/feedback";
import { HeaderVista, ConfirmDelete, ButtonIcon, Loading, Volver, Divider } from "../../../elementos";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { completeDateAndNotEqualDates } from "../../../../utils/validateDate";

const UpdateProgramacionInspeccion = () => {
  const [error, seterror] = useState(false)
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const actualTitle = useRef(null);
  const [programacion, setprogramacion] = useState({
    fecha_desde: "",
    fecha_hasta: "",
    semana: "",
    mes: "",
    anio: new Date().getFullYear(),
  });
  const id_programacion = searchParams.get("id_programacion");
  const [getProgramacion, responseProgramacion] = useGetProgramacionMutation();
  const [deleteProgramacion, responseDelete] = useDeleteProgramacionesMutation();
  const [updateProgramacion, responseUpdate] = useUpdateProgramacionMutation();

  const handleUpdate = () => {
    if (!programacion.fecha_desde || !programacion.fecha_hasta || !programacion.mes || !programacion.anio) {
      dispatch(
        openFeedback({
          severity: "error",
          message: "Debe completar todos los campos.",
        })
      );
      return;
    }
    if(error){
      dispatch(
        openFeedback({
          severity: "error",
          message: "Los valores ingresados son invalidos",
        })
      );
      return;
    }
    updateProgramacion({ body: programacion, id: id_programacion });
  };

  const handleCancel = () => {
    navigate("/configuracion/inspecciones");
  };

  useEffect(() => {
    getProgramacion(id_programacion);
  }, []);

  useEffect(() => {
    if (responseProgramacion.isSuccess) {
      let formatInspection = {
        fecha_desde: responseProgramacion.data.inspeccion.fecha_desde,
        fecha_hasta: responseProgramacion.data.inspeccion.fecha_hasta,
        semana: responseProgramacion.data.inspeccion.semana,
        anio: responseProgramacion.data.inspeccion.periodo.slice(0, 4),
        mes: responseProgramacion.data.inspeccion.periodo.slice(4, 6),
      };
      let titleProgramacion = "Periodo de inspecion ";
      actualTitle.current = titleProgramacion;
      setprogramacion(formatInspection);
    }
    if (responseUpdate.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "Registro actualizado.",
        })
      );
      navigate(`/configuracion/inspecciones`);
    }
    if (responseUpdate.isError) {
      if (responseUpdate.error.originalStatus === 400) {
        dispatch(
          openFeedback({
            severity: "error",
            message: responseUpdate?.error?.data,
          })
        );
      } else {
        dispatch(
          openFeedback({
            severity: "error",
            message: "Error al actualizar el registro.",
          })
        );
      }
    }
  }, [responseProgramacion.isSuccess, responseUpdate.isSuccess, , responseUpdate.isError]);

  if (responseProgramacion.isLoading || responseUpdate.isLoading) {
    return <Loading />;
  }

  //Editar fecha desde , hasta y semana
  return (
    <Container component={"main"} maxWidth="lg">
      <HeaderVista
        titulo={actualTitle.current}
        start={<Volver label="Volver" to={`/configuracion/inspecciones`} />}
        end={
          <ConfirmDelete
            id={id_programacion}
            slice={deleteProgramacion}
            response={responseDelete}
            returnUrl={`/configuracion/inspecciones`}
            variant="outlined"
            size="small"
          />
        }
      />
      <BodyProgramarInspeccion programacion={programacion} setprogramacion={setprogramacion} seterror={seterror}/>
      {completeDateAndNotEqualDates(programacion) && (
        <p style={{ color: "orange", fontSize: "1rem", fontWeight: "bold" }}>Las fechas desde y hasta son iguales.</p>
      )}
      <Divider />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-evenly",
        }}
      >
        <ButtonIcon
          startIcon={<CloseIcon />}
          styleButton={{ color: "#D32F2F" }}
          size="small"
          color="error"
          variant="outlined"
          noLink
          handleClick={handleCancel}
        >
          Cancelar
        </ButtonIcon>
        <ButtonIcon
          startIcon={<CheckIcon />}
          styleButton={{ color: "#7bb57d" }}
          size="small"
          variant="outlined"
          color="success"
          noLink
          handleClick={handleUpdate}
        >
          Guardar
        </ButtonIcon>
      </Box>
      <br />
    </Container>
  );
};

export default UpdateProgramacionInspeccion;
