export const checkIfAllAtributesAretrueCaniles = (obj, semana) => {
  if (!obj?.estado_solado || !obj?.estado_rejas || obj?.funcionamiento_bebedero === null) {
    return true;
  }
  if ((obj?.imagenes.length === 0 && semana === 1) ) {
    return true;
  }
  if (obj?.cantidad_equipos_totales > 0) {
    if (!obj?.cantidad_equipos_a_reparar || !obj?.cantidad_equipos_a_reparar === 0) {
      return true;
    }
    if (!obj?.limpieza_de_equipos) {
      return true;
    }
  }
  return false;
};

export const checkIfAllAtributesAretrueDeportiva = (obj, semana) => {
  if (!obj?.estado_solado) {
    return true;
  }
  if (obj?.imagenes.length === 0 && semana === 1) {
    return true;
  }
  if (obj?.cantidad_equipos_totales > 0) {
    if (!obj?.cantidad_equipos_a_reparar && !obj?.cantidad_equipos_a_reparar === 0) {
      return true;
    }
  }

  return false;
};

export const checkIfAllAtributesAretruePostas = (obj, semana) => {
  if (!obj?.estado_solado) {
    return true;
  }
  if (obj?.imagenes.length === 0 && semana === 1) {
    return true;
  }
  if (obj?.cantidad_equipos_totales > 0) {
    if (!obj?.cantidad_equipos_a_reparar && obj?.cantidad_equipos_a_reparar !== 0) {
      return true;
    }
  }
  return false;
};

export const checkIfAllAtributesAretrueMobiliario = (obj, semana) => {
  if (obj?.imagenes.length === 0 && semana === 1) {
    return true;
  }
  if (obj?.bancos.totales > 0) {
    if (
      (!obj?.bancos?.grafiteados && obj?.bancos?.grafiteados !== 0) ||
      (!obj?.bancos?.defectuosos && obj?.bancos?.defectuosos !== 0)
    ) {
      return true;
    }
  }
  if (obj?.mesas.totales > 0) {
    if (
      (!obj?.mesas?.grafiteados && obj?.mesas?.grafiteados !== 0) ||
      (!obj?.mesas?.defectuosos && obj?.mesas?.defectuosos !== 0)
    ) {
      return true;
    }
  }
  if (obj?.bicicleteros.totales > 0) {
    if (
      (!obj?.bicicleteros?.grafiteados && obj?.bicicleteros?.grafiteados !== 0) ||
      (!obj?.bicicleteros?.defectuosos && obj?.bicicleteros?.defectuosos !== 0)
    ) {
      return true;
    }
  }
  if (obj?.bebederos.totales > 0) {
    if (
      (!obj?.bebederos?.grafiteados && obj?.bebederos?.grafiteados !== 0) ||
      (!obj?.bebederos?.defectuosos && obj?.bebederos?.defectuosos !== 0)
    ) {
      return true;
    }
  }
  if (obj?.carteles.totales > 0) {
    if (
      (!obj?.carteles?.rotos && obj?.carteles?.rotos !== 0) ||
      (!obj?.carteles?.ploteo_defectuoso && obj?.carteles?.ploteo_defectuoso !== 0)
    ) {
      return true;
    }
  }
  return false;
};

export const checkIfAllAtributesAretruePatios = (obj, semana) => {
  if (!obj?.estado_del_solado || !obj?.estado_rejas) {
    return true;
  }

  if (obj?.imagenes.length === 0 && semana === 1) {
    return true;
  }

  if (obj?.cantidad_equipos_totales > 0) {
    if (!obj?.cantidad_equipos_a_reparar && obj?.cantidad_equipos_a_reparar !== 0) {
      return true;
    }
    if (!obj?.limpieza_de_equipos) {
      return true;
    }
  }
  return false;
};

export const checkIfAllAtributesAretrueCanteros = (obj, semana) => {
  if (!obj?.cobertura || !obj?.estado) {
    return true;
  }
  if (obj?.imagenes.length === 0 && semana === 1) {
    return true;
  }
  return false;
};

export const checkIfAllAtributesAretrueCesped = (obj, semana) => {
  if (!obj?.cobertura || !obj?.estado) {
    return true;
  }
  if (obj?.imagenes.length === 0 && semana === 1) {
    return true;
  }
  return false;
};

export const checkIfAllAtributesAretrueLimpieza = (obj, semana) => {
  if (!obj?.general) {
    return true;
  }
  if (obj?.imagenes.length === 0 && semana === 1) {
    return true;
  }
  return false;
};
