/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import WarningIcon from "@mui/icons-material/Warning";
import SearchIcon from "@mui/icons-material/Search";

import {
  useDownloadRelevamientoMutation,
  useGetRelevamientosMutation,
} from "../../../../store/api/endpoints/relevamientoApiSlice";
import { ButtonIcon, Loading, Error } from "../../../elementos/";
import "../../../../assets/css/table.css";
import { useState } from "react";

export default function ListaRelevamiento({ setfechaRelevameinto, fechaRelevameinto }) {
  const [searchParams] = useSearchParams();
  const [windowWidth, setwindowWidth] = useState();

  const navigate = useNavigate();
  const id = searchParams.get("id");
  const [getRelevamientos, responseRelevamientos] = useGetRelevamientosMutation();
  const [getDownloadOneRelevamiento, fileDownloaded] = useDownloadRelevamientoMutation();
  const handleDownload = async (id) => {
    getDownloadOneRelevamiento(id);
  };

  useEffect(() => {
    function handleResize() {
      setwindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    if (responseRelevamientos.isSuccess) {
      const firstRelevamiento = responseRelevamientos.data.relevamientos[0];
      if (firstRelevamiento) {
        const { fecha_relevado } = firstRelevamiento;
        setfechaRelevameinto({
          dateFrom: fecha_relevado,
          dateTo: fecha_relevado,
        });
      }

      for (let i = 1; i < responseRelevamientos.data.relevamientos.length; i++) {
        const element = responseRelevamientos.data.relevamientos[i];
        const { fecha_relevado } = element;

        if (fecha_relevado > fechaRelevameinto.dateTo) {
          setfechaRelevameinto((prev) => ({
            ...prev,
            dateTo: fecha_relevado,
          }));
        }

        if (fecha_relevado < fechaRelevameinto.dateFrom) {
          setfechaRelevameinto((prev) => ({
            ...prev,
            dateFrom: fecha_relevado,
          }));
        }
      }
    }
    if (fileDownloaded.isSuccess) {
      window.open(fileDownloaded?.data?.ruta);
    }
  }, [responseRelevamientos.isSuccess, fileDownloaded.isSuccess]);
  useEffect(() => {
    if (!id) navigate("/");
    getRelevamientos(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (responseRelevamientos.isLoading || fileDownloaded.isLoading) return <Loading />;
  if (responseRelevamientos.isError || fileDownloaded.isError) return <Error message="Error al obtener los datos." />;
  return (
    <>
      <table className="formato1" cellSpacing="0" cellPadding="0">
        <thead>
          <tr style={{ height: "10%" }}>
            <th align="left" style={{ textAlign: "center" }}>
              Fecha
            </th>
            <th align="center" style={{ textAlign: "center" }}>
              Estado
            </th>
            <th align="right" style={{ textAlign: "center" }}>
              Acciones
            </th>
          </tr>
        </thead>
        <tbody>
          {responseRelevamientos?.data?.relevamientos.length > 0 ? (
            responseRelevamientos?.data?.relevamientos?.map((element, index) => {
              return (
                <tr key={index} style={{ height: "10%" }}>
                  {}
                  <td align="left" style={{ textAlign: "center" }}>
                    <p>{element?.fecha_relevado}</p>
                  </td>
                  <td align="center" style={{ textAlign: "center" }}>
                    {element?.incompleto && (
                      <div
                        style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1rem" }}
                      >
                        <WarningIcon color="warning" />
                        <span style={{ fontSize: "0.85rem" }}>Esta incompleto</span>
                      </div>
                    )}
                  </td>
                  <td
                    align="rigth"
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ display: "flex", justifyContent: "center", alignitems: "center" }}>
                      <ButtonIcon
                        variant="outlined"
                        size="large"
                        startIcon={<SearchIcon color="primary" />}
                        to={`/espaciosverdes/relevamiento?id=${element.codigo}&espacio_verde=${element.espacio_verde}`}
                      >
                        {windowWidth > 472 && "Ver"}
                      </ButtonIcon>

                      <ButtonIcon
                        variant="outlined"
                        endIcon={<FileDownloadIcon />}
                        noLink
                        handleClick={() => handleDownload(element?.codigo)}
                      >
                        {windowWidth > 472 && "Descargar"}
                      </ButtonIcon>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="3">No se encontraron registros</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}
