/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useGetProgramacionesMutation } from "../../../store/api/endpoints/informesApiSlice";
import { ButtonIcon, Error, HeaderVista, Loading } from "../../elementos";
import TableListaInformes from "./TablaListaInformes";
import { Container } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
const InspeccionesScreen = () => {
  const [getInformes, responseInformes] = useGetProgramacionesMutation();
  const [informes, setinformes] = useState({});
  const [pagination, setPagination] = React.useState({
    page: 0,
    limit: 15,
    totalPages: 0,
  });

  useEffect(() => {
    getInformes(`page=${pagination.page}&limit=${pagination.limit}`);
  }, [pagination.page, pagination.limit]);

  useEffect(() => {
    if (responseInformes.isSuccess) {
      setinformes(responseInformes.data?.inspecciones);
      setPagination((prev) => ({
        ...prev,
        count: responseInformes.data?.total,
        totalPages: responseInformes.data?.totalPages,
      }));
    }
  }, [responseInformes.isSuccess]);

  if (responseInformes.isLoading) {
    return <Loading />;
  }

  if (responseInformes.isError) {
    return <Error />;
  }

  return (
    <Container component={"main"} maxWidth="lg">
      <HeaderVista
        titulo="Periodos de inspecciones"
        end={
          <ButtonIcon
            style={{ width: "100%", maxWidth: "500px", fontSize: "0.67rem" }}
            size={"small"}
            to="/configuracion/inspecciones/nuevo"
            variant="outlined"
            startIcon={<AddIcon />}
          >
            Nuevo periodo
          </ButtonIcon>
        }
      />
      <TableListaInformes informes={informes} setPagination={setPagination} pagination={pagination} />
      <br />
    </Container>
  );
};

export default InspeccionesScreen;
