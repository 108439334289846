/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useSearchParams } from "react-router-dom";
import { useDeleteMutation, useGetByIDMutation } from "../../../store/api/endpoints/cuadranteApiSlice";
import { useGetAllPoligonosOfCuadrantesMutation } from "../../../store/api/endpoints/poligonoApiSlice";
import ListaDePoligonos from "./poligonos/ListaDePoligonos";
import { Volver, Error, Loading, HeaderVista, ConfirmDelete, ButtonIcon } from "../../elementos/";
import { Container } from "@mui/material";
import { useDispatch } from "react-redux";
import { poligonos } from "../../../store/slices/poligonos";

const CuadranteSolo = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [nombreCuadrante, setNombreCuadrante] = useState("");
  const dispatch = useDispatch();
  const [getCuadrante, responseCuadrante] = useGetByIDMutation();
  const [deleteCuadrante, responseDelete] = useDeleteMutation();
  const [getPoligonos, responsePoligonos] = useGetAllPoligonosOfCuadrantesMutation();
  useEffect(() => {
    getCuadrante(id);
    getPoligonos(id);
  }, []);

  useEffect(() => {
    if (responseCuadrante.isSuccess) {
      setNombreCuadrante(responseCuadrante?.data?.nombre);
    }
    if (responsePoligonos.isSuccess) {
      dispatch(poligonos(responsePoligonos?.data?.data));
    }
  }, [responsePoligonos.isSuccess, responseCuadrante.isSuccess]);

  if (responseCuadrante.isLoading || responsePoligonos.isLoading) {
    return <Loading />;
  }
  if (responsePoligonos.isError) {
    return <Error message="Error al obtener los datos." />;
  }

  return (
    <Container component={"main"} maxWidth="lg">
      <HeaderVista
        titulo={"Ficha de " + nombreCuadrante}
        start={<Volver label="Volver" to="/configuracion/cuadrantes" />}
        end={
          <>
            <ButtonIcon
              variant="outlined"
              size="small"
              startIcon={<AddIcon />}
              to={`/configuracion/cuadrante/crearpoligono/${id}`}
            >
              Crear poligono
            </ButtonIcon>
            <ConfirmDelete
              id={id}
              response={responseDelete}
              slice={deleteCuadrante}
              returnUrl={`/configuracion/cuadrante?id=${id}`}
              variant="outlined"
              size="small"
            />
          </>
        }
      />
      <ListaDePoligonos />
      <br />
    </Container>
  );
};

export default CuadranteSolo;
