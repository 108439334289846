/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetAllAtributesOfEEVVMutation,
  useGetEspacioVerdeMutation,
} from "../../../store/api/endpoints/espaciosApiSlice";

import { EspacioVerdeForm } from "./EspacioVerdeForm";
import { Loading, Error, Volver, HeaderVista } from "../../elementos";
/* import EditIcon from "@mui/icons-material/Edit"; */
import { useDispatch } from "react-redux";
import { openFeedback } from "../../../store/slices/feedback";
import "../../../assets/css/espacioverdesoloscreen.css";
import { Container, Grid } from "@mui/material";
import { editAreas, espacioVerde } from "../../../store/slices/espacioverdes";

export const EspacioVerde = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [isEditable, setisEditable] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const routeBack = useRef(null);
  const [getEspacioVerde, responseEspacioVerde] = useGetEspacioVerdeMutation();
  /*   const [deleteEspacio, responseDelete] = useDeleteEspacioMutation(); */
  const [getAllDatos, responseAllDatos] = useGetAllAtributesOfEEVVMutation();

  /*   const [getSelf, responseSelf] = useGetSelfMutation(); */

  useEffect(() => {
    try {
      Promise.all([getEspacioVerde(id), getAllDatos() /* , getSelf(sessionStorage.getItem("token")) */]);
    } catch (error) {
      dispatch(
        openFeedback({
          severity: "error",
          message: "Error al intentar comunicarse con el servidor",
        })
      );
    }
    routeBack.current = location.pathname.includes("/espaciosverdes")
      ? `/espaciosverdes/detalle?id=${id}`
      : `/inspecciones/detalles?id=${id}`;
  }, []);

  useEffect(() => {
    if (responseAllDatos.isSuccess && responseEspacioVerde.isSuccess) {
      const editAreasObj = {
        comunas: responseAllDatos?.data?.comunas?.map((element) => ({
          label: "Comuna " + element?.codigo,
          value: element?.codigo,
        })),
        areas: responseAllDatos?.data?.areas?.map((element) => ({
          label: element.nombre,
          value: element.codigo,
        })),
        barrios: responseAllDatos?.data?.barrios?.map((element) => ({
          label: element.name,
          value: element.id_barrio,
        })),
        estados: responseAllDatos?.data?.estados?.map((element) => ({
          label: element.nombre,
          value: element.codigo,
        })),
        clases: responseAllDatos?.data?.clases?.map((element) => ({
          label: element.nombre,
          value: element.codigo,
        })),
        poligonos: responseAllDatos?.data?.poligonos?.map((element) => ({
          label: element.nombre,
          value: element.codigo,
          cuadrante: element.cuadrante,
        })),
        cuadrantes: responseAllDatos?.data?.cuadrantes?.map((element) => ({
          label: element.nombre,
          value: element.codigo,
        })),
        empresas: responseAllDatos?.data?.empresas.map((element) => ({
          label: element.nombre,
          value: element.codigo,
        })),
        opciones_riego: responseAllDatos?.data?.opciones_riego.map((element) => ({
          label: element.nombre,
          value: element.id,
        })),
        tipo_cerramiento: responseAllDatos?.data?.tipo_cerramiento.map((element) => ({
          label: element.nombre,
          value: element.codigo,
        })),
        tipo_rejas: responseAllDatos?.data?.tipo_rejas?.map((element) => ({
          label: element.nombre,
          value: element.codigo,
        })),
      };
      dispatch(editAreas(editAreasObj));
      dispatch(espacioVerde(responseEspacioVerde?.data?.dato));
    }
  }, [responseAllDatos.isSuccess, responseEspacioVerde.isSuccess]);

  /*   const handleEditable = () => {
    setisEditable(!isEditable);
  };
 */
  useEffect(() => {
    if (responseEspacioVerde.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: responseEspacioVerde.error?.data?.message,
        })
      );
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseEspacioVerde.isError]);

  if (responseAllDatos.isLoading || responseEspacioVerde.isLoading) {
    return <Loading />;
  }

  if (responseEspacioVerde?.isError || responseAllDatos.isError) {
    return <Error />;
  }
  return (
    <Container component={"main"} maxWidth="lg">
      <HeaderVista
        start={
          <Volver
            label={routeBack?.current?.includes("/inspecciones/detalles") ? "Historial" : "Volver"}
            to={routeBack.current}
          />
        }
        titulo={responseEspacioVerde?.data?.dato?.nombre_eevv}
        /*  end={
          responseSelf?.data?.usuario.perfil === PERMISOS.ADMINISTRADOR && (
            <>
              <ConfirmDelete
                id={id}
                response={responseDelete}
                slice={deleteEspacio}
                returnUrl={`/`}
                variant="outlined"
              />
              <ButtonIcon noLink handleClick={handleEditable} variant="outlined" size="medium" startIcon={<EditIcon />}>
                Editar
              </ButtonIcon>
            </>
          )
        } */
      />
      <br />
      <Container component={"main"} maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <EspacioVerdeForm id={id} isEditable={isEditable} setisEditable={setisEditable} />
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};
