export const getDateToday = () => {
  let date = new Date();
  return date.toISOString().slice(0, 10);
};

export const getLastMonth = () => {
  let date = new Date();
  date.setMonth(date.getMonth() - 1);
  return date.toISOString().slice(0, 10);
};
