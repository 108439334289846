import { tiendasApi } from "../api";

export const optionsSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllOptions: builder.mutation({
      query: () => ({
        url: "/options/",
        method: "GET",
      }),
    }),
  }),
});

export const { useGetAllOptionsMutation } = optionsSlice;
