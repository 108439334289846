import React from "react";
import Input from "../../../../elementos/Input";
import Select from "../../../../elementos/Select";
import { Container, Grid } from "@mui/material";

export const PatioJuegos = ({ setareas, areas, isDisabled = true, index, dataAllAtributes, options }) => {
  const handleChange = (value, key) => {
    setareas((prev) => ({
      ...prev,
      patiodejuegos: {
        ...prev.patiodejuegos,
        [index]: {
          ...prev.patiodejuegos[index],
          [key]: value,
        },
      },
    }));
  };
  return (
    <Container component={"main"} maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <h3>Patio de juegos {index + 1}</h3>
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="tel"
            label="Cantidad de equipos"
            variant="standard"
            value={areas?.equipos_tot}
            disabled={isDisabled}
            onChange={handleChange}
            restricciones={["soloNumeros"]}
            name="equipos_tot"
            min={0}
            max={200}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="limpieza de equipamiento"
            value={areas?.limpieza_equipamiento}
            defaultValue=""
            options={options?.limpieza}
            type="text"
            onChange={handleChange}
            name={"limpieza_equipamiento"}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="Limpieza de solado"
            value={areas?.limpieza_solado}
            defaultValue=""
            options={options?.limpieza}
            type="text"
            onChange={handleChange}
            name={"limpieza_solado"}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="number"
            label="Equipos defectuosos"
            variant="standard"
            value={areas?.equipos_defectuosos}
            disabled={isDisabled}
            onChange={handleChange}
            restricciones={["soloNumeros"]}
            name="equipos_defectuosos"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="number"
            label="Equipos con falta de pintura"
            variant="standard"
            value={areas?.equipos_falta_pintura}
            disabled={isDisabled}
            onChange={handleChange}
            restricciones={["soloNumeros"]}
            name="equipos_falta_pintura"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="Estado de solado"
            value={areas?.estado_solado}
            defaultValue=""
            options={options?.porcentaje}
            type="text"
            onChange={handleChange}
            name={"estado_solado"}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="Estado de rejas"
            value={areas?.estado_rejas}
            defaultValue=""
            options={options?.porcentaje}
            type="text"
            onChange={handleChange}
            name={"estado_rejas"}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="Pintura de rejas"
            value={areas?.pintura_rejas}
            defaultValue=""
            options={options?.porcentaje}
            type="text"
            onChange={handleChange}
            name={"pintura_rejas"}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="text"
            label="Observaciones"
            variant="standard"
            value={areas?.observaciones}
            disabled={isDisabled}
            onChange={handleChange}
            restricciones={["alfanumerico"]}
            name="observaciones"
          />
        </Grid>
      </Grid>
      <br />
    </Container>
  );
};
