import React from "react";
import ButtonIcon from "./ButtonIcon";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";

export default function Volver(props) {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <ButtonIcon variant="outlined" size="small" color="neutral" startIcon={<ChevronLeftIcon />} noLink handleClick={handleBack}>
      {props.label}
    </ButtonIcon>
  );
}
