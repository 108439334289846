import { tiendasApi } from "../api";

export const cuadranteApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    getAll: builder.mutation({
      query: () => ({
        url: `/cuadrantes`,
        method: "GET",
      }),
    }),
    update: builder.mutation({
      query: ({ id, body }) => ({
        url: `/cuadrantes?id=${id}`,
        method: "PUT",
        body: body,
      }),
    }),
    delete: builder.mutation({
      query: ({ id }) => ({
        url: `/cuadrantes?id=${id}`,
        method: "DELETE",
      }),
    }),
    createCuadrante: builder.mutation({
      query: (body) => ({
        url: "/cuadrantes",
        method: "POST",
        body: body,
      }),
    }),
    getOne: builder.mutation({
      query: (nombre) => ({
        url: `/cuadrantes/${nombre}`,
        method: "GET",
      }),
    }),
    getByID: builder.mutation({
      query: (nombre) => ({
        url: `/cuadrantes/getbyid/${nombre}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useCreateCuadranteMutation,
  useDeleteMutation,
  useGetAllMutation,
  useUpdateMutation,
  useGetOneMutation,
  useGetByIDMutation,
} = cuadranteApiSlice;
