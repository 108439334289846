import React from "react";
import InfoIcon from "@mui/icons-material/Info";

export const NA = ({ type }) => {
  return (
    <div
      style={{
        backgroundColor: "lightgray",
        height: "3rem",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        width: "100%",
        /*    maxWidth: "400px", */
        borderRadius: "0px 0px 20px 20px",
      }}
    >
      <InfoIcon style={{ fill: "#ffc116" }} fontSize="large" />
      <h5 style={{ fontSize: "0.8rem" }}>El espacio no tiene {type}</h5>
    </div>
  );
};
