import { tiendasApi } from "../api";

export const uploadFileApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation({
      query(data) {
        const { url, body } = data;
        return {
          url: "/" + url,
          method: "POST",
          body: body,
        };
      },
    }),

    removeFile: builder.mutation({
      query: (body) => ({
        url: `/upload/image`,
        method: "Delete",
        body: body,
      }),
    }),
    uploadImage: builder.mutation({
      query: (data) => {
        const body = new FormData();
        body.append("Content-Type", data.type);
        body.append("file", data);
        return {
          url: "/upload/image",
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const { useUploadFileMutation, useRemoveFileMutation, useUploadImageMutation } = uploadFileApiSlice;
