import { CancelOutlined } from "@mui/icons-material";
import React from "react";
import ButtonIcon from "../../../elementos/ButtonIcon2";
import AddIcon from "@mui/icons-material/Add";
import { Container, Grid } from "@mui/material";

const BotonCrearAreas = ({ listLength, text, handleAdd, type, handleRemove }) => {
  return (
    <Container component={"main"} maxWidth="xs">
      <Grid container spacing={2} justifyContent={"center"} alignContent={"center"}>
        <Grid item xs={8} md={8}>
          <p style={{ /* width: "50%",  */ textAlign: "left" }}>
            {text} {listLength}
          </p>
        </Grid>
        <Grid item xs={2} md={2}>
          <ButtonIcon
            variant="outlined"
            startIcon={<AddIcon />}
            type="button"
            handleClick={() => handleAdd(type, listLength)}
            noLink
          />
        </Grid>
        <Grid item xs={2} md={2}>
          <ButtonIcon
            variant="outlined"
            startIcon={<CancelOutlined style={{ fill: "red" }} />}
            type="button"
            handleClick={handleRemove}
            noLink
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default BotonCrearAreas;
