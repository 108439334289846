/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useExportDataWithDatesMutation,
  useGetRelevamientosMutation,
} from "../../../../store/api/endpoints/relevamientoApiSlice";

import { Error, Volver, Loading, HeaderVista, ButtonIcon } from "../../../elementos";
import ListaRelevamiento from "./ListaRelevamientos";

import { Container, Grid } from "@mui/material";

export const EspaciosVerdesRelevamientoScreen = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [relevamientos, setrelevamientos] = useState({});
  const id = searchParams.get("id");
  const [getRelevamientos] = useGetRelevamientosMutation();
  const [downloadFileBeetwenDates, responseFile] = useExportDataWithDatesMutation();
  const [fechaRelevameinto, setfechaRelevameinto] = useState({
    dateFrom: "",
    dateTo: "",
  });
  const handleDownloadAll = (id) => {
    let options;
    options = `&id=${id}`;
    if (fechaRelevameinto.dateFrom && fechaRelevameinto.dateTo) {
      options = options + `&from=${fechaRelevameinto.dateFrom}&to=${fechaRelevameinto.dateTo}`;
    }
    downloadFileBeetwenDates(options);
  };
  const navigateNewRelevamient = () => {
    navigate(`/relevamiento/crear?id=${id}`);
  };

  useEffect(() => {
    if (responseFile.isSuccess) {
      window.open(responseFile?.data?.ruta);
    }
  }, [responseFile.isSuccess]);

  if (responseFile.isLoading) {
    return <Loading />;
  }

  if (responseFile.isError) {
    return <Error />;
  }
  return (
    <>
      <HeaderVista
        start={<Volver label="Volver" to={`/espaciosverdes/espacio?id=${id}`} />}
        titulo="Lista de relevamientos"
        end={
          <>
            <ButtonIcon noLink handleClick={navigateNewRelevamient}>
              Nuevo relevamiento
            </ButtonIcon>
            <ButtonIcon noLink handleClick={() => handleDownloadAll(id)}>
              Descargar todos
            </ButtonIcon>
          </>
        }
      />
      <Container className="containerBigTable" component="main" maxWidth="lg">
        <Grid container style={{ width: "100%" }}>
          {!relevamientos ? (
            <Grid item xs={12} md={12}>
              <Loading />
            </Grid>
          ) : (
            <>
              <ListaRelevamiento setfechaRelevameinto={setfechaRelevameinto} fechaRelevameinto={fechaRelevameinto} />
            </>
          )}
        </Grid>
      </Container>
    </>
  );
};
