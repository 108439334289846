import { createSlice } from "@reduxjs/toolkit";

export const comunasSlice = createSlice({
  name: "comunas",
  initialState: {
    comunas: [],
  },
  reducers: {
    comunas: (state, action) => {
      state.comunas = action.payload;
    },
  },
});

export const { comunas } = comunasSlice.actions;

export const getcomunas = (state) => state.comunas.comunas;
