/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./assets/css/Routes.css";

//Componentes Vistas
import RequireAuth from "./components/vistas/auth/RequireAuth";
import Login from "./components/vistas/auth/Login";
import { RelevamientoScreen } from "./components/vistas/Relevamiento/RelevamientoScreen";
import EspaciosVerdes from "./components/vistas/espacioverdes/EspacioVerdeScreen.js";
import { EspaciosVerdesRelevamientoScreen } from "./components/vistas/espacioverdes/Relevamientos/EspaciosVerdesRelevamientoScreen";
import { EspacioVerdeAreas } from "./components/vistas/espacioverdes/Areas/EspacioVerdeAreas";
import { MiPerfilScreen } from "./components/vistas/clientes/Mi perfil/MiPerfilScreen";
import { EspaciosVerdesRelevamiento } from "./components/vistas/espacioverdes/Relevamientos/EspacioVerdeRelevamiento";
import Mapa from "./components/vistas/espacioverdes/Maps";
import { Cliente, Clientes, ClienteNuevo, ClienteUpdate } from "./components/vistas/clientes/";
import CuadranteScreen from "./components/vistas/cuadrante/";
import { EspacioVerdeNuevo } from "./components/vistas/espacioverdes/Crear/EspacioVerdeNuevo";
import { ReportesScreen } from "./components/vistas/Reportes";
import { EspacioVerde } from "./components/vistas/espacioverdes/InfoEspacioVerdeScreen";
import CuadranteSolo from "./components/vistas/cuadrante/InformacionDelCuadrante";
import NotFound from "./components/vistas/No encontrada";
import CrearCuadrante from "./components/vistas/cuadrante/crear/CrearCuadrante";
import CrearPoligono from "./components/vistas/cuadrante/crear/CrearPoligono";
import Navbar from "./components/vistas/Navbar/Navbar";
import CrearArea from "./components/vistas/espacioverdes/crear area";
import HoraDeRelevamiento from "./components/vistas/editar hora relevamiento";
import DetalleEspacioVerde from "./components/vistas/espacioverdes/detalles";
import UpdateProgramacionInspeccion from "./components/vistas/inspecciones/ProgramarInspeccion/UpdateProgramacio";
import NewProgramacionInspeccion from "./components/vistas/inspecciones/ProgramarInspeccion/NewProgramacionInspeccion";
import CrearInspeccion from "./components/vistas/inspecciones/Nueva inspeccion/CrearInspeccion";
import InspeccionesScreen from "./components/vistas/inspecciones/InspeccionesScreen";
import DashboardInformes from "./components/vistas/inspecciones/dashboard/DashboardInformes";
import DetalleEspacio from "./components/vistas/inspecciones/detallesEspacio";
import { ScreenDescargas } from "./components/vistas/inspecciones/Descargas/ScreenDescargas";
import { ListadoInformes } from "./components/vistas/informes/ListadoInformes";
import ScreenInformesPDF from "./components/vistas/inspecciones/Descargas/ScreenInformesPDF";
import RequireRol from "./components/vistas/auth/PerfilValidate";
import { PERMISOS } from "./constants/permisos";
import UltimosInformes from "./components/vistas/inspecciones/TablaUltimosInformes";

export default function Router() {
  const excludeNavbarOfRoutes = ["/descargas/inspecciones/pdf"];
  const [token] = useState(sessionStorage.getItem("token"));
  const [pathName] = useState(window.location.pathname);

  return (
    <BrowserRouter>
      {!excludeNavbarOfRoutes.includes(pathName) && <Navbar />}
      <Routes>
        {token && (
          <Route element={<RequireRol RequireRol={[PERMISOS.ADMINISTRADOR, PERMISOS.VISUALIZADOR_CON_REPORTES]} />}>
            {/*INFORME SEMANALES */}
            <Route path="/descargas/inspecciones/pdf" element={<ScreenInformesPDF />} />
          </Route>
        )}

        <Route path="login" element={<Login />} />
        <Route element={<RequireAuth />}>
          <Route
            element={
              <RequireRol
                RequireRol={[
                  PERMISOS.ADMINISTRADOR,
                  PERMISOS.INSPECTOR,
                  PERMISOS.VISUALIZADOR_SIN_REPORTES,
                  PERMISOS.VISUALIZADOR_CON_REPORTES,
                ]}
              />
            }
          >
            <Route index path="/" element={<DashboardInformes />} />
            <Route path="/espaciosverdes" element={<EspaciosVerdes />} />
            <Route path="/espaciosverdes/espacio" element={<EspacioVerde />} />
            <Route path="/espaciosverdes/areas" element={<EspacioVerdeAreas />} />
            <Route path="/espaciosverdes/mapa" element={<Mapa />} />
            <Route path="/espaciosverdes/detalle" element={<DetalleEspacioVerde />} />
            <Route path="/relevamiento/listado" element={<EspaciosVerdesRelevamientoScreen />} />
            <Route path="/inspecciones/espacio" element={<EspacioVerde />} />
            <Route path="/inspecciones/mapa" element={<Mapa />} />
            <Route path="/inspecciones/" element={<DashboardInformes />} />
            <Route path="/inspecciones/detalles" element={<DetalleEspacio />} />
            <Route path="/inspecciones/listado" element={<ListadoInformes />} />
            <Route path="/inspecciones/nuevo" element={<CrearInspeccion />} />
            <Route path="/miperfil/" element={<MiPerfilScreen />} />
          </Route>
          <Route element={<RequireRol RequireRol={[PERMISOS.ADMINISTRADOR, PERMISOS.VISUALIZADOR_CON_REPORTES]} />}>
            {/*INFORME SEMANALES */}
            <Route path="/descargas/inspecciones" element={<ScreenDescargas />} />
          </Route>
          <Route element={<RequireRol RequireRol={[PERMISOS.ADMINISTRADOR, PERMISOS.INSPECTOR]} />}>
            <Route path="/relevamiento/editar" element={<RelevamientoScreen />} />
            <Route path="/relevamiento/crear" element={<RelevamientoScreen />} />
            <Route path="/espaciosverdes/relevamiento" element={<EspaciosVerdesRelevamiento />} />
          </Route>
          <Route element={<RequireRol RequireRol={[PERMISOS.ADMINISTRADOR]} />}>
            {/* CONFIGURACION */}
            <Route path="/configuracion/usuario/editar" element={<ClienteUpdate />} />
            <Route path="/configuracion/inspecciones/editar" element={<UpdateProgramacionInspeccion />} />
            <Route path="/configuracion/inspecciones/nuevo" element={<NewProgramacionInspeccion />} />
            <Route path="/configuracion/inspecciones" element={<InspeccionesScreen />} />
            <Route path="/configuracion/usuario/nuevo" element={<ClienteNuevo />} />
            <Route path="/configuracion/usuarios" element={<Clientes />} />
            <Route path="/configuracion/hora-relevamiento" element={<HoraDeRelevamiento />} />
            <Route path="/configuracion/usuario" element={<Cliente />} />
            <Route path="/configuracion/cuadrante/crearpoligono/:id" element={<CrearPoligono />} />
            <Route path="/configuracion/cuadrantes/crear" element={<CrearCuadrante />} />
            <Route path="/configuracion/cuadrante/" element={<CuadranteSolo />} />
            {/* LOGS */}
            <Route path="/logs/ultimosinformes" element={<UltimosInformes />} />
            {/*INFORME PUNTAJES */}
            <Route path="/configuracion/reportes" element={<ReportesScreen />} />
            <Route path="/configuracion/cuadrantes" element={<CuadranteScreen />} />
            <Route path="/espaciosverdes/crear" element={<EspacioVerdeNuevo />} />
            <Route path="/espaciosverdes/creararea" element={<CrearArea />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
