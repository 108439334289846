import { React } from "react";

import "../../assets/css/Error.css";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Volver from "./Volver2";
import HeaderVista2 from "./HeaderVista2";

export default function Error({ message, error }) {
  return (
    <Container component={"main"} maxWidth="lg" style={{ minHeight: "200px" }}>
      <HeaderVista2 start={<Volver label="Volver" />} />

      <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 15 }}>
        <Grid item xs={12} align="center" style={{ backgroundColor: "rgb(232 234 232)" }}>
          <div id="error">
            <div className="titulo">¡Ups!</div>
          </div>
          <div id="errorLeyenda">
            <p className="text">
              {message}
              <br />
              Por favor intenta nuevamente.
            </p>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
