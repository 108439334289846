import { Grid } from "@mui/material";
import React  from "react";
import { Input, Select } from "../../../elementos";

const BodyProgramarInspeccion = ({ programacion, setprogramacion,seterror }) => {
  const handleChange = (type, value) => {
    if (type === "mes") {
      let fechaDesce = `${programacion.anio ?programacion.anio: new Date().getFullYear()+1 }-${value}-01`;
      let fechaHasta = `${programacion.anio ?programacion.anio: new Date().getFullYear()+1 }-${ value}-07`;

      setprogramacion((prev) => ({
        ...prev,
        [type]: value,
        fecha_desde: fechaDesce,
        fecha_hasta: fechaHasta,
      }));
      return ;
    }
    if (type === "anio") {
      let mesDefault = programacion.mes;
      let fechaDesde = `${value}-${mesDefault}-01`;
      let fechaHasa = `${value}-${mesDefault}-07`;

      setprogramacion((prev) => ({
        ...prev,
        [type]: value,
        fecha_desde: fechaDesde,
        fecha_hasta: fechaHasa,
      }));
      return ;

    }
    if (value && type) {
      setprogramacion((prev) => ({
        ...prev,
        [type]: value,
      }));
      return ;
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Select
          label={"Semana"}
          value={programacion?.semana}
          options={[
            { value: "1", label: "Semana 1" },
            { value: "2", label: "Semana 2" },
            { value: "3", label: "Semana 3" },
            { value: "4", label: "Semana 4" },
          ]}
          fullWidth
          defaultValue=""
          onChange={(e) => handleChange("semana", e)}
          type="number"
          name={"semana"}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Select
          label="Mes"
          value={programacion?.mes}
          defaultValue=""
          options={[
            { label: "Enero", value: "01" },
            { label: "Febrero", value: "02" },
            { label: "Marzo", value: "03" },
            { label: "Abril", value: "04" },
            { label: "Mayo", value: "05" },
            { label: "Junio", value: "06" },
            { label: "Julio", value: "07" },
            { label: "Agosto", value: "08" },
            { label: "Septiembre", value: "09" },
            { label: "Octubre", value: "10" },
            { label: "Noviembre", value: "11" },
            { label: "Diciembre", value: "12" },
          ]}
          type="text"
          onChange={(e) => handleChange("mes", e)}
          name={"mes"}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Input
          type="number"
          value={programacion?.anio}
          label="Ingrese el año"
          onChange={(e) => handleChange("anio", e)}
          required
          restricciones={["alfanumerico"]}
          seterror={seterror}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          type="date"
          value={programacion?.fecha_desde}
          label="Seleccione la fecha desde"
          onChange={(e) => handleChange("fecha_desde", e)}
          required
          restricciones={["fecha"]}
          seterror={seterror}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          type="date"
          value={programacion?.fecha_hasta}
          label="Seleccione la fecha hasta"
          onChange={(e) => handleChange("fecha_hasta", e)}
          required
          restricciones={["fecha"]}
          seterror={seterror}
        />
      </Grid>
      <br />
    </Grid>
  );
};

export default BodyProgramarInspeccion;
