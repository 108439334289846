import axios from "axios";
import React, { useEffect, useState } from "react";
import { Buffer } from "buffer";
import { Loading } from "../../../../elementos";
if (!window.Buffer) {
  window.Buffer = Buffer;
}

const ListadoFotos = (props) => {
  const [loading, setloading] = useState(true);
  const fetchImage = async () => {
    const divContainer = document.getElementById(`${props.id}-${props.label}-${props.semana.split(" ").join("_")}`);
    for (let index = 0; index < props.imagenes.length; index++) {
      const elementImage = document.createElement("img", {
        style: { height: "200px", width: "200px", display: "block" },
      });

      let urlImg = props?.imagenes[index]?.split(".JPG")[0];

      const image = await axios.get(urlImg + "_baja.JPG", {
        responseType: "arraybuffer",
      });

      const imageBase = Buffer.from(image.data, "binary").toString("base64");
      if (divContainer && imageBase) {
        elementImage.src = `data:image/jpeg;charset=utf-8;base64,${imageBase}`;
        elementImage.style.height = "300px";
        elementImage.style.width = "300px";

        divContainer.appendChild(elementImage);
      }
    }
    setloading(false);
  };

  useEffect(() => {
    fetchImage();
  }, [props.imagenes]);
  return (
    <div style={{ height: "100%", minHeight: "40px", marginBottom: "10px" }}>
      <p style={{ textAlign: "left", margin: "1px" }}>
        <b>
          {props.semana}
          {props.imagenes?.length === 0 &&
            !props.label.includes("Semana 1") &&
            "(Solo se cargan fotos cuando hay cambios en el espacio)"}
        </b>
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          // justifyContent: "space-between",
          maxWidth: "22cm",
          gap: "3px",
          height: "100%",
        }}
        id={`${props.id}-${props.label}-${props.semana.split(" ").join("_")}`}
      ></div>
      <p style={{ color: "red" }}>{props.observaciones}</p>
    </div>
  );
};

export default ListadoFotos;
