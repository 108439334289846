/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getStatusSidebar } from "../../../store/slices/sidebar";
import "../../../assets/css/Sidebar.css";
import { SidebarData } from "../../../constants/SidebarData";
import useComponentVisible from "../../elementos/useVisible";
import { useGetSelfMutation } from "../../../store/api/endpoints/authApiSlice";
import { Loading } from "../../elementos";
import "../../../assets/css/dropdown.css";
import SubMenu from "./Dropdown";
export default function Sidebar() {
  const [getSelf, responseSelf] = useGetSelfMutation();
  const { ref, isComponentVisible } = useComponentVisible(false);
  const [isAdmin, setisAdmin] = useState();
  const statusSidebar = useSelector(getStatusSidebar);
  /*  const handleClick = useCallback(
    (link) => {
      dispatch(changeStatusSidebar(!getStatusSidebar));
      setIsComponentVisible(!getStatusSidebar);
      link && navigate(link);
    },
    [getStatusSidebar]
  ); */

  useEffect(() => {
    getSelf(sessionStorage.getItem("token"));
  }, []);

  useEffect(() => {
    if (responseSelf.isSuccess) {
      setisAdmin(responseSelf?.data?.usuario?.perfil);
    }
  }, [responseSelf.isSuccess]);

  if (responseSelf.isLoading) {
    return <Loading />;
  }

  return (
    responseSelf.isSuccess && (
      <div id="sidebar" className={statusSidebar || isComponentVisible ? "open" : "closed"} ref={ref}>
        {SidebarData.map((el, index) => (
          <SubMenu item={el} key={index} rolActual={isAdmin} />
        ))}
      </div>
    )
  );
}
