import React, { useRef } from "react";
import { Container, Divider, Grid } from "@mui/material";
import { AreaDeportiva, Caniles, Mobiliario, PatioJuegos, PostaAerobica } from "../Areas/Areas secas";
import { Desplegable, ButtonIcon } from "../../../elementos";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import { useSelector } from "react-redux";
const ListaAreasAMostrar = ({
  selectedArea,
  optionSelected,
  setoptionSelected,
  setareas,
  areas,
  handleSubmit,
  handleCancel,
}) => {
  const state = useSelector((state) => state);
  const options = useRef(state.options);
  return (
    <Container component={"main"} maxWidth="lg">
      {selectedArea === 3 && (
        <Desplegable text="Patio de juegos" option={optionSelected} setoption={setoptionSelected} name="patio">
          <PatioJuegos
            options={options.current?.options}
            areas={areas?.patiodejuegos[0]}
            isDisabled={false}
            index={0}
            setareas={setareas}
            dataAllAtributes={options.current?.options}
          />
        </Desplegable>
      )}

      {selectedArea === 4 && (
        <Desplegable text="Posta aerobica" option={optionSelected} setoption={setoptionSelected} name="posta">
          <PostaAerobica
            areas={areas?.postaAerobica[0]}
            options={options.current?.options}
            setareas={setareas}
            isDisabled={false}
            index={0}
            dataAllAtributes={options.current?.options}
          />
        </Desplegable>
      )}
      {selectedArea === 2 && (
        <Desplegable text="Mobiliario" option={optionSelected} setoption={setoptionSelected} name="mobiliario">
          <Mobiliario
            isDisabled={false}
            areas={areas?.mobiliarios[0]}
            setareas={setareas}
            index={1}
            options={options.current?.options}
            dataAllAtributes={options.current?.options}
          />
        </Desplegable>
      )}
      {selectedArea === 1 && (
        <Desplegable text="Area deportiva" option={optionSelected} setoption={setoptionSelected} name="area">
          <AreaDeportiva
            isDisabled={false}
            setareas={setareas}
            areas={areas?.areaDeportiva[0]}
            index={0}
            options={options.current?.options}
            dataAllAtributes={options.current?.options}
          />
        </Desplegable>
      )}

      {selectedArea === 5 && (
        <Desplegable text="Caniles" option={optionSelected} setoption={setoptionSelected} name="caniles">
          <Caniles
            options={options.current?.options}
            setareas={setareas}
            areas={areas?.caniles[0]}
            index={0}
            dataAllAtributes={options.current?.options}
            isDisabled={false}
          />
        </Desplegable>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={6} md={6}>
          <ButtonIcon
            color="error"
            handleClick={handleCancel}
            variant="contained"
            noLink
            styleButton={{ marginLeft: "10px" }}
            startIcon={<CancelIcon />}
          >
            Cancelar
          </ButtonIcon>
        </Grid>
        <Grid item xs={6} md={4}>
          <ButtonIcon
            color="success"
            handleClick={handleSubmit}
            variant={"contained"}
            styleButton={{ marginLeft: "10px" }}
            noLink
            startIcon={<CheckIcon />}
          >
            Guardar
          </ButtonIcon>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ListaAreasAMostrar;
