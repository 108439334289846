/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetAllOptionsMutation } from "../../../../store/api/endpoints/optionsApiSlice";
import {
  useDeleteRelevamientoMutation,
  useGetOneRelevamientoMutation,
} from "../../../../store/api/endpoints/relevamientoApiSlice";
import Error from "../../../elementos/Error";
import Loading from "../../../elementos/Loading";
import RelevamientoGeneral from "../Areas/RelevamientoGeneral";
import TitleEspaciosRelevamientos from "./TitleEspaciosRelevamientos";
import { useGetSelfMutation } from "../../../../store/api/endpoints/authApiSlice";
import { useDispatch } from "react-redux";
import { openFeedback } from "../../../../store/slices/feedback";
import { Container, Grid } from "@mui/material";
import createObjectForEdit from "../../../../utils/createObjForEditRelevamiento";
import { options } from "../../../../store/slices/options";

export const EspaciosVerdesRelevamiento = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const id = searchParams.get("id");
  const dispatch = useDispatch();
  const id_espacio_verde = searchParams.get("espacio_verde");
  const [error, seterror] = useState({
    error: false,
    message: "",
  });
  const [getRelevamiento, responseRelevamieno] = useGetOneRelevamientoMutation();
  const [getAllOptions, responseOptions] = useGetAllOptionsMutation();
  const [deleteRelevamiento, responseDelete] = useDeleteRelevamientoMutation();
  const [getSelf, responseSelf] = useGetSelfMutation();

  useEffect(() => {
    getAllOptions();
    getSelf(sessionStorage.getItem("token"));
    getRelevamiento(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    if (responseRelevamieno.isSuccess) {
      const today = new Date().getTime();
      const converToDate = new Date(
        `${responseRelevamieno?.data?.relevamientos.fecha_relevado}T${responseRelevamieno?.data?.relevamientos.hora}Z`
      ).getTime();

      const timeStampDiference = today - converToDate;
      const diferenceInHours = timeStampDiference / (1000 * 60 * 60).toFixed();

      if (responseSelf?.data?.usuario?.perfil === 2 && diferenceInHours > process.env.HORAS_PARA_EDITAR) {
        seterror({
          error: true,
          message: `No puede editar el relevamiento. Paso mas de ${process.env.HORAS_PARA_EDITAR} horas de su creacion.`,
        });
        return;
      }

      if (
        responseSelf?.data?.usuario?.codigo !== responseRelevamieno?.data?.relevamientos?.usuario &&
        responseSelf?.data?.usuario?.perfil === 2
      ) {
        seterror({
          error: true,
          message: `No puede editar el relevamiento porque no fue creado por usted.`,
        });
        return;
      }

      let rel_relevamiento = createObjectForEdit(responseRelevamieno?.data);
      /*     sessionStorage.removeItem("rel_relevamientos"); */
      sessionStorage.setItem("rel_relevamientos", JSON.stringify(rel_relevamiento));
      navigate(`/relevamiento/editar?id=${id_espacio_verde}`, {
        state: { label: `Editando el relevamiento ${id}` },
      });
    }
  };

  useEffect(() => {
    if (responseOptions.isSuccess) {
      dispatch(options(responseOptions?.data));
    }
  }, [responseOptions.isSuccess]);

  useEffect(() => {
    if (error.error) {
      dispatch(
        openFeedback({
          severity: "error",
          message: error.message,
        })
      );
    }
    seterror({
      error: false,
      message: "",
    });
  }, [error.error]);

  if (responseRelevamieno.isLoading || responseOptions.isLoading || responseSelf.isLoading) return <Loading />;
  return (
    <Container component={"main"} maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TitleEspaciosRelevamientos
            relevamiento={responseRelevamieno?.data?.relevamientos}
            onClick={handleClick}
            permisos={responseSelf?.data?.usuario.perfil}
            handleClick={handleClick}
            deleteRelevamiento={deleteRelevamiento}
            responseDelete={responseDelete}
            id={id}
            id_espacio_verde={id_espacio_verde}
          />
        </Grid>
      </Grid>
      <Grid container>
        <RelevamientoGeneral relevamiento={responseRelevamieno?.data} responseOptions={responseOptions} />
      </Grid>
      {(responseRelevamieno?.isError || responseOptions.isError) && <Error />}
    </Container>
  );
};
