/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";

import Input from "./Input";
import { Divider, Grid } from "@mui/material";
import "../../assets/css/Filter.css";
import SelectMultiple from "./SelectMultiple";
import RadioButton from "./RadioButton";
import ButtonIcon from "./ButtonIcon";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import { useEffect } from "react";

export default function FiltroInformes({ filter, setFilter, btnClicked, comunas }) {
  const [option, setoption] = React.useState("informes");
  const [listUsuarios, setlistUsuarios] = React.useState([]);

  const handleGenderChange = (event) => {
    setoption(event.target.value);
  };

  const handleChange = (value, name) => {
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  React.useEffect(() => {
    for (let i = 1; i <= 12; i++) {
      const usuario = { label: `Usuario ${i}`, value: i };
      setlistUsuarios((prev) => [...prev, usuario]);
    }
  }, []);

  useEffect(() => {
    if (!option)
      setFilter(() => ({
        usuario: [],
        empresa: [],
        dateFrom: "",
        dateTo: "",
        comunas: [],
      }));
  }, [option]);

  const getRegistersWithFilters = () => {
    btnClicked(true);
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={2}
      style={{
        width: "98%",
        display: "flex",
        position: "absolute",
        backgroundColor: "#f7f7f9",
        borderRadius: "20px",
        marginLeft: "0px",
        padding: "10px",
        border: "3px solid #ededef",
      }}
    >
      <h3>Seleccione el filtrado</h3>
      <Grid item xs={12} sm={1} md={1} style={{ width: "100%", height: "100%", padding: "10px", marginRight: "10px" }}>
        {listUsuarios.length > 0 && (
          <SelectMultiple
            label="Usuario"
            disabled={false}
            defaultValue=""
            options={listUsuarios}
            type="text"
            onChange={(e) => handleChange(e, "usuario")}
            elegidos={filter?.usuario ? Object.values(filter?.usuario).map((el) => el) : []}
            name={"usuario"}
          />
        )}
      </Grid>
      {option === "reportes" && (
        <Grid container>
          <Grid
            item
            xs={12}
            sm={1}
            md={1}
            style={{ width: "100%", height: "100%", padding: "10px", marginRight: "10px" }}
          >
            {listEmpresas.length > 0 && (
              <SelectMultiple
                label="Empresa"
                disabled={false}
                defaultValue=""
                options={listEmpresas}
                type="text"
                onChange={(e) => handleChange(e, "empresa")}
                elegidos={filter?.empresa ? filter?.empresa : []}
                name={"empresa"}
              />
            )}
            {comunas.length > 0 && (
              <SelectMultiple
                label="Comuna"
                disabled={false}
                defaultValue=""
                options={comunas.map((el) => ({ value: el.codigo, label: `Comuna ${el.codigo}` }))}
                type="text"
                onChange={(e) => handleChange(e, "comunas")}
                elegidos={filter?.comunas ? filter?.comunas : []}
                name={"comunas"}
              />
            )}
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} sm={1} md={1} style={{ width: "100%", height: "100%", padding: "10px", marginRight: "10px" }}>
        <Input
          type="date"
          value={filter?.dateFrom}
          label="Seleccione la fecha desde"
          onChange={(e) => handleChange(e, "dateFrom")}
          required
          restricciones={["fecha"]}
          min={"2018-01-01"}
          max={filter.fromTo}
        />
      </Grid>
      <Grid item xs={12} sm={1} md={1} style={{ width: "100%", height: "100%", padding: "10px", marginRight: "10px" }}>
        <Input
          type="date"
          value={filter?.dateTo}
          label="Seleccione la fecha hasta"
          onChange={(e) => handleChange(e, "dateTo")}
          required
          restricciones={["fecha"]}
          min={"2018-01-01"}
        />
      </Grid>
      <Grid item xs={12} sm={1} md={1} className="boxType" style={{ border: "none" }}>
        <RadioButton
          label="Informes"
          name="informes"
          id="tipo"
          value="informes"
          checked={option === "informes"}
          onChange={handleGenderChange}
        />
        <RadioButton
          label="Reportes"
          name="reportes"
          id="tipo"
          value="reportes"
          checked={option === "reportes"}
          onChange={handleGenderChange}
        />
      </Grid>
      <Grid container>
        <Grid item xs={6} sm={6} md={6} className="boxType" style={{ border: "none" }}>
          <ButtonIcon
            variant="outlined"
            size="small"
            color="error"
            noLink
            handleClick={() => {}}
            styleButton={{ fontWeight: 500 }}
            startIcon={<CancelIcon />}
          >
            Cancelar
          </ButtonIcon>
        </Grid>
        <Grid item xs={6} sm={6} md={6} className="boxType" style={{ border: "none" }}>
          <ButtonIcon
            variant="outlined"
            size="small"
            color="dark"
            styleButton={{ backgroundColor: "#FFE600", fontWeight: 500, fontSize: "0.8rem" }}
            noLink
            handleClick={getRegistersWithFilters}
            startIcon={<SearchIcon />}
          >
            Buscar
          </ButtonIcon>
        </Grid>
      </Grid>

      <Divider orientation="vertical" />
    </Grid>
  );
}

const listEmpresas = [
  { label: "Algeri", value: 1 },
  { label: "Ninguna", value: 2 },
  { label: "V. INTEGRAL", value: 3 },
  { label: "INDALTEC", value: 4 },
  { label: "MIICA S.A", value: 5 },
  { label: "PARQUIZAR", value: 6 },
  { label: "V. CUCULO", value: 7 },
];
