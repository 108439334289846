import React from "react";
import Input from "../../../../elementos/Input";
import Select from "../../../../elementos/Select";
import { Container, Grid } from "@mui/material";

export const Caniles = ({ isDisabled = false, areas, setareas, index, dataAllAtributes, options }) => {
  const handleChange = (value, key) => {
    setareas((prev) => ({
      ...prev,
      caniles: {
        ...prev.caniles,
        [index]: {
          ...prev.caniles[index],
          [key]: value,
        },
      },
    }));
  };
  return (
    <Container component={"main"} maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <h3>Canil {++index}</h3>
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Cantidad de equipos"
            value={areas?.cant_equipos_tot}
            disabled={isDisabled}
            type="tel"
            name={`cant_equipos_tot`}
            restricciones={["soloNumeros"]}
            onChange={handleChange}
            min={0}
            max={200}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Cantidad de bolsas exp totales"
            value={areas?.cant_bolsas_exp_tot}
            disabled={isDisabled}
            type="tel"
            name={`cant_bolsas_exp_tot`}
            restricciones={["soloNumeros"]}
            onChange={handleChange}
            min={0}
            max={200}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="Limpieza de equipamiento"
            value={areas?.limpieza_equipamiento}
            defaultValue=""
            type="text"
            options={options.limpieza}
            onChange={handleChange}
            disabled={isDisabled}
            name={"limpieza_equipamiento"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Expend sin bolsas"
            disabled={isDisabled}
            type="number"
            name={`expend_sin_bolsa`}
            value={areas?.expend_sin_bolsa}
            restricciones={["soloNumeros"]}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Cantidad Equipos defectuosos"
            value={areas?.equipos_defectuosos}
            disabled={isDisabled}
            type="teñ"
            name={`equipos_defectuosos`}
            restricciones={["soloNumeros"]}
            onChange={handleChange}
            min={0}
            max={200}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Cantidad de bebederos"
            value={areas?.cant_bebederos_tot}
            disabled={isDisabled}
            type="tel"
            name={`cant_bebederos_tot`}
            restricciones={["soloNumeros"]}
            onChange={handleChange}
            min={0}
            max={200}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Bebederos defectuosos"
            value={areas?.bebederos_defectuosos}
            disabled={isDisabled}
            type="number"
            name={`bebederos_defectuosos`}
            restricciones={["soloNumeros"]}
            onChange={handleChange}
            min={0}
            max={200}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Equipo con falta pintura"
            disabled={isDisabled}
            type="number"
            name={`equipos_falta_pintura`}
            value={areas?.equipos_falta_pintura}
            restricciones={["soloNumeros"]}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="Estado de solado"
            value={areas?.estado_solado}
            defaultValue=""
            type="text"
            onChange={handleChange}
            options={options.porcentaje}
            disabled={isDisabled}
            name={"estado_solado"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="Limpieza de solado"
            value={areas?.limpieza_solado}
            defaultValue=""
            type="text"
            onChange={handleChange}
            options={options.limpieza}
            disabled={isDisabled}
            name={"limpieza_solado"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="Estado de reja"
            onChange={handleChange}
            defaultValue=""
            name={"estado_rejas"}
            options={options.porcentaje}
            disabled={isDisabled}
            value={areas?.estado_rejas}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="Pintura de reja"
            onChange={handleChange}
            defaultValue=""
            name={"pintura_rejas"}
            options={options.porcentaje}
            disabled={isDisabled}
            value={areas?.pintura_rejas}
          />
        </Grid>
      </Grid>
      <br />
    </Container>
  );
};
