import React from "react";
import Input from "../../../../elementos/Input";
import Select from "../../../../elementos/Select";
import { Container, Grid } from "@mui/material";

export const Mobiliario = ({ isDisabled, areas, setareas, index, dataAllAtributes }) => {
  const handleChange = (value, key) => {
    setareas((prev) => ({
      ...prev,
      mobiliarios: {
        ...prev.mobiliarios,
        [index]: {
          ...prev.mobiliarios[index],
          [key]: value,
        },
      },
    }));
  };
  return (
    <Container component={"main"} maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <h3>Mobiliario </h3>
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="Tipo de banco"
            value={areas?.bancos_tipo}
            defaultValue=""
            type="text"
            options={dataAllAtributes?.tipos_bancos_mob.map((tipo) => ({
              label: tipo.descripcion,
              value: tipo.codigo,
            }))}
            onChange={handleChange}
            name={"bancos_tipo"}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="tel"
            value={areas?.bancos_cantidad}
            disabled={isDisabled}
            label="Cantidad de bancos"
            variant="standard"
            onChange={handleChange}
            name="bancos_cantidad"
            restricciones={["soloNumeros"]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="number"
            value={areas?.mesas_cantidad}
            disabled={isDisabled}
            label="Cantidad de mesas"
            variant="standard"
            onChange={handleChange}
            name="mesas_cantidad"
            restricciones={["soloNumeros"]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="tel"
            value={areas?.bebederos_cantidad}
            disabled={isDisabled}
            label="Cantidad de bebederos"
            variant="standard"
            onChange={handleChange}
            name="bebederos_cantidad"
            restricciones={["soloNumeros"]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="tel"
            value={areas?.bicicleteros_cantidad}
            disabled={isDisabled}
            label="Cantidad de bicicleteros"
            variant="standard"
            onChange={handleChange}
            name="bicicleteros_cantidad"
            restricciones={["soloNumeros"]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="Tipo de carteles"
            value={areas?.carteles_tipo}
            defaultValue=""
            type="text"
            options={dataAllAtributes?.tipos_carteles_mob.map((tipo) => ({
              label: tipo.descripcion,
              value: tipo.codigo,
            }))}
            onChange={handleChange}
            name={"carteles_tipo"}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="tel"
            value={areas?.carteles_cantidad}
            disabled={isDisabled}
            label="Cantidad de carteles "
            variant="standard"
            onChange={handleChange}
            name="carteles_cantidad"
            restricciones={["soloNumeros"]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="En obra"
            value={areas?.en_obra ? areas?.en_obra : "0"}
            defaultValue=""
            options={[
              { label: "Si", value: 1 },
              { label: "No", value: 0 },
            ]}
            type="text"
            onChange={handleChange}
            disabled={isDisabled}
            name={"en_obra"}
          />
        </Grid>
      </Grid>
      <br />
    </Container>
  );
};
