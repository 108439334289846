/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetAllAtributesOfAreasMutation,
  useGetAreasEspacioVerdeMutation,
  useGetEspacioVerdeMutation,
  useUpdateAreasMutation,
} from "../../../../store/api/endpoints/espaciosApiSlice";
import { useGetAllOptionsMutation } from "../../../../store/api/endpoints/optionsApiSlice";
import { openFeedback } from "../../../../store/slices/feedback";
import { Error, Loading, ButtonIcon, HeaderVista, Volver, Divider } from "../../../elementos";
import { AreaDeportiva, Caniles, Mobiliario, PatioJuegos, PostaAerobica } from "./Areas secas/";
import { CardAreaVerde } from "./Areas secas/CardAreaVerde";

import "../../../../assets/css/espacioverdeareas.css";

import { Container, Grid } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

export const EspacioVerdeAreas = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const navigate = useNavigate();
  const id = searchParams.get("id");
  const areaSelected = useRef();
  const [areas, setareas] = useState({
    init: true,
  });
  const [getAllOptions, responseOptions] = useGetAllOptionsMutation();
  const [getAreas, response] = useGetAreasEspacioVerdeMutation();
  const [updateArea, responseUpdateArea] = useUpdateAreasMutation();
  const [
    getAllAtributesOfAreas,
    {
      data: dataAllAtributes,
      isLoading: isLoadingAllAtributes,
      isError: isErrorToAllAtributes,
      error: errorToGetAllAtributes,
    },
  ] = useGetAllAtributesOfAreasMutation();
  const [getEspacioVerde, responseEspacioVerde] = useGetEspacioVerdeMutation();
  const [option, setoption] = useState("");
  const [isEditable, setisEditable] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    updateArea({ data: areas, id });
    setisEditable(false);
  };

  const handleCancel = () => {
    setisEditable(false);
  };

  const handleRedirect = () => {
    let nombre = location?.state?.nombre;
    if (!nombre) {
      nombre = responseEspacioVerde?.data?.dato?.nombre_eevv;
    }
    navigate(`/espaciosverdes/creararea?id-eevv=${id}`, { state: { nombre } });
  };

  useEffect(() => {
    try {
      Promise.all([getAllOptions(), getAreas(id), getAllAtributesOfAreas(), getEspacioVerde(id)]);
    } catch (error) {
      console.log("Error al llamar al microservicio");
    }
  }, []);

  useEffect(() => {
    if (response.isError || isErrorToAllAtributes) {
      dispatch(
        openFeedback({
          severity: "error",
          message: response.error?.data?.message || errorToGetAllAtributes,
        })
      );
    }
    if (responseUpdateArea.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "Registro actualizado correctamente.",
        })
      );
    }
  }, [response.isError, isErrorToAllAtributes, responseUpdateArea.isSuccess, responseUpdateArea.isError]);

  useMemo(() => {
    setareas({
      mobiliarios: response?.data?.mobiliarios,
      patiodejuegos: response?.data?.patiodejuegos,
      postaAerobica: response?.data?.postaAerobica,
      caniles: response?.data?.caniles,
      areaDeportiva: response?.data?.areas_deportivas,
    });
  }, [response.data]);

  if (response.isLoading || responseOptions.isLoading || isLoadingAllAtributes || responseUpdateArea.isLoading) {
    return <Loading />;
  }
  if (response.isError || isErrorToAllAtributes) {
    return <Error message={response?.error?.data?.message || errorToGetAllAtributes?.error?.data?.message} />;
  }
  return (
    response.isSuccess && (
      <Container component={"main"} maxWidth="lg">
        <HeaderVista
          start={<Volver label="Volver" to={`/espaciosverdes/detalle?id=${id}`} />}
          titulo={"Listado de areas"}
          end={
            <>
              <ButtonIcon
                noLink
                handleClick={() => setisEditable(!isEditable)}
                variant="outlined"
                startIcon={<EditIcon />}
              >
                Editar
              </ButtonIcon>
              <ButtonIcon
                noLink
                handleClick={handleRedirect}
                variant="outlined"
                color="success"
                startIcon={<AddIcon />}
              >
                Nueva
              </ButtonIcon>
            </>
          }
        />
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <CardAreaVerde
              areaSelected={areaSelected}
              areas={areas?.patiodejuegos}
              options={responseOptions?.data}
              setoption={setoption}
              isEditable={!isEditable}
              option={option}
              title="Patio de juegos"
              setareas={setareas}
              dataAllAtributes={dataAllAtributes}
              component={<PatioJuegos />}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <CardAreaVerde
              areaSelected={areaSelected}
              areas={areas?.postaAerobica}
              options={responseOptions?.data}
              setareas={setareas}
              setoption={setoption}
              isEditable={!isEditable}
              option={option}
              title="Posta aerobica"
              dataAllAtributes={dataAllAtributes}
              component={<PostaAerobica />}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <CardAreaVerde
              areaSelected={areaSelected}
              areas={areas?.mobiliarios}
              options={responseOptions?.data}
              setareas={setareas}
              setoption={setoption}
              isEditable={!isEditable}
              option={option}
              title="Mobiliario"
              dataAllAtributes={dataAllAtributes}
              component={<Mobiliario />}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <CardAreaVerde
              areaSelected={areaSelected}
              setareas={setareas}
              areas={areas?.areaDeportiva}
              isEditable={!isEditable}
              options={responseOptions?.data}
              setoption={setoption}
              option={option}
              title="Area deportiva"
              dataAllAtributes={dataAllAtributes}
              component={<AreaDeportiva />}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <CardAreaVerde
              areaSelected={areaSelected}
              areas={areas?.caniles}
              options={responseOptions?.data}
              setareas={setareas}
              setoption={setoption}
              isEditable={!isEditable}
              option={option}
              title="Caniles"
              dataAllAtributes={dataAllAtributes}
              component={<Caniles />}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <br />
          {isEditable && (
            <Grid container maxWidth={"lg"} justifyContent={"center"}>
              <Divider />
              <Grid item xs={6} md={6}>
                <ButtonIcon
                  color="error"
                  handleClick={handleCancel}
                  variant={"contained"}
                  noLink
                  startIcon={<CancelIcon />}
                >
                  Cancelar
                </ButtonIcon>
              </Grid>
              <Grid item xs={6} md={4}>
                <ButtonIcon
                  color="success"
                  handleClick={handleSubmit}
                  variant={"contained"}
                  noLink
                  startIcon={<CheckIcon />}
                >
                  Guardar
                </ButtonIcon>
              </Grid>
            </Grid>
          )}
        </Grid>
        <br />
      </Container>
    )
  );
};
