import React from "react";
import Input from "../../../../elementos/Input";
import Select from "../../../../elementos/Select";
import { Container, Grid } from "@mui/material";

export const PatioJuegos = ({ setareas, areas, isDisabled = true, index, dataAllAtributes }) => {
  const handleChange = (value, key) => {
    setareas((prev) => ({
      ...prev,
      patiodejuegos: {
        ...prev.patiodejuegos,
        [index]: {
          ...prev.patiodejuegos[index],
          [key]: value,
        },
      },
    }));
  };

  return (
    <Container component={"main"} maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <h3>Patio de juegos </h3>
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="Tipo Solado"
            value={areas?.tipo_solados ? areas?.tipo_solados : ""}
            defaultValue=""
            options={dataAllAtributes?.solado_patios?.map((element) => ({
              label: element.descripcion,
              value: element.codigo,
            }))}
            type="text"
            onChange={handleChange}
            disabled={isDisabled}
            name={"tipo_solados"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            type="tel"
            fullWidth
            variant="standard"
            onChange={handleChange}
            disabled={isDisabled}
            value={areas?.cant_equipos}
            label="Cantidad de equipos"
            name={`cant_equipos`}
            restricciones={["soloNumeros"]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="Rejas"
            value={areas?.rejas ? areas?.rejas : "0"}
            defaultValue=""
            options={[
              { label: "Si", value: 1 },
              { label: "No", value: 0 },
            ]}
            type="text"
            onChange={handleChange}
            disabled={isDisabled}
            name={"rejas"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="En obra"
            value={areas?.en_obra ? areas?.en_obra : "0"}
            defaultValue=""
            options={[
              { label: "Si", value: 1 },
              { label: "No", value: 0 },
            ]}
            type="text"
            onChange={handleChange}
            disabled={isDisabled}
            name={"en_obra"}
          />
        </Grid>
      </Grid>
      <br />
    </Container>
  );
};
