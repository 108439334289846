/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ButtonIcon, Error, HeaderVista, Loading, Volver } from "../../../elementos";
import FooterSemanas from "./components/FooterSemanas";
import TableDataTitleInforme from "./components/TableDataTitleInforme";
import BodyCreateInspecciones from "./components/BodyCreateInspecciones";
import { useGetAllAtributesOfEEVVMutation } from "../../../../store/api/endpoints/espaciosApiSlice";
import {
  useGetOneInformeMutation,
  useGetSemanasEditablesMutation,
  useUpdateInformeFullApiMutation,
} from "../../../../store/api/endpoints/informesApiSlice";
import { useGetAllOptionsMutation } from "../../../../store/api/endpoints/optionsApiSlice";
import { openFeedback } from "../../../../store/slices/feedback";
import { options } from "../../../../store/slices/options";
import { Container, Divider, Grid } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import HistoryIcon from "@mui/icons-material/History";
import "../../../../assets/css/createInspeccion.css";
import { getInformeFull, updateInformeFull, updateTieneAreasInforme, updatesemanasEditables } from "../../../../store/slices/informes";

const MemorizedFooter = memo(FooterSemanas);

const CrearInspeccion = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const informeStore = useSelector(getInformeFull, shallowEqual);
  const [semanaSeleccionada, setsemanaSeleccionada] = useState();
  const [editable, seteditable] = useState(false);
  const id = searchParams.get("id");
  const updating = searchParams.get("updating");
  const navigate = useNavigate();
  const [todoListo, setTodoListo] = useState(false);
  const [getAllOptions, responseOptions] = useGetAllOptionsMutation();
  const [getAllAtributes, responseGetAllaAtributes] = useGetAllAtributesOfEEVVMutation();
  const [getInforme, responseInforme] = useGetOneInformeMutation();
  const [getSemanasEditables, responseSemanasEditables] = useGetSemanasEditablesMutation();
  const [UpdateInformesFullApi, responseInformeFullApi] = useUpdateInformeFullApiMutation();


  useEffect(() => {
    // const periodo = actualDate.getFullYear() + (actualDate.getMonth() + 1).toString().padStart(2, "0");
    Promise.all([getAllOptions(), getAllAtributes(), getInforme(id)]);
    if (!updating || (updating !== "true" && updating !== "false")) navigate("/inspecciones");
  }, []);

  useEffect(() => {
    if (responseOptions.isSuccess) {
      dispatch(options(responseOptions?.data));
    }
    if (responseInforme.isSuccess) {
      // setinformes(responseInforme?.data);
      dispatch(updateInformeFull(responseInforme?.data));
      let tenencia = {
        tiene_postas: responseInforme?.data?.tiene_postas,
        tiene_mobiliario: responseInforme?.data?.tiene_mobiliario,
        tiene_patio: responseInforme?.data?.tiene_patio,
        tiene_canil: responseInforme?.data?.tiene_canil,
        tiene_deportiva: responseInforme?.data?.tiene_deportiva,
        tiene_cesped: responseInforme?.data?.tiene_cesped,
        tiene_canteros: responseInforme?.data?.tiene_canteros,
      };
      dispatch(updateTieneAreasInforme(tenencia));
    }
    if (responseSemanasEditables.isSuccess) {
      dispatch(updatesemanasEditables(responseSemanasEditables?.data));
      setsemanaSeleccionada(responseSemanasEditables?.data?.find((el) => el.editable)?.semana ?? "");
    }

    if( responseOptions.isSuccess && responseGetAllaAtributes.isSuccess && responseInforme.isSuccess && responseSemanasEditables.isSuccess && informeStore.id_eevv!=null ){
      setTodoListo(true)
    }

  }, [
    responseOptions.isSuccess,
    responseGetAllaAtributes.isSuccess,
    responseInforme.isSuccess,
    responseSemanasEditables.isSuccess,
  ]);

  useEffect(() => {

    if (responseInformeFullApi.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: `Informe actualizado.`,
        })
      );
    }
    if (responseInformeFullApi.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: "No se pudo actualizar el informe."
        })
      );
    }

  }, [responseInformeFullApi.isSuccess, responseInformeFullApi.isError])


  useEffect(() => {
    getSemanasEditables(informeStore?.mes_codigo);
  }, [informeStore?.mes_codigo]);


  useEffect(() => {
    if (todoListo) {
      handleSave()
    }
  }, [informeStore])

  const handleSave = async (data) => {
    dispatch(
      openFeedback({
        severity: "info",
        message: `Actualizando informe...`,
      })
    );
    await UpdateInformesFullApi({ data: informeStore, id: id });
  };

  if (
    responseOptions.isLoading ||
    responseGetAllaAtributes.isLoading ||
    responseInforme.isLoading ||
    responseSemanasEditables.isLoading
  ) {
    return <Loading />;
  }

  if (
    responseOptions.isError ||
    responseGetAllaAtributes.isError ||
    (responseInforme.isError && responseInforme.error.data.message !== "No existen registros.")
  ) {
    return <Error />;
  }


  return (
    <Container component={"main"} maxWidth="lg">
      <HeaderVista
        start={<Volver label="Volver" to={`/inspecciones`} color="primary" />}
        titulo={`Nueva inspeccion semana ${semanaSeleccionada ? semanaSeleccionada.split("_")[1] : ""} 
        `}
        end={
          <Volver
            label="Historial"
            to={`/inspecciones/detalles?id=${informeStore?.id_eevv}`}
            icon={<HistoryIcon />}
            variant="outlined"
            color="success"
          />
        }
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TableDataTitleInforme informe={informeStore} />
        </Grid>
        {semanaSeleccionada && (
          <Grid item xs={12} md={12}>
            <BodyCreateInspecciones
              semana={semanaSeleccionada}
              editable={editable}
              id={id}
            />
          </Grid>
        )}

        <Divider />
        <Grid item xs={12} md={12}>
          <ButtonIcon
            type="submit"
            variant="contained"
            color="success"
            noLink
            handleClick={() => handleSave()}
            startIcon={<SaveIcon />}
            styleButton={{ marginLeft: "15spx" }}
          >
            Guardar cambios
          </ButtonIcon>
        </Grid>
        <div
          style={{
            zIndex: 1,
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "8%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <MemorizedFooter
            setsemanaSeleccionada={setsemanaSeleccionada}
            selecter={semanaSeleccionada}
            seteditable={seteditable}
            updating={updating}
          />
        </div>
      </Grid>
      <br />
      <br />
    </Container>
  );
};

export default CrearInspeccion;
