import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ButtonIcon } from "../../../../elementos";

export default function TableFilter(props) {
  const handleChange = (e) => {
    props.handleChange(e);
  };
  return (
    <TableContainer component={Paper} style={{ marginTop: 15 }}>
      <Table sx={{ minWidth: 250 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Nombre espacio</TableCell>
            <TableCell sx={{maxWidth:"200px"}}>Codigo</TableCell>
            {props?.items?.length > 0 && <TableCell align="right">Accion</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {props?.items?.map((row) => (
            <TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row" sx={{ fontSize: '9pt'}}>
                {row.nombre_eevv} Codigo {row.codigo}
              </TableCell>
              <TableCell scope="row" sx={{ fontSize: '9pt',width:60}}>
                 {row.codigo}
              </TableCell>
              <TableCell align="right">
                <ButtonIcon
                  type="button"
                  startIcon={props.icon}
                  noLink
                  size={"1rem"}
                  handleClick={() => handleChange(row)}
                  variant={"outlined"}
                  color={props.iconLabel === "Eliminar" ? "error" : "success"}
                >
                  {props.iconLabel}
                </ButtonIcon>
              </TableCell>
            </TableRow>
          ))}
          <TableRow key={"empty"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            {props?.items?.length === 0 && (
              <TableCell align="center">
                <span>No hay espacios seleccionados. Se obtendran todos</span>
              </TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
