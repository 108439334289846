import React, { useEffect } from "react";
import BasicCard from "./CardEspacioVerde";
import { shallowEqual, useSelector } from "react-redux";
import { getEspaciosVerdes } from "../../../../store/slices/espacioverdes";
import { Grid } from "@mui/material";
import { useState } from "react";

const ListaEspacios = () => {
  const espaciosVerdes = useSelector(getEspaciosVerdes, shallowEqual);
  const [listEspacios, setlistEspacios] = useState([]);

  useEffect(() => {
    setlistEspacios(espaciosVerdes);
  }, [espaciosVerdes]);

  return (
    <Grid container spacing={2} style={{ justifyContent: "center" }} >
      {listEspacios?.map((espacio) => (
        <Grid item xs={12} md={9} key={espacio?.id_espacio_verde} style={{ justifyContent: "center" }}>
          <BasicCard espacioVerde={espacio} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ListaEspacios;
