export const completeDateAndNotEqualDates = ({ fecha_desde, fecha_hasta, anio, semana, mes }) => {
    return !!fecha_desde && fecha_desde === fecha_hasta && anio && semana && mes;
  };
  
  export function obtenerInicioYFinDeSemana(anio, mes, semana) {
    var primerDiaMes = new Date(anio, mes - 1, 1);
    var primerDiaSemana = new Date(primerDiaMes.setDate(primerDiaMes.getDate() + (semana - 1) * 7));
    var ultimoDiaSemana = new Date(primerDiaSemana);
    ultimoDiaSemana.setDate(ultimoDiaSemana.getDate() + 6);
    
      return { primerDiaSemana, ultimoDiaSemana };
    }
    
    