import { tiendasApi } from "../api";

export const permisosApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    getPermisos: builder.mutation({
      query: (id) => ({
        url: `/permisos?id=${id}`,
        method: "GET",
      }),
    }),
    getAllPermisos: builder.mutation({
      query: () => ({
        url: `/permisos/allpermisos`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetPermisosMutation, useGetAllPermisosMutation } = permisosApiSlice;
