import React, { useState } from "react";
import { ButtonIcon } from "../../elementos";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ListAreasOFRelevamientos = ({ handleOption, errorInArea, selected }) => {
  const selectOption = (value) => {
    handleOption(value);
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h2>Seleccione opcion a cargar</h2>
      <div style={{ display: "flex", flexDirection: "row", marginBottom: "1.5rem", justifyContent: "space-evenly" }}>
        <ButtonIcon
          type="button"
          handleClick={() => selectOption("limpieza")}
          noLink
          styleButton={{
            fontSize: "0.92rem",
            width: "30%",
            maxWidth: "200px",
            backgroundColor: selected === "limpieza" ? "lightgray" : "",
            boxShadow: selected === "limpieza" ? "rgb(31 31 31) 1px 2px 3px 0px inset" : "",
            opacity: selected === "limpieza" ? "0.7" : "",
          }}
          variant={"outlined"}
          color="success"
          endIcon={
            errorInArea?.limpieza ? (
              <ErrorIcon style={{ fill: "red" }} />
            ) : (
              <CheckCircleIcon style={{ fill: "green" }} />
            )
          }
        >
          Limpieza
        </ButtonIcon>
        <ButtonIcon
          type="button"
          handleClick={() => selectOption("area_verde")}
          styleButton={{
            fontSize: "0.92rem",
            width: "30%",
            maxWidth: "200px",
            backgroundColor: selected === "area_verde" ? "lightgray" : "",
            boxShadow: selected === "area_verde" ? "rgb(31 31 31) 1px 2px 3px 0px inset" : "",
            opacity: selected === "area_verde" ? "0.7" : "",
          }}
          noLink
          variant={"outlined"}
          color="success"
          endIcon={
            errorInArea?.area_verde ? (
              <ErrorIcon style={{ fill: "red" }} />
            ) : (
              <CheckCircleIcon style={{ fill: "green" }} />
            )
          }
        >
          A. verde
        </ButtonIcon>
        <ButtonIcon
          type="button"
          handleClick={() => selectOption("area_seca")}
          styleButton={{
            fontSize: "0.92rem",
            width: "30%",
            maxWidth: "200px",
            backgroundColor: selected === "area_seca" ? "lightgray" : "",
            boxShadow: selected === "area_seca" ? "rgb(31 31 31) 1px 2px 3px 0px inset" : "",
            opacity: selected === "area_seca" ? "0.7" : "",
          }}
          noLink
          variant={"outlined"}
          color="success"
          endIcon={
            errorInArea?.area_seca ? (
              <ErrorIcon style={{ fill: "red" }} />
            ) : (
              <CheckCircleIcon style={{ fill: "green" }} />
            )
          }
        >
          A. seca
        </ButtonIcon>
      </div>
    </div>
  );
};

export default ListAreasOFRelevamientos;
