import React, { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Icon } from "@mui/material";
import "../../../assets/css/desplegable.css";

export const Desplegable = ({ text, children = () => {}, icon, option, setoption, name, current, toggleDefault }) => {
  const [toggle, settoggle] = useState();

  useEffect(() => {
    settoggle(toggleDefault);
  }, [option]);

  const handleSelect = () => {
    setoption(name);
    settoggle(!toggle);
    if (current?.current && toggle === false) {
      current.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  };

  useEffect(() => {
    if (toggle) {
      const element = document.getElementById(name);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [toggle]);

  useEffect(() => {
    if (option !== name) {
      settoggle(false);
    }
  }, [name, option]);

  return (
    <div className="container-desplegable" style={{ borderRadius: toggle ? "20px 0px" : "20px" }} ref={current}>
      <div
        className="container-icons"
        style={{ borderRadius: toggle ? "20px 20px 0px 0px" : "20px", padding: "6px 6px" }}
        onClick={() => handleSelect()}
        id={name}
      >
        <h3 className="text-desplegable">{text}</h3>
        <Icon>{icon}</Icon>
        {toggle && name === option ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </div>
      {toggle && name === option && (
        <div
          style={{
            width: "100%",
            padding: "4px",
            maxWidth: "1200px",
            border: "1px solid #ffe600",
            borderRadius: "0px 0px 20px 20px ",
            boxShadow:
              "rgba(0, 0, 0, 0.2) 2px 2px 10px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
          }}
        >
          {children}
          <br />
        </div>
      )}
    </div>
  );
};
