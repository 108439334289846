import React from "react";
import ListadoFotos from "./ListadoFotos";

const SeccionFoto = ({ semana_1, semana_2, semana_3, semana_4, label, id }) => {
  return (
    <div style={{ maxHeight: "100%" }}>
      <p className="sectionTitle">
        <b>{label}</b>
      </p>
      <ListadoFotos imagenes={semana_1} semana="Semana 1" id={id} label={label} />
      <ListadoFotos imagenes={semana_2} semana="Semana 2" id={id} label={label} />
      <ListadoFotos imagenes={semana_3} semana="Semana 3" id={id} label={label} />
      <ListadoFotos imagenes={semana_4} semana="Semana 4" id={id} label={label} />
    </div>
  );
};

export default SeccionFoto;
