import React from "react";

export const InfoBasicaEspacio = ({ espacio, toggleLocation, color, index }) => {
  return (
    <div
      style={{
        marginTop: "5px",
        width: "100%",
        maxWidth: "720px",
        justifyContent: "center",
        transition: "all 0.5s ease-out",
      }}
      key={index}
    >
      {espacio && (
        <>
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "60px",
              paddingLeft: "8px",
              minWidth: "40%",
              alignItems: "center",
              backgroundColor: "#d6d6d6",
              opacity: "0.9",
              justifyContent: "flex-start",
            }}
          >
            <div style={{ width: "70%", flexDirection: "column", alignItems: "center" }}>
              <h5
                style={{
                  cursor: "pointer",
                  margin: "0 0 0 0",
                  color: color,
                  display: "flex",
                  textAlign: "left",
                  overflow: "hidden",
                }}
              >
                {espacio.nombre_eevv.split("(")[0]}
              </h5>
              <h6 style={{ margin: "1px 0px 1px 0px", width: "70%", display: "flex", textAlign: "left" }}>
                {espacio.codigo}
              </h6>
            </div>
            {toggleLocation && (
              <div style={{ width: "30%", flexDirection: "column", alignItems: "center" }}>
                <p>{espacio.distancia && ` ${espacio?.distancia + 1.4} KM Aprox.`}</p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
