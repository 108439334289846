import {
  checkIfAllAtributesAretrueCaniles,
  checkIfAllAtributesAretrueCanteros,
  checkIfAllAtributesAretrueCesped,
  checkIfAllAtributesAretrueDeportiva,
  checkIfAllAtributesAretrueLimpieza,
  checkIfAllAtributesAretrueMobiliario,
  checkIfAllAtributesAretruePatios,
  checkIfAllAtributesAretruePostas,
} from "./funcionesValidacionInformes";

export const getErroresAreas = (areas, informes, semana) => {
  let ObjectError = {};
  if (areas?.tiene_canteros) {
    ObjectError.canteros = checkIfAllAtributesAretrueCanteros(informes?.canteros, semana);
  }
  if (areas?.tiene_canil) {
    ObjectError.caniles = checkIfAllAtributesAretrueCaniles(informes?.caniles, semana);
  }
  if (areas?.tiene_cesped) {
    ObjectError.cesped = checkIfAllAtributesAretrueCesped(informes?.cesped, semana);
  }
  if (areas?.tiene_patio) {
    ObjectError.patioJuegos = checkIfAllAtributesAretruePatios(informes?.patiojuegos, semana);
  }
  if (areas?.tiene_deportiva) {
    ObjectError.playonDeportivo = checkIfAllAtributesAretrueDeportiva(informes?.deportiva, semana);
  }
  if (areas?.tiene_postas) {
    ObjectError.PostaAerobica = checkIfAllAtributesAretruePostas(informes?.postas, semana);
  }
  if (areas?.tiene_mobiliario) {
    ObjectError.mobiliario = checkIfAllAtributesAretrueMobiliario(informes?.mobiliario, semana);
  }
  ObjectError.observacionesGenerales = !informes.observaciones_generales || informes.observaciones_generales.length < 3;
  ObjectError.limpieza = checkIfAllAtributesAretrueLimpieza(informes?.limpieza, semana);
  return ObjectError;
};
