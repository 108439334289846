import React from "react";
import Select from "../../../../elementos/Select";
import { Container, Grid } from "@mui/material";

const Limpieza = ({ optionLimpieza, valueLimpieza }) => {
  return (
    <Container component={"main"} maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Select
            defaultValue=""
            value={valueLimpieza?.av_limpieza_gral}
            label="Limpieza General"
            options={optionLimpieza}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            defaultValue=""
            value={valueLimpieza?.av_limpieza_dia}
            label="Limpieza del dia"
            options={optionLimpieza}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            defaultValue=""
            value={valueLimpieza?.av_limpieza_garitas}
            label="Limpieza de garitas"
            options={optionLimpieza}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            defaultValue=""
            value={valueLimpieza?.av_limpieza_pto_verde}
            label="Limpieza puntos verdes"
            options={optionLimpieza}
            disabled={true}
          />
        </Grid>
      </Grid>
      <br />
    </Container>
  );
};

export default Limpieza;
