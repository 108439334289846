import React from "react";
import ButtonIcon from "../../elementos/ButtonIcon";
import { Container, Grid } from "@mui/material";
import { HeaderVista } from "../../elementos";

const NotFound = () => {
  return (
    <Container component={"main"} maxWidth="lg">
      <Grid container spacing={2}>
        <HeaderVista
          start={<></>}
          titulo={"Pagina no encontrada."}
          end={
            <>
              <ButtonIcon size={"big"} variant={"contained"} to="/" children={"Ir aL INICIO"} />
            </>
          }
        />
      </Grid>
    </Container>
  );
};

export default NotFound;
