/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateCuadranteMutation } from "../../../../store/api/endpoints/cuadranteApiSlice";
import { useDispatch } from "react-redux";
import { openFeedback } from "../../../../store/slices/feedback";
import "../../../../assets/css/crearPoligono.css";
import { Container, Grid } from "@mui/material";
import { CancelOutlined } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import { Error, HeaderVista, Input, Loading, ButtonIcon, Volver, Divider } from "../../../elementos";

const CrearCuadrante = () => {
  const [nombre, setnombre] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [createCuadrante, responseCreate] = useCreateCuadranteMutation();
  const handleCancel = () => {
    navigate("/configuracion/cuadrantes");
  };

  const handleCreate = (e) => {
    e.preventDefault();
    createCuadrante({ nombre: nombre });
  };

  useEffect(() => {
    if (responseCreate.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "Cuadrante creado",
        })
      );
      navigate(`/configuracion/cuadrante?id=${responseCreate.data.cuadranteCreado.codigo}`);
    }

    if (responseCreate.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: responseCreate.error?.data?.message,
        })
      );
    }
  }, [responseCreate.isSuccess, responseCreate.isError]);

  if (responseCreate.isLoading) {
    return <Loading />;
  }

  if (responseCreate.isError) {
    return <Error message="Error al obtener los datos." />;
  }

  return (
    <>
      <HeaderVista start={<Volver label="Volver" to="/configuracion/cuadrantes" />} titulo="Crear cuadrante" />
      <form onSubmit={handleCreate}>
        <Container component={"main"} maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Input label="Nombre del cuadrante" value={nombre} onChange={setnombre} type="text" required />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ButtonIcon
                type="button"
                variant="contained"
                color="error"
                noLink
                handleClick={handleCancel}
                startIcon={<CancelOutlined />}
              >
                Cancelar
              </ButtonIcon>
              <ButtonIcon
                type="submit"
                variant="contained"
                color="success"
                noLink
                startIcon={<CheckIcon />}
                styleButton={{ marginLeft: "10px" }}
              >
                Guardar
              </ButtonIcon>
            </Grid>
          </Grid>
          <br />
        </Container>
      </form>
    </>
  );
};

export default CrearCuadrante;
