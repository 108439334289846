import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Typography } from "@mui/material";
import { ButtonIcon } from "../../../elementos";

const TitleTable = ({ id }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between", padding: "0.45rem 0.45rem" }}>
      <Typography textAlign={"left"} variant="h6" id="tableTitle" component="span" fontSize={"1rem"}>
        Areas del espacio
      </Typography>
      <div>
        <ButtonIcon to={`/espaciosverdes/areas?id=${id}`} variant={"contained"} styleButton={{ color: "white" }}>
          Ver detalles
        </ButtonIcon>
      </div>
    </div>
  );
};
export default function BasicTable({ cantidadActivas, enObra, id_espacio_verde }) {
  const rows = [
    {
      label: "Area deportiva",
      cantidad: cantidadActivas?.cantidad_deportivas + enObra?.deportiva,
      activos: cantidadActivas?.cantidad_deportivas,
      en_obra: enObra?.deportiva,
      id: 1,
    },
    {
      label: "Caniles",
      cantidad: cantidadActivas?.cantidad_caniles + enObra?.caniles,
      activos: cantidadActivas?.cantidad_caniles,
      en_obra: enObra?.caniles,
      id: 2,
    },
    {
      label: "Mobiliario",
      cantidad: cantidadActivas?.cantidad_mobiliarios + enObra?.mobiliario,
      activos: cantidadActivas?.cantidad_mobiliarios,
      en_obra: enObra?.mobiliario,
      id: 3,
    },
    {
      label: "Patio de juegos",
      cantidad: cantidadActivas?.cantidad_patios + enObra?.patios,
      activos: cantidadActivas?.cantidad_patios,
      en_obra: enObra?.patios,
      id: 4,
    },
    {
      label: "Posta aerobicas",
      cantidad: cantidadActivas?.cantidad_postas + enObra?.postas,
      activos: cantidadActivas?.cantidad_postas,
      en_obra: enObra?.postas,
      id: 5,
    },
  ];
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <TitleTable id={id_espacio_verde} />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 450 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Tipo de Área</TableCell>
                <TableCell align="right">Cantidad</TableCell>
                <TableCell align="right">Activas</TableCell>
                <TableCell align="right">En obra</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {row.label}
                  </TableCell>
                  <TableCell align="right">{row.cantidad || 0}</TableCell>
                  <TableCell align="right">{row.activos || 0}</TableCell>
                  <TableCell align="right">{row.en_obra || 0}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
