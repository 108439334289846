/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loading from "../../elementos/Loading";
import { openFeedback } from "../../../store/slices/feedback";

import { useGetClienteMutation, useUpdateContactoClienteMutation } from "../../../store/api/endpoints/clientesApiSlice";

import HeaderVista2 from "../../elementos/HeaderVista2";
import Volver from "../../elementos/Volver";

import "../../../assets/css/vistaFormulario.css";

import { useGetAllPermisosMutation } from "../../../store/api/endpoints/permisosApiSlice";
import ListaDeAtributosClientes from "./ListaDeAtributosClientes";
import { useGetAllPoligonosMutation } from "../../../store/api/endpoints/espaciosApiSlice";
import { useGetAllPerfilesMutation } from "../../../store/api/endpoints/perfilesApiSlice";

export const ClienteUpdate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [poligonos, setpoligonos] = useState();
  const [perfiles, setperfiles] = useState();
  const [getAllPoligonos, responsePoligonos] = useGetAllPoligonosMutation();
  const [
    getCliente,
    {
      data: responseGetCliente,
      isLoading: isLoadingGetCliente,
      isSuccess: isSuccessGetCliente,
      isError: isErrorGetCliente,
    },
  ] = useGetClienteMutation();
  const [
    updateContactoCliente,
    { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, isError: isErrorUpdate, error: errorDataUpdate },
  ] = useUpdateContactoClienteMutation();
  const [getAllPerfiles, responsePerfiles] = useGetAllPerfilesMutation();
  const [getAllPermisos, responsePermisos] = useGetAllPermisosMutation();
  const [cliente, setcliente] = useState({});
  // eslint-disable-next-line no-unused-vars
  const handleSubmit = (e) => {
    updateContactoCliente({ id, cliente });
  };

  useEffect(() => {
    Promise.all([getCliente(id), getAllPermisos(), getAllPoligonos(), getAllPerfiles()]);
    if (!id) {
      navigate(`/configuracion/usuarios`);
    }
  }, []);
  useEffect(() => {
    if (isSuccessGetCliente) {
      setcliente({
        activo: responseGetCliente?.usuario?.activo,
        nombre: responseGetCliente?.usuario?.nombre,
        apellido: responseGetCliente?.usuario?.apellido,
        email: responseGetCliente?.usuario?.email,
        telefono: responseGetCliente?.usuario?.telefono,
        usuario: responseGetCliente?.usuario?.usuario,
        poligonos: responseGetCliente?.poligonos,
        perfil: responseGetCliente?.usuario?.perfil,
      });
    }

    if (responsePoligonos.isSuccess) {
      setpoligonos(responsePoligonos?.data);
    }
    if (responsePerfiles.isSuccess) {
      setperfiles(responsePerfiles?.data.perfiles);
    }
  }, [isSuccessGetCliente, responsePoligonos.isSuccess, responsePermisos.isSuccess, responsePerfiles.isSuccess]);

  useEffect(() => {
    if (isSuccessUpdate) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha actualizado el registro!",
        })
      );
      navigate(`/configuracion/usuario?id=${id}`);
    }

    if (isErrorUpdate) {
      dispatch(
        openFeedback({
          severity: "error",
          message: `Error al actualizar el usuario. ${errorDataUpdate}`,
        })
      );
    }
    if (isErrorGetCliente) {
      dispatch(
        openFeedback({
          severity: "error",
          message: "Error al obtener los datos del usuario.",
        })
      );
      navigate(`/configuracion/usuarios`);
    }
  }, [isSuccessUpdate, isErrorUpdate, isErrorGetCliente]);

  if (isLoadingGetCliente || isLoadingUpdate) {
    return <Loading />;
  }
  if (cliente && poligonos) {
    return (
      <>
        <HeaderVista2
          start={<Volver to="/configuracion/usuarios" label="Volver" />}
          titulo={"Modificando " + responseGetCliente?.usuario?.nombre + " " + responseGetCliente?.usuario?.apellido}
          end={<></>}
        />
        <ListaDeAtributosClientes
          cliente={cliente}
          setcliente={setcliente}
          poligonos={poligonos}
          perfiles={perfiles}
          onSubmit={handleSubmit}
        />
        <br />
      </>
    );
  } else {
    return <Loading />;
  }
};
