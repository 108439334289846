export const createFilter = (filters) => {
  let options;
  if (filters?.anioFrom && filters?.anioTo && filters?.mesFrom && filters?.mesTo) {
    let formatFecha = {
      dateFrom: `${filters.anioFrom}-${filters.mesFrom}`,
      dateTo: `${filters.anioTo}-${filters.mesTo}`,
    };
    options = `?from=${formatFecha.dateFrom}-01&to=${formatFecha.dateTo}-31`;
  }
  if (filters?.inspectores?.length > 0) {
    options = options + `&inspector=${filters.inspectores}`;
  }
  if (filters?.poligonos?.length > 0) {
    options = options + `&poligonos=${filters.poligonos}`;
  }
  if (filters?.comunas?.length > 0) {
    options = options + `&comunas=${filters.comunas}`;
  }
  if (filters?.fileType) {
    options = options + `&fileType=${filters.fileType}`;
  }
  if (filters?.empresas_prestadoras) {
    options = options + `&empresas_prestadoras=${filters.empresas_prestadoras}`;
  }
  if (filters?.espacios) {
    let espaciosID = filters.espacios.map((el) => el.id_espacio_verde);
    options = options + `&espacios=${espaciosID.toString()}`;
  }
  if (filters?.semanas) {
    options = options + `&semanas=${filters.semanas}`;
  }

  if(filters?.id){
    options = `?_id=${filters.id}`;
  }
  return options;
};
