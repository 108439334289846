import React from "react";
import "../../../assets/css/table.css";
import { shallowEqual, useSelector } from "react-redux";
import { getUsuarios } from "../../../store/slices/clientes";
import ButtonIcon from "../../elementos/ButtonIcon";
import SearchIcon from "@mui/icons-material/Search";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
const ListaClientes = () => {
  const usuarios = useSelector(getUsuarios, shallowEqual);
  return (
    <div className="tableContainer">
      <table className="formato1" cellSpacing="0" cellPadding="0">
        <thead>
          <tr style={{ height: "10%" }}>
            <th align="left">Nombre</th>
            <th align="left">Apellido</th>
            <th align="center">Ver</th>
          </tr>
        </thead>
        <tbody>
          {usuarios?.length > 0 ? (
            usuarios?.map((cliente, index) => {
              return (
                <tr key={index} style={{ height: "10%" }}>
                  <td align="left" style={{ display: "flex", alignItems: "center" }}>
                    {cliente.activo === 1 ? (
                      <FiberManualRecordIcon style={{ color: "green" }} />
                    ) : (
                      <FiberManualRecordIcon style={{ color: "#ff1c1c" }} />
                    )}
                    <p>{cliente.nombre}</p>
                  </td>
                  <td align="left">
                    <p>{cliente.apellido}</p>
                  </td>
                  <td align="center">
                    <ButtonIcon
                      variant="outlined"
                      size="small"
                      color="primary"
                      arialLabel="detailsUser"
                      startIcon={<SearchIcon />}
                      to={"/configuracion/usuario?id=" + cliente.codigo}
                      marginRight
                    />
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="4">No se encontraron registros</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ListaClientes;
