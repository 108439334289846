/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet } from "react-router-dom";
import SinPermisos from "./SinPermisos";
import { useEffect } from "react";
import { useGetSelfMutation } from "../../../store/api/endpoints/authApiSlice";
import { Loading } from "../../elementos";
const RequireRol = ({ RequireRol, selfPerfil }) => {
  const [getSelf, responseSelf] = useGetSelfMutation();
  const token = sessionStorage.getItem("token");
  useEffect(() => {
    if (token) {
      getSelf();
    }
  }, []);

  if (responseSelf.isLoading) {
    return <Loading />;
  }

  return RequireRol.includes(responseSelf.data?.usuario.perfil) ? (
    <>
      <Outlet style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "15px" }} />
    </>
  ) : (
    <SinPermisos />
  );
};

export default RequireRol;
