import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    nombre: null,
    permisos: null,
  },
  reducers: {
    setCredentials: (state, action) => {
      state.token = action.payload.token;
      sessionStorage.setItem("token", action.payload.token);
    },
    logOut: (state, action) => {
      sessionStorage.removeItem("token");
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export const getUsuarioNombre = (state) => state.auth.nombre;
export const getUsuarioToken = () => sessionStorage.getItem("token");
export const getUsuarioPermisos = (state) => state.auth.permisos;
