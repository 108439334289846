import { makeStyles } from "@mui/styles";
import React from "react";
const useStyles = makeStyles({
  container: {
    position: "absolute",
    left: "50%",
    top: "50%",
    color: "black",
    transform: "translate(-50%, -50%)",
  },
  tableCaratula: {
    width: "500px !important",
    "& .MuiTableCell-root": {
      border: "1px solid black",
      backgroundColor: "#a8a8a8",
      color: "white",
    },
    "& tr": {
      border: "1px solid black",
    },
    "& tr > td": {
      border: "1px solid black",
      padding: "10px",
    },
    "& td": {
      border: "1px solid black",
    },
  },
  valueRow: {
    textAlign: "left",
    color: "black",
  },
  descriptionRow: {
    textAlign: "left",
    color: "white",
    backgroundColor: "#bababa",
  },
});
const TableCaratule = () => {
  const clasess = useStyles();
  return (
    <div className={clasess.container}>
      <table className={clasess.tableCaratula} cellSpacing={0}>
        <tbody>
          <tr>
            <td className={clasess.descriptionRow}>Comuna</td>
            <td className={clasess.valueRow}>1</td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>Mes</td>
            <td className={clasess.valueRow}>Mayo</td>
          </tr>
          <tr>
            <td className={clasess.descriptionRow}>Semana</td>
            <td className={clasess.valueRow}>2</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TableCaratule;
