import { tiendasApi } from "../api";

export const informesApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    getInformes: builder.mutation({
      query: (filter) => ({
        url: `/informes?${filter}`,
        method: "GET",
      }),
    }),

    getProgramaciones: builder.mutation({
      query: (query) => ({
        url: `/inspeccion/verprogramadas?${query}`,
        method: "GET",
      }),
    }),
    getProgramacion: builder.mutation({
      query: (id) => ({
        url: `/inspeccion/inspeccion-programada/${id}`,
        method: "GET",
      }),
    }),
    getSemanasEditables: builder.mutation({
      query: (date) => ({
        url: `/inspeccion/periodo?periodo=${date}`,
        method: "GET",
      }),
    }),
    deleteProgramaciones: builder.mutation({
      query: (id) => ({
        url: `/inspeccion/${id}`,
        method: "DELETE",
      }),
    }),
    createProgramacion: builder.mutation({
      query: (body) => ({
        url: `/inspeccion/programar_Inspeccion`,
        method: "POST",
        body,
      }),
    }),
    updateProgramacion: builder.mutation({
      query: ({ body, id }) => ({
        url: `/inspeccion/${id}`,
        method: "PUT",
        body,
      }),
    }),
    uploadFile: builder.mutation({
      query: (file) => ({
        url: `/informes/subir`,
        method: "POST",
        body: file,
      }),
    }),
    //Informes---------
    createInforme: builder.mutation({
      query: (body) => ({
        url: `/informes`,
        method: "POST",
        body,
      }),
    }),
    getInformeOFEEVV: builder.mutation({
      query: (data) => ({
        url: `/informes?id=${data.id}&mes_codigo=${data.mes_codigo}`,
        method: "GET",
      }),
    }),
    getAllInformesOfEEVV: builder.mutation({
      query: (id) => ({
        url: `/informes/${id}`,
        method: "GET",
      }),
    }),
    getOneInforme: builder.mutation({
      query: (id) => ({
        url: `/informes/one/${id}`,
        method: "GET",
      }),
    }),
    updateInforme: builder.mutation({
      query: (data) => ({
        url: `/informes`,
        method: "PUT",
        body: data,
      }),
    }),
    updateInformeFullApi: builder.mutation({
      query: ({id,data}) => ({
        url: `/informes/updateFull/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    //Get informe in tsv
    getInformeTSV: builder.mutation({
      query: (parametros) => ({
        url: `/informes/download${parametros}`,
        method: "GET",
      }),
    }),
    getAllIdWithOfFilters: builder.mutation({
      query: (parametros) => ({
        url: `/informes/listids${parametros}`,
        method: "GET",
      }),
    }),
    getEspaciosYPeriodos: builder.mutation({
      query: (parametros) => ({
        url: `/informes/espaciosyperiodos${parametros}`,
        method: "GET",
      }),
    }),
    getLastInformes: builder.mutation({
      query: ({ limit, page, fecha_ultima_modif, espacio, comunas, poligonos, inspectores }) => ({
        url: `/informes/ultimosinformes?limit=${limit}&page=${page}&fecha_ultima_modif=${
          fecha_ultima_modif ?? ""
        }&espacio=${espacio ?? ""}&comunas=${comunas ?? ""}&poligonos=${poligonos ?? ""}&inspectores=${
          inspectores ?? ""
        }`,
        method: "GET",
      }),
    }),
    getPeriodosEditables: builder.mutation({
      query: () => ({
        url: "/inspeccion/periodos",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetInformesMutation,
  useUploadFileMutation,
  useDeleteProgramacionesMutation,
  useGetProgramacionMutation,
  useGetProgramacionesMutation,
  useCreateProgramacionMutation,
  useUpdateProgramacionMutation,
  useCreateInformeMutation,
  useGetInformeOFEEVVMutation,
  useUpdateInformeMutation,
  useGetOneInformeMutation,
  useGetSemanasEditablesMutation,
  useGetAllInformesOfEEVVMutation,
  useGetInformeTSVMutation,
  useGetEspaciosYPeriodosMutation,
  useGetAllIdWithOfFiltersMutation,
  useGetLastInformesMutation,
  useGetPeriodosEditablesMutation,
  useUpdateInformeFullApiMutation
} = informesApiSlice;
