/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Loading, HeaderVista, Volver, Input, Error, ButtonIcon } from "../../elementos";
import ListaCuadrantes from "./ListaDeCuadrantes";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useGetAllMutation, useGetOneMutation } from "../../../store/api/endpoints/cuadranteApiSlice";
import "../../../assets/css/InformacionDelCuadrante.css";
import { useDispatch } from "react-redux";
import { cuadrantes } from "../../../store/slices/cuadrantes";

const CuadranteScreen = () => {
  const [inputFilter, setinputFilter] = useState("");
  const dispatch = useDispatch();
  const allcuadrantes = useRef(null);
  const [timer, settimer] = useState(null);
  const [page, setpage] = useState(0);
  const [cantRow, setcantRow] = useState(10);
  const [cuadrantesList, setcuadrantesList] = useState({});
  const [getCuadrantes, responseCuadrantes] = useGetAllMutation();
  const [getOneCuadrante, responseOneCuadrante] = useGetOneMutation();

  useEffect(() => {
    getCuadrantes({ page, cantRow });
  }, []);

  useEffect(() => {
    if (responseCuadrantes.isSuccess) {
      allcuadrantes.current = responseCuadrantes?.data?.data;
      setcuadrantesList(responseCuadrantes?.data?.data);
    }
  }, [responseCuadrantes.isSuccess, responseOneCuadrante.isSuccess]);

  const handleInput = React.useCallback(
    (e) => {
      setinputFilter(e);
      clearTimeout(timer);
      if (e.trim().length > 0) {
        const newTimer = setTimeout(async () => {
          getOneCuadrante(e);
        }, 400);
        settimer(newTimer);
      } else {
        setcuadrantesList(allcuadrantes.current);
      }
    },
    [timer, getOneCuadrante]
  );

  useEffect(() => {
    if (cuadrantesList.length > 0) {
      dispatch(cuadrantes(cuadrantesList));
    }
  }, [cuadrantesList]);

  if (responseCuadrantes.isError) {
    return <Error message="Error al obtener los datos." />;
  }

  return (
    <Container component="main" maxWidth="lg">
      <HeaderVista
        start={<Volver label="Volver" to="/" />}
        titulo="Cuadrantes"
        end={
          <ButtonIcon
            variant="outlined"
            size="small"
            color="primary"
            startIcon={<AddIcon />}
            to="/configuracion/cuadrantes/crear"
            children="Nuevo cuadrante"
          />
        }
      />
      <Grid container>
        <Grid item xs={12} md={12}>
          <Input
            label="Buscar cuadrante"
            icon={<SearchIcon />}
            value={inputFilter}
            onChange={handleInput}
            type="text"
            placeholder="Ingrese nombre del cuadrante"
            restricciones={["alfanumerico"]}
            style={{ width: "100%", padding: "10px" }}
          />
        </Grid>
      </Grid>
      <br />
      {cuadrantes?.isLoading ? <Loading /> : <ListaCuadrantes />}
      {/*  <Pagination setrowsPerPages={setrowsPerPages} label="Usuarios" setpage={setPage} page={page} rowsPerPage={cantRows} total={totalClientes ? totalClientes : 0} nextPage={responseAllClientes?.nextUrl ? responseAllClientes?.nextUrl : false} /> */}
      <br />
    </Container>
  );
};

export default CuadranteScreen;
