import { Container, Grid } from "@mui/material";
import React from "react";
import { Input, Select } from "../../../../elementos";
import ListImages from "../components/Images/ListImages";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getInformeFull, updateInformeFull } from "../../../../../store/slices/informes";
const Mobiliario = ({ semana, editable, id }) => {
  const dispatch = useDispatch();

  const informeStore = useSelector(getInformeFull, shallowEqual);

  const handleChange = (name, value, subName) => {
    let informeTemp = JSON.stringify(informeStore);
    informeTemp = JSON.parse(informeTemp);
    if (subName!=="select") {
      informeTemp[`${semana}`].mobiliario[`${name}`][`${subName}`] = value;
    }else{
      informeTemp[`${semana}`].mobiliario[`${name}`] = value;
    }

    dispatch(updateInformeFull(informeTemp));
  };

  return (
    <Container maxWidth="lg" component={"main"}>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Select
            defaultValue=""
            value={
              informeStore[`${semana}`]?.mobiliario?.en_obra ? informeStore[`${semana}`]?.mobiliario?.en_obra : false
            }
            label="¿Espacio completo en Obra?"
            options={[
              { label: "Si", value: true },
              { label: "No", value: false },
            ]}
            disabled={!editable}
            onChange={(e) => handleChange("en_obra",e,"select")}
          />
        </Grid>
        {informeStore[`${semana}`]?.mobiliario?.bancos.totales > 0 && (
          <>
            <Grid item xs={12} md={6}>
              <Input
                fullWidth
                type="number"
                label="Cantidad de bancos"
                variant="standard"
                value={informeStore[`${semana}`]?.mobiliario?.bancos?.totales}
                restricciones={["soloNumeros"]}
                name="totales"
                min={0}
                max={informeStore[`${semana}`]?.mobiliario?.bancos?.totales}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                fullWidth
                type="number"
                label="Bancos defectuosos"
                variant="standard"
                value={informeStore[`${semana}`]?.mobiliario?.bancos?.defectuosos ?? ""}
                restricciones={["soloNumeros"]}
                onChange={(e) => handleChange( "bancos",e, "defectuosos")}
                name="defectuosos"
                min={0}
                max={informeStore[`${semana}`]?.mobiliario?.bancos?.totales}
                disabled={!editable || informeStore[`${semana}`]?.mobiliario?.bancos?.totales === 0}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                fullWidth
                type="number"
                label="Bancos grafiteados"
                variant="standard"
                value={informeStore[`${semana}`]?.mobiliario?.bancos?.grafiteados ?? ""}
                restricciones={["soloNumeros"]}
                onChange={(e) => handleChange( "bancos",e, "grafiteados")}
                name="grafiteados"
                min={0}
                max={informeStore[`${semana}`]?.mobiliario?.bancos?.totales}
                disabled={!editable || informeStore[`${semana}`]?.mobiliario?.bancos?.totales === 0}
              />
            </Grid>
          </>
        )}
        {informeStore[`${semana}`]?.mobiliario?.mesas?.totales > 0 && (
          <>
            <Grid item xs={12} md={6}>
              <Input
                fullWidth
                type="number"
                label="Cantidad de mesas"
                variant="standard"
                value={informeStore[`${semana}`]?.mobiliario?.mesas?.totales}
                restricciones={["soloNumeros"]}
                name="totales"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                fullWidth
                type="number"
                label="Mesas defetuosas"
                variant="standard"
                value={informeStore[`${semana}`]?.mobiliario?.mesas?.defectuosos ?? ""}
                restricciones={["soloNumeros"]}
                onChange={(e) => handleChange( "mesas", e,"defectuosos")}
                name="defectuosos"
                min={0}
                max={informeStore[`${semana}`]?.mobiliario?.mesas?.totales}
                disabled={!editable || informeStore[`${semana}`]?.mobiliario?.mesas?.totales === 0}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                fullWidth
                type="number"
                label="Mesas grafiteadas"
                variant="standard"
                value={informeStore[`${semana}`]?.mobiliario?.mesas?.grafiteados ?? ""}
                restricciones={["soloNumeros"]}
                onChange={(e) => handleChange("mesas",e,  "grafiteados")}
                name="grafiteados"
                min={0}
                max={informeStore[`${semana}`]?.mobiliario?.mesas?.totales ?? 0}
                disabled={!editable || informeStore[`${semana}`]?.mobiliario?.mesas?.totales === 0}
              />
            </Grid>
          </>
        )}

        {informeStore[`${semana}`]?.mobiliario?.bicicleteros?.totales > 0 && (
          <>
            <Grid item xs={12} md={6}>
              <Input
                fullWidth
                type="number"
                label="Bicicleteros totales"
                variant="standard"
                value={informeStore[`${semana}`]?.mobiliario?.bicicleteros?.totales ?? ""}
                restricciones={["soloNumeros"]}
                name="defectuosos"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                fullWidth
                type="number"
                label="Bicicleteros defectuosos"
                variant="standard"
                value={informeStore[`${semana}`]?.mobiliario?.bicicleteros?.defectuosos ?? ""}
                restricciones={["soloNumeros"]}
                onChange={(e) => handleChange("bicicleteros",e,  "defectuosos")}
                name="defectuosos"
                min={0}
                max={informeStore[`${semana}`]?.mobiliario?.bicicleteros?.totales}
                disabled={!editable || informeStore[`${semana}`]?.mobiliario?.bicicleteros?.totales === 0}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                fullWidth
                type="number"
                label="Bicicleteros grafiteados"
                variant="standard"
                value={informeStore[`${semana}`]?.mobiliario?.bicicleteros?.grafiteados ?? ""}
                restricciones={["soloNumeros"]}
                onChange={(e) => handleChange("bicicleteros",e,  "grafiteados")}
                name="grafiteados"
                min={0}
                max={informeStore[`${semana}`]?.mobiliario?.bicicleteros?.totales}
                disabled={!editable || informeStore[`${semana}`]?.mobiliario?.bicicleteros?.totales === 0}
              />
            </Grid>
          </>
        )}

        {informeStore[`${semana}`]?.mobiliario?.bebederos?.totales > 0 && (
          <>
            <Grid item xs={12} md={6}>
              <Input
                fullWidth
                type="number"
                label="Bebederos totales"
                variant="standard"
                value={informeStore[`${semana}`]?.mobiliario?.bebederos?.totales}
                restricciones={["soloNumeros"]}
                name="totales"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                fullWidth
                type="number"
                label="Bebederos defectuosos"
                variant="standard"
                value={informeStore[`${semana}`]?.mobiliario?.bebederos?.defectuosos ?? ""}
                restricciones={["soloNumeros"]}
                onChange={(e) => handleChange( "bebederos",e, "defectuosos")}
                name="defectuosos"
                min={0}
                max={informeStore[`${semana}`]?.mobiliario?.bebederos?.totales}
                disabled={!editable || informeStore[`${semana}`]?.mobiliario?.bebederos?.totales === 0}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                fullWidth
                type="number"
                label="Bebederos grafiteados"
                variant="standard"
                value={informeStore[`${semana}`]?.mobiliario?.bebederos?.grafiteados ?? ""}
                restricciones={["soloNumeros"]}
                onChange={(e) => handleChange("bebederos",e,  "grafiteados")}
                name="grafiteados"
                min={0}
                max={informeStore[`${semana}`]?.mobiliario?.bebederos?.totales}
                disabled={!editable || informeStore[`${semana}`]?.mobiliario?.bebederos?.totales === 0}
              />
            </Grid>
          </>
        )}
        {informeStore[`${semana}`]?.mobiliario?.carteles?.totales > 0 && (
          <>
            <Grid item xs={12} md={6}>
              <Input
                fullWidth
                type="number"
                label="Carteles totales"
                variant="standard"
                value={informeStore[`${semana}`]?.mobiliario?.carteles?.totales}
                restricciones={["soloNumeros"]}
                name="totales"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                fullWidth
                type="number"
                label="Carteles rotos o faltantes"
                variant="standard"
                value={informeStore[`${semana}`]?.mobiliario?.carteles?.rotos ?? ""}
                restricciones={["soloNumeros"]}
                onChange={(e) => handleChange("carteles",e,  "rotos")}
                name="defectuosos"
                min={0}
                max={informeStore[`${semana}`]?.mobiliario?.carteles?.totales}
                disabled={!editable || informeStore[`${semana}`]?.mobiliario?.carteles?.totales === 0}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                fullWidth
                type="number"
                label="Ploteo defectuoso/grafiteado"
                variant="standard"
                value={informeStore[`${semana}`]?.mobiliario?.carteles?.ploteo_defectuoso ?? ""}
                restricciones={["soloNumeros"]}
                onChange={(e) => handleChange("carteles",e,  "ploteo_defectuoso")}
                name="rotos"
                min={0}
                max={informeStore[`${semana}`]?.mobiliario?.carteles?.totales}
                disabled={!editable || informeStore[`${semana}`]?.mobiliario?.carteles?.totales === 0}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={12}>
          <Input
            fullWidth
            type="text"
            label="Observaciones"
            variant="standard"
            multiline
            value={informeStore[`${semana}`]?.mobiliario?.observaciones ?? ""}
            restricciones={[]}
            onChange={(e) => handleChange("observaciones",e,  "select")}
            name="observaciones"
            inputProps={{ maxLength: 1000 }}
            disabled={!editable}
          />
        </Grid>
        <Grid item xs={12} md={12} flexWrap={"nowrap"}>
          <ListImages
            imagenes={informeStore[`${semana}`]?.mobiliario?.imagenes}
            ruta={`${semana}.mobiliario.imagenes`}
            handleChange={handleChange}
            editable={editable}
          />
        </Grid>
      </Grid>
      <br />
    </Container>
  );
};

export default Mobiliario;
