import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Typography } from "@mui/material";
import { ButtonIcon, Loading } from "../../../elementos";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useGetInformeOFEEVVMutation } from "../../../../store/api/endpoints/informesApiSlice";
import HistoryIcon from "@mui/icons-material/History";
import { createFilter } from "../../../../utils/DescargaInspeccion-CreateQuery.utils";
function createData(fecha_ult_modif, id, usr_ult_modif, mes_codigo) {
  return { fecha_ult_modif, id, usr_ult_modif, mes_codigo };
}

const TitleTable = ({ id, cantidad }) => {
  const navigate = useNavigate();
  const [getInforme, responseInforme] = useGetInformeOFEEVVMutation();

  const handleRedirect = () => {
    let actualDate = new Date();
    let month = actualDate.getMonth()+1;
    let formattedDayOfMonth = month < 10 ? "0" + month : month;
    getInforme({
      id: id,
      mes_codigo: actualDate.getFullYear() + "" + formattedDayOfMonth,
    });
  };
  React.useEffect(() => {
    if (responseInforme.isSuccess) {
      navigate(`/inspecciones/nuevo?id=${responseInforme?.data?.id}&updating=true`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseInforme.isSuccess]);

  return (
    <div style={{ display: "flex", justifyContent: "space-between", padding: "0.45rem 0.45rem" }}>
      <Typography
        textAlign={"left"}
        variant="h6"
        id="tableTitle"
        component="span"
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "1.5rem",
        }}
      >
        <HistoryIcon />
        Ultimos informes
      </Typography>
      <div>
        <ButtonIcon noLink variant={"contained"} styleButton={{ color: "white" }} handleClick={handleRedirect}>
          Crear
        </ButtonIcon>
      </div>
    </div>
  );
};

export default function TableListaInformes({ informes, id_espacio_verde }) {
  const dataRows = [];
  const navigate = useNavigate();
  const handleNavigateDownload = (id) => {
    let filters = {
      id
    }
    const options = createFilter(filters);
    window.open(`${process.env.REACT_APP_API_PDF}obtenerPDF${options}`, "_blank");
  };

  const handleNavigateToInspeccion = (id) => {
    navigate(`/inspecciones/nuevo?id=${id}&updating=false`);
  };

  if (informes?.length > 0) {
    informes?.forEach((item) => {
      dataRows.push(createData(item.fecha_ult_modif, item._id, item.usr_ult_modif, item.mes_codigo));
    });
  }
  if (!informes) {
    <Loading />;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <TitleTable id={id_espacio_verde} cantidad={informes?.length} />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 150 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Periodo</TableCell>
                <TableCell align="left">Fecha ultima modif</TableCell>
                <TableCell align="left">Usuario ult modif</TableCell>
                <TableCell align="left">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {informes?.length > 0 ? (
                dataRows.map((row, index) => (
                  <TableRow key={row._id ?? index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell align="left" width={"30%"}>
                      {String(row.mes_codigo).slice(0, 4)}{String(row.mes_codigo).slice(4, 6)}
                    </TableCell>
                    <TableCell align="left" size="small" width={"30%"}>
                      {row.fecha_ult_modif}
                    </TableCell>
                    <TableCell align="left" size="small" width={"30%"}>
                      {row.usr_ult_modif}
                    </TableCell>
                    <TableCell align="left" size="small" width={"30%"} className="tableListInformes">
                      <ButtonIcon
                        startIcon={<SearchIcon />}
                        noLink
                        handleClick={() => handleNavigateToInspeccion(row.id)}
                      >
                        <span className="btnActionsInspeciones">Ver</span>
                      </ButtonIcon>
                      <ButtonIcon
                        noLink
                        startIcon={<PictureAsPdfIcon />}
                        handleClick={() => handleNavigateDownload(row.id)}
                      >
                        <span className="btnActionsInspeciones">Descargar</span>
                      </ButtonIcon>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow sx={{ textAlign: "center", "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell align="center" colSpan={8}>
                    No hay registros
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
