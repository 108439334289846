import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fecha_ult_modif: null,
  usr_ult_modif: null,
  fecha_relevamiento:null,
  completo: false,
  limpieza: {
    general: null,
    observaciones: null,
    imagenes: [],
  },
  cesped: {
    cobertura: null,
    estado: null,
    observaciones: null,
    imagenes: [],
  },
  canteros: {
    cobertura: null,
    estado: null,
    observaciones: null,
    imagenes: [],
  },
  patiojuegos: {
    cantidad_equipos_totales: null,
    cantidad_equipos_a_reparar: null,
    limpieza_de_equipos: null,
    estado_solado: null,
    estado_rejas: null,
    observaciones: null,
    imagenes: [],
  },
  caniles: {
    funcionamiento_bebedero: null,
    cantidad_equipos_totales: null,
    cantidad_equipos_a_reparar: null,
    limpieza_de_equipos: null,
    estado_solado: null,
    estado_rejas: null,
    observaciones: null,
    imagenes: [],
  },
  posta: {
    cantidad_equipos_totales: null,
    cantidad_equipos_a_reparar: null,
    estado_solado: null,
    observaciones: null,
    imagenes: [],
  },
  deportiva: {
    cantidad_equipos_totales: null,
    cantidad_equipos_a_reparar: null,
    estado_solado: null,
    observaciones: null,
    imagenes: [],
  },
  mobiliario: {
    bancos: {
      totales: null,
      grafiteados: null,
      defectuosos: null,
    },
    mesas: {
      totales: null,
      grafiteados: null,
      defectuosos: null,
    },
    bicicleteros: {
      totales: null,
      grafiteados: null,
      defectuosos: null,
    },
    bebederos: {
      totales: null,
      grafiteados: null,
      defectuosos: null,
    },
    cestos: {
      totales: null,
      grafiteados: null,
      defectuosos: null,
    },
    carteles: {
      totales: null,
      grafiteados: null,
      defectuosos: null,
    },
    observaciones: null,
    imagenes: [],
  },
  observaciones_generales: null,
};

export const informeSlice = createSlice({
  name: "informe",
  initialState: {
    erroresInAreas: {
      limpieza: true,
      cesped: true,
      canteros: true,
      patioJuegos: true,
      caniles: true,
      PostaAerobica: true,
      playonDeportivo: true,
      mobiliario: true,
      observacionesGenerales: true,
    },
    semanas_editables: [
      {
        editable: null,
        semana: null,
        fecha_desde: null,
        fecha_hasta: null,
      },
      {
        editable: null,
        semana: null,
        fecha_desde: null,
        fecha_hasta: null,
      },
      {
        editable: null,
        semana: null,
        fecha_desde: null,
        fecha_hasta: null,
      },
      {
        editable: null,
        semana: null,
        fecha_desde: null,
        fecha_hasta: null,
      },
    ],
    informe: {
      id_eevv: null,
      nombre_eevv: null,
      codigo_eevv: null,
      direccion: null,
      clase: null,
      puntaje_mes_anterior: null,
      mes_codigo: null,
      comuna: null,
      empresa: null,
      poligono: null,
      fecha_ult_modif: null,
      usr_ult_modif: null,
      semana_1: { ...initialState },
      semana_2: { ...initialState },
      semana_3: { ...initialState },
      semana_4: { ...initialState },
    },
    contiene_areas: {
      tiene_postas: false,
      tiene_mobiliario: false,
      tiene_patio: false,
      tiene_canil: false,
      tiene_deportiva: false,
      tiene_cesped: false,
      tiene_canteros: false,
    },
    informes: [],
  },
  reducers: {
    updateErrorOfInforme: (state, action) => {
      state.erroresInAreas.limpieza = action.payload.limpieza;
      state.erroresInAreas.cesped = action.payload.cesped;
      state.erroresInAreas.canteros = action.payload.canteros;
      state.erroresInAreas.patioJuegos = action.payload.patioJuegos;
      state.erroresInAreas.caniles = action.payload.caniles;
      state.erroresInAreas.PostaAerobica = action.payload.PostaAerobica;
      state.erroresInAreas.playonDeportivo = action.payload.playonDeportivo;
      state.erroresInAreas.mobiliario = action.payload.mobiliario;
      state.erroresInAreas.observacionesGenerales = action.payload.observacionesGenerales;
    },
    updateDataOfInforme: (state, action) => {
      return {
        ...initialState,
        ...action.payload,
      };
    },
    updateInformeFull: (state, action) => {
      let newSlice = {...state.informe,...action.payload}
      state.informe = newSlice
    },
    updateTieneAreasInforme: (state, action) => {
      state.contiene_areas.tiene_postas = action.payload.tiene_postas;
      state.contiene_areas.tiene_mobiliario = action.payload.tiene_mobiliario;
      state.contiene_areas.tiene_patio = action.payload.tiene_patio;
      state.contiene_areas.tiene_canil = action.payload.tiene_canil;
      state.contiene_areas.tiene_deportiva = action.payload.tiene_deportiva;
      state.contiene_areas.tiene_cesped = action.payload.tiene_cesped;
      state.contiene_areas.tiene_canteros = action.payload.tiene_canteros;
    },
    updatesemanasEditables: (state, action) => {
      state.semanas_editables = action.payload;
    },
    updateInformes: (state, action) => {
      state.informes = action.payload;
    },
  },
});

export const {
  updateErrorOfInforme,
  updateDataOfInforme,
  updateTieneAreasInforme,
  updatesemanasEditables,
  updateInformes,
  updateInformeFull
} = informeSlice.actions;

export default informeSlice.reducer;

export const getErrorsOfAreas = (state) => state.informes;
export const getInforme = (state) => state.informes.informe;
export const getTieneAreas = (state) => state.informes.contiene_areas;
export const getSemanasEditables = (state) => state.informes.semanas_editables;
export const getInformes = (state) => state.informes.informes;
export const getInformeFull = state=>state.informes.informe

/* export const deleteInforme = (state) => (state.informes.informes = {}); */
