/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch } from "react-redux";
import "../../../../assets/css/reportesScreen.css";
import { Container, Grid } from "@mui/material";
import {
  useGetAllEmpresasMutation,
  useGetAllEspaciosMutation,
  useGetAllPoligonosMutation,
  useGetComunasMutation,
} from "../../../../store/api/endpoints/espaciosApiSlice";
import { openFeedback } from "../../../../store/slices/feedback";
import { Loading, Input, ButtonIcon, SelectMultiple, Error, HeaderVista, Divider, Select } from "../../../elementos";
import {
  useGetInformeTSVMutation,
} from "../../../../store/api/endpoints/informesApiSlice";
import { listMonth } from "../../../../constants/Meses";
import differenceInMonths from "../../../../utils/diferenceBetweenTwoMonth";
import { createFilter } from "../../../../utils/DescargaInspeccion-CreateQuery.utils";
import TableFilter from "./Tables/TableFilter";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useGetAllNamesUsersMutation } from "../../../../store/api/endpoints/usuariosApiSlice";

export const ScreenDescargas = () => {
  const dispatch = useDispatch();
  const [empresas, setempresas] = useState([]);
  const [listPoligonos, setlistPoligonos] = useState([]);
  const [listComunas, setlistComunas] = useState([]);
  const [listUsers, setlistUsers] = useState([]);
  const [inputFilter, setinputFilter] = useState("");
  let actualDate = new Date()
  const [filters, setfilters] = useState({
    poligonos: [],
    comunas: [],
    fileType: 1,
    anioTo: actualDate.getFullYear(),
    mesTo: "",
    inspectores: [],
    semanas:[],
    empresas_prestadoras: [],
    espacios: [],
    anioFrom: actualDate.getFullYear(),
    mesFrom: "",
  });
  const [timer, settimer] = useState(null);
  const [getPoligonos, responsePoligonos] = useGetAllPoligonosMutation();
  const [getComunas, responseComunas] = useGetComunasMutation();
  const [getInformeTSV, responseInformeTSV] = useGetInformeTSVMutation();
  const [getAllEmpresas, responseAllEmpresas] = useGetAllEmpresasMutation();
  const [getAllUsers, responseAllusers] = useGetAllNamesUsersMutation();
  const [getAllEspacios, responseAllEEVV] = useGetAllEspaciosMutation();

  let now = new Date();
  let daysOfYear = [];
  for (let d = now.getFullYear(); d >= now.getFullYear() - 4; d--) {
    daysOfYear.push({ label: d, value: d });
  }

  useEffect(() => {
    Promise.all([getPoligonos(), getComunas(), getAllEmpresas(), getAllUsers("?cantRows=150")]);
  }, []);

  const handleSubmit = () => {
    let options = "";
    if (!filters.anioFrom || !filters.anioTo || !filters.mesFrom || !filters.mesTo) {
      dispatch(
        openFeedback({
          severity: "error",
          message: "Debe seleccionar las fechas!",
        })
      );
      return;
    }
    options = createFilter(filters);
    if (filters.fileType === 2) {
      getInformeTSV(options);
    }
    if (filters.fileType === 1) {
      window.open(`${process.env.REACT_APP_API_PDF}obtenerPDF${options}`, "_blank");
    }
  };

  const deleteEEVV = (espacio) => {
    let actualValue = [...filters.espacios];
    if (actualValue.length > 0) {
      let existEEVV = validateIfExistEEVV(espacio.id_espacio_verde);
      if (existEEVV >= 0) {
        actualValue.splice(existEEVV, 1);
        setfilters((prev) => ({
          ...prev,
          espacios: [...actualValue],
        }));
        return true;
      } else {
        return false;
      }
    }
  };

  const validateIfExistEEVV = (id) => {
    let actualValue = [...filters.espacios];
    return actualValue.findIndex((el) => el?.id_espacio_verde === id);
  };

  const handleChange = (value, name) => {
    if (name === "espacios") {
      let actualValue = [...filters.espacios];
      if (validateIfExistEEVV(value.id_espacio_verde) === -1) {
        actualValue.push(value);
        setfilters((prev) => ({
          ...prev,
          [name]: [...actualValue],
        }));
      } else {
        dispatch(
          openFeedback({
            severity: "warning",
            message: "Ya ha seleccionado el espacio!.",
          })
        );
      }
      return;
    }
    if (value && name) {
      setfilters((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleInput = React.useCallback(
    (e) => {
      setinputFilter(e);
      clearTimeout(timer);
      if (e.length > 0) {
        const newTimer = setTimeout(async () => {
          getAllEspacios({ page: 0, rowsPerPage: 10, name: e });
        }, 600);
        settimer(newTimer);
      }
    },
    [timer]
  );

  useEffect(() => {
    const date1 = new Date(filters.dateFrom + "/01");
    const date2 = new Date(filters.dateTo + "/01");
    if (differenceInMonths(date1, date2) >= 6) {
      dispatch(
        openFeedback({
          severity: "warning",
          message: "El rango de fechas es muy amplio. Intente con un rango menor.!",
        })
      );
      setfilters((prev) => ({
        ...prev,
        dateFrom: "",
        dateTo: "",
      }));
    }
    if (filters.dateTo < filters.dateFrom) {
      dispatch(
        openFeedback({
          severity: "warning",
          message: "La fecha de desde no puede ser mayor a la fecha hasta!",
        })
      );
      setfilters((prev) => ({
        ...prev,
        dateFrom: "",
        dateTo: "",
      }));
    }
  }, [filters.dateFrom, filters.dateTo]);

  useEffect(() => {
    if (responsePoligonos.isSuccess && listPoligonos.length === 0) {
      setlistPoligonos(
        responsePoligonos?.data.map((poligono) => {
          return { label: poligono?.nombre, value: poligono?.codigo };
        })
      );
    }
    if (responseAllusers.isSuccess && listUsers.length === 0) {
      let memList = responseAllusers?.data.map((el) => ({ label: el.nombre + " " + el.apellido, value: el.codigo }));
      setlistUsers(memList);
    }
    if (responseComunas.isSuccess && listComunas.length === 0) {
      setlistComunas(
        responseComunas?.data?.comunas?.map((comuna) => {
          return { label: `Comuna ${comuna.codigo}`, value: comuna.codigo };
        })
      );
    }
    if (responseAllEmpresas.isSuccess && empresas.length === 0) {
      let empresasPrestadoras = responseAllEmpresas?.data?.empresas_prestadoras.map((empresa) => ({
        label: empresa.nombre,
        value: empresa.codigo,
      }));
      setempresas(empresasPrestadoras);
    }
  
    if (responseInformeTSV.isSuccess && filters.fileType === 2) {
      if(!responseInformeTSV?.data?.ruta || responseInformeTSV?.data?.message === "No hay informes para esa busqueda."){
        dispatch(
          openFeedback({
            severity: "warning",
            message: "No existen informes para esa busqueda.",
          })
        );
      }else{
        window.open(responseInformeTSV?.data?.ruta, "_blank");
      }
    }
  }, [
    responsePoligonos.isSuccess,
    responseComunas.isSuccess,
    responseAllusers.isSuccess,
    responseInformeTSV.isSuccess,
    responseAllEmpresas.isSuccess,
  ]);

  useEffect(() => {
    if (listPoligonos.length > 0) {
      setfilters((prev) => ({
        ...prev,
        poligonos: [],
      }));
    }
  }, [listPoligonos]);

  useEffect(() => {
    if (responseInformeTSV.isError) {
      if (responseInformeTSV.error?.status === 404) {
        dispatch(
          openFeedback({
            severity: "warning",
            message: "No se encontraron informes para los filtros seleccionados.",
          })
        );
      } else {
        dispatch(
          openFeedback({
            severity: "error",
            message: "Error al obtener los datos!",
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseInformeTSV.isError]);

  if (
    responseInformeTSV.isLoading ||
    !listPoligonos.length ||
    !listComunas.length ||
    !empresas.length 
  ) {
    return <Loading />;
  }

  if (responseAllEmpresas.isError || responseComunas.isError || responsePoligonos.isError) {
    return <Error />;
  }

  return (
    <Container component={"main"} maxWidth="lg">
      <HeaderVista titulo="Buscar inspeccion"></HeaderVista>
      <Grid container spacing={2} sx={{ justifyContent: "center" }}>
        <Grid item xs={12} md={12}>
          <span>Para descargar, por favor indicanos la fecha.</span>
        </Grid>
        <Grid item xs={12} md={3}>
          <Select
            defaultValue=""
            value={filters.fileType}
            label="Tipo de descarga"
            options={[
              { label: "PDF", value: 1 },
              { label: "EXCEL", value: 2 },
            ]}
            onChange={(e) => handleChange(e, "fileType")}
            name="fileType"
          />
        </Grid>
        {filters.fileType === 2 && <Grid item xs={12} md={3}>
          <SelectMultiple
            label="Semanas"
            disabled={false}
            defaultValue=""
            options={[{label:"Semana 1",value:"semana_1"},{label:"Semana 2",value:"semana_2"},{label:"Semana 3",value:"semana_3"},{label:"Semana 4",value:"semana_4"}]}
            type="text"
            onChange={(e) => handleChange(e, "semanas")}
            elegidos={filters.semanas}
            name={"semanas"}
          />
        </Grid>}
        
        <Grid item xs={12} md={12}>
          <span>Ingrese período de inicio.</span>
        </Grid>
        <Grid item xs={12} md={3}>
          <Select
            defaultValue=""
            value={filters.anioFrom}
            label="Seleccione el año de inicio"
            options={daysOfYear}
            onChange={(e) => handleChange(e, "anioFrom")}
            name="anioFrom"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Select
            defaultValue=""
            value={filters.mesFrom}
            label="Seleccione el mes de inicio"
            options={listMonth}
            onChange={(e) => handleChange(e, "mesFrom")}
            name="mesFrom"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <span>Ingrese período de fin.</span>
        </Grid>
        <Grid item xs={12} md={3}>
          <Select
            defaultValue=""
            value={filters.anioTo}
            label="Seleccione el año de fin"
            options={daysOfYear}
            onChange={(e) => handleChange(e, "anioTo")}
            name="anioTo"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Select
            defaultValue=""
            value={filters.mesTo}
            label="Seleccione la mes de fin"
            options={listMonth}
            onChange={(e) => handleChange(e, "mesTo")}
            name="mesTo"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <span>Ingrese otros filtros.</span>
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectMultiple
            label="Poligonos"
            disabled={false}
            defaultValue=""
            options={listPoligonos}
            type="text"
            onChange={(e) => handleChange(e, "poligonos")}
            elegidos={filters.poligonos}
            name={"poligono"}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectMultiple
            label="Comunas"
            disabled={false}
            defaultValue=""
            options={listComunas}
            type="text"
            onChange={(e) => handleChange(e, "comunas")}
            elegidos={filters.comunas}
            name={"comunas"}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectMultiple
            defaultValue=""
            disabled={false}
            type="text"
            elegidos={filters.empresas_prestadoras}
            label="Empresa prestadora"
            options={empresas}
            onChange={(e) => handleChange(e, "empresas_prestadoras")}
            name="empresas_prestadoras"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectMultiple
            defaultValue=""
            disabled={false}
            type="text"
            elegidos={filters.inspectores}
            label="Inspectores"
            options={listUsers}
            onChange={(e) => handleChange(e, "inspectores")}
            name="empresas_prestadoras"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            label="Espacio verde"
            placeholder="Ingrese el nombre o codigo del espacio"
            fullWidth
            value={inputFilter}
            variant="standard"
            restricciones={["alfanumerico"]}
            onChange={(e) => handleInput(e, "espacio")}
          />
          {inputFilter.length > 0 && (
            <TableFilter
              items={responseAllEEVV?.data?.resultados}
              handleChange={(e) => handleChange(e, "espacios")}
              icon={<AddIcon />}
              iconLabel="Agregar"
            />
          )}
        </Grid>
        <Grid item xs={12} md={6} style={{ paddingTop: "16px" }}>
          <TableFilter
            items={filters.espacios}
            label="Espacios seleccionados"
            handleChange={(e) => deleteEEVV(e)}
            icon={<DeleteIcon color="red" />}
            iconLabel="Eliminar"
          />
        </Grid>
        <Divider />
        <Grid item xs={12} sm={12} md={12}>
          <ButtonIcon
            type="submit"
            size={"big"}
            variant={"outlined"}
            noLink
            startIcon={<SearchIcon />}
            children={"Buscar"}
            handleClick={handleSubmit}
          />
        </Grid>
      </Grid>
      <br />
    </Container>
  );
};
