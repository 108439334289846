import { Container, Grid } from "@mui/material";
import React, { memo, lazy, Suspense, useState } from "react";
import { useGetLastInformesMutation } from "../../../../store/api/endpoints/informesApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { updateInformes } from "../../../../store/slices/informes";
import { ButtonIcon, HeaderVista, Input, Loading, SelectMultiple } from "../../../elementos";
import AutoComplete from "../../../elementos/AutoComplete";
import {
  useGetAllEspaciosMutation,
  useGetAllPoligonosMutation,
  useGetComunasMutation,
} from "../../../../store/api/endpoints/espaciosApiSlice";
import { comunas, getcomunas } from "../../../../store/slices/comunas";
import { getPoligonos, poligonos } from "../../../../store/slices/poligonos";
import { useGetAllNamesUsersMutation } from "../../../../store/api/endpoints/usuariosApiSlice";
import { clientes, getUsuarios } from "../../../../store/slices/clientes";
import SearchIcon from "@mui/icons-material/Search";
import { cleanEspaciosVerdes, espaciosVerdes, getEspaciosVerdes } from "../../../../store/slices/espacioverdes";
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect } from "react";
const DataTable = lazy(() => import("./TableUltimosInformes"));

function moviePropsAreEqual(prevMovie, nextMovie) {
  return (
    prevMovie?.filters?.page === nextMovie?.filters?.page &&
    prevMovie?.filters?.pageSize === nextMovie?.filters?.pageSize
  );
}

const MemoHead = memo(HeaderVista);
const TablaMemo = memo(DataTable, moviePropsAreEqual);

const UltimosInformes = () => {
  const dispatch = useDispatch();
  const [timer, settimer] = useState();
  let initFilters = {
    page: 0,
    pageSize: 10,
    espacio: {},
    fechainspeccion: "",
    comunas: [],
    poligonos: [],
    inspector: [],
  }
  const [filters, setfilters] = React.useState(initFilters);

  const listPoligonos = useSelector(getPoligonos);
  const listComunas = useSelector(getcomunas);
  const listUsers = useSelector(getUsuarios);
  const listEspacios = useSelector(getEspaciosVerdes);
  const [getUltimos, responseUltimos] = useGetLastInformesMutation();
  const [getAllComunas, responseComunas] = useGetComunasMutation();
  const [getAllPoligonos, responsePoligonos] = useGetAllPoligonosMutation();
  const [getAllUsers, responseAllusers] = useGetAllNamesUsersMutation();
  const [getAllEspacios, responseAllEEVV] = useGetAllEspaciosMutation();

  /* 
  const handleClick = () => {
    setfechaInspeccion("");
    getUltimos({ page: filters.page + 1, limit: filters.pageSize, fecha_ultima_modif: "" });
  }; */
  React.useEffect(() => {
    Promise.all([getAllPoligonos(), getAllComunas(), getAllUsers()]);
    setfilters(initFilters)
  }, []);

  const handleInputEspacios = React.useCallback(
    (e) => {
      clearTimeout(timer);
      if (e.length > 0) {
        const newTimer = setTimeout(async () => {
          getAllEspacios({ page: 0, rowsPerPage: 10, name: e });
        }, 600);
        settimer(newTimer);
      }
    },
    [timer]
  );

  const handleChange = (value, name) => {
    if (name) {
      setfilters((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = () => {
    getUltimos({
      page: filters.page,
      limit: filters.pageSize,
      fecha_ultima_modif: filters.fechainspeccion,
      espacio: filters?.espacio?.value,
      poligonos: filters.poligonos.join(","),
      comunas: filters.comunas.join(","),
      inspectores: filters.inspector.join(","),
    });
  };

  const handleCleanFilters = ()=>{
    setfilters(initFilters)
  }

  useEffect(() => {
    dispatch(cleanEspaciosVerdes());
  }, [])
  

  React.useEffect(() => {
    getUltimos({ page: filters.page + 1, limit: filters.pageSize });
  }, [filters.page, filters.pageSize]);

  //DISPATCHS
  React.useEffect(() => {
    if (responseUltimos.isSuccess) {
      dispatch(updateInformes({ data: responseUltimos?.data?.data, total: responseUltimos?.data?.metadata[0]?.total }));
    }
    if (responsePoligonos.isSuccess) {
      dispatch(
        poligonos(
          responsePoligonos?.data.map((poligono) => {
            return { label: poligono?.nombre, value: poligono?.codigo };
          })
        )
      );
    }
    if (responseComunas.isSuccess) {
      dispatch(
        comunas(
          responseComunas?.data?.comunas?.map((comuna) => {
            return { label: `Comuna ${comuna.codigo}`, value: comuna.codigo };
          })
        )
      );
    }
    if (responseAllusers.isSuccess) {
      dispatch(
        clientes(responseAllusers?.data.map((el) => ({ label: el.nombre + " " + el.apellido, value: el.codigo })))
      );
    }
    if (responseAllEEVV.isSuccess) {
      dispatch(
        espaciosVerdes(
          responseAllEEVV?.data?.resultados.map((el) => ({ label: el?.nombre_eevv, value: el?.id_espacio_verde }))
        )
      );
    }
  }, [
    responseUltimos.isSuccess,
    responsePoligonos.isSuccess,
    responseComunas.isSuccess,
    responseAllEEVV.isSuccess,
    responseAllusers.isSuccess,
  ]);

  if (
    responseComunas.isLoading ||
    responsePoligonos.isLoading ||
    responseUltimos.isLoading ||
    responseAllusers.isLoading
  )
    return <Loading />;

  return (
    <Container component="main" maxWidth="lg">
      <MemoHead titulo="Ultimos informes" />
      <Grid container sx={{ display: "flex", justifyContent: "center" }} spacing={2}>
        <Grid item xs={12} md={4}>
          <Input
            type="date"
            label="Fecha de relevamiento"
            value={filters.fechainspeccion ??""}
            restricciones={["fecha"]}
            onChange={(e) => handleChange(e, "fechainspeccion")}
          />
        </Grid>
        <br />
        <Grid item xs={12} md={4}>
          <AutoComplete
            label="Espacio verde"
            value={filters?.espacio?.label??""}
            options={listEspacios ?? []}
            onInputChange={(event, newInputValue, reason) => {
              if (reason === 'reset') {
                handleChange({},"espacio")
                return
              } 
            }}
            handleWrite={handleInputEspacios}
            handleSelect={(e) => handleChange(e, "espacio")}
            styles={{maxWidth:"100%",minWidth:150,marginTop:"16px"}}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectMultiple
            defaultValue=""
            disabled={false}
            type="text"
            elegidos={filters.comunas ??[]}
            onChange={(e) => handleChange(e, "comunas")}
            name="comunas"
            options={listComunas}
            label="Comunas"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectMultiple
            defaultValue=""
            disabled={false}
            type="text"
            elegidos={filters.poligonos ?? []}
            onChange={(e) => handleChange(e, "poligonos")}
            name="poligonos"
            options={listPoligonos}
            label="Poligonos"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectMultiple
            defaultValue=""
            disabled={false}
            type="text"
            elegidos={filters.inspector ?? []}
            onChange={(e) => handleChange(e, "inspector")}
            name="inspector"
            options={listUsers}
            label="Inspector"
          />
        </Grid>
      </Grid>
      <br/>
      <Grid container sx={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={5} md={3}>
          <ButtonIcon
            type="submit"
            size={"big"}
            variant={"outlined"}
            noLink
            startIcon={<SearchIcon />}
            children={"Buscar"}
            handleClick={handleSubmit}
          />
        </Grid>
        <Grid item xs={7} md={3}>
          <ButtonIcon
            type="submit"
            size={"big"}
            color="error"
            variant={"outlined"}
            noLink
            startIcon={<DeleteIcon />}
            children={"Eliminar filtros"}
            handleClick={handleCleanFilters}
          />
        </Grid>
      </Grid>
      <br />
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          justifyItems: "center",
        }}
        spacing={2}
      >
        <Grid item xs={12} md={12}>
          <br />
          <Suspense fallback={<Loading />}>
            <TablaMemo filters={filters} setfilters={setfilters} />
          </Suspense>
        </Grid>
      </Grid>
      <br />
    </Container>
  );
};

export default UltimosInformes;
