import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ButtonIcon } from "../../../elementos";
import { monthNames } from "../../../../constants/Meses";

const style = {
  position: "absolute",
  width: "85%",
  top: "50%",
  left: "50%",
  maxWidth: "400px",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  padding: "30px 10px",
};

function BasicModal({ semanasEditables, handleFunction, idEspacio, setmodalPeriodos }) {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
    setmodalPeriodos(false);
  };
  const handleCreate = (periodo) => {
    handleFunction(idEspacio, periodo);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ fontSize: "1rem", textAlign: "center" }}>
            Seleccione el Periodo que desea cargar
          </Typography>
          <Box>
            {semanasEditables.map((el, index) => (
              <div align="center" style={{ marginTop: "10px" }} key={index}>
                <ButtonIcon
                  key={index}
                  size="small"
                  variant="outlined"
                  styleButton={{ minWidth: "60px", marginLeft: "0px" }}
                  noLink
                  handleClick={() => handleCreate(el)}
                >
                  {monthNames[el.slice(-2) - 1] + " " + el.slice(0, 4)}
                </ButtonIcon>
              </div>
            ))}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default React.memo(BasicModal);
