import { tiendasApi } from "../api";

export const poligonoApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllPoligonosOfCuadrantes: builder.mutation({
      query: (cuadrante) => ({
        url: `/poligonos/${cuadrante}`,
        method: "GET",
      }),
    }),
    getOnePoligono: builder.mutation({
      query: (nombre) => ({
        url: `/poligonos/${nombre}`,
        method: "GET",
      }),
    }),
    createPoligono: builder.mutation({
      query: (body) => ({
        url: `/poligonos`,
        method: "POST",
        body: body,
      }),
    }),
    deletePoligono: builder.mutation({
      query: (id) => ({
        url: `/poligonos/${id}`,
        method: "DELETE",
      }),
    }),
    getAllPoligonos: builder.mutation({
      query: () => ({
        url: `/todos/`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetAllPoligonosOfCuadrantesMutation,
  useCreatePoligonoMutation,
  useDeletePoligonoMutation,
  useGetOnePoligonoMutation,
  useGetAllPoligonosMutation,
} = poligonoApiSlice;
