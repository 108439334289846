/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetAllIdWithOfFiltersMutation } from "../../../../store/api/endpoints/informesApiSlice";
import { Loading } from "../../../elementos";
import CreateCaratulaPDF from "./Caraturla/CreateCaratulaPDF";
import TablaDescripcionEspacioVerde from "./Tables/TablaDescripcionEspacioVerde";
import TablaPlantillaSeguimientoSemanal from "./Tables/TablaPlantillaSeguimientoSemanal";
import "./index.css";
const ScreenInformesPDF = () => {
  const [searchParams] = useSearchParams();
  const [idInformes, setidInformes] = useState([]);
  const [getIdInformes, responseIDInformes] = useGetAllIdWithOfFiltersMutation();
  const container = useRef(null);
  // eslint-disable-next-line no-unused-vars

  useEffect(() => {
    const filters = {
      poligonos: searchParams.get("poligonos"),
      comunas: searchParams.get("comunas"),
      dateTo: searchParams.get("dateTo"),
      dateFrom: searchParams.get("dateFrom"),
      empresas_prestadoras: searchParams.get("empresa_prestadora"),
      espacios: searchParams.get("espacios"),
    };
    let options = "";
    if (filters.dateFrom && filters.dateTo) {
      options = `?from=${filters.dateFrom}-01&to=${filters.dateTo}-31`;
    }
    if (filters.poligonos?.length > 0) {
      options = options + `&poligonos=${filters.poligonos}`;
    }
    if (filters.comunas?.length > 0) {
      options = options + `&comunas=${filters.comunas}`;
    }
    if (filters.empresas_prestadoras?.length > 0) {
      options = options + `&empresas_prestadoras=${filters.empresas_prestadoras}`;
    }
    if (filters.espacios?.length > 0) {
      options = options + `&espacios=${filters.espacios}`;
    }
    getIdInformes(options);
  }, []);

  useEffect(() => {
    if (responseIDInformes.isSuccess) {
      setidInformes(responseIDInformes?.data);
    }
  }, [responseIDInformes.isSuccess]);

  if (responseIDInformes.isLoading || responseIDInformes.isUninitialized) {
    return <Loading />;
  }

  return (
    <div id="pdf" ref={container}>
      <CreateCaratulaPDF />
      <br />
      <div className="tablas">
        {idInformes?.map((el, index) => {
          return (
            <div key={index} className="pageBreak">
              <TablaDescripcionEspacioVerde id={el._id} />
              <TablaPlantillaSeguimientoSemanal
                id={el._id}
                lastId={index === idInformes.length - 1}
                totalInspecciones={idInformes.length}
              />
            </div>
          );
        })}
      </div>
      <br />
      <br />
    </div>
  );
};

export default ScreenInformesPDF;
