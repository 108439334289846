import { Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { openFeedback } from "../../store/slices/feedback";

const FileInput = (props) => {
  const dispatch = useDispatch();
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === props.type) {
      props.onChange(file);
    } else {
      dispatch(
        openFeedback({
          severity: "error",
          message: "El formato no es valido.",
        })
      );
      props.onChange(null);
    }
  };
  return (
    <Button variant="contained" component="label" /* styleButton={{ fontSize: "10px" }} */>
      {props.label}
      <input type="file" accept={props.type} onChange={handleFileChange} style={{ display: "none" }} />
    </Button>
  );
};

export default FileInput;
