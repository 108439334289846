import { createSlice } from "@reduxjs/toolkit";

export const cuadrantesSlice = createSlice({
  name: "cuadrantes",
  initialState: {
    cuadrantes: {},
  },
  reducers: {
    cuadrantes: (state, action) => {
      state.cuadrantes = action.payload;
    },
  },
});

export const { cuadrantes } = cuadrantesSlice.actions;

export const getCuadrantes = (state) => state.cuadrantes.cuadrantes;
