/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { InfoBasicaEspacio } from "./InfoBasicaEspacio";
import { shallowEqual, useSelector } from "react-redux";
import { getEspaciosVerdes } from "../../../store/slices/espacioverdes";
import { useNavigate } from "react-router-dom";

export default function ListaEspaciosVerdes({ toggleLocation }) {
  const navigate = useNavigate();
  const [optionEspacioVerde, setOptionEspacioVerde] = useState("");
  const espaciosVerdes = useSelector(getEspaciosVerdes, shallowEqual);
  const handleNavigation = (id) => {
    navigate(`/espaciosverdes/detalle?id=${id}`);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        bgcolor: "background.paper",
        height: "100%",
        transition: "all 0.5s ease-out",
      }}
    >
      {espaciosVerdes?.map((element, index) => (
        <div
          key={index}
          style={{
            marginTop: "5px",
            width: "98%",
            maxWidth: "720px",
            justifyContent: "center",
            transition: "all 0.5s ease-out",
          }}
          onClick={() => handleNavigation(element.id_espacio_verde)}
        >
          <InfoBasicaEspacio
            toggleLocation={toggleLocation}
            index={index}
            setoption={setOptionEspacioVerde}
            name={element.id_espacio_verde}
            option={optionEspacioVerde}
            espacio={element}
          />
        </div>
      ))}
    </div>
  );
}
