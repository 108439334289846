import React from "react";
import { Input, Select } from "../../../../elementos";
import { Container, Grid } from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getOptions } from "../../../../../store/slices/options";
import ListImages from "../components/Images/ListImages";
import { getInformeFull, updateInformeFull } from "../../../../../store/slices/informes";
const Limpieza = ({ semana, editable, id }) => {
  const listOptions = useSelector(getOptions);
  const dispatch = useDispatch()
  const informeStore = useSelector(getInformeFull, shallowEqual);

  const handleChange = (name, value, type) => {
    let informeTemp = JSON.stringify(informeStore)
    informeTemp = JSON.parse(informeTemp)
    informeTemp[`${semana}`].limpieza[`${name}`] = value
    dispatch(updateInformeFull(informeTemp));
  };

  return (
    <Container maxWidth="lg" component={"main"}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Select
            label="Limpieza General"
            options={listOptions.limpieza}
            value={informeStore[`${semana}`]?.limpieza?.general ?? ""}
            onChange={(e) => handleChange("general", e, "select")}
            name="general"
            disabled={!editable}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            fullWidth
            type="text"
            label="Observaciones"
            variant="standard"
            multiline
            value={informeStore[`${semana}`]?.limpieza?.observaciones ?? ""}
            restricciones={[]}
            onChange={(e) => handleChange("observaciones", e, "input")}
            name="observaciones"
            inputProps={{ maxLength: 1000 }}
            disabled={!editable}
          />
        </Grid>
        <Grid item xs={12} md={12} flexWrap={"nowrap"}>
          <ListImages
            imagenes={informeStore[`${semana}`]?.limpieza?.imagenes}
            ruta={`${semana}.limpieza.imagenes`}
            handleChange={handleChange}
            editable={editable}
          />
        </Grid>
      </Grid>
      <br />
    </Container>
  );
};

export default Limpieza;
