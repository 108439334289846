import React from "react";
import TableCaratule from "../Tables/TableCaratule";
import defaultImage from "../../../../../assets/images/caratulaInspeccion.jpg";

const CreateCaratulaPDF = () => {
  return (
    <div className="print-first-page">
      <img
        src={defaultImage}
        alt="caratule"
        style={{
          width: "100%",
          height: "90%",
        }}
      />
      <TableCaratule />
    </div>
  );
};

export default CreateCaratulaPDF;
