import React from "react";
import Select from "../../../../elementos/Select";
import { Container, Grid } from "@mui/material";

const Cesped = ({ optionCesped, valueCesped }) => {
  return (
    <Container component={"main"} maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Select
            value={valueCesped?.av_cobertura_cesped}
            label="Cobertura de cesped"
            options={optionCesped?.porcentaje}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            value={valueCesped?.av_estado_cesped}
            label="Estado de cesped"
            options={optionCesped?.porcentaje}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            value={valueCesped?.av_perfilado}
            label="Perfilado"
            options={optionCesped?.atencion2}
            disabled={true}
          />
        </Grid>
      </Grid>
      <br />
    </Container>
  );
};

export default Cesped;
