import { createSlice } from "@reduxjs/toolkit";

export const optionsSlice = createSlice({
  name: "Options",
  initialState: {
    options: {
      atencion: [],
      atencion2: [],
      limpieza: [],
      porcentaje: [],
      solado_postas: [],
      solado_patios: [],
      tipo_rejas: [],
      tipos_bancos_mob: [],
      solado_caniles: [],
      tipo_area_deportiva: [],
      tipos_carteles_mob: [],
      riego: [],
    },
  },
  reducers: {
    options: (state, action) => {
      if (action.payload !== undefined) {
        state.options.atencion = action.payload.atencion;
        state.options.atencion2 = action.payload.atencion2;
        state.options.limpieza = action.payload.limpieza;
        state.options.porcentaje = action.payload.porcentaje;
        state.options.solado_postas = action.payload.solado_postas;
        state.options.solado_patios = action.payload.solado_patios;
        state.options.tipo_rejas = action.payload.tipo_rejas;
        state.options.tipos_carteles_mob = action.payload.tipos_carteles_mob;
        state.options.solado_caniles = action.payload.solado_caniles;
        state.options.tipos_bancos_mob = action.payload.tipos_bancos_mob;
        state.options.tipo_area_deportiva = action.payload.tipo_area_deportiva;
        state.options.riego = action.payload.riego;
      }
    },
  },
});

export const { options } = optionsSlice.actions;
export const getOptions = (state) => state.options.options;
