import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ButtonIcon, Loading, Pagination } from "../../elementos";
import { monthNames } from "../../../constants/Meses";
import EditIcon from "@mui/icons-material/Edit";

function createData(fecha_desde, fecha_hasta, semana, ultima_modificacion, ultimo_usuario, _id, anio, mes) {
  return { fecha_desde, fecha_hasta, semana, ultima_modificacion, ultimo_usuario, _id, anio, mes };
}

export default function TableListaInformes({ informes, pagination, setPagination }) {
  const navigate = useNavigate();
  const dataRows = [];
  if (informes?.length > 0) {
    informes?.forEach((item) => {
      dataRows.push(
        createData(
          item.fecha_desde,
          item.fecha_hasta,
          item.semana,
          item.ultima_modificacion,
          item.ultimo_usuario,
          item.id,
          item.anio,
          item.mes
        )
      );
    });
  }
  const changePagination = (type, value) => {
    setPagination((prev) => ({
      ...prev,
      [type]: value,
    }));
  };
  const handleNavigate = (id_programacion) => {
    navigate(`/configuracion/inspecciones/editar?id_programacion=${id_programacion}`);
  };
  if (!informes) {
    <Loading />;
  }

  return (
    <Container component={"main"}>
      <Grid container sx={{ overflow: "auto" }}>
        <TableContainer sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
          <Table sx={{ minWidth: 350 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Periodo</TableCell>
                <TableCell align="center">Semana</TableCell>
                <TableCell align="center">Desde</TableCell>
                <TableCell align="center" width="100">
                  Hasta
                </TableCell>
                <TableCell align="left">Ult. Usuario</TableCell>
                <TableCell align="center">Ult. Modif</TableCell>
                <TableCell align="left">Accion</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {informes.length > 0 ? (
                dataRows.map((row) => (
                  <TableRow key={row._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell align="left">
                      {monthNames[row.mes - 1]} {row.anio}
                    </TableCell>
                    <TableCell align="center">{row.semana}</TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {row.fecha_desde}
                    </TableCell>
                    <TableCell align="center">{row.fecha_hasta}</TableCell>
                    <TableCell align="left">{row.ultimo_usuario ? row.ultimo_usuario : "-"}</TableCell>
                    <TableCell align="center">{row.ultima_modificacion ? row.ultima_modificacion : "-"}</TableCell>
                    <TableCell align="left" style={{ cursor: "pointer" }}>
                      <ButtonIcon
                        noLink
                        variant="outlined"
                        handleClick={() => handleNavigate(row._id)}
                        startIcon={<EditIcon sx={{ color: "#1976d2" }} />}
                        styleButton={{ color: "#1976d2" }}
                      >
                        Editar
                      </ButtonIcon>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow sx={{ textAlign: "center", "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell align="center" colSpan={8}>
                    No hay registros
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid container>
        <Pagination
          label="Informes"
          setrowsPerPages={(e) => changePagination("limit", e)}
          setpage={(e) => changePagination("page", e)}
          page={pagination.page}
          rowsPerPage={pagination.limit}
          total={pagination.totalPages}
          nextPage={true}
        />
      </Grid>
    </Container>
  );
}
