import { Link } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import "../../assets/css/DisplayInfoUl.css";
import { useRef, useState } from "react";
import { useEffect } from "react";

export default function DisplayInfoUl({ titulo, data, editar }) {
  // eslint-disable-next-line no-unused-vars
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    // definimos una función para actualizar el ancho de la ventana
    const handleResize = () => {
      setWidth(elementRef.current.offsetWidth);
    };

    // añadimos un listener para re-establecer el estado del ancho cuando cambia el tamaño de la pantalla
    window.addEventListener("resize", handleResize);

    // llamamos a la función una vez para establecer el ancho inical
    setWidth(elementRef.current.offsetWidth);

    // limpiamos el listener en cada desmontaje
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="DisplayInfoUl" ref={elementRef}>
        <div className="row">
          <div className="titulo">{titulo}</div>
          {editar && (
            <div className="icono">
              <Link to={editar.path}>
                <IconButton size="small">
                  <EditIcon />
                </IconButton>
              </Link>
            </div>
          )}
        </div>

        <ul className="lista">
          {data.map(({ index, value }, key) => {
            return (
              <li key={key} style={{ width: "80%" }}>
                {index}: <span>{value}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}
