export default function createObjectForEdit(relevamientoGeneral) {
  let patios = relevamientoGeneral?.patioDeJuegos.map((el) => ({
    cant_equipos: el?.equipos_tot,
    equipos_defectuosos: el?.equipos_defectuosos,
    equipos_falta_pintura: el?.equipos_falta_pintura,
    limpieza_equipamiento: el?.limpieza_equipamiento,
    limpieza_solado: el?.limpieza_solado,
    estado_solado: el?.estado_solado,
    pintura_rejas: el?.pintura_rejas,
    estado_rejas: el?.estado_rejas,
  }));
  let caniles = relevamientoGeneral?.caniles.map((el) => ({
    cant_equipos: el?.equipos_tot,
    equipos_defectuosos: el?.equipos_defectuosos,
    equipos_falta_pintura: el?.equipos_falta_pintura,
    limpieza_equipamiento: el?.limpieza_equipamiento,
    limpieza_solado: el?.limpieza_solado,
    estado_solado: el?.estado_solado,
    pintura_rejas: el?.pintura_rejas,
    estado_rejas: el?.estado_rejas,
  }));
  let deportiva = relevamientoGeneral?.areaDeportiva.map((el) => ({
    cant_equipos_tot: el?.cant_equipos_tot,
    equipos_defectuosos: el?.equipos_defectuosos,
    equipos_falta_pintura: el?.equipos_falta_pintura,
    limpieza: el?.limpieza,
    estado_solado: el?.estado_solado,
    tejido_perimetral: el?.tejido_perimetral,
  }));
  let postas = relevamientoGeneral?.postaAerobica.map((el) => ({
    cant_equipos: el?.cant_equipos,
    equipos_defectuosos: el?.equipos_defectuosos,
    equipos_falta_pintura: el?.equipos_falta_pintura,
    limpieza_solado: el?.limpieza_solado,
    limpieza_postas: el?.limpieza_postas,
    estado_solado: el?.estado_solado,
  }));
  let mobiliarios = relevamientoGeneral?.mobiliario?.map((el) => ({
    limpieza_bajo: el?.limpieza_bajo,
    limpieza_sobre: el?.limpieza_sobre,
    bancos_tot: el?.bancos_tot,
    bancos_defectuosos: el?.bancos_defectuosos,
    bancos_grafiteados: el?.bancos_grafiteados,
    mesas_tot: el?.mesas_tot,
    mesas_defectuosas: el?.mesas_defectuosas,
    mesas_grafiteadas: el?.mesas_grafiteadas,
    bebederos_tot: el?.bebederos_tot,
    bebederos_defectuosos: el?.bebederos_defectuosos,
    bebederos_grafiteados: el?.bebederos_grafiteados,
    bicicleteros_tot: el?.bicicleteros_tot,
    bicicleteros_defectuosos: el?.bicicleteros_defectuosos,
    bicicleteros_grafiteados: el?.bicicleteros_grafiteados,
    carteles_tot: el?.carteles_tot,
    carteles_defectuosos: el?.carteles_defectuosos,
    ploteo_defectuosos: el?.ploteo_defectuosos,
    carteles_grafiteados: el?.carteles_grafiteados,
  }));

  let rel_relevamiento = {
    limpieza_general: {
      limpieza: {
        av_limpieza_gral: relevamientoGeneral?.relevamientos?.av_limpieza_gral,
        av_limpieza_dia: relevamientoGeneral?.relevamientos?.av_limpieza_dia,
        av_limpieza_pto_verde: relevamientoGeneral?.relevamientos?.av_limpieza_pto_verde,
        av_limpieza_garitas: relevamientoGeneral?.relevamientos?.av_limpieza_garitas,
      },
      cestos: {
        cestos_tot: relevamientoGeneral?.relevamientos?.cestos_tot,
        cestos_rotos: relevamientoGeneral?.relevamientos?.cestos_rotos,
        av_limpieza_cestos: relevamientoGeneral?.relevamientos?.av_limpieza_cestos,
      },
    },
    area_seca: {
      areaDeportiva: deportiva,
      caminos: {
        as_vegetacion_espontanea: relevamientoGeneral?.relevamientos?.as_vegetacion_espontanea,
        as_estado_caminos_int: relevamientoGeneral?.relevamientos?.as_estado_caminos_int,
        as_nivel_cobertura_solado_suelto: relevamientoGeneral?.relevamientos?.as_nivel_cobertura_solado_suelto,
        as_limpieza_camino_int: relevamientoGeneral?.relevamientos?.as_limpieza_camino_int,
        estado_de_bolardos: relevamientoGeneral?.relevamientos?.estado_de_bolardos,
        pintura_de_muretes: relevamientoGeneral?.relevamientos?.pintura_de_muretes,
        limpieza_rejas: relevamientoGeneral?.relevamientos?.limpieza_rejas,
        estado_de_rejas: relevamientoGeneral?.relevamientos?.estado_de_rejas,
        pintura_de_rejas: relevamientoGeneral?.relevamientos?.pintura_de_rejas,
        as_limpieza_veredas: relevamientoGeneral?.relevamientos?.as_limpieza_veredas,
      },
      patio_juegos: patios,
      caniles: caniles,
      mobiliarios: mobiliarios,
      posta_aero: postas,
    },
    area_verde: {
      canteros: {
        av_limpieza_canteros: relevamientoGeneral?.av_limpieza_canteros
          ? relevamientoGeneral?.av_limpieza_canteros
          : "",
        av_cobertura_canteros: relevamientoGeneral?.av_cobertura_canteros
          ? relevamientoGeneral?.av_cobertura_canteros
          : "",
        av_estado_canteros: relevamientoGeneral?.av_estado_canteros ? relevamientoGeneral?.av_estado_canteros : "",
        av_carpido_desmalezado: relevamientoGeneral?.av_carpido_desmalezado
          ? relevamientoGeneral?.av_carpido_desmalezado
          : "",
      },
      cesped: {
        av_cobertura_cesped: relevamientoGeneral?.av_cobertura_cesped,
        av_estado_cesped: relevamientoGeneral?.av_estado_cesped,
        av_perfilado: relevamientoGeneral?.av_perfilado,
      },
    },
  };
  return rel_relevamiento;
}
