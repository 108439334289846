import { tiendasApi } from "../api";

export const clientesApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    getClientes: builder.mutation({
      query: (headersTemp) => {
        const { page, cantRows } = headersTemp;
        return {
          url: `/usuarios?page=${page}&cantRows=${cantRows}`,
          method: "GET",
        };
      },
    }),
    getCliente: builder.mutation({
      query: (id) => ({
        url: "/usuarios/getone/" + id,
        method: "GET",
      }),
    }),
    createCliente: builder.mutation({
      query: (body) => ({
        url: "/usuarios",
        method: "POST",
        body: body,
      }),
    }),
    updateContactoCliente: builder.mutation({
      query(data) {
        const { id, cliente } = data;
        return {
          url: `/usuarios/${id}`,
          method: "PUT",
          body: cliente,
        };
      },
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/usuarios/${id}`,
        method: "DELETE",
      }),
    }),
    getClienteFilter: builder.mutation({
      query: (name) => ({
        url: `/usuarios/getbyname?name=${name}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetClientesMutation,
  useGetClienteMutation,
  useCreateClienteMutation,
  useUpdateContactoClienteMutation,
  useDeleteUserMutation,
  useGetClienteFilterMutation,
} = clientesApiSlice;
