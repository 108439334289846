import React from "react";
import Input from "../../../../elementos/Input";
import Select from "../../../../elementos/Select";
import { Container, Grid } from "@mui/material";

export const PostaAerobica = ({ isDisabled = true, setareas, areas, index, dataAllAtributes, options }) => {
  const handleChange = (value, key) => {
    setareas((prev) => ({
      ...prev,
      postaAerobica: {
        ...prev.postaAerobica,
        [index]: {
          ...prev.postaAerobica[index],
          [key]: value,
        },
      },
    }));
  };

  return (
    <Container component={"main"} maxWidth="lg">
      <Grid contaienr spacing={2}>
        <Grid item xs={12} md={12}>
          <h3>Posta aerobica {++index}</h3>
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="tel"
            label="Cantidad de equipos"
            variant="standard"
            value={areas?.equipos_tot}
            disabled={isDisabled}
            onChange={handleChange}
            restricciones={["soloNumeros"]}
            name="equipos_tot"
            min={0}
            max={200}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="limpieza de solado"
            value={areas?.tipo_solados}
            defaultValue=""
            options={dataAllAtributes?.solado_postas?.map((element) => ({
              label: element.descripcion,
              value: element.codigo,
            }))}
            type="text"
            onChange={handleChange}
            name={"tipo_solados"}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="Limpieza de postas"
            value={areas?.tipo_solados}
            defaultValue=""
            options={dataAllAtributes?.solado_postas?.map((element) => ({
              label: element.descripcion,
              value: element.codigo,
            }))}
            type="text"
            onChange={handleChange}
            name={"tipo_solados"}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="number"
            label="Equipos defectuosos"
            variant="standard"
            value={areas?.equipos_defectuosos}
            disabled={isDisabled}
            onChange={handleChange}
            restricciones={["soloNumeros"]}
            name="equipos_defectuosos"
            min={0}
            max={200}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label="Estado de solados"
            value={areas?.tipo_solados}
            defaultValue=""
            options={dataAllAtributes?.solado_postas?.map((element) => ({
              label: element.descripcion,
              value: element.codigo,
            }))}
            type="text"
            onChange={handleChange}
            name={"tipo_solados"}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="number"
            label="Equipos con falta de pintura"
            variant="standard"
            value={areas?.equipos_falta_pintura}
            disabled={isDisabled}
            onChange={handleChange}
            restricciones={["soloNumeros"]}
            name="equipos_falta_pintura"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            type="text"
            label="Observaciones"
            variant="standard"
            value={areas?.observaciones}
            disabled={isDisabled}
            onChange={handleChange}
            restricciones={["alfanumericos"]}
            name="observaciones"
          />
        </Grid>
      </Grid>
      <br />
    </Container>
  );
};
