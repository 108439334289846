import React from "react";
import Volver from "../../../elementos/Volver";
import { Container, Grid } from "@mui/material";
import { HeaderVista, ConfirmDelete, ButtonIcon } from "../../../elementos";
import { PERMISOS } from "../../../../constants/permisos";
import EditIcon from "@mui/icons-material/Edit";

const TitleEspaciosRelevamientos = ({
  relevamiento,
  permisos,
  handleClick,
  deleteRelevamiento,
  responseDelete,
  id,
  id_espacio_verde,
}) => {
  return (
    <Container component={"main"} maxWidth="lg">
      <HeaderVista
        start={<Volver label="Volver" to={`/relevamiento/listado?id=${id}`} />}
        titulo={`Relevamiento n° ${relevamiento?.codigo}`}
        end={
          <>
            <ButtonIcon
              variant="outlined"
              size="small"
              color="neutral"
              startIcon={<EditIcon color="primary" />}
              noLink
              handleClick={handleClick}
            >
              Editar
            </ButtonIcon>
            {permisos === PERMISOS.ADMINISTRADOR && (
              <ConfirmDelete
                id={id}
                slice={deleteRelevamiento}
                response={responseDelete}
                returnUrl={`/relevamiento/listado?id=${id_espacio_verde}`}
                variant="outlined"
                size="small"
              />
            )}
          </>
        }
      />
      <Grid container>
        <Grid item xs={12} md={12}>
          <h3> {relevamiento?.nombre_eevv} </h3>
        </Grid>
        <Grid item xs={12} md={12}>
          <p>Fecha de relevamiento: {relevamiento?.fecha_relevado} </p>
        </Grid>
        <Grid item xs={12} md={12}>
          <p>Hora: {relevamiento?.hora} </p>
        </Grid>
        <Grid item xs={12} md={12}>
          <p>Usuario: {relevamiento?.usuario} </p>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TitleEspaciosRelevamientos;
