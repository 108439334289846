import { createSlice } from "@reduxjs/toolkit";

export const espacioVerdeSlice = createSlice({
  name: "EspacioVerde",
  initialState: {
    espacioVerde: {},
    espaciosVerdes: [],
    areasOfEspacio: {},
    editAreas: {},
  },
  reducers: {
    espacioVerde: (state, action) => {
      state.espacioVerde = action.payload;
    },
    espaciosVerdes: (state, action) => {
      state.espaciosVerdes = action.payload;
    },
    areasOfEspacio: (state, action) => {
      state.areasOfEspacio = action.payload;
    },
    cleanEspacioVerde: (state, action) => {
      state.espacioVerde = {};
    },
    cleanEspaciosVerdes: (state, action) => {
      state.espaciosVerdes = [];
    },
    editAreas: (state, action) => {
      state.editAreas = action.payload;
    },
  },
});

export const { espacioVerde, cleanEspacioVerde, espaciosVerdes, areasOfEspacio, editAreas,cleanEspaciosVerdes } = espacioVerdeSlice.actions;
export const getEditAreas = (state) => state.espacioVerde.editAreas;
export const getareasOfEspacio = (state) => state.espacioVerde.areasOfEspacio;
export const getEspaciosVerdes = (state) => state.espacioVerde.espaciosVerdes;
export const getEspacioVerde = (state) => state.espacioVerde.espacioVerde;
