export const listValuesEspaciosVerdes = [
  {
    id: 1,
    name: "Tipo",
    type: "desplegable",
    value: "tipo",
  },
  {
    id: 2,
    name: "Codigo",
    type: "input",
    value: "codigo",
  },
  {
    id: 3,
    name: "Nombre",
    type: "input",
    value: "nombre_eevv",
  },
  {
    id: 4,
    name: "Direccion",
    type: "input",
    value: "direccion",
  },
  {
    id: 6,
    name: "Barrio",
    type: "desplegable",
    value: "barrios",
    single: "barrio",
  },
  {
    id: 7,
    name: "Area",
    type: "desplegable",
    value: "areas",
    single: "area",
  },
  {
    id: 8,
    name: "Superficie seca",
    type: "input",
    value: "superficie_seca",
  },
  {
    id: 9,
    name: "Superficie verde",
    type: "input",
    value: "superficie_verde",
  },
  {
    id: 10,
    name: "Punto verde",
    type: "desplegable",
    value: "punto_verde",
  },
  {
    id: 11,
    name: "Centro de jubilados",
    type: "desplegable",
    value: "cent_jubilados",
  },
  {
    id: 12,
    name: "Calesita",
    type: "desplegable",
    value: "calesita",
  },
  {
    id: 13,
    name: "Mastil",
    type: "desplegable",
    value: "mastil",
  },
  {
    id: 14,
    name: "Monumento",
    type: "desplegable",
    value: "monumento",
  },
  {
    id: 15,
    name: "Fuente",
    type: "desplegable",
    value: "fuente",
  },
  {
    id: 16,
    name: "Obra de arte",
    type: "desplegable",
    value: "obra_arte",
  },
  {
    id: 17,
    name: "Guarda parque",
    type: "desplegable",
    value: "guarda_parque",
  },
  {
    id: 18,
    name: "Garita",
    type: "desplegable",
    value: "garita",
  },
  {
    id: 19,
    name: "Otro tipo de cerramiento",
    type: "desplegable",
    value: "otro_tipo_cerramiento",
  },
  {
    id: 20,
    name: "Clase",
    type: "desplegable",
    value: "clase",
  },
  {
    id: 21,
    name: "Estado",
    type: "desplegable",
    value: "estado",
  },
  {
    id: 22,
    name: "Es emblematico",
    type: "desplegable",
    value: "es_emblematico",
  },
  {
    id: 23,
    name: "Tiene bolardos",
    type: "desplegable",
    value: "bolardo",
  },
  {
    id: 20,
    name: "Tiene muretes",
    type: "desplegable",
    value: "muretes",
  },
  { id: 21, name: "Cantidad cestos", type: "input", value: "cant_cestos" },
];
