import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Sidebar from "../Navbar/Sidebar";
import { getStatusSidebar } from "../../../store/slices/sidebar";

const RequireAuth = () => {
  const token = sessionStorage.getItem("token");
  if (!token) {
    sessionStorage.clear();
  }
  const location = useLocation();
  const statusSidebar = useSelector(getStatusSidebar);
  return token ? (
    <>
      <Sidebar />
      <div id="routesContainer" className={!statusSidebar ? "extendido" : ""}>
        <div id="contenedor">
          <Outlet style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "15px" }} />
        </div>
      </div>
    </>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
