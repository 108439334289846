import { tiendasApi } from "../api";

export const perfilesApiSlice = tiendasApi.injectEndpoints({
    endpoints:builder=>({
        getAllPerfiles: builder.mutation({
            query:()=>({
                url:"/perfiles",
                method:"GET"
            })
        })
    })
})
 

export const {useGetAllPerfilesMutation} = perfilesApiSlice