import { Dialog, DialogActions, DialogContent, IconButton, Slide } from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { ButtonIcon } from "../../../../../elementos";

const MaximizeImage = ({ imagen, isOpenImage, setIsOpenImage, handleDelete, ChangeMaximizeImage }) => {
  const fullWidth = true;
  const handleClose = () => {
    setIsOpenImage(false);
  };
  const styles = {
    customMaxWidth: {
      maxWidth: "none", // arbitrary value
      minHeight: "80vh",
      maxHeight: "80vh",
    },
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  return (
    <div>
      <Dialog
        classes={{ paper: styles.customMaxWidth }}
        fullWidth={fullWidth}
        open={isOpenImage}
        TransitionComponent={Transition}
        onClose={handleClose}
      >
        <DialogContent>
          <div className="display-image" style={{ display: "flex" }}>
            <IconButton name="details" onClick={() => ChangeMaximizeImage("back")}>
              <NavigateBeforeIcon />
            </IconButton>
            <img src={imagen.src} alt={`Imagen informe${imagen.src}`} className="image-informe" />
            <IconButton name="details" onClick={() => ChangeMaximizeImage("next")}>
              <NavigateNextIcon />
            </IconButton>
          </div>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "space-evenly" }}>
          <ButtonIcon noLink variant={"outlined"} handleClick={handleClose} startIcon={<CloseIcon />}>
            Cancelar
          </ButtonIcon>
          <ButtonIcon
            noLink
            handleClick={() => handleDelete(imagen.src)}
            styleButton={{ backgroundColor: "red", color: "white" }}
            startIcon={<DeleteIcon />}
          >
            Eliminar
          </ButtonIcon>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MaximizeImage;
