import React, { useEffect, useState } from "react";
import { AreaDeportiva } from "../Areas/Areas secas/AreaDeportiva";
import { Caniles } from "../Areas/Areas secas/Caniles";
import { Mobiliario } from "../Areas/Areas secas/Mobiliario";
import { PostaAerobica } from "../Areas/Areas secas/PostaAerobica";
import BotonCrearAreas from "./BotonCrearAreas";
import { PatioJuegos } from "../Areas/Areas secas/PatioJuegos";
import { Container, Grid } from "@mui/material";

export const AgregarAreas = ({ valoresDeLasAreas, setvaloresDeLasAreas, responseOptions, dataAllAtributes }) => {
  const [areasOFEEVV, setareasOFEEVV] = useState({
    patios: [],
    postas: [],
    mobiliarios: [],
  });

  useEffect(() => {
    if (valoresDeLasAreas?.patiodejuegos) {
      setareasOFEEVV((prev) => ({
        ...prev,
        patios: Object.values(valoresDeLasAreas?.patiodejuegos),
      }));
    }
    if (valoresDeLasAreas?.postaAerobica) {
      setareasOFEEVV((prev) => ({
        ...prev,
        postas: Object.values(valoresDeLasAreas?.postaAerobica),
      }));
    }
    if (valoresDeLasAreas?.mobiliarios) {
      setareasOFEEVV((prev) => ({
        ...prev,
        mobiliarios: Object.values(valoresDeLasAreas?.mobiliarios),
      }));
    }
    if (valoresDeLasAreas?.areaDeportiva) {
      setareasOFEEVV((prev) => ({
        ...prev,
        areaDeportiva: Object.values(valoresDeLasAreas?.areaDeportiva),
      }));
    }
  }, [valoresDeLasAreas]);

  const removeComponent = (type) => {
    switch (type) {
      case "patio":
        const newPatios = [...areasOFEEVV.patios];
        const deleteLastPatioDeJuegos = newPatios && newPatios.length > 0 ? Object.values(newPatios).slice(0, -1) : [];
        setvaloresDeLasAreas((prev) => ({
          ...prev,
          patiodejuegos: deleteLastPatioDeJuegos,
        }));
        setareasOFEEVV((prev) => ({
          ...prev,
          patios: deleteLastPatioDeJuegos,
        }));
        break;
      case "posta":
        const newPostas = [...areasOFEEVV.postas];
        const deleteLastPosta = newPostas && newPostas.length > 0 ? Object.values(newPostas).slice(0, -1) : [];
        setvaloresDeLasAreas((prev) => ({
          ...prev,
          postaAerobica: deleteLastPosta,
        }));
        setareasOFEEVV((prev) => ({
          ...prev,
          postas: deleteLastPosta,
        }));
        break;
      case "mobiliario":
        const newMobiliario = [...areasOFEEVV.mobiliarios];
        const deleteLastMobiliario =
          newMobiliario && newMobiliario.length > 0 ? Object.values(newMobiliario).slice(0, -1) : [];
        setvaloresDeLasAreas((prev) => ({
          ...prev,
          mobiliarios: deleteLastMobiliario,
        }));
        setareasOFEEVV((prev) => ({
          ...prev,
          mobiliarios: deleteLastMobiliario,
        }));
        break;
      case "deportiva":
        const newDeportiva = [...areasOFEEVV.areaDeportiva];
        const deleteLastDeportiva =
          newDeportiva && newDeportiva.length > 0 ? Object.values(newDeportiva).slice(0, -1) : [];
        setvaloresDeLasAreas((prev) => ({
          ...prev,
          areaDeportiva: deleteLastDeportiva,
        }));
        setareasOFEEVV((prev) => ({
          ...prev,
          areaDeportiva: deleteLastDeportiva,
        }));
        break;
      case "caniles":
        const newCaniles = [...areasOFEEVV.areaDeportiva];
        const deleteLastCanil = newCaniles && newCaniles.length > 0 ? Object.values(newCaniles).slice(0, -1) : [];
        setvaloresDeLasAreas((prev) => ({
          ...prev,
          caniles: deleteLastCanil,
        }));
        setareasOFEEVV((prev) => ({
          ...prev,
          caniles: deleteLastCanil,
        }));
        break;
      default:
        break;
    }
  };

  const addComponent = (type) => {
    switch (type) {
      case "patio":
        setareasOFEEVV((prev) => ({
          ...prev,
          patios: [...prev.patios, <PatioJuegos />],
        }));
        break;
      case "posta":
        setareasOFEEVV((prev) => ({
          ...prev,
          postas: [...prev.postas, <PostaAerobica />],
        }));
        break;
      case "mobiliario":
        setareasOFEEVV((prev) => ({
          ...prev,
          mobiliarios: [...prev.mobiliarios, <Mobiliario />],
        }));
        break;
      case "deportiva":
        setareasOFEEVV((prev) => ({
          ...prev,
          areaDeportiva: [...prev.areaDeportiva, <AreaDeportiva />],
        }));
        break;
      case "caniles":
        setareasOFEEVV((prev) => ({
          ...prev,
          caniles: [...prev.caniles, <Caniles />],
        }));
        break;
      default:
        break;
    }
  };

  return (
    <Container component={"main"} maxWidth="lg">
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <BotonCrearAreas
            listLength={areasOFEEVV.patios?.length}
            text="Patio de juegos"
            handleAdd={() => addComponent("patio")}
            type="patio"
            handleRemove={() => removeComponent("patio")}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <BotonCrearAreas
            listLength={areasOFEEVV.postas?.length}
            text="Posta aerobica"
            handleAdd={() => addComponent("posta")}
            type="posta"
            handleRemove={() => removeComponent("posta")}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <BotonCrearAreas
            listLength={areasOFEEVV.mobiliarios?.length}
            text="Mobiliarios"
            handleAdd={() => addComponent("mobiliario")}
            type="mobiliario"
            handleRemove={() => removeComponent("mobiliario")}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <BotonCrearAreas
            listLength={areasOFEEVV.areaDeportiva?.length}
            text="Area deportiva"
            handleAdd={() => addComponent("deportiva")}
            type="deportiva"
            handleRemove={() => removeComponent("deportiva")}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          {areasOFEEVV?.patios?.map((value, index) => (
            <React.Fragment key={index}>
              <PatioJuegos
                index={index}
                isDisabled={false}
                options={responseOptions}
                dataAllAtributes={dataAllAtributes}
                setareas={setvaloresDeLasAreas}
                areas={value}
              />
            </React.Fragment>
          ))}
        </Grid>
        <Grid item xs={12} md={12}>
          {areasOFEEVV?.postas?.map((value, index) => (
            <React.Fragment key={index}>
              <PostaAerobica
                index={index}
                isDisabled={false}
                options={responseOptions}
                dataAllAtributes={dataAllAtributes}
                setareas={setvaloresDeLasAreas}
                areas={value}
              />
            </React.Fragment>
          ))}
        </Grid>
        <Grid item xs={12} md={12}>
          {areasOFEEVV?.mobiliarios?.map((value, index) => (
            <React.Fragment key={index}>
              <Mobiliario
                index={index}
                isDisabled={false}
                dataAllAtributes={dataAllAtributes}
                setareas={setvaloresDeLasAreas}
                areas={value}
                options={dataAllAtributes}
              />
            </React.Fragment>
          ))}
        </Grid>
        <Grid item xs={12} md={12}>
          {areasOFEEVV?.areaDeportiva?.map((value, index) => (
            <React.Fragment key={index}>
              <AreaDeportiva
                index={index}
                isDisabled={false}
                dataAllAtributes={dataAllAtributes}
                setareas={setvaloresDeLasAreas}
                areas={value}
                options={dataAllAtributes}
              />
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};
