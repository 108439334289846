import React from "react";
import ConfirmDelete from "../../../elementos/ConfirmDelete";
import { useDeletePoligonoMutation } from "../../../../store/api/endpoints/poligonoApiSlice";
import { Container, Grid } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { getPoligonos } from "../../../../store/slices/poligonos";
import { shallowEqual, useSelector } from "react-redux";

const ListaDePoligonos = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const poligonos = useSelector(getPoligonos, shallowEqual);
  const [deletePoligono, responseDelete] = useDeletePoligonoMutation();

  return (
    <Container component="main" maxWidth="lg">
      <Grid container spacing={2}>
        <table className="formato1" cellSpacing="0" cellPadding="0">
          <thead>
            <tr style={{ height: "10%" }}>
              <th align="left">Nombre </th>
              <th align="center">Eliminar</th>
              {/* } */}
            </tr>
          </thead>
          <tbody>
            {poligonos?.length > 0 ? (
              poligonos?.map((poligono, index) => {
                return (
                  <tr key={index} style={{ height: "10%" }}>
                    <td align="left">{poligono?.nombre}</td>
                    <td align="center">
                      <ConfirmDelete
                        id={poligono.codigo}
                        response={responseDelete}
                        slice={deletePoligono}
                        returnUrl={`/configuracion/cuadrante?id=${id}`}
                        variant="outlined"
                        size="small"
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="4">No se encontraron registros</td>
              </tr>
            )}
          </tbody>
        </table>
      </Grid>
    </Container>
  );
};

export default ListaDePoligonos;
