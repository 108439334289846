import { createSlice } from "@reduxjs/toolkit";

export const clienteSlice = createSlice({
  name: "users",
  initialState: {
    clientes: [],
  },
  reducers: {
    clientes: (state, action) => {
      state.clientes = action.payload;
    },
  },
});

export const { clientes } = clienteSlice.actions;

export const getUsuarios = (state) => state.clientes.clientes;
