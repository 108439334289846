import React from "react";

function RadioButton(props) {
  const { label, value, checked, onChange, name, id, disabled } = props;

  return (
    <div style={{ display: "flex", width: "35%" }}>
      <label class={`radio-${name}`} id={id}>
        <input
          style={{ width: "10px" }}
          type="radio"
          name={name}
          id={id}
          value={value}
          disabled={disabled}
          checked={checked}
          onChange={() => onChange(value)}
        ></input>
        {label}
      </label>
    </div>
  );
}

export default RadioButton;
