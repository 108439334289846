import { tiendasApi } from "../api";

export const espaciosApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    getEspacioVerde: builder.mutation({
      query: (id) => ({
        url: "/espaciosverdes/" + id,
        method: "GET",
      }),
    }),
    getAllEspacios: builder.mutation({
      query: (params) => {
        const { page, rowsPerPage, name } = params;
        return {
          url: `/espaciosverdes?page=${page}&limit=${rowsPerPage}&name=${name}`,
          method: "GET",
        };
      },
    }),
    getComunas: builder.mutation({
      query: () => {
        return {
          url: `/espaciosverdes/datos/comunas`,
          method: "GET",
        };
      },
    }),
    getBarrios: builder.mutation({
      query: () => {
        return {
          url: `/espaciosverdes/datos/barrios`,
          method: "GET",
        };
      },
    }),
    getEstados: builder.mutation({
      query: () => {
        return {
          url: `/espaciosverdes/datos/estados`,
          method: "GET",
        };
      },
    }),
    getAreas: builder.mutation({
      query: () => {
        return {
          url: `/espaciosverdes/datos/areas`,
          method: "GET",
        };
      },
    }),
    getClases: builder.mutation({
      query: () => {
        return {
          url: `/espaciosverdes/datos/clases`,
          method: "GET",
        };
      },
    }),
    updateEspacioVerde: builder.mutation({
      query: ({ id, espacioVerde }) => ({
        url: "/espaciosverdes/update/" + parseInt(id),
        method: "PUT",
        body: { ...espacioVerde },
      }),
    }),
    updateAreas: builder.mutation({
      query: ({ id, data }) => ({
        url: "/espaciosverdes/updatearea/" + parseInt(id),
        method: "PUT",
        body: { ...data },
      }),
    }),
    getAreasEspacioVerde: builder.mutation({
      query: (id) => {
        return {
          url: `/espaciosverdes/areasespacioverde/get?id=${id}`,
          method: "GET",
        };
      },
    }),
    getEspaciosOrdenadosPorCercania: builder.mutation({
      query: (data) => {
        return {
          url: `/espaciosverdes/ordenado/cercania?latitude=${data.latitude}&longitude=${data.longitude}&cantRows=${data.rowsPerPage}&page=${data.page}&name=${data.espacio}`,
          method: "GET",
        };
      },
    }),
    getObrasEnEspacio: builder.mutation({
      query: (id) => {
        return {
          url: `/espaciosverdes/getobrasdeespacio/${id}`,
          method: "GET",
        };
      },
    }),
    createEspacioVerde: builder.mutation({
      query: (body) => {
        return {
          url: `/espaciosverdes/crearEspacio`,
          method: "POST",
          body: body,
        };
      },
    }),
    createAreaEspacio: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/espaciosverdes/crearArea/${id}`,
          method: "POST",
          body: body,
        };
      },
    }),
    getAllTipoEspacio: builder.mutation({
      query: () => {
        return {
          url: `/espaciosverdes/datos/tipoespacio`,
          method: "GET",
        };
      },
    }),
    getAllTipoRiegos: builder.mutation({
      query: () => {
        return {
          url: `/espaciosverdes/datos/tiporiegos`,
          method: "GET",
        };
      },
    }),
    getAllEmpresas: builder.mutation({
      query: () => {
        return {
          url: `/espaciosverdes/datos/empresas`,
          method: "GET",
        };
      },
    }),
    getAllCuadrantes: builder.mutation({
      query: () => {
        return {
          url: `/espaciosverdes/datos/cuadrantes`,
          method: "GET",
        };
      },
    }),
    getAllAtributesOfEEVV: builder.mutation({
      query: () => {
        return {
          url: `/espaciosverdes/datos/alldatos`,
          method: "GET",
        };
      },
    }),
    getAllPoligonos: builder.mutation({
      query: () => ({
        url: `/espaciosverdes/datos/poligonos`,
        method: "GET",
      }),
    }),
    getAllAtributesOfAreas: builder.mutation({
      query: () => ({
        url: `/espaciosverdes/datos/atributosdeareas`,
        method: "GET",
      }),
    }),
    deleteEspacio: builder.mutation({
      query: (id) => ({
        url: `/espaciosverdes/deleteespacio/${id}`,
        method: "DELETE",
      }),
    }),
    getCantidadDeAreas: builder.mutation({
      query: (id) => ({
        url: `/espaciosverdes/cantidadareas/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetEspacioVerdeMutation,
  useGetAllEspaciosMutation,
  useGetComunasMutation,
  useGetBarriosMutation,
  useGetEstadosMutation,
  useGetAreasMutation,
  useGetClasesMutation,
  useUpdateEspacioVerdeMutation,
  useGetAreasEspacioVerdeMutation,
  useGetEspaciosOrdenadosPorCercaniaMutation,
  useGetObrasEnEspacioMutation,
  useCreateAreaEspacioMutation,
  useCreateEspacioVerdeMutation,
  useGetAllEmpresasMutation,
  useGetAllTipoEspacioMutation,
  useGetAllTipoRiegosMutation,
  useGetAllPoligonosMutation,
  useGetAllCuadrantesMutation,
  useGetAllAtributesOfAreasMutation,
  useDeleteEspacioMutation,
  useUpdateAreasMutation,
  useGetAllAtributesOfEEVVMutation,
  useGetCantidadDeAreasMutation,
} = espaciosApiSlice;
