import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { ButtonIcon, Error, Loading } from "../../../elementos";
import {
  useGetInformeOFEEVVMutation,
  useGetPeriodosEditablesMutation,
} from "../../../../store/api/endpoints/informesApiSlice";
import { Link, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import HistoryIcon from "@mui/icons-material/History";
import "./index.css";
import { useGetObrasEnEspacioMutation } from "../../../../store/api/endpoints/espaciosApiSlice";
import { useWindowSize } from "../../../../hooks/hookResize";
import BasicModal from "./ModalSelectPeriodo";
import { useDispatch } from "react-redux";
import { openFeedback } from "../../../../store/slices/feedback";

export default function BasicCard({ espacioVerde }) {
  const size = useWindowSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [enObra, setenObra] = React.useState(false);
  const [getInforme, responseInforme] = useGetInformeOFEEVVMutation();
  const [getObrasEspacio, responseObrasEspacio] = useGetObrasEnEspacioMutation();
  const [getSemanasEditables, responseSemanasEditables] = useGetPeriodosEditablesMutation();
  const [modalPeriodos, setmodalPeriodos] = React.useState(false);

  const handleValidateInforme = () => {
    getSemanasEditables();
  };

  /**
   *
   * @param {*} id
   * @param {*} periodo
   * @call to api
   */
  const handleCreate = (id, periodo) => {
    getInforme({
      id,
      mes_codigo: periodo,
    });
  };

  React.useEffect(() => {
    getObrasEspacio(espacioVerde.id_espacio_verde);
  }, []);

  React.useEffect(() => {
    if (responseSemanasEditables.isSuccess) {
      if (responseSemanasEditables?.data.length > 1) {
        setmodalPeriodos(true);
      } else {
        if(!responseSemanasEditables?.data[0]){
          dispatch(
            openFeedback({
              severity: "error",
              message: `No existe una semana cargada.`,
            })
          );
          return;
        }else{
          handleCreate(espacioVerde.id_espacio_verde, responseSemanasEditables?.data[0]);
        }
      }
    }
  }, [responseSemanasEditables.isSuccess]);

  React.useEffect(() => {
    if (responseInforme.isSuccess) {
      navigate(`/inspecciones/nuevo?id=${responseInforme?.data?.id}&updating=true`);
    }
    if (responseObrasEspacio.isSuccess) {
      let getObras = { ...responseObrasEspacio?.data?.areas };
      setenObra(Object.values(getObras).some((el) => el === true || el > 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseInforme.isSuccess, responseObrasEspacio.isSuccess]);

  if (responseInforme.isLoading || responseSemanasEditables.isLoading) {
    return <Loading />;
  }

  if (responseInforme.isError) {
    return <Error />;
  }

  return (
    <Card
      sx={{
        height: "130px",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
        border: "0.4px solid grey",
      }}
    >
      <Link to={`/inspecciones/espacio?id=${espacioVerde.id_espacio_verde}`} style={{ width: "100%" }}>
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            paddingLeft: "10px",
            paddingTop: "2px",
            paddingBottom: 0,
          }}
        >
          <Typography variant="b" color={"black"} textAlign={"left"} sx={{ fontWeight: "bold", fontSize: 16 }}>
            {espacioVerde?.nombre_eevv}
          </Typography>
          <Typography variant="body2" sx={{ color: "black" }} textAlign={"left"}>
            {espacioVerde?.codigo}
          </Typography>
          <Typography color="text.primary" textAlign={"left"} sx={{ fontWeight: 500, color: "black" }}>
            Espacio en obra: {enObra ? "Si" : "No"}
          </Typography>
        </CardContent>
      </Link>
      <CardActions
        style={{
          gap: 10,
          width: "35%",
          bottom: 0,
          display: "flex",
          flexDirection: "column",
          marginRight: "5px",
          justifyContent: "space-evenly",
        }}
        className="containerBtnInspecciones"
      >
        <ButtonIcon
          noLink
          handleClick={() => navigate(`/inspecciones/detalles?id=${espacioVerde.id_espacio_verde}`)}
          variant="outlined"
          color="success"
          size="small"
          styleButton={{ minWidth: "60px" }}
          startIcon={<HistoryIcon />}
        >
          {size.width > 600 && "Historial"}
        </ButtonIcon>
        {modalPeriodos && (
          <BasicModal
            semanasEditables={responseSemanasEditables?.data}
            handleFunction={handleCreate}
            idEspacio={espacioVerde.id_espacio_verde}
            setmodalPeriodos={setmodalPeriodos}
          />
        )}

        <ButtonIcon
          size="small"
          variant="outlined"
          styleButton={{ minWidth: "60px", marginLeft: "0px" }}
          noLink
          startIcon={<AddIcon />}
          handleClick={() => handleValidateInforme()}
        >
          {size.width > 600 && "Crear"}
        </ButtonIcon>
      </CardActions>
    </Card>
  );
}
