import { tiendasApi } from '../api'

export const usuariosApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getUsuarios: builder.mutation({
            query: (params) => ({
                url: `/usuarios${params}`,
                method: 'GET',
            })
        }),
        getAllNamesUsers: builder.mutation({
            query: (params) => ({
                url: `/usuarios/getAllNames`,
                method: 'GET',
            })
        }),
        getUsuario: builder.mutation({
            query: (id) => ({
                url: '/usuarios/' + id,
                method: 'GET',
            })
        }),
        createUsuario: builder.mutation({
            query: (body) => ({
                url: '/usuarios',
                method: 'POST',
                body: body,
            })
        }),
        updateUsuario: builder.mutation({
            query(data){
                const { id, body } = data
                return {
                    url: '/usuarios/' + id,
                    method: 'PUT',
                    body: body,
                }
            }
        }),
    })
})

export const {
    useGetUsuariosMutation,
    useGetUsuarioMutation,
    useCreateUsuarioMutation,
    useUpdateUsuarioMutation,
    useGetAllNamesUsersMutation
} = usuariosApiSlice