import React from "react";
import { InfoEspacio } from "./InfoEspacio";
import { Container } from "@mui/material";

function MapaScreen() {
  return (
    <Container component={"main"} maxWidth="lg">
      <InfoEspacio />
    </Container>
  );
}

export default MapaScreen;
