import React, { useEffect, useState } from "react";
import Limpieza from "../Areas/Limpieza";
import Cesped from "../Areas/Cesped";
import Canteros from "../Areas/Canteros";
import PatioDeJuegos from "../Areas/PatiosDeJuegos";
import Caniles from "../Areas/Caniles";
import Mobiliario from "../Areas/Mobiliario";
import ObservacioensGenerales from "../Areas/ObservacioensGenerales";
import PlayonDeportivo from "../Areas/PlayonDeportivo";
import PostaAerobica from "../Areas/PostaAerobica";
import { Desplegable, Divider, Input, Select } from "../../../../elementos";
import { Container, Grid } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  getErrorsOfAreas,
  getInformeFull,
  getTieneAreas,
  updateErrorOfInforme,
  updateInformeFull,
} from "../../../../../store/slices/informes";
import { getErroresAreas } from "../../../../../utils/createObjErrorAreas";

const BodyCreateInspecciones = ({ semana, editable, id }) => {
  const dispatch = useDispatch();
  const informeStore = useSelector(getInformeFull, shallowEqual);

  const handleChange = (name, value) => {
    let informeTemp = JSON.stringify(informeStore);
    informeTemp = JSON.parse(informeTemp);
    if (name === "en_obra") {
      informeTemp[`${semana}`][`${name}`] = value === 0 ? true : false;
    } else {
      informeTemp[`${semana}`][`${name}`] = value;
    }
    dispatch(updateInformeFull(informeTemp));
  };
  const [inspeccion, setInpeccion] = useState({
    fecha_relevamiento: "",
    en_obra: false,
  });
  const errores = useSelector(getErrorsOfAreas, shallowEqual);
  const areas = useSelector(getTieneAreas, shallowEqual);
  const tamañoGrid = editable && !inspeccion?.fecha_relevamiento;
  const [option, setoption] = useState();

  useEffect(() => {
    if (informeStore) {
      setInpeccion({
        fecha_relevamiento: informeStore[`${semana}`]?.fecha_relevamiento,
        en_obra: informeStore[`${semana}`]?.en_obra ? informeStore[`${semana}`]?.en_obra : false,
      });
    }
  }, [informeStore]);

  useEffect(() => {
    if (informeStore) {
      let ObjectError = getErroresAreas(areas, informeStore[`${semana}`], semana);
      dispatch(updateErrorOfInforme(ObjectError));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [informeStore, semana]);
  return (
    <Container component={"main"} maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={tamañoGrid ? 11 : 12} md={tamañoGrid ? 5 : 6}>
          <Input
            disabled={!editable}
            type="date"
            label="Fecha de relevamiento"
            value={informeStore[`${semana}`]?.fecha_relevamiento}
            restricciones={["fecha"]}
            onChange={(e) => handleChange("fecha_relevamiento", e)}
          />
        </Grid>
        {tamañoGrid && (
          <Grid item xs={1} md={1} sx={{ display: "flex", justifyContent: "center" }}>
            <Grid
              item
              xs={1}
              md={1}
              sx={{ display: "flex", alignContent: "center", justifyContent: "center", alignItems: "center" }}
            >
              <ErrorIcon style={{ fill: "red" }} />
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Select
            defaultValue=""
            value={informeStore[`${semana}`]?.en_obra=== false ? 1 : 0}
            label="¿Espacio completo en Obra?"
            options={[
              { label: "Si", value: 0 },
              { label: "No", value: 1 },
            ]}
            disabled={!editable}
            onChange={(e) => handleChange("en_obra", e)}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Divider />
          <br />
        </Grid>
        <Grid item xs={12} md={12}>
          <Desplegable
            text="Limpieza"
            setoption={setoption}
            option={option}
            toggleDefault={true}
            children={<Limpieza id={id} semana={semana} editable={editable} />}
            icon={
              errores?.erroresInAreas?.limpieza ? (
                <ErrorIcon style={{ fill: "red" }} />
              ) : (
                <CheckCircleIcon style={{ fill: "green" }} />
              )
            }
            name="limpieza"
          />
        </Grid>
        {areas && areas?.tiene_cesped && (
          <Grid item xs={12} md={12}>
            <Desplegable
              text="Cesped"
              setoption={setoption}
              option={option}
              toggleDefault={true}
              children={<Cesped id={id} semana={semana} editable={editable} />}
              icon={
                errores?.erroresInAreas?.cesped ? (
                  <ErrorIcon style={{ fill: "red" }} />
                ) : (
                  <CheckCircleIcon style={{ fill: "green" }} />
                )
              }
              name="cesped"
            />
          </Grid>
        )}
        {areas && areas?.tiene_canteros && (
          <Grid item xs={12} md={12}>
            <Desplegable
              text="Canteros"
              setoption={setoption}
              option={option}
              toggleDefault={true}
              children={<Canteros id={id} semana={semana} editable={editable} />}
              icon={
                errores?.erroresInAreas?.canteros ? (
                  <ErrorIcon style={{ fill: "red" }} />
                ) : (
                  <CheckCircleIcon style={{ fill: "green" }} />
                )
              }
              name="canteros"
            />
          </Grid>
        )}
        {areas?.tiene_patio && (
          <Grid item xs={12} md={12}>
            <Desplegable
              text="Patios de juegos"
              setoption={setoption}
              option={option}
              toggleDefault={true}
              children={<PatioDeJuegos id={id} semana={semana} editable={editable} />}
              icon={
                errores?.erroresInAreas?.patioJuegos ? (
                  <ErrorIcon style={{ fill: "red" }} />
                ) : (
                  <CheckCircleIcon style={{ fill: "green" }} />
                )
              }
              name="patioJuegos"
            />
          </Grid>
        )}
        {areas?.tiene_canil && (
          <Grid item xs={12} md={12}>
            <Desplegable
              text="Caniles"
              setoption={setoption}
              option={option}
              toggleDefault={true}
              children={<Caniles id={id} semana={semana} editable={editable} />}
              icon={
                errores?.erroresInAreas?.caniles ? (
                  <ErrorIcon style={{ fill: "red" }} />
                ) : (
                  <CheckCircleIcon style={{ fill: "green" }} />
                )
              }
              name="caniles"
            />
          </Grid>
        )}
        {areas?.tiene_postas && (
          <Grid item xs={12} md={12}>
            <Desplegable
              text="Posta aerobica"
              setoption={setoption}
              option={option}
              toggleDefault={true}
              children={<PostaAerobica id={id} semana={semana} editable={editable} />}
              icon={
                errores?.erroresInAreas?.PostaAerobica ? (
                  <ErrorIcon style={{ fill: "red" }} />
                ) : (
                  <CheckCircleIcon style={{ fill: "green" }} />
                )
              }
              name="postas"
            />
          </Grid>
        )}
        {areas?.tiene_deportiva && (
          <Grid item xs={12} md={12}>
            <Desplegable
              text="Playon deportivo"
              setoption={setoption}
              option={option}
              toggleDefault={true}
              children={<PlayonDeportivo id={id} semana={semana} editable={editable} />}
              icon={
                errores?.erroresInAreas?.playonDeportivo ? (
                  <ErrorIcon style={{ fill: "red" }} />
                ) : (
                  <CheckCircleIcon style={{ fill: "green" }} />
                )
              }
              name="deportiva"
            />
          </Grid>
        )}
        {areas?.tiene_mobiliario && (
          <Grid item xs={12} md={12}>
            <Desplegable
              text="Mobiliario"
              setoption={setoption}
              option={option}
              toggleDefault={true}
              children={<Mobiliario id={id} semana={semana} editable={editable} />}
              icon={
                errores?.erroresInAreas?.mobiliario ? (
                  <ErrorIcon style={{ fill: "red" }} />
                ) : (
                  <CheckCircleIcon style={{ fill: "green" }} />
                )
              }
              name="mobiliario"
            />
          </Grid>
        )}
        <Grid item xs={12} md={12}>
          <Desplegable
            text="Observaciones Generales"
            setoption={setoption}
            option={option}
            toggleDefault={true}
            children={<ObservacioensGenerales id={id} semana={semana} editable={editable} />}
            icon={
              errores?.erroresInAreas?.observacionesGenerales ? (
                <ErrorIcon style={{ fill: "red" }} />
              ) : (
                <CheckCircleIcon style={{ fill: "green" }} />
              )
            }
            name="observacionesGenerales"
          />
        </Grid>
      </Grid>
      <Divider />
    </Container>
  );
};

export default BodyCreateInspecciones;
