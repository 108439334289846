/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetClienteMutation,
  useDeleteUserMutation,
  useUpdateContactoClienteMutation,
} from "../../../store/api/endpoints/clientesApiSlice";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { Error, DisplayInfoUl, Loading, ButtonIcon, ConfirmDelete, Volver, HeaderVista } from "../../elementos/";

import "../../../assets/css/vistaDetalleList.css";

import { useDispatch } from "react-redux";
import { openFeedback } from "../../../store/slices/feedback";
import { useGetAllPoligonosMutation } from "../../../store/api/endpoints/espaciosApiSlice";
import { useGetAllPerfilesMutation } from "../../../store/api/endpoints/perfilesApiSlice";

import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import EditIcon from "@mui/icons-material/Edit";

export const Cliente = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const id = searchParams.get("id");
  const dispatch = useDispatch();
  const [data, setData] = useState({
    poligonos: "",
    perfil: "",
  });
  const [getCliente, responseCliente] = useGetClienteMutation();
  const [deleteUser, responseDelete] = useDeleteUserMutation();
  const [updateUsuario, responseUpdate] = useUpdateContactoClienteMutation();
  const [getAllPoligonos, responsePoligonos] = useGetAllPoligonosMutation();
  const [getAllPerfiles, responsePerfiles] = useGetAllPerfilesMutation();
  const [estadoCliente, setEstadoCliente] = useState();

  const handleActivate = () => {
    let cliente = { ...responseCliente?.data?.usuario, activo: 1 };
    delete cliente.codigo;
    if (cliente) {
      updateUsuario({ id, cliente });
    }
  };

  useEffect(() => {
    if (responseDelete.isSuccess || responseUpdate.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: responseDelete?.data?.message || responseUpdate?.data?.message,
        })
      );
      navigate("/configuracio/usuarios");
    }

    if (responseDelete.isError || responseUpdate.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: responseDelete?.data?.message || responseUpdate?.error?.data?.message,
        })
      );
    }
  }, [responseDelete.isError, responseUpdate.isError, responseDelete.isSuccess, responseUpdate.isSuccess]);

  useEffect(() => {
    Promise.all([getCliente(id), getAllPoligonos(), getAllPerfiles()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let poligonos;
    let perfil;
    if (responseCliente.isSuccess) {
      if (responsePoligonos.isSuccess) {
        setEstadoCliente(responseCliente?.data?.usuario.activo === 1 ? "ACTIVO" : "INACTIVO");
        poligonos = responseCliente?.data?.poligonos
          .map((el) => {
            const option = responsePoligonos?.data?.find((value) => el === value.codigo);
            return option ? option?.nombre : null;
          })
          .filter((label) => label)
          .join(", ");
      }
      if (responsePerfiles.isSuccess) {
        perfil = responsePerfiles?.data?.perfiles?.find((perfil) => {
          return perfil.codigo === responseCliente?.data?.usuario?.perfil;
        });
      }
    }
    setData((data) => ({
      ...data,
      poligonos,
      perfil: perfil?.nombre,
    }));
  }, [responsePoligonos.isSuccess, responseCliente.isSuccess, responsePerfiles.isSuccess]);

  useEffect(() => {
    if (responseCliente?.isError || responsePoligonos.isError)
      dispatch(
        openFeedback({
          severity: "error",
          message: "Error al obtener los datos.",
        })
      );
  }, [responseCliente?.isError, responsePoligonos.isError]);

  if (
    responseCliente.isLoading ||
    responsePoligonos.isLoading ||
    responsePerfiles.isLoading ||
    responseUpdate.isLoading ||
    responseDelete.isLoading
  ) {
    return <Loading />;
  }
  if (responseCliente.isError || responsePoligonos.isError || responsePerfiles.isError) return <Error />;

  if (responseCliente.isSuccess && responsePoligonos.isSuccess)
    return (
      <>
        <HeaderVista
          start={<Volver to="/configuracion/usuarios" label="Volver" />}
          titulo={"Ficha de " + responseCliente?.data?.usuario.nombre + " " + responseCliente?.data?.usuario.apellido}
          end={
            <>
              <ButtonIcon
                variant="outlined"
                size="small"
                color="dark"
                startIcon={<EditIcon />}
                to={`/configuracion/usuario/editar?id=${id}`}
                handleClick={false}
              >
                Editar
              </ButtonIcon>
              {responseCliente?.data?.usuario.activo === 1 ? (
                <ConfirmDelete
                  id={id}
                  slice={deleteUser}
                  response={responseDelete}
                  returnUrl={"/configuracion/usuarios"}
                  variant="outlined"
                  size="small"
                />
              ) : (
                <ButtonIcon
                  variant="outlined"
                  size="small"
                  noLink
                  color="success"
                  startIcon={<PlayCircleOutlineIcon />}
                  handleClick={handleActivate}
                >
                  Activar
                </ButtonIcon>
              )}
            </>
          }
        />
        <Container component="main" maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <DisplayInfoUl
                data={[
                  {
                    index: "Nombre",
                    value: responseCliente?.data?.usuario.nombre,
                  },
                  {
                    index: "Apellido",
                    value: responseCliente?.data?.usuario.apellido,
                  },
                  {
                    index: "Usuario",
                    value: responseCliente?.data?.usuario.usuario,
                  },
                  {
                    index: "Email",
                    value: responseCliente?.data?.usuario.email,
                  },
                  {
                    index: "Telefono",
                    value: responseCliente?.data?.usuario.telefono,
                  },
                  { index: "Perfil", value: data.perfil },
                  {
                    index: "Poligonos",
                    value: data.poligonos ? data.poligonos : ["No tiene asignado ningun poligono"],
                  },
                  { index: "Estado", value: estadoCliente },
                ]}
              />
            </Grid>
          </Grid>
        </Container>
        <br />
      </>
    );
  if (responseCliente.isError || responsePoligonos.isSuccess) {
    return <Error message={"Error al obtener los datos."} />;
  } else {
    return <h2>Ocurrio un error.</h2>;
  }
};
